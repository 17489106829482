import styled from 'styled-components'
import Text from 'components/Text'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'
import React from 'react'

const NftBody = styled.div`
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c2c8d5;
  }
`

const PropertyContainer = styled.div`
  padding: 10px 0;
`
const StatName = styled(Text)`
  font-weight: 700;
  font-size: 18px;
`
const StatValue = styled(Text)`
  font-weight: 400;
  font-size: 25px;
`
const StatColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const PropertyRow = styled.div`
  display: flex;
  justify-content: space-between;
`
const Divider = styled.div`
  height: 3px;
  width: 100%;
  background-color: #41393e;
`
const SkillContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
`
const SkillRow = styled.div`
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 20px;
`
const SkillIcon = styled.div`
  flex: 1;
`
const SkillDetail = styled.div`
  flex: 5;
`
const SkillStatus = styled(Text)`
  margin-top: 0px;
  font-size: 20px;
  color: #ff982e;
`
const SkillTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const EllipsisAddress = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
`
const SkillName = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: #9ce315;
`
const SkillDescription = styled(Text)`
  font-weight: 700;
  font-size: 23px;
`
const Title = styled(Text)`
  color: #b29210;
  font-weight: 700;
  font-size: 20px;
  margin: 10px 0 0;
`
const Property = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`

const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const Icon = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 100px;
`

const PropertyTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #9f9f9f;
`

const PropertyContent = styled.td`
  border: 1px solid #9f9f9f;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-size: 16px;
`

const PropertyLabel = styled.td`
  border: 1px solid #9f9f9f;
  padding: 5px;
  text-align: left;
  color: #68a425;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
`

export default function ItemDetail({ nft }: { nft: any }) {
  if (!nft) return null
  const rarityName = getHeroRarity(nft.rarity)
  const className = getHeroClassName(nft.classType)
  return (
    <NftBody>
      <Title>ABOUT</Title>
      <PropertyContainer>
        <PropertyTable>
          <tr>
            <PropertyLabel>Rarity</PropertyLabel>
            <PropertyContent>{rarityName}</PropertyContent>
          </tr>
          <tr>
            <PropertyLabel>Class</PropertyLabel>
            <PropertyContent>{className}</PropertyContent>
          </tr>
        </PropertyTable>
      </PropertyContainer>
      <Title>STATS</Title>
      <PropertyContainer>
        <PropertyTable>
          <tr>
            <PropertyLabel style={{ textAlign: 'center', width: '33%' }}>AS</PropertyLabel>
            <PropertyLabel style={{ textAlign: 'center', width: '33%' }}>ATK</PropertyLabel>
            <PropertyLabel style={{ textAlign: 'center', width: '33%' }}>DEF</PropertyLabel>
          </tr>
          <tr>
            <PropertyContent>{nft && nft.stats.AS}</PropertyContent>
            <PropertyContent>{nft && nft.stats.ATK}</PropertyContent>
            <PropertyContent>{nft && nft.stats.DEF}</PropertyContent>
          </tr>
          <tr>
            <PropertyLabel style={{ textAlign: 'center', width: '33%' }}>FR</PropertyLabel>
            <PropertyLabel style={{ textAlign: 'center', width: '33%' }}>HP</PropertyLabel>
            <PropertyLabel style={{ textAlign: 'center', width: '33%' }}>MANA</PropertyLabel>
          </tr>
          <tr>
            <PropertyContent>{nft && nft.stats.FR}</PropertyContent>
            <PropertyContent>{nft && nft.stats.HP}</PropertyContent>
            <PropertyContent>{nft && nft.stats.MANA}</PropertyContent>
          </tr>
        </PropertyTable>
      </PropertyContainer>
      <Title>DESCRIPTION</Title>
      <Text fontSize={14} margin="10px 0px 0px 0px">
        {nft && nft.description}
      </Text>
    </NftBody>
  )
}
