const server = localStorage.getItem('server')

export const HERO_MARKET_SERVER = server
  ? server === 's1'
    ? 'https://s1.plantempires.io'
    : 'https://s2.plantempires.io'
  : 'https://s1.plantempires.io'
export const PEFI_API = server
  ? server === 's1'
    ? 'https://pefi-claim.bravechain.net'
    : 'https://pefi-claim-s2.plantempires.io'
  : 'https://pefi-claim.bravechain.net'
export const ROFI_API = 'https://api.rofi.games'

export const CHEST_ENDPOINT = {
  NFTS: HERO_MARKET_SERVER + '/plant-empire/boxes',
  PIECES: HERO_MARKET_SERVER + '/plant-empire/chest-pieces',
  OPENCHEST: HERO_MARKET_SERVER + '/plant-empire/heroes/open-box',
}

export const PLANT_ENDPOINT = {
  NFTS: HERO_MARKET_SERVER + '/plant-empire/heroes',
  ORDERS: HERO_MARKET_SERVER + '/plant-empire/orders/heroes',
  STATISTICS: HERO_MARKET_SERVER + '/plant-empire/statistics/heroes',
  TRANSACTIONS: HERO_MARKET_SERVER + '/plant-empire/transactions/heroes?page=',
  EVOLVE: PEFI_API + '/api/plant/upgradeStar',
  FUSION_AVAILABLE: HERO_MARKET_SERVER + '/plant-empire/game/heros/listForFusion?owner=',
  NFT_DETAIL: HERO_MARKET_SERVER + '/plant-empire/game/heros',
}

export const ORBS_ENDPOINT = {
  INGAME: PEFI_API + '/api/orbs/',
  MINT: PEFI_API + '/api/orbs/mint',
  ORDERS: HERO_MARKET_SERVER + '/plant-empire/orders/orbs',
  STATISTICS: HERO_MARKET_SERVER + '/plant-empire/statistics/orbs',
  TRANSACTIONS: HERO_MARKET_SERVER + '/plant-empire/transactions/orbs?page=',
  EVOLVE: PEFI_API + '/api/orb/upgradeStar',
  FUSION_AVAILABLE: HERO_MARKET_SERVER + '/plant-empire/game/orbs/listForFusion?owner=',
}

export const HOLY_ENDPOINT = {
  INGAME: PEFI_API + '/api/holy/',
  MINT: PEFI_API + '/api/holy/mint',
  NFTS: HERO_MARKET_SERVER + '/plant-empire/holys',
  ORDERS: HERO_MARKET_SERVER + '/plant-empire/orders/holies',
  STATISTICS: HERO_MARKET_SERVER + '/plant-empire/statistics/holies',
  TRANSACTIONS: HERO_MARKET_SERVER + '/plant-empire/transactions/holies?page=',
  ALLOW_GIFT: PEFI_API + '/api/holy/allowGift',
  GIFT_HOLY: PEFI_API + '/api/holy/giftHoly',
}

export const PIECE_ENDPOINT = {
  INGAME: PEFI_API + '/api/piece/',
  MINT: PEFI_API + '/api/holy/mint',
  NFTS: HERO_MARKET_SERVER + '/plant-empire/pieces',
  ORDERS: HERO_MARKET_SERVER + '/plant-empire/orders/pieces',
  STATISTICS: HERO_MARKET_SERVER + '/plant-empire/statistics/pieces',
  TRANSACTIONS: HERO_MARKET_SERVER + '/plant-empire/transactions/pieces?page=',
}

export const TOKEN_ENDPOINT = {
  GTOKEN_BALANCE: PEFI_API + '/api/wallet',
  CONVERT_REQUEST: PEFI_API + '/api/request',
}

export const SHOP_ENDPOINT = {
  PACKS: HERO_MARKET_SERVER + '/plant-empire/game/gemPacks',
  FARMS: HERO_MARKET_SERVER + '/plant-empire/game/getFarmShopPacks',
}

export const ROFI_ACCOUNT = {
  AUTH: ROFI_API + '/api/v1/users/auth/wallet',
  INFO: ROFI_API + '/api/v1/users/info',
  OTP: ROFI_API + '/api/v1/users/update-email/otp',
  VERIFY: ROFI_API + '/api/v1/users/update-email/verify',
}

export const CHECKIN = {
  CHECKINDAY: HERO_MARKET_SERVER + '/plant-empire/game/checkinDay',
  GET_SIGNATURE: PEFI_API + '/api/checkin',
}

export const MINT_TICKET = {
  MINT_TICKET: PEFI_API + '/api/mintTicket',
}

export const INGAME_ACCOUNT = {
  HAS_ACCOUNT: HERO_MARKET_SERVER + '/plant-empire/game/users/has',
}

export const LUCKY_SPIN = {
  SIGN: 'https://pefi-claim-s2-test.bravechain.net' + '/api/luckySpin/spin',
  TX_SUCCESS: 'https://pefi-claim-s2-test.bravechain.net' + '/api/luckySpin/spin/success',
}

export const HOLY_MIN_PRICE = 5
export const PIECE_MIN_PRICE = 0
