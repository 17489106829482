import React, { useState } from 'react'
import styled from 'styled-components'
import Plants from './Plants'
import Chests from './Chests'
import Orbs from './Orbs'
import Holy from './Holy'
import Footer from 'components/Footer'

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
  background: #222938;
  &.rewards {
    grid-template-columns: 25% 50% 25%;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 100%;
  `}
`
const SideBar = styled.div`
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `}
`
const HeaderNavbar = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`
const NavItem = styled.div`
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-family: Gudea-Medium;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  &.active,
  &:hover {
    background: #14324c;
  }
`

export default function Herofi() {
  const [pageState, setPageState] = useState<string>('heroes')

  const renderPageHeader = (pageState: any) => {
    return (
      <PageContainer>
        <SideBar></SideBar>
        <HeaderNavbar>
          <NavItem className={pageState === 'chests' ? `active` : ''} onClick={() => setPageState('chests')}>
            CHESTS
          </NavItem>
          <NavItem className={pageState === 'heroes' ? `active` : ''} onClick={() => setPageState('heroes')}>
            PLANTS
          </NavItem>
          <NavItem className={pageState === 'orbs' ? `active` : ''} onClick={() => setPageState('orbs')}>
            ORBS
          </NavItem>
          <NavItem className={pageState === 'holy' ? `active` : ''} onClick={() => setPageState('holy')}>
            HOLY
          </NavItem>
        </HeaderNavbar>
      </PageContainer>
    )
  }

  const renderContent = (pageState: any) => {
    if (pageState === 'heroes') {
      return <Plants />
    }

    if (pageState === 'chests') {
      return <Chests />
    }

    if (pageState === 'orbs') {
      return <Orbs />
    }
    if (pageState === 'holy') {
      return <Holy />
    }

    return <Plants />
  }

  return (
    <>
      {renderPageHeader(pageState)}
      {renderContent(pageState)}
    </>
  )
}
