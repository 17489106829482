import React from 'react'
import { useWalletModalToggle } from 'state/application/hooks'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import Text, { MagraText } from 'components/Text'

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 14px;
  padding: 5px 30px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin: 0 auto 10px;
  width: 100%;
`

const UnlockButton = () => {
  const toggleWalletModal = useWalletModalToggle()

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button onClick={toggleWalletModal}>
        <MagraText fontSize={25} fontWeight={600}>
          CONNECT WALLET
        </MagraText>
      </Button>
    </div>
  )
}

export default UnlockButton
