import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Close from 'assets/pefi/special-pack/close-button.png'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #026092;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    height: 100%;
  `}
`

const ModalTitle = styled.div`
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `}
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`

export default function BuyModalContent({
  rightContent,
  onDismiss,
  leftContent,
}: {
  onDismiss: () => void
  leftContent: () => ReactNode | undefined
  rightContent: () => ReactNode | undefined
}) {
  return (
    <Wrapper>
      <ModalTitle>CONFIRMATION</ModalTitle>
      <ModalContent>
        <div>{leftContent()}</div>
      </ModalContent>
      <CloseIcon src={Close} onClick={onDismiss} />
    </Wrapper>
  )
}
