import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import buttonCancel from 'assets/pefi/my-assets/sell-modal/cancel.png'
import buttonSell from 'assets/pefi/my-assets/sell-modal/confirm.png'
import buttonSelling from 'assets/pefi/my-assets/sell-modal/loading.png'
import Preview from 'components/PreviewCard/OrbCardPreview/Preview'
import { Nft } from 'constants/types'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Text from 'components/Text'
import { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { SwapCallbackError } from 'components/MyAssets/styleds'
import Modal from './Modal'
import UpdatePriceModalContent from './UpdatePriceModalContent'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'

const Card = styled.div`
  background-color: transparent;
  overflow: hidden;
  padding: 10px;
  flex: 1;
`
const Divider = styled.div`
  height: 3px;
  width: 100%;
  background-color: #41393e;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const PropertyRow = styled(Row)`
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 0px 13px;
`
const Property = styled.div`
  display: flex;
  align-items: center;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const CardBody = styled.div`
  overflow: hidden;
`
const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
`
const Container = styled.div`
  flex: 1;
  align-items: stretch;
  flex-direction: column;
`
const NftImage = styled.div`
  padding-top: 4px;
  flex: 2;
`
const Action = styled.div`
  width: 100%;
  display: block;
  text-align: center;
`
const Title = styled.p`
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
`
const PriceContainer = styled.div`
  width: 100%;
  padding: 20px;
`
const PriceContent = styled.div`
  padding: 15px;
  border: none;
  border-radius: 6px;
`
const PriceContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00436d;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
`
const PriceInput = styled.input`
  background: transparent;
  width: 152px;
  color: #fff;
  appearance: none;
  font-family: Gudea-Medium;
  font-size: 23px;
  height: 40px;
  border: none;
  outline: none;
  padding: 5px 18px 5px 30px;
  text-align: right;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-ms-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-moz-placeholder {
    font-family: Gudea-Medium;

    ::-moz-placeholder {
      font-family: Gudea-Medium;
    }
`

const rotate = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`

const BUSDToken = styled.img`
  animation: ${rotate} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  margin-right: 5px;
  position: relative;
  top: 2px;
`

const TextWithCurrency = styled.div`
  font-size: 23px;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonSell = styled.img`
  cursor: pointer;
  margin-bottom: 20px;
`

function UpdatePriceItem({
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  onDismiss,
  nft,
  attemptingTxn,
}: {
  onConfirm: (price: string) => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
  onDismiss: () => void
  nft: any
  attemptingTxn: boolean
}) {
  const { nftId, rarity, classType } = nft

  const [price, setPrice] = useState(nft.price)
  const TRADING_FEE = 4 // %
  const yourReceived = useMemo(() => parseFloat(price) && (parseFloat(price) * (100 - TRADING_FEE)) / 100, [price])
  const isEnable = yourReceived > 0

  const rarityName = getHeroRarity(rarity)
  const className = getHeroClassName(classType)
  return (
    <Container>
      <Main>
        <Card>
          <div style={{ display: 'flex', position: 'relative' }}>
            <Preview nft={nft} />
          </div>
          <CardBody>
            <PropertyRow>
              <Property>
                <PropertyName>Rarity:&nbsp;</PropertyName>
                <Text style={{ color: '#9ce315', fontSize: 16 }}>{rarityName}</Text>
              </Property>
              <div style={{ padding: '0 5px' }}>|</div>
              <Property>
                <PropertyName>Class:&nbsp;</PropertyName>
                <Text style={{ color: '#9ce315', fontSize: 16 }}>{className}</Text>
              </Property>
            </PropertyRow>
          </CardBody>
        </Card>
        <PriceContainer>
          <PriceContent>
            <PriceContentRow>
              <PropertyName>Price:&nbsp;</PropertyName>
              <PriceInput
                placeholder="Price"
                type="number"
                defaultValue={nft.price}
                onChange={(event) => setPrice(event.target.value)}
              />
            </PriceContentRow>
            <PriceContentRow>
              <PropertyName>Trading Fee:</PropertyName>
              <Text fontSize={28}>{TRADING_FEE}%</Text>
            </PriceContentRow>
            <PriceContentRow>
              <PropertyName>You&apos;ll Receive:&nbsp;</PropertyName>
              <TextWithCurrency>{isNaN(yourReceived) ? 0 : yourReceived.toFixed(2)} BUSD</TextWithCurrency>
            </PriceContentRow>
          </PriceContent>
          <Action>
            <div style={{ width: '100%' }}>
              <ButtonSell src={buttonCancel} onClick={onDismiss} />
            </div>
            <div style={{ width: '100%' }}>
              {attemptingTxn ? (
                <ButtonSell style={{ cursor: 'not-allowed' }} src={buttonSelling} />
              ) : (
                <>
                  {isEnable ? (
                    <ButtonSell src={buttonSell} onClick={() => onConfirm(price)} />
                  ) : (
                    <ButtonSell src={buttonSell} style={{ cursor: 'not-allowed', opacity: '0.3' }} />
                  )}
                </>
              )}
            </div>
          </Action>
        </PriceContainer>
      </Main>
      {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
    </Container>
  )
}

export default function UpdatePriceModal({
  onConfirm,
  onDismiss,
  sellErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  nft,
}: {
  isOpen: boolean
  originalTrade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent | undefined
  onAcceptChanges: () => void
  onConfirm: (price: string) => void
  sellErrorMessage: ReactNode | undefined
  onDismiss: () => void
  nft: Nft | undefined
}) {
  if (txHash) onDismiss()
  const content = useCallback(() => {
    return nft ? (
      <UpdatePriceItem
        onConfirm={onConfirm}
        disabledConfirm={false}
        swapErrorMessage={sellErrorMessage}
        nft={nft}
        onDismiss={onDismiss}
        attemptingTxn={attemptingTxn}
      />
    ) : null
  }, [onConfirm, sellErrorMessage])

  // text to show while loading
  const pendingText = <Trans>Selling...</Trans>

  const confirmationContent = useCallback(
    () =>
      sellErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={sellErrorMessage} />
      ) : (
        <UpdatePriceModalContent onDismiss={onDismiss} content={content} />
      ),
    [onDismiss, content, sellErrorMessage]
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {confirmationContent()}
    </Modal>
  )
}
