/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import PE_PLANT_HERO_NFT_ABI from 'abis/pe-plant-hero-nft-abi.json'
import PE_PIECE_MARKET_ABI from 'abis/pe-piece-market-abi.json'
import axios from 'axios'
import BoxesFilter from 'components/Filters/ChestFilter/Filter'
import { getDecimalAmount } from 'utils/formatBalance'
import PieceFilter from 'components/Filters/PieceFilter/Filter'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { Modal } from 'antd'
import GiftModal from 'components/MyAssets/Plant/GiftModal'
import SellModal from 'components/MyAssets/Piece/Sell/SellModal'
import UpdatePriceModal from 'components/MyAssets/Piece/Sell/UpdatePriceModal'
import Text from 'components/Text'
import {
  PE_PIECE_NFT_ADDRESS,
  S2_PIECE_NFT_ADDRESS,
  S2_PIECE_MARKET_ADDRESS,
  PE_PIECE_MARKET_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { CHEST_ENDPOINT } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft, PEBoxFilterConditions } from 'constants/types'
import { usePieceMarketContract, usePieceMarketContractS2 } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import includes from 'lodash/includes'
import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import paginate from 'utils/paginate'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import NftGrid from 'pages/AccountPage/MyAssets/PEFI/Plants/NftGrid'
import Pagination from 'pages/AccountPage/MyAssets/PEFI/Pagination'
import UnlockButton from 'pages/AccountPage/MyAssets/UnlockButton'
import DetailBox from './Details'
import Result from './Result'
import { PieceConfig } from 'pages/SpecialPack/GachaBox/piece-config'
import buttonSell from 'assets/pefi/my-assets/sell-button.png'
import buttonUpdate from 'assets/pefi/my-assets/update-price-button.png'
import buttonCancel from 'assets/pefi/my-assets/cancel-button.png'
import buttonCancelling from 'assets/pefi/my-assets/cancelling-button.png'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import BigNumber from 'bignumber.js'
import minus from 'assets/pefi/orbs/minus.png'
import plus from 'assets/pefi/orbs/plus.png'

const NUMBER_PER_PAGE = 12
const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`
const General = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

const NFTArea = styled.div`
  flex: 4;
  padding: 30px;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const HeaderItem = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin-top: 30px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
  `}
`

const RowTwo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
`

const ButtonSell = styled.img`
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const ItemType = styled.div`
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  font-family: Gudea-Medium;
  border: 2px solid #03c5ff;
  padding: 7px 10px 3px;
  border-radius: 10px;
  min-width: 200px;
  color: #fff;
  background: #1b405c;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 0;
    font-size: 20px;
  `}
  &.active {
    border: 2px solid #9ce315;
    background: #9ce315;
  }
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background-color: transparent;
  width: 100%;
  max-width: 100vw;
  &.rewards {
    grid-template-columns: 25% 50% 25%;
  }
  &.main-content {
    ${({ theme }) => theme.mediaWidth.upToMedium`
      grid-template-columns: 100%;
    `}
  }
`

const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 30px 3px;
  max-width: 200px;
  margin: 30px auto 0;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 25%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
  `}
`

const ListHeroContainer = styled.div`
  padding: 25px;
  background: #14324c50;
  border-radius: 10px;
`

const ChestCardContainer = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const GachaBoxPreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const ActionButtons = styled.div`
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 16px;
  height: 35px;
  padding: 7px 15px 5px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
  }
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxName = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #f2c32a;
  font-family: Montio;
`

const BoxId = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

const FilterModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609299 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

const StatusTitle = styled.div`
  margin-bottom: 25px;
  font-size: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default function Piece() {
  // Define state
  const [{ showGift, giftErrorMessage, nftGiftSelected }, setGiftState] = useState<{
    showGift: boolean
    giftErrorMessage: string | undefined
    nftGiftSelected: Nft | undefined
  }>({
    showGift: false,
    giftErrorMessage: undefined,
    nftGiftSelected: undefined,
  })

  const [{ showSell, sellErrorMessage, nftSellSelected }, setSellState] = useState<{
    showSell: boolean
    sellErrorMessage: string | undefined
    nftSellSelected: any | undefined
  }>({
    showSell: false,
    sellErrorMessage: undefined,
    nftSellSelected: undefined,
  })

  const [{ showUpdatePrice, nftUpdateSelected }, setUpdatePriceState] = useState<{
    showUpdatePrice: boolean
    nftUpdateSelected: any | undefined
  }>({
    showUpdatePrice: false,
    nftUpdateSelected: undefined,
  })

  const [orderCanceling, setOrderCanceling] = useState<{
    tokenId: string | undefined
    isCanceling: boolean
  }>({
    tokenId: undefined,
    isCanceling: false,
  })

  const handleSellDismiss = useCallback(() => {
    setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
  }, [sellErrorMessage])

  const handleUpdatePriceDismiss = useCallback(() => {
    setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
  }, [])

  const [{ chestShowDetail, isShowChestDetail }, setShowChestDetails] = useState<{
    chestShowDetail: any
    isShowChestDetail: boolean
  }>({
    chestShowDetail: undefined,
    isShowChestDetail: false,
  })

  const [nfts, setNfts] = useState<any[]>([])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [isShowResult, setIsShowResult] = useState<boolean>(false)
  const [itemsResult, setItemsResult] = useState<any>(null)

  const { chainId, account } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const server = localStorage.getItem('server')

  const marketS1 = usePieceMarketContract()
  const marketS2 = usePieceMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const nftAddress = server
    ? server === 's1'
      ? PE_PIECE_NFT_ADDRESS[56]
      : S2_PIECE_NFT_ADDRESS[56]
    : PE_PIECE_NFT_ADDRESS[56]

  const marketCTFS1 = new web3.eth.Contract(
    PE_PIECE_MARKET_ABI as AbiItem[],
    chainId ? PE_PIECE_MARKET_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )
  const marketCTFS2 = new web3.eth.Contract(
    PE_PIECE_MARKET_ABI as AbiItem[],
    chainId ? S2_PIECE_MARKET_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )

  const marketCTF = server ? (server === 's1' ? marketCTFS1 : marketCTFS2) : marketCTFS1

  useEffect(() => {
    fetchAllPieces(account)
  }, [account])

  const [myNftsData, setMyNftsData] = useState<any[]>([])

  const fetchAllPieces = async (owner: any) => {
    const boxes = await getNftsOfOwner(owner)
    const nfts_updated_price = await updatePrice(boxes)
    const sorted_boxes = nfts_updated_price.sort((a: any, b: any) => a.chestType - b.chestType)
    setMyNftsData(sorted_boxes)
  }

  const getNftsOfOwner = async (owner: any) => {
    const url = `${CHEST_ENDPOINT.PIECES}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      // NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const updatePrice = async (sortedMyNftsData: any) => {
    const array = []
    for (const nft of sortedMyNftsData) {
      const price = await getPriceInMarket(nft)
      const nftRef = {
        ...nft,
        price: price,
      }
      array.push(nftRef)
    }
    return array
  }

  const getPriceInMarket = async (nft: any) => {
    const useCTF = marketCTF
    if (!useCTF || nft.status !== 'Selling') return undefined
    const saleInfo = await useCTF.methods.getSale(nftAddress, Number(nft.tokenId)).call()
    if (!saleInfo) return 0
    return Number(saleInfo.price / 10 ** 18).toFixed(2)
  }

  const [filter, setFilter] = useState<PEBoxFilterConditions>({
    rarityNames: [],
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredOrders = useMemo(() => {
    if (filter.rarityNames.length === 0) return myNftsData
    return myNftsData.filter((order) => {
      return filter.rarityNames.length > 0 ? includes(filter.rarityNames, order.chestType.toString()) : true
    })
  }, [myNftsData, filter, searchValue])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const currentOrderList = useMemo(
    () => paginate(filteredOrders, NUMBER_PER_PAGE, currentPage),
    [filteredOrders, currentPage]
  )

  async function onCancelOrderClick(nftSelected: Nft) {
    console.log('on cancel item')
    const useContract = market
    if (!useContract || !nftSelected) return
    const estimate = useContract.estimateGas.cancelOrder
    const method: (...args: any) => Promise<TransactionResponse> = useContract.cancelOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSelected.tokenId]

    setOrderCanceling({
      tokenId: nftSelected.tokenId,
      isCanceling: true,
    })
    await estimate(...args, {})
      .then((estimatedGasLimit: any) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            await fetchAllPieces(account)
            setTimeout(() => {
              NotificationManager.success('Cancel order success.', 'Success', 2000)
              setOrderCanceling({
                tokenId: nftSelected.tokenId,
                isCanceling: false,
              })
            }, 9000)
          })
          .catch((error) => {
            setOrderCanceling({
              tokenId: nftSelected.tokenId,
              isCanceling: false,
            })
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error: any) => {
        setOrderCanceling({
          tokenId: nftSelected.tokenId,
          isCanceling: false,
        })
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      setNfts(currentOrderList)
    })()
  }, [currentOrderList])

  const NftList = ({ nfts }: { nfts: Nft[] }) => {
    if (nfts.length === 0) {
      return (
        <EmptyNft>
          <div>No pieces available.</div>
        </EmptyNft>
      )
    }

    const available = nfts.filter((n: any) => n.status === 'Available')
    const selling = nfts.filter((n: any) => n.status === 'Selling')

    const Render_container = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
      const [show_this_nfts, set_show_this_nfts] = useState<boolean>(true)
      const [currentPage, setCurrentPage] = useState<number>(1)
      const currentOrderList = useMemo(() => paginate(nfts, NUMBER_PER_PAGE, currentPage), [nfts, currentPage])
      return (
        <ListHeroContainer>
          <StatusTitle>
            {title}
            <img
              style={{ cursor: 'pointer' }}
              src={show_this_nfts ? minus : plus}
              onClick={() => set_show_this_nfts(!show_this_nfts)}
            />
          </StatusTitle>
          {show_this_nfts && (
            <>
              <NftGrid>
                {currentOrderList.map((nft: any, index) => {
                  return (
                    <div key={index}>
                      <ChestCard nft={nft} />
                    </div>
                  )
                })}
              </NftGrid>
              <General>
                <TabWrapper>
                  {nfts.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      onChange={setCurrentPage}
                      totalPage={Math.ceil(nfts.length / NUMBER_PER_PAGE)}
                    />
                  )}
                </TabWrapper>
              </General>
            </>
          )}
        </ListHeroContainer>
      )
    }

    return (
      <>
        {available.length > 0 && <>{Render_container({ nfts: available, title: 'NFTs Available' })}</>}
        {selling.length > 0 && <>{Render_container({ nfts: selling, title: 'NFTs Selling' })}</>}
      </>
    )
  }

  const renderPieceState = (nft: any) => {
    const { status, price } = nft
    switch (status) {
      case 'Available':
        return (
          <Row style={{ justifyContent: 'center' }}>
            <ButtonSell
              src={buttonSell}
              onClick={() => {
                // NotificationManager.info('Coming soon.', 'Feature', 2000)
                setSellState({
                  sellErrorMessage: undefined,
                  showSell: true,
                  nftSellSelected: nft,
                })
              }}
            />
          </Row>
        )
      case 'Selling':
        return (
          <>
            <SellingStatus>
              <Text fontSize={20} fontWeight={700} color={'#000'}>
                {price && <span>{price} BUSD</span>}
              </Text>
            </SellingStatus>
            <RowTwo style={{ marginTop: 10 }}>
              <ButtonSell
                src={buttonUpdate}
                onClick={() => {
                  if (nft.isOnOldMarket) {
                    return
                  }
                  setUpdatePriceState({
                    showUpdatePrice: true,
                    nftUpdateSelected: nft,
                  })
                }}
                className={nft.isOnOldMarket ? 'disable' : ''}
                style={{ width: '100%' }}
              />
              {orderCanceling.tokenId === nft.tokenId && orderCanceling.isCanceling ? (
                <ButtonSell src={buttonCancelling} style={{ width: '100%' }} />
              ) : (
                <ButtonSell src={buttonCancel} style={{ width: '100%' }} onClick={() => onCancelOrderClick(nft)} />
              )}
            </RowTwo>
          </>
        )
      default:
        return null
    }
  }

  async function onSellConfirm(price: string) {
    console.log('on sell item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftSellSelected) return
    const method: (...args: any) => Promise<TransactionResponse> = market.placeOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSellSelected.tokenId, decimal_price]

    setAttemptingTxn(true)
    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        console.log('txHash', txFull.transactionHash)
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        await fetchAllPieces(account)
        setTimeout(() => {
          NotificationManager.success('Sell Piece success.', 'Success', 2000)
          setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
          setAttemptingTxn(false)
        }, 9000)
      })
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
      })
  }

  const ChestCard = ({ nft }: any) => {
    const box = PieceConfig.find((b: any) => b.box_id === nft.chestType)
    if (!box) return null
    return (
      <ChestCardContainer>
        <Container>
          <BoxName>{box.name}</BoxName>
          <GachaBoxPreview src={box.img} />
          <BoxId>#{nft.tokenId}</BoxId>
        </Container>
        {!account ? (
          <ActionButtons>
            <UnlockButton />
          </ActionButtons>
        ) : undefined}
        {renderPieceState(nft)}
      </ChestCardContainer>
    )
  }

  async function onUpdatePriceConfirm(price: string) {
    console.log('on update price item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftUpdateSelected) return
    const estimate = market.estimateGas.updatePrice
    const method: (...args: any) => Promise<TransactionResponse> = market.updatePrice
    const args: Array<string | string[] | number> = [nftAddress, nftUpdateSelected.tokenId, decimal_price]
    setAttemptingTxn(true)
    await estimate(...args, {})
      .then((estimatedGasLimit: any) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            await fetchAllPieces(account)
            setTimeout(() => {
              NotificationManager.success('Update price success.', 'Success', 2000)
              setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
              setAttemptingTxn(false)
            }, 9000)
          })
          .catch((error) => {
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error: any) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const renderModals = () => {
    return (
      <>
        <FilterModal
          title="FILTER"
          visible={showFilterModal}
          onCancel={() => setShowFilterModal(false)}
          footer={null}
          className="select-server"
        >
          <div style={{ padding: '0 30px 30px', margin: 'auto', width: '100%' }}>
            <PieceFilter
              id="filter-dialog"
              onConfirm={(newFilter: PEBoxFilterConditions) => {
                setFilter(newFilter)
                setShowFilterModal(false)
              }}
              onCancel={() => {
                setShowFilterModal(false)
              }}
            />
          </div>
        </FilterModal>

        <SellModal
          nft={nftSellSelected}
          isOpen={showSell}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onSellConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleSellDismiss}
        />
        <UpdatePriceModal
          nft={nftUpdateSelected}
          isOpen={showUpdatePrice}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onUpdatePriceConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleUpdatePriceDismiss}
        />
      </>
    )
  }

  return (
    <PageContainer className="main-content">
      {renderModals()}
      <StyledFilterButton onClick={() => setShowFilterModal(true)}>
        <Text fontWeight={700} fontSize={22}>
          FILTER
        </Text>
      </StyledFilterButton>
      <NFTArea>
        <NftList nfts={nfts} />
      </NFTArea>
    </PageContainer>
  )
}
