import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import Preview from 'components/PreviewCard/OrbCardPreview/Preview'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'

const Container = styled.div`
  flex: 1;
`

const Card = styled.div`
  background-color: transparent;
  overflow: hidden;
`

const CardBody = styled.div`
  overflow: hidden;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`
const PropertyRow = styled(Row)`
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 5px 13px;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const Property = styled.div`
  color: #fff8e8;
  display: flex;
  align-items: center;
`

const HolyPreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const NftID = styled.p`
  position: absolute;
  bottom: 0;
  margin: 10px 0px;
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  font-family: Gudea-Medium;
  text-align: center;
  align-self: center;
  color: #fff8e8;
`

export default function Item({ nft }: { nft: any }) {
  const { rarity, classType } = nft
  const rarityName = getHeroRarity(rarity)
  const className = getHeroClassName(classType)
  return (
    <Container>
      <Card>
        <div style={{ display: 'flex', position: 'relative' }}>
          <Preview nft={nft} />
        </div>
        <CardBody>
          <PropertyRow>
            <Property>
              <PropertyName>Rarity:&nbsp;</PropertyName>
              <Text style={{ color: '#9ce315', fontSize: 16 }}>{rarityName}</Text>
            </Property>
            <div style={{ padding: '0 5px' }}>|</div>
            <Property>
              <PropertyName>Class:&nbsp;</PropertyName>
              <Text style={{ color: '#9ce315', fontSize: 16 }}>{className}</Text>
            </Property>
          </PropertyRow>
        </CardBody>
      </Card>
    </Container>
  )
}
