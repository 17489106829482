/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import PE_MINT_ORB_ABI from 'abis/pe-mint-orb-abi.json'
import PE_ORB_MARKET_ABI from 'abis/pe-orb-market-abi.json'
import PE_ORB_EVOLVE_ABI from 'abis/pe-orb-evolve-abi.json'
import button_info from 'assets/pefi/button-info.png'
import buttonCancel from 'assets/pefi/my-assets/cancel-button.png'
import buttonCancelling from 'assets/pefi/my-assets/cancelling-button.png'
import buttonGift from 'assets/pefi/my-assets/gift-button.png'
import buttonSell from 'assets/pefi/my-assets/sell-button.png'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import buttonUpdate from 'assets/pefi/my-assets/update-price-button.png'
import axios from 'axios'
import BigNumber from 'bignumber.js'
// import FilterModal from 'components/FilterModal'
import Footer from 'components/Footer'
import Preview from 'components/PreviewCard/OrbCardPreview/Preview'
import Filter from 'components/Filters/HeroFilter/Filter'
import DetailModal from 'components/MyAssets/Orbs/DetailModal/DetailModal'
import GiftModal from 'components/MyAssets/Orbs/GiftModal'
import SellModal from 'components/MyAssets/Orbs/Sell/SellModal'
import UpdatePriceModal from 'components/MyAssets/Orbs/Sell/UpdatePriceModal'
import SelectHolyForUpgradeStarModal from 'components/MyAssets/Orbs/Evolve/SelectHolyForEvolveModal'
import UpgradeResultModal from 'components/MyAssets/Orbs/Evolve/EvolveResultModal'
import UpgradeStarModal from 'components/MyAssets/Orbs/Evolve/EvolveModal'
import Text, { MagraText } from 'components/Text'
import {
  PE_MINT_ORB_ADDRESS,
  PE_ORB_MARKET_ADDRESS,
  PE_ORB_NFT_ADDRESS,
  PE_ORB_EVOLVE_ADDRESS,
  S2_ORB_NFT_ADDRESS,
  S2_ORB_MARKET_ADDRESS,
  S2_ORB_EVOLVE_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { ORBS_ENDPOINT, HOLY_ENDPOINT, MINT_TICKET } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft, PEFilterConditions } from 'constants/types'
import {
  useMintOrbContract,
  useOrbMarketContract,
  useOrbNFTContract,
  useOrbEvolveContract,
  useMintOrbContractS2,
  useOrbMarketContractS2,
  useOrbNFTContractS2,
  useOrbEvolveContractS2,
} from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import includes from 'lodash/includes'
import { Dots } from 'pages/styleds'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { getDecimalAmount } from 'utils/formatBalance'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'
import paginate from 'utils/paginate'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import NftGrid from './NftGrid'
import Pagination from '../Pagination'
import UnlockButton from '../../UnlockButton'
import Slider from 'react-slick'
import previous from 'assets/images/previous-page.png'
import next from 'assets/images/next-page.png'
import minus from 'assets/pefi/orbs/minus.png'
import plus from 'assets/pefi/orbs/plus.png'
import { Modal } from 'antd'
import Countdown from 'react-countdown'
import mintTicketImage from 'assets/pefi/mint-ticket.png'
export interface NftCardProps {
  nft: Nft
  refresh: () => void
}

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const NUMBER_PER_PAGE = 12
const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`

const PropertyName = styled(Text)`
  font-weight: 600;
  font-size: 15px;
  padding-right: 5px;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 15px;
  `};
`
const FilterPanel = styled.div`
  flex: 1;
  background: #14324c50;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
  padding: 30px;
`
const General = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

const NFTArea = styled.div`
  flex: 4;
  padding: 30px;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const FilterTitle = styled.div`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #fff;
`

const Card = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const CardBody = styled.div`
  overflow: hidden;
  padding-bottom: 30px;
`

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const PropertyRow = styled(Row)`
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 0px 5px;
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const Property = styled.div`
  display: flex;
  align-items: center;
`

const TokenLogo = styled.img`
  margin-right: 5px;
  position: relative;
  top: 2px;
`
const UnAuthorizedContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 500px;
  flex-direction: column;
  justify-content: space-evenly;
`

const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: contain;
  background-repeat: no-repeat;
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const ButtonDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #03c5ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PageContainer = styled.div`
  display: block;
  background: transparent;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
`

const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 30px 3px;
  max-width: 200px;
  margin: 30px auto 0;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 25%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
  `}
`

const ButtonSell = styled.img`
  cursor: pointer;
  width: 100%;
`

const ListHeroContainer = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #14324c50;
  border-radius: 10px;
  .orbs-slider {
    .slick-arrow {
      &::before {
        display: none;
      }
    }
    .slick-slide {
      padding: 10px;
    }
    .slick-dots li.slick-active button:before {
      color: #fff;
    }
    .slick-dots li button:before {
      color: #cecece;
    }
  }
`

const StatusTitle = styled.div`
  margin-bottom: 25px;
  font-size: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   display: block;
  // `};
`

const InfoIcon = styled.img`
  height: 20px;
  margin-left: 10px;
  position: relative;
  top: 5px;
  cursor: pointer;
  &:hover {
    + .tips {
      display: block;
    }
  }
`

const Tips = styled.div`
  display: none;
  position: absolute;
  right: 5px;
  bottom: 30px;
  color: #fff;
  width: 150px;
  background: #121212;
  z-index: 2;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 10px;
`

const MintHolyButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 5px auto;
  position: relative;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 20px;
  `};
`

const Previous = styled.img`
  height: 40px;
  cursor: pointer;
`
const Next = styled.img`
  height: 40px;
  cursor: pointer;
`

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: calc(100% - 50px);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 10px auto 0;
  `}
`

const MintTicketCount = styled.span`
  margin-left: 10px;
  padding: 0 10px;
  background: #013b59;
  border-radius: 15px;
  border: 2px solid #03c5ff;
  display: flex;
  align-items: center;
`

const ROFIAccountModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const RewardDescription = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 0 20px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const CheckinButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const CountdownContent = styled.div`
  font-size: 14px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const FilterModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609299 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

export default function Heroes() {
  // Define state
  const [{ showConfirm, buyErrorMessage, nftDetailSelected }, setShowDetail] = useState<{
    showConfirm: boolean
    buyErrorMessage: string | undefined
    nftDetailSelected: Nft | undefined
  }>({
    showConfirm: false,
    buyErrorMessage: undefined,
    nftDetailSelected: undefined,
  })
  const [{ showSell, sellErrorMessage, nftSellSelected }, setSellState] = useState<{
    showSell: boolean
    sellErrorMessage: string | undefined
    nftSellSelected: any | undefined
  }>({
    showSell: false,
    sellErrorMessage: undefined,
    nftSellSelected: undefined,
  })

  const [{ showUpdatePrice, nftUpdateSelected }, setUpdatePriceState] = useState<{
    showUpdatePrice: boolean
    nftUpdateSelected: any | undefined
  }>({
    showUpdatePrice: false,
    nftUpdateSelected: undefined,
  })
  const [{ showGift, giftErrorMessage, nftGiftSelected }, setGiftState] = useState<{
    showGift: boolean
    giftErrorMessage: string | undefined
    nftGiftSelected: any | undefined
  }>({
    showGift: false,
    giftErrorMessage: undefined,
    nftGiftSelected: undefined,
  })
  const [orderCanceling, setOrderCanceling] = useState<{
    tokenId: string | undefined
    isCanceling: boolean
  }>({
    tokenId: undefined,
    isCanceling: false,
  })
  const [{ showUpgradeStar, upgradeStarErrorMessage, orbSelected, materialHolySelected }, setUpgradeStar] = useState<{
    showUpgradeStar: boolean
    upgradeStarErrorMessage: string | undefined
    orbSelected: Nft | undefined
    materialHolySelected: any
  }>({
    showUpgradeStar: false,
    upgradeStarErrorMessage: undefined,
    orbSelected: undefined,
    materialHolySelected: {},
  })

  const [{ upgradeResult, nftResult, showUpgradeResult }, setUpgradeResult] = useState<{
    upgradeResult: boolean
    nftResult: Nft | undefined
    showUpgradeResult: boolean
  }>({
    upgradeResult: false,
    nftResult: undefined,
    showUpgradeResult: false,
  })
  const [showSelectHeroForUpgradeStar, setShowSelectHeroForUpgradeStar] = useState<boolean>(false)
  const [selectingSlotHeroForUpgradeStar, setSelectingSlotHeroForUpgradeStar] = useState<string>('0')

  const [nfts, setNfts] = useState<Nft[]>([])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [randomRate, setRandomRate] = useState<any>(0)

  const [{ mintingOrbId, state }, setMintingOrb] = useState<{
    mintingOrbId: string
    state: boolean
  }>({
    mintingOrbId: '',
    state: false,
  })

  const { chainId, account } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18

  const server = localStorage.getItem('server')

  const marketS1 = useOrbMarketContract()
  const marketS2 = useOrbMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const mintOrbContractS1 = useMintOrbContract()
  const mintOrbContractS2 = useMintOrbContractS2()
  const mintOrbContract = server ? (server === 's1' ? mintOrbContractS1 : mintOrbContractS2) : mintOrbContractS1

  const orbContractS1 = useOrbNFTContract()
  const orbContractS2 = useOrbNFTContractS2()
  const orbContract = server ? (server === 's1' ? orbContractS1 : orbContractS2) : orbContractS1

  const evolveContractS1 = useOrbEvolveContract()
  const evolveContractS2 = useOrbEvolveContractS2()
  const evolveContract = server ? (server === 's1' ? evolveContractS1 : evolveContractS2) : evolveContractS1

  const nftAddress = server
    ? server === 's1'
      ? PE_ORB_NFT_ADDRESS[56]
      : S2_ORB_NFT_ADDRESS[56]
    : PE_ORB_NFT_ADDRESS[56]

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const mintOrbCTF = new web3.eth.Contract(
    PE_MINT_ORB_ABI as AbiItem[],
    chainId ? PE_MINT_ORB_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )

  const ORB_MARKET_ADDRESS = server
    ? server === 's1'
      ? PE_ORB_MARKET_ADDRESS
      : S2_ORB_MARKET_ADDRESS
    : PE_ORB_MARKET_ADDRESS

  const marketCTF = new web3.eth.Contract(
    PE_ORB_MARKET_ABI as AbiItem[],
    chainId ? ORB_MARKET_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )

  const ORB_EVOLVE_ADDRESS = server
    ? server === 's1'
      ? PE_ORB_EVOLVE_ADDRESS
      : S2_ORB_EVOLVE_ADDRESS
    : PE_ORB_EVOLVE_ADDRESS

  const evolveCTF = new web3.eth.Contract(
    PE_ORB_EVOLVE_ABI as AbiItem[],
    chainId ? ORB_EVOLVE_ADDRESS[chainId] : '0xd44598A5E7089ae600ccAed6AffEc79BA14368c0'
  )

  useEffect(() => {
    if (!account || account === null) return
    fetchOrbs(account)
    getNextSuccessPercent()
    fetchMintTicketData(account)
  }, [account, orbSelected])

  const [myNftsData, setMyNftsData] = useState<any[]>([])
  const [myHolyData, setMyHolyData] = useState<any[]>([])
  const [orbsIngame, setOrbsIngame] = useState<any[]>([])
  const [requiredTime, setRequiredTime] = useState<any>(1)
  const [showOrbIngame, setShowOrbIngame] = useState<boolean>(true)
  const [showNftsAvailable, setShowNftsAvailable] = useState<boolean>(true)
  const [showNftsSelling, setShowNftsSelling] = useState<boolean>(true)
  const [mintTicket, setMintTicket] = useState<any>(0)
  const [openConfirmMint, setOpenConfirmMint] = useState<boolean>(false)
  const [mintableAt, setMintAbleAt] = useState<any>(undefined)

  const updatePrice = async (nfts: any) => {
    const array = []
    for (const nft of nfts) {
      const price = await getPriceInMarket(nft)
      const nftRef = {
        ...nft,
        price: price,
      }
      array.push(nftRef)
    }
    return array
  }

  const fetchMintTicketData = async (account: any) => {
    const url = `${MINT_TICKET.MINT_TICKET}/${account}`
    try {
      const response = await axios.get(url)
      const tickets = response.data.data.ticket
      setMintTicket(tickets)
    } catch (error) {
      console.error(error)
    }
  }

  const getPriceInMarket = async (nft: any) => {
    const useCTF = marketCTF
    if (!useCTF || nft.status !== 'sell') return undefined
    const saleInfo = await useCTF.methods.getSale(nftAddress, Number(nft.nftId)).call()
    return Number(saleInfo.price / 10 ** 18).toFixed(3)
  }

  const fetchOrbs = async (owner: any) => {
    const orbsIngame = await getOrbsIngame(owner)
    const mapped = []
    for (const orb of orbsIngame) {
      mapped.push({
        ...orb,
        proof: [],
      })
    }
    setOrbsIngame(mapped)

    const holy = await getHolyOfOwner(owner)
    setMyHolyData(holy)
  }

  const getOrbsIngame = async (owner: any) => {
    const url = ORBS_ENDPOINT.INGAME + owner
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        const all_orbs = response.data.data.orbs
        const nfts = all_orbs.filter((orb: any) => orb.nftId !== '')
        const non_nfts = all_orbs.filter((orb: any) => orb.nftId === '')
        const nfts_updated_price = await updatePrice(nfts)
        setMyNftsData(nfts_updated_price)
        const mintableAt = response.data.data.info.mintableAt
        const now = Date.now()
        if (now - mintableAt * 1000 < 0) {
          setMintAbleAt(response.data.data.info.mintableAt)
        } else {
          setMintAbleAt(undefined)
        }
        return non_nfts
      }
    } catch (error: any) {
      console.error('getOrbsIngame', error)
    }
    return []
  }

  const getHolyOfOwner = async (owner: any) => {
    const url = `${HOLY_ENDPOINT.NFTS}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  // const getProofOrbs = async (owner: any) => {
  //   const url = ORBS_ENDPOINT.MINT + owner
  //   try {
  //     const response = await axios.get(url)
  //     if (response.status === 200) {
  //       return response.data.data.orbs
  //     }
  //   } catch (error: any) {
  //     console.error('getProofOrbs', error)
  //   }
  //   return []
  // }

  const getNextSuccessPercent = async () => {
    if (!evolveCTF || !orbSelected) return undefined
    const heroId = orbSelected.nftId
    const next = await evolveCTF.methods.getNextSuccessPercent(heroId).call()
    setRandomRate(next)
    return
  }

  const [filter, setFilter] = useState<PEFilterConditions>({
    classNames: [],
    rarityNames: [],
    star: { from: 0, to: 0 },
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredOrders = useMemo(() => {
    if (
      filter.classNames.length === 0 &&
      filter.rarityNames.length === 0 &&
      filter.star.from === 0 &&
      filter.star.to === 0 &&
      searchValue === ''
    ) {
      return myNftsData
    }
    if (searchValue !== '') {
      return myNftsData.filter((order) => {
        return !searchValue
          ? true
          : new RegExp(searchValue.trim(), 'ig').test(order.detail.name) ||
              new RegExp(searchValue.trim(), 'ig').test(order.tokenId)
      })
    }
    return myNftsData.filter((order) => {
      return (
        (filter.classNames.length > 0 ? includes(filter.classNames, getHeroClassName(order.classType)) : true) &&
        (filter.rarityNames.length > 0 ? includes(filter.rarityNames, getHeroRarity(order.rarity)) : true) &&
        filter.star.from <= order.star &&
        filter.star.to >= order.star
      )
    })
  }, [myNftsData, filter, searchValue])
  // const [currentPage, setCurrentPage] = useState<number>(1)
  // const currentOrderList = useMemo(
  //   () => paginate(filteredOrders, NUMBER_PER_PAGE, currentPage),
  //   [filteredOrders, currentPage]
  // )

  useEffect(() => {
    ;(async () => {
      setNfts(filteredOrders)
    })()
  }, [filteredOrders])

  const renderHolyState = (nft: any) => {
    const { status, price, lockToTime } = nft
    const isLockIngame = lockToTime ? Date.parse(lockToTime) > Date.now() : false
    switch (status) {
      case 'unused':
        return (
          <Row>
            <ButtonSell
              src={buttonSell}
              onClick={() => {
                if (isLockIngame) return
                setSellState({
                  sellErrorMessage: undefined,
                  showSell: true,
                  nftSellSelected: nft,
                })
              }}
              style={{ opacity: isLockIngame ? '0.3' : '1' }}
            />
            <ButtonSell
              src={buttonGift}
              onClick={() => {
                setGiftState({
                  giftErrorMessage: undefined,
                  showGift: true,
                  nftGiftSelected: nft,
                })
              }}
            />
          </Row>
        )
      case 'sell':
        return (
          <>
            <SellingStatus>
              <Text fontSize={20} fontWeight={700} color={'#000'}>
                {price && (
                  <span>
                    {/* <TokenLogo width="20px" src={PEFiToken} /> */}
                    {price} BUSD
                  </span>
                )}
              </Text>
            </SellingStatus>
            <Row>
              <ButtonSell
                src={buttonUpdate}
                onClick={() => {
                  setUpdatePriceState({
                    showUpdatePrice: true,
                    nftUpdateSelected: nft,
                  })
                }}
              />
              {orderCanceling.tokenId === nft.tokenId && orderCanceling.isCanceling ? (
                <ButtonSell src={buttonCancelling} />
              ) : (
                <ButtonSell src={buttonCancel} onClick={() => onCancelOrderClick(nft)} />
              )}
            </Row>
          </>
        )
      default:
        return (
          <Row>
            <ButtonSell
              src={buttonSell}
              onClick={() => {
                // NotificationManager.info('Coming soon.', 'Feature', 2000)
                setSellState({
                  sellErrorMessage: undefined,
                  showSell: true,
                  nftSellSelected: nft,
                })
              }}
            />
            <ButtonSell
              src={buttonGift}
              onClick={() => {
                // NotificationManager.info('Coming soon.', 'Feature', 2000)
                setGiftState({
                  giftErrorMessage: undefined,
                  showGift: true,
                  nftGiftSelected: nft,
                })
              }}
            />
          </Row>
        )
    }
  }

  const handleSellDismiss = useCallback(() => {
    setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
  }, [])

  const handleUpdatePriceDismiss = useCallback(() => {
    setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
  }, [])

  const convertHolyType = (type: string) => {
    switch (type) {
      case 'green':
        return 1
      case 'red':
        return 2
      case 'blue':
        return 3
      default:
        return 4
    }
  }

  const convertLocalIdToByte32 = (localId: string) => {
    return web3.utils.padRight(web3.utils.fromAscii(localId), 64)
  }

  async function mintOrb(orbId: any) {
    const orb = orbsIngame.find((o: any) => o.id === orbId)
    console.log('on mint orb', orb)
    if (!mintOrbContract || !orb || !account) return
    setMintingOrb({
      mintingOrbId: orbId,
      state: true,
    })
    const localId = orb.id
    const star = orb.star
    const rarity = orb.rarity
    const classType = orb.classType
    const data = {
      address: account,
      localId: localId,
      star: star,
      rarity: rarity,
      classType: classType,
    }
    axios({
      method: 'post',
      url: ORBS_ENDPOINT.MINT,
      headers: {},
      data: data,
      validateStatus: (status: number) => {
        return status === 500 || status === 200
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const signed = response.data.data
          const method: (...args: any) => Promise<TransactionResponse> = mintOrbContract.mintOrb
          const args: Array<string | string[] | number> = [
            signed.localId,
            star,
            rarity,
            classType,
            signed.nonce,
            signed.signature,
          ]
          console.log('args', args)
          method(...args, {})
            .then(async (response) => {
              const txFull = await response.wait()
              console.log('txHash', txFull.transactionHash)
              if (txFull.status === 0) {
                NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
                setAttemptingTxn(false)
                return
              }
              await fetchOrbs(account)
              await fetchMintTicketData(account)
              setTimeout(() => {
                NotificationManager.success('Mint Orb success. Please check in NFTs tab', 'Success', 2000)
                setMintingOrb({
                  mintingOrbId: orb.id,
                  state: false,
                })
                setOpenConfirmMint(false)
              }, 9000)
            })
            .catch((error) => {
              setMintingOrb({
                mintingOrbId: orb.id,
                state: false,
              })
              console.error(error)
              NotificationManager.error('Can not get data. Please try again', 'Error', 2000)
            })
        } else {
          console.error('response', response)
          NotificationManager.error('Can not get data. Please try again', 'Error', 2000)
          setMintingOrb({
            mintingOrbId: orb.id,
            state: false,
          })
          setOpenConfirmMint(false)
        }
        return
      })
      .catch((error) => {
        console.error(error)
        setMintingOrb({
          mintingOrbId: orb.id,
          state: false,
        })
        setOpenConfirmMint(false)
        NotificationManager.error('Can not get data. Please try again', 'Error', 2000)
        getOrbsIngame(account)
      })
  }

  async function onSellConfirm(price: string) {
    console.log('on sell item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftSellSelected) return
    const method: (...args: any) => Promise<TransactionResponse> = market.placeOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSellSelected.nftId, decimal_price]
    setAttemptingTxn(true)
    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        console.log('txHash', txFull.transactionHash)
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        setTimeout(() => {
          NotificationManager.success('Sell Orb success.', 'Success', 2000)
          setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
          setAttemptingTxn(false)
        }, 9000)
        await fetchOrbs(account)
        // marketCTF.getPastEvents(
        //   'PlaceOrder',
        //   {
        //     fromBlock: txFull.blockNumber,
        //     toBlock: txFull.blockNumber,
        //     filter: {
        //       nftAddress: nftSellSelected.nftAddress,
        //       tokenId: nftSellSelected.tokenId,
        //     },
        //   },
        //   async (error: any, events) => {
        //     if (error) {
        //       console.error(error)
        //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        //       return
        //     }
        //     console.log('PlaceOrder', events)
        //     if (Object.values(events).length === 0) {
        //       setAttemptingTxn(false)
        //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
        //       return
        //     }
        //     await fetchOrbs(account)
        //     NotificationManager.success('Sell Orb success.', 'Success', 2000)
        //     setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
        //     setAttemptingTxn(false)
        //   }
        // )
      })
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
      })
  }

  async function onUpdatePriceConfirm(price: string) {
    console.log('on update price item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftUpdateSelected) return
    const estimate = market.estimateGas.updatePrice
    const method: (...args: any) => Promise<TransactionResponse> = market.updatePrice
    const args: Array<string | string[] | number> = [nftAddress, nftUpdateSelected.nftId, decimal_price]
    setAttemptingTxn(true)
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            setTimeout(() => {
              NotificationManager.success('Update price success.', 'Success', 2000)
              setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
              setAttemptingTxn(false)
            }, 9000)
            await getOrbsIngame(account)
            // marketCTF.getPastEvents(
            //   'UpdatePrice',
            //   {
            //     fromBlock: txFull.blockNumber,
            //     toBlock: txFull.blockNumber,
            //     filter: {
            //       nftAddress: nftUpdateSelected.nftAddress,
            //       tokenId: nftUpdateSelected.tokenId,
            //     },
            //   },
            //   async (error: any, events) => {
            //     if (error) {
            //       console.error(error)
            //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            //       return
            //     }
            //     console.log('UpdatePrice', events)
            //     if (Object.values(events).length === 0) {
            //       setAttemptingTxn(false)
            //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            //       return
            //     }
            //     await getOrbsIngame(account)
            //     NotificationManager.success('Update price success.', 'Success', 2000)
            //     setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
            //     setAttemptingTxn(false)
            //   }
            // )
          })
          .catch((error) => {
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onCancelOrderClick(nftSelected: any) {
    console.log('on cancel item')
    setOrderCanceling({
      tokenId: nftSelected.nftId,
      isCanceling: true,
    })
    const useContract = market
    const useCTF = marketCTF
    if (!useContract || !nftSelected) return
    const estimate = useContract.estimateGas.cancelOrder
    const method: (...args: any) => Promise<TransactionResponse> = useContract.cancelOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSelected.nftId]

    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            setTimeout(() => {
              NotificationManager.success('Cancel order success.', 'Success', 2000)
              setOrderCanceling({
                tokenId: nftSelected.nftId,
                isCanceling: false,
              })
            }, 9000)
            await getOrbsIngame(account)
            // useCTF.getPastEvents(
            //   'CancelOrder',
            //   {
            //     fromBlock: txFull.blockNumber,
            //   },
            //   async (error: any, events) => {
            //     if (error) {
            //       console.error(error)
            //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            //       setOrderCanceling({
            //         tokenId: nftSelected.nftId,
            //         isCanceling: false,
            //       })
            //       return
            //     }
            //     console.log('CancelOrder', events)
            //     if (Object.values(events).length === 0) {
            //       setAttemptingTxn(false)
            //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            //       return
            //     }
            //     await getOrbsIngame(account)
            //     NotificationManager.success('Cancel order success.', 'Success', 2000)
            //     setOrderCanceling({
            //       tokenId: nftSelected.nftId,
            //       isCanceling: false,
            //     })
            //   }
            // )
          })
          .catch((error) => {
            setOrderCanceling({
              tokenId: nftSelected.nftId,
              isCanceling: false,
            })
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error) => {
        setOrderCanceling({
          tokenId: nftSelected.nftId,
          isCanceling: false,
        })
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onGiftConfirm(nft: any, receiver: string) {
    if (!orbContract || !nft || !account) return
    setAttemptingTxn(true)

    const method: (...args: any) => Promise<TransactionResponse> = orbContract.transferFrom
    const args: Array<string | string[] | number> = [account, receiver, nft.nftId]

    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        setTimeout(() => {
          setAttemptingTxn(false)
          setGiftState({
            nftGiftSelected: undefined,
            showGift: false,
            giftErrorMessage,
          })
          NotificationManager.success('Gift Orb completed.', 'Success', 2000)
        }, 9000)
        await getOrbsIngame(account)
      })
      .catch((error) => {
        setAttemptingTxn(false)
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onEvolveConfirm() {
    try {
      console.log('on evolve orb')
      setAttemptingTxn(true)
      if (!evolveContract || !orbSelected || !materialHolySelected || !orbSelected.nftId) return
      const orbId = orbSelected.nftId
      const holyPackageIds: string[] = []
      Object.values(materialHolySelected).forEach((n: any) => {
        if (!n) return
        holyPackageIds.push(n.tokenId)
      })
      const method: (...args: any) => Promise<TransactionResponse> = evolveContract.upgradeStar
      const args: Array<string | string[] | number> = [orbId, holyPackageIds]
      const estimate = evolveContract.estimateGas.upgradeStar
      return estimate(...args, {})
        .then((estimatedGasLimit) => {
          method(...args, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
          })
            .then(async (response) => {
              const txFull = await response.wait()
              console.log('txHash', txFull.transactionHash)
              if (txFull.status === 0) {
                NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
                setAttemptingTxn(false)
                return
              }
              evolveCTF.getPastEvents(
                'StarUpgrade',
                {
                  fromBlock: txFull.blockNumber,
                },
                async (error: any, events) => {
                  if (error) {
                    console.error(error)
                    setAttemptingTxn(false)
                    NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
                    return
                  }
                  if (Object.values(events).length === 0) return
                  const this_event = Object.values(events).find((e: any) => e.returnValues.orbId === orbId)
                  if (!this_event) return
                  console.log('StarUpgrade', this_event)
                  const isSuccess = this_event.returnValues.isSuccess
                  if (!isSuccess) {
                    await getNextSuccessPercent()
                  }
                  setUpgradeResult({
                    upgradeResult: isSuccess,
                    nftResult: orbSelected,
                    showUpgradeResult: true,
                  })
                  setAttemptingTxn(false)
                }
              )
            })
            .catch((error) => {
              setAttemptingTxn(false)
              NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            })
        })
        .catch((error) => {
          setAttemptingTxn(false)
          NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        })
    } catch (error: any) {
      setAttemptingTxn(false)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 1000)
    }
  }

  const showSelectHeroForUpgrateStarWithSlot = (slot: string) => {
    setShowSelectHeroForUpgradeStar(true)
    setSelectingSlotHeroForUpgradeStar(slot)
  }

  const handleUpgradeStarDismiss = () => {
    setUpgradeStar({
      showUpgradeStar: false,
      upgradeStarErrorMessage,
      orbSelected: undefined,
      materialHolySelected: undefined,
    })
  }

  const handleUpgradeResultDismiss = () => {
    if (upgradeResult) {
      setUpgradeStar({
        showUpgradeStar: false,
        upgradeStarErrorMessage,
        orbSelected: undefined,
        materialHolySelected: undefined,
      })
    } else {
      setUpgradeStar({
        showUpgradeStar: true,
        upgradeStarErrorMessage,
        orbSelected: orbSelected,
        materialHolySelected: undefined,
      })
    }
    setUpgradeResult({
      upgradeResult: false,
      nftResult: undefined,
      showUpgradeResult: false,
    })
    setAttemptingTxn(false)
  }

  function onSelectMaterialHero(nft: any, slot: string) {
    setShowSelectHeroForUpgradeStar(false)
    const refMaterialHeroSelected = {
      ...materialHolySelected,
    }
    refMaterialHeroSelected[slot] = nft
    setUpgradeStar({
      upgradeStarErrorMessage: undefined,
      showUpgradeStar: true,
      orbSelected: orbSelected,
      materialHolySelected: refMaterialHeroSelected,
    })
  }

  const isMobile = window.innerWidth <= 500

  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: boolean
  }) => {
    if (completed) {
      setMintAbleAt(undefined)
      return null
    } else {
      return (
        <CountdownContent>
          You can mint after {minutes}m {seconds}s
        </CountdownContent>
      )
    }
  }

  const getTimeCanMint = () => {
    const now = Date.now()
    const round = Math.floor(now / requiredTime)
    return requiredTime * (round + 1)
  }

  const HolyIngameList = ({ orbs, title }: { orbs: any[]; title: string }) => {
    if (orbs.length === 0) {
      return (
        <ListHeroContainer style={{ marginTop: 0 }}>
          <StatusTitle>
            <span style={{ display: 'flex' }}>{title}</span>
          </StatusTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
            No Orbs available
          </div>
        </ListHeroContainer>
      )
    }
    if (!isMobile && orbs.length <= 4) {
      return (
        <ListHeroContainer style={{ marginTop: 0 }}>
          <StatusTitle>
            <span style={{ display: 'flex' }}>
              {title}
              <MintTicketCount>
                <span style={{ color: mintTicket === 0 ? 'red' : '#fff' }}>{mintTicket}</span>/2
                <span>
                  <img style={{ width: 40 }} src={mintTicketImage} alt="" />
                </span>
              </MintTicketCount>
            </span>
          </StatusTitle>
          <NftGrid>
            {orbs.map((orb: any, index) => {
              const rarityName = getHeroRarity(orb.rarity)
              const className = getHeroClassName(orb.classType)
              return (
                <Card key={index}>
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <Preview nft={orb} />
                  </div>
                  <CardBody>
                    <PropertyRow>
                      <Property>
                        <PropertyName>Rarity:&nbsp;</PropertyName>
                        <PropertyName style={{ color: '#9ce315' }}>{rarityName}</PropertyName>
                      </Property>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>|</div>
                      <Property>
                        <PropertyName>Class:&nbsp;</PropertyName>
                        <PropertyName style={{ color: '#9ce315' }}>{className}</PropertyName>
                      </Property>
                    </PropertyRow>
                    {mintTicket === 0 || (mintTicket > 0 && mintableAt) ? (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MintHolyButton className="disable">MINT</MintHolyButton>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {mintingOrbId === orb.id && state ? (
                          <MintHolyButton className="disable">
                            <Dots>LOADING</Dots>
                          </MintHolyButton>
                        ) : (
                          <MintHolyButton
                            onClick={() => {
                              setMintingOrb({
                                mintingOrbId: orb.id,
                                state: false,
                              })
                              setOpenConfirmMint(true)
                            }}
                          >
                            MINT
                          </MintHolyButton>
                        )}
                      </div>
                    )}
                    {mintTicket === 0 && (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        You have run out of turns to mint this week
                      </div>
                    )}
                    {mintTicket > 0 && mintableAt && (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <Countdown date={mintableAt * 1000} renderer={renderCountdown} zeroPadTime={2} />
                      </div>
                    )}
                  </CardBody>
                  <ButtonDetails
                    onClick={() => {
                      setShowDetail({
                        buyErrorMessage: undefined,
                        showConfirm: true,
                        nftDetailSelected: orb,
                      })
                    }}
                  >
                    <Text fontSize={18} fontWeight={600}>
                      DETAILS
                    </Text>
                  </ButtonDetails>
                </Card>
              )
            })}
          </NftGrid>
        </ListHeroContainer>
      )
    }

    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            // dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            // dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            // dots: true,
          },
        },
      ],
    }
    let slider: any = undefined
    return (
      <ListHeroContainer style={{ marginTop: 0 }}>
        <StatusTitle>
          <span style={{ display: 'flex', alignItems: 'flex-end' }}>
            {title}
            <MintTicketCount>
              <span style={{ color: mintTicket === 0 ? 'red' : '#fff' }}>{mintTicket}</span>/2
              <span>
                <img style={{ width: 40 }} src={mintTicketImage} alt="" />
              </span>
            </MintTicketCount>
          </span>
          <img
            style={{ cursor: 'pointer' }}
            src={showOrbIngame ? minus : plus}
            onClick={() => setShowOrbIngame(!showOrbIngame)}
          />
        </StatusTitle>
        {showOrbIngame && (
          <>
            <Slider ref={(c: any) => (slider = c)} {...settings} className="orbs-slider">
              {orbs?.map((orb: any, index) => {
                const rarityName = getHeroRarity(orb.rarity)
                const className = getHeroClassName(orb.classType)
                return (
                  <Card key={index}>
                    <div style={{ display: 'flex', position: 'relative' }}>
                      <Preview nft={orb} />
                    </div>
                    <CardBody>
                      <PropertyRow>
                        <Property>
                          <PropertyName>Rarity:&nbsp;</PropertyName>
                          <PropertyName style={{ color: '#9ce315' }}>{rarityName}</PropertyName>
                        </Property>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>|</div>
                        <Property>
                          <PropertyName>Class:&nbsp;</PropertyName>
                          <PropertyName style={{ color: '#9ce315' }}>{className}</PropertyName>
                        </Property>
                      </PropertyRow>
                      {mintTicket === 0 || (mintTicket > 0 && mintableAt) ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <MintHolyButton className="disable">MINT</MintHolyButton>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {mintingOrbId === orb.id && state ? (
                            <MintHolyButton className="disable">
                              <Dots>LOADING</Dots>
                            </MintHolyButton>
                          ) : (
                            <MintHolyButton
                              onClick={() => {
                                setMintingOrb({
                                  mintingOrbId: orb.id,
                                  state: false,
                                })
                                setOpenConfirmMint(true)
                              }}
                            >
                              MINT
                            </MintHolyButton>
                          )}
                        </div>
                      )}
                      {mintTicket === 0 && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10,
                            fontSize: 13,
                          }}
                        >
                          You have run out of turns to mint this week
                        </div>
                      )}
                      {mintTicket > 0 && mintableAt && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                          <Countdown date={mintableAt * 1000} renderer={renderCountdown} zeroPadTime={2} />
                        </div>
                      )}
                    </CardBody>
                    <ButtonDetails
                      onClick={() => {
                        setShowDetail({
                          buyErrorMessage: undefined,
                          showConfirm: true,
                          nftDetailSelected: orb,
                        })
                      }}
                    >
                      <Text fontSize={18} fontWeight={600}>
                        DETAILS
                      </Text>
                    </ButtonDetails>
                  </Card>
                )
              })}
            </Slider>
            <StyledPagination>
              <Previous src={previous} onClick={() => slider.slickPrev()} />
              <Text fontWeight={700} fontSize={25} margin="5px 20px">
                {`${orbs.length} Orbs`}
              </Text>
              <Next src={next} onClick={() => slider.slickNext()} />
            </StyledPagination>
          </>
        )}
      </ListHeroContainer>
    )
  }

  const parseHolyTypeToString = (type: number) => {
    switch (type) {
      case 1:
        return 'green'
      case 2:
        return 'red'
      case 3:
        return 'blue'
      default:
        return 'yellow'
    }
  }

  const NftList = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
    if (nfts.length === 0) {
      return (
        <ListHeroContainer>
          <StatusTitle>{title}</StatusTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
            No Orbs available
          </div>
        </ListHeroContainer>
      )
    }

    const available = nfts.filter((n: any) => n.status !== 'sell')
    const selling = nfts.filter((n: any) => n.status === 'sell')

    const NftCard = (index: number, orb: any, rarityName: string, className: string) => {
      return (
        <Card key={index}>
          <div style={{ display: 'flex', position: 'relative' }}>
            <Preview nft={orb} />
          </div>
          <CardBody>
            <PropertyRow>
              <Property>
                <PropertyName>Rarity:&nbsp;</PropertyName>
                <PropertyName style={{ color: '#9ce315' }}>{rarityName}</PropertyName>
              </Property>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>|</div>
              <Property>
                <PropertyName>Class:&nbsp;</PropertyName>
                <PropertyName style={{ color: '#9ce315' }}>{className}</PropertyName>
              </Property>
            </PropertyRow>
            <>{renderHolyState(orb)}</>
          </CardBody>
          <ButtonDetails
            onClick={() => {
              setShowDetail({
                buyErrorMessage: undefined,
                showConfirm: true,
                nftDetailSelected: orb,
              })
            }}
          >
            <Text fontSize={18} fontWeight={600}>
              DETAILS
            </Text>
          </ButtonDetails>
        </Card>
      )
    }

    const Render_container = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
      const [show_this_nfts, set_show_this_nfts] = useState<boolean>(true)
      const [currentPage, setCurrentPage] = useState<number>(1)
      const currentOrderList = useMemo(() => paginate(nfts, NUMBER_PER_PAGE, currentPage), [nfts, currentPage])
      return (
        <ListHeroContainer>
          <StatusTitle>
            {title}
            <img
              style={{ cursor: 'pointer' }}
              src={show_this_nfts ? minus : plus}
              onClick={() => set_show_this_nfts(!show_this_nfts)}
            />
          </StatusTitle>
          {show_this_nfts && (
            <>
              <NftGrid>
                {currentOrderList.map((orb: any, index) => {
                  const rarityName = getHeroRarity(orb.rarity)
                  const className = getHeroClassName(orb.classType)
                  return <>{NftCard(index, orb, rarityName, className)}</>
                })}
              </NftGrid>
              <General>
                <TabWrapper>
                  {nfts.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      onChange={setCurrentPage}
                      totalPage={Math.ceil(nfts.length / NUMBER_PER_PAGE)}
                    />
                  )}
                </TabWrapper>
              </General>
            </>
          )}
        </ListHeroContainer>
      )
    }

    return (
      <>
        {available.length > 0 && <>{Render_container({ nfts: available, title: 'NFTs Available' })}</>}
        {selling.length > 0 && <>{Render_container({ nfts: selling, title: 'NFTs Selling' })}</>}
      </>
    )

    // const render_container_available = (nfts: any[]) => {
    //   const [show_this_nfts, set_show_this_nfts] = useState<boolean>(true)
    //   const [currentPage, setCurrentPage] = useState<number>(1)
    //   const currentOrderList = useMemo(() => paginate(nfts, NUMBER_PER_PAGE, currentPage), [nfts, currentPage])
    //   return (
    //     <ListHeroContainer>
    //       <StatusTitle>
    //         NFTs Available
    //         <img
    //           style={{ cursor: 'pointer' }}
    //           src={show_this_nfts ? minus : plus}
    //           onClick={() => set_show_this_nfts(!show_this_nfts)}
    //         />
    //       </StatusTitle>
    //       {show_this_nfts && (
    //         <>
    //           <NftGrid>
    //             {currentOrderList.map((orb: any, index) => {
    //               // const type = parseHolyTypeToString(holy.holyType)
    //               const rarityName = getHeroRarity(orb.rarity)
    //               const className = getHeroClassName(orb.classType)
    //               return <>{NftCard(index, orb, rarityName, className)}</>
    //             })}
    //           </NftGrid>
    //           <General>
    //             <TabWrapper>
    //               {nfts.length > 0 && (
    //                 <Pagination
    //                   currentPage={currentPage}
    //                   onChange={setCurrentPage}
    //                   totalPage={Math.ceil(nfts.length / NUMBER_PER_PAGE)}
    //                 />
    //               )}
    //             </TabWrapper>
    //           </General>
    //         </>
    //       )}
    //     </ListHeroContainer>
    //   )
    // }
    // const render_container_selling = (nfts: any[]) => {
    //   return (
    //     <ListHeroContainer>
    //       <StatusTitle>
    //         NFTs Selling
    //         <img
    //           style={{ cursor: 'pointer' }}
    //           src={showNftsSelling ? minus : plus}
    //           onClick={() => setShowNftsSelling(!showNftsSelling)}
    //         />
    //       </StatusTitle>
    //       {showNftsSelling && (
    //         <>
    //           <NftGrid>
    //             {nfts.map((orb: any, index) => {
    //               // const type = parseHolyTypeToString(holy.holyType)
    //               const rarityName = getHeroRarity(orb.rarity)
    //               const className = getHeroClassName(orb.classType)
    //               return <>{NftCard(index, orb, rarityName, className)}</>
    //             })}
    //           </NftGrid>
    //           <General>
    //             <TabWrapper>
    //               {nfts.length > 0 && (
    //                 <Pagination
    //                   currentPage={currentPage}
    //                   onChange={setCurrentPage}
    //                   totalPage={Math.ceil(nfts.length / NUMBER_PER_PAGE)}
    //                 />
    //               )}
    //             </TabWrapper>
    //           </General>
    //         </>
    //       )}
    //     </ListHeroContainer>
    //   )
    // }

    // return (
    //   <>
    //     {available.length > 0 && <>{render_container_available(available)}</>}
    //     {selling.length > 0 && <>{render_container_selling(selling)}</>}
    //   </>
    // )
  }

  const maintain = () => {
    NotificationManager.info('Please try again later.', 'MAINTENANCE', 2000)
  }

  const handleConfirmDismiss = useCallback(() => {
    setShowDetail({ showConfirm: false, buyErrorMessage, nftDetailSelected: undefined })
  }, [])

  const renderModals = () => {
    return (
      <>
        <FilterModal
          title="FILTER"
          visible={showFilterModal}
          onCancel={() => setShowFilterModal(false)}
          footer={null}
          className="select-server"
        >
          <div style={{ padding: '0 30px 30px', margin: 'auto', width: '100%' }}>
            <Filter
              id="filter-dialog"
              onConfirm={(newFilter: PEFilterConditions) => {
                setFilter(newFilter)
                setShowFilterModal(false)
              }}
              onCancel={() => {
                setShowFilterModal(false)
              }}
            />
          </div>
        </FilterModal>
        <DetailModal
          nft={nftDetailSelected}
          isOpen={showConfirm}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onSellClick={() => console.log('sell')}
          onUpgradeStarClick={() => {
            setUpgradeStar({
              upgradeStarErrorMessage: undefined,
              showUpgradeStar: true,
              orbSelected: nftDetailSelected,
              materialHolySelected: undefined,
            })
            handleConfirmDismiss()
            // maintain()
          }}
          onWithDrawClick={() => console.log('aaa')}
          swapErrorMessage={undefined}
          onDismiss={handleConfirmDismiss}
        />
        <SellModal
          nft={nftSellSelected}
          isOpen={showSell}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onSellConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleSellDismiss}
        />
        <UpdatePriceModal
          nft={nftUpdateSelected}
          isOpen={showUpdatePrice}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onUpdatePriceConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleUpdatePriceDismiss}
        />
        <GiftModal
          onGift={onGiftConfirm}
          attemptingTxn={attemptingTxn}
          isOpen={showGift}
          onDismiss={() => setGiftState({ nftGiftSelected: undefined, showGift: false, giftErrorMessage })}
          nft={nftGiftSelected}
        />

        <UpgradeStarModal
          nft={orbSelected}
          materialHolySelected={materialHolySelected}
          isOpen={showUpgradeStar}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          randomRate={randomRate}
          onConfirm={onEvolveConfirm}
          upgradeStarErrorMessage={undefined}
          onDismiss={handleUpgradeStarDismiss}
          showSelectHero={showSelectHeroForUpgrateStarWithSlot}
          upgradeSuccess={false}
          onRefreshData={() => {
            fetchOrbs(account)
          }}
        />

        {showUpgradeResult && (
          <UpgradeResultModal
            upgradeResult={upgradeResult}
            nft={nftResult}
            isOpen={showUpgradeResult}
            upgradeStarErrorMessage={undefined}
            onDismiss={handleUpgradeResultDismiss}
            onRefreshData={() => {
              fetchOrbs(account)
            }}
          />
        )}

        <SelectHolyForUpgradeStarModal
          isOpen={showSelectHeroForUpgradeStar}
          nft={orbSelected}
          materialHolySelected={materialHolySelected}
          slot={selectingSlotHeroForUpgradeStar}
          onConfirm={onSelectMaterialHero}
          onDismiss={() => setShowSelectHeroForUpgradeStar(false)}
          nfts={Object.values(myHolyData)}
        />
      </>
    )
  }

  return (
    <PageContainer className="main-content">
      {renderModals()}
      {(orbsIngame.length > 0 || nfts.length > 0) && (
        <StyledFilterButton onClick={() => setShowFilterModal(true)}>
          <Text fontWeight={700} fontSize={22}>
            FILTER
          </Text>
        </StyledFilterButton>
      )}
      <NFTArea>
        <HolyIngameList orbs={orbsIngame} title="Orbs (in-game)" />
        <NftList nfts={nfts} title="NFTs" />
      </NFTArea>
      {openConfirmMint && (
        <ROFIAccountModal
          title="ATTENTION !!!"
          visible={openConfirmMint}
          onCancel={() => setOpenConfirmMint(false)}
          footer={null}
        >
          <FlexLine style={{ marginTop: 20 }}>
            <RewardDescription>
              Make sure you want to mint this Orb and have enough BNB (~0.0015) for transaction fee.
            </RewardDescription>
          </FlexLine>
          <FlexLine style={{ marginTop: 20 }}>
            <RewardDescription>
              Note: You maybe need to wait for 5 minutes to try again if reject blockchain transaction.
            </RewardDescription>
          </FlexLine>
          {state ? (
            <FlexLine>
              <CheckinButton className="disable">CANCEL</CheckinButton>
              <CheckinButton className="disable">
                <Dots>LOADING</Dots>
              </CheckinButton>
            </FlexLine>
          ) : (
            <FlexLine>
              <CheckinButton
                style={{ background: '#f6c931' }}
                onClick={() => {
                  setOpenConfirmMint(false)
                  setMintingOrb({
                    mintingOrbId: mintingOrbId,
                    state: false,
                  })
                }}
              >
                CANCEL
              </CheckinButton>
              <CheckinButton
                onClick={() => {
                  mintOrb(mintingOrbId)
                }}
              >
                CONFIRM
              </CheckinButton>
            </FlexLine>
          )}
        </ROFIAccountModal>
      )}
    </PageContainer>
  )
}
