import newHeroBg from 'assets/herofi/breed-hero-bg.png'
import buttonCancel from 'assets/pefi/my-assets/sell-modal/cancel.png'
import buttonSell from 'assets/pefi/my-assets/sell-modal/confirm.png'
import buttonSelling from 'assets/pefi/my-assets/sell-modal/loading.png'
import Close from 'assets/pefi/special-pack/close-button.png'
import axios from 'axios'
import { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { SHOP_ENDPOINT } from 'constants/mkpconfigs'
import { useActiveWeb3React } from 'hooks/web3'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import UpgradeStarModalContent from './ModalContent'

const Container = styled.div`
  width: 100%;
  position: relative;
  background: #026092;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `}
`

const Action = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 0 auto 30px;
  padding: 0 30px;
  &.mobile {
    gap: 0;
    padding: 10px 50px;
  }
`
const ModalTitle = styled.div`
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const Content = styled.div`
  display: block;
  text-align: center;
  color: #fff;
  padding: 30px 0;
`

const MainContent = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`

const SubContent = styled.div`
  font-size: 16px;
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`

const ButtonSell = styled.img`
  cursor: pointer;
  margin-top: 20px;
`

const isMobile = window.innerWidth <= 500
function ConfirmationPopup({
  selectedPack,
  onDismiss,
  onConfirm,
  attemptingTxn,
}: {
  selectedPack: any
  onDismiss: () => void
  onConfirm: (selectedPack: any) => void
  attemptingTxn: boolean
}): JSX.Element {
  const { account } = useActiveWeb3React()

  const [shownPack, setShownPack] = useState<any>(selectedPack)

  const fetchGemPrice = async () => {
    const url = SHOP_ENDPOINT.PACKS
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.response
      } else {
        console.error(response)
        return []
      }
    } catch (error: any) {
      console.error(error)
    }
    return []
  }

  const updateGemPacks = async () => {
    const gemPacks = await fetchGemPrice()
    const shownPack = updateGemPrice(gemPacks)
    setShownPack(shownPack)
  }

  const updateGemPrice = (packs: any) => {
    const this_pack = packs.find((p: any) => p.packId === selectedPack.pack_id)
    const selectedPackRef = {
      ...selectedPack,
      number_of_gems: this_pack.gem,
      rofi_price: this_pack.pefi,
    }
    return selectedPackRef
  }

  useEffect(() => {
    updateGemPacks()
    setInterval(() => {
      updateGemPacks()
    }, 120000)
  }, [account])

  return (
    <Container>
      <ModalTitle>PURCHASE CONFIRMATION</ModalTitle>
      <Content>
        <MainContent>You will receive {shownPack.number_of_gems} gems in-game.</MainContent>
        <SubContent>Note: the gems amount will be changed by the PEFI token price.</SubContent>
      </Content>
      {attemptingTxn ? (
        <Action style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <ButtonSell src={buttonSelling} />
          </div>
        </Action>
      ) : (
        <Action>
          <div style={{ textAlign: 'center' }}>
            <ButtonSell src={buttonCancel} onClick={onDismiss} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <ButtonSell src={buttonSell} onClick={() => onConfirm(shownPack)} />
          </div>
        </Action>
      )}
      <CloseIcon src={Close} onClick={onDismiss} />
    </Container>
  )
}

export default function ConfirmModal({
  isShowModal,
  attemptingTxn,
  selectedPack,
  onConfirm,
  onDismiss,
}: {
  isShowModal: boolean
  attemptingTxn: boolean
  selectedPack: any
  onConfirm: (selectedPack: any) => void
  onDismiss: () => void
}) {
  const content = useCallback(() => {
    return (
      <ConfirmationPopup
        selectedPack={selectedPack}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        attemptingTxn={attemptingTxn}
      />
    )
  }, [onDismiss])

  const buypackErrorMessage = undefined

  const confirmationContent = useCallback(
    () =>
      buypackErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={buypackErrorMessage} />
      ) : (
        <UpgradeStarModalContent onDismiss={onDismiss} content={content} />
      ),
    [onDismiss, content, buypackErrorMessage]
  )

  return (
    <Modal isOpen={isShowModal} onDismiss={onDismiss}>
      {confirmationContent()}
    </Modal>
  )
}
