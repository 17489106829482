import { Col, Row, Modal } from 'antd'
import axios from 'axios'
import { ROFI_ACCOUNT } from 'constants/mkpconfigs'
import { useActiveWeb3React } from 'hooks/web3'
import AppBody from 'pages/AppBody'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Web3 from 'web3'
import Countdown from 'react-countdown'
import UnlockButton from 'pages/AccountPage/MyAssets/UnlockButton'
import { NotificationManager } from 'react-notifications'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'
const PageBody = styled(AppBody)`
  display: flex;
  flex-direction: row;
  max-width: 2000px;
  background: url(${filterBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #14324c;
`

const SideBar = styled(Col)`
  background: #14324c50;
  padding: 30px;
`
const PageContent = styled(Col)`
  padding: 30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
  `};
`

const MainContent = styled.div`
  padding: 30px;
  min-height: calc(100vh - 61px);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `};
`

const SetupAccount = styled.div`
  background: #14324c50;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
`

const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
  font-size: 18px;
`

const InformationWrapper = styled.div`
  width: 50%;
  background: #14324c50;
  border-radius: 20px;
  padding: 30px;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 100%;
  `};
`

const InformationTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
`

const InformationContainer = styled.div``

const InformationRow = styled(Row)`
  padding: 5px 0;
  height: 40px;
  margin-bottom: 10px;
`

const InfoLabel = styled(Col)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 14px;
  `};
`
const InfoContent = styled(Col)`
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const Text = styled.div`
  text-align: left;
`
const Label = styled.div`
  font-size: 24px;
  font-weight: bold;
`
const Content = styled.div`
  font-size: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const SetupButton = styled.div`
  padding: 5px 30px;
  background: #9ce315;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  &:hover,
  &.active {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.3;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
    font-size: 16px;
  `};
`

const MainContentNoAccount = styled(MainContent)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SidebarTitle = styled.div`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  margin-top: 30px;
`

const ROFIAccountModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
    }
  }
`

const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const InputText = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 16px;
  &:disabled {
    color: #fff;
    cursor: not-allowed;
  }
`

const OTPButton = styled.div`
  border-radius: 10px;
  padding: 5px 10px 2px;
  width: 120px;
  height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5d5d5d !important;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #f6c931;
  }
  &.disable {
    cursor: default;
    &:hover {
      background: #fff;
    }
  }
`

const ConfirmButton = styled.div`
  border-radius: 10px;
  padding: 5px 10px 2px;
  width: 120px;
  height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  background: #9ce315;
  cursor: pointer;
  &:hover {
    background: #f6c931;
  }
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const SetupAccountText = styled.div`
  border-radius: 10px;
  padding: 5px 10px 2px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 16px;
  background: #9ce315;
  cursor: pointer;
  &:hover {
    background: #f6c931;
  }
`

const ReminderText = styled.div`
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
  margin-top: -10px;
`

const ErrorText = styled(ReminderText)`
  color: red;
`

export default function AccountPage() {
  const { account } = useActiveWeb3React()
  const [open_connect_email_modal, set_open_connect_email_modal] = useState(false)
  const [open_setup_account_modal, set_open_setup_account_modal] = useState(false)
  const [start_countdown, set_start_countdown] = useState(false)
  const [countdown_expired_at, set_countdown_expired_at] = useState(undefined)
  const [account_data, set_account_data] = useState(undefined)
  const [change_account, set_change_account] = useState(false)
  const [email, set_email] = useState(undefined)
  const [otp, set_otp] = useState(undefined)
  const [error, set_error] = useState(undefined)
  const [is_sending_OTP, set_is_sending_OTP] = useState(false)
  const [is_verifing_OTP, set_is_verifing_OTP] = useState(false)
  const [fetching_data, set_is_fetching_data] = useState(false)

  useEffect(() => {
    fetchAccountInfo()
    const otp_expired_at = localStorage.getItem('otp_expired_at')
    set_countdown_expired_at(otp_expired_at)
    window.ethereum &&
      window.ethereum.on('accountsChanged', () => {
        localStorage.removeItem('token_api')
        set_account_data(undefined)
        set_change_account(true)
        set_is_fetching_data(false)
      })
  }, [account])

  const get_token_api = async () => {
    const message = await signLoginMessage()
    // fetch token here
    const url = `${ROFI_ACCOUNT.AUTH}`
    try {
      const response = await axios({
        method: 'post',
        url: url,
        headers: {
          contentType: 'application/json',
        },
        data: {
          token: message,
        },
      })
      if (response.status === 200) {
        const token = response.data.accessToken
        if (!token) return
        localStorage.setItem('token_api', token)
        fetchAccountInfo()
      }
    } catch (error) {
      console.error('error', error)
    }
  }
  const signLoginMessage = async () => {
    const web3_current_provider = new Web3(window.ethereum)
    try {
      const message = new Date().getTime() + '.' + account + '.herofi.io'
      const signedSignature = await web3_current_provider.eth.personal.sign(message, account)
      return `${message}-${signedSignature}`
    } catch (e) {
      console.log(e)
    }
  }

  const fetchAccountInfo = async () => {
    set_is_fetching_data(true)
    const token_api = localStorage.getItem('token_api')
    if (!token_api) {
      return await get_token_api()
    }
    const url = `${ROFI_ACCOUNT.INFO}`
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          Authorization: `Bearer ${token_api}`,
        },
        data: {},
      })
      if (response.status === 200) {
        const rofi_id = response.data.data
        if (!rofi_id) return get_token_api()
        if (rofi_id.wallet.toLowerCase() !== account.toLowerCase()) {
          localStorage.removeItem('token_api')
          set_account_data(undefined)
          fetchAccountInfo()
          return
        }
        set_account_data(rofi_id)
        set_change_account(false)
        set_is_fetching_data(false)
        return
      } else {
        console.error(response)
        localStorage.removeItem('token_api')
        set_account_data(undefined)
        fetchAccountInfo()
        set_is_fetching_data(false)
        return
      }
    } catch (error) {
      console.error('here', error)
      localStorage.removeItem('token_api')
      set_account_data(undefined)
      set_is_fetching_data(false)
      return
    }
  }

  const return_error = (code) => {
    switch (code) {
      case 106:
        return 'OTP expired!'
      case 107:
        return 'Wrong OTP!'
      case 121:
        return 'Email linked to other account.'
      case 122:
        return 'User linked email to account.'
      case 123:
        return 'Send OTP error! Please try again.'
      case 124:
        return 'Update email failed! Please try again.'
      case 125:
        return 'Newest OTP still available. Please check your email.'
      default:
        return ''
    }
  }

  const validate_email = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) return true
    return false
  }

  const send_otp = async () => {
    set_is_sending_OTP(true)
    const token_api = localStorage.getItem('token_api')
    if (!email || !token_api) return
    const url = `${ROFI_ACCOUNT.OTP}`
    try {
      const response = await axios({
        method: 'post',
        url: url,
        headers: {
          Authorization: `Bearer ${token_api}`,
          contentType: 'application/json',
        },
        data: {
          email: email,
        },
        validateStatus: (status) => {
          return status === 400 || status === 200
        },
      })
      console.log('send_otp', response)
      if (response.status === 200) {
        const data = response.data
        set_is_sending_OTP(false)
        if (data.code !== 0) return set_error(return_error(response.data.code))
        set_start_countdown(true)
        const otp_expired_at = Date.now() + 300 * 1000
        localStorage.setItem('otp_expired_at', otp_expired_at)
        set_countdown_expired_at(otp_expired_at)
      } else {
        console.error('send_otp', response)
        set_is_sending_OTP(false)
        set_error(return_error(response.data.code))
        // NotificationManager.error(return_error(response.data.code), 'Error', 1000)
        return
      }
    } catch (error) {
      console.error(error)
      set_is_sending_OTP(false)
      NotificationManager.error(error.data ? error.data.message : 'Please try again later.', 'Error', 1000)
    }
  }

  const verify_otp = async () => {
    set_is_verifing_OTP(true)
    const token_api = localStorage.getItem('token_api')
    if (!email || !otp || !token_api) return
    const url = `${ROFI_ACCOUNT.VERIFY}`
    try {
      const response = await axios({
        method: 'post',
        url: url,
        headers: {
          Authorization: `Bearer ${token_api}`,
          contentType: 'application/json',
        },
        data: {
          email: email,
          otp: otp,
        },
        validateStatus: (status) => {
          return status === 400 || status === 200
        },
      })
      console.log('verify_otp', response)
      if (response.status === 200) {
        const data = response.data
        set_is_verifing_OTP(false)
        if (data.code !== 0) return set_error(return_error(response.data.code))
        fetchAccountInfo()
        set_open_connect_email_modal(false)
        return
      }
    } catch (error) {
      set_is_sending_OTP(false)
      console.error(error)
    }
  }

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      set_start_countdown(false)
      return <>Send OTP</>
    } else {
      return (
        <>
          <div>
            {minutes}:{seconds}
          </div>
        </>
      )
    }
  }

  const renderConnectWalletForm = () => {
    return (
      <div>
        <InputLine>
          <InputText
            type="text"
            placeholder="Enter your email address"
            onChange={(e) => {
              set_email(e.target.value)
              set_error(undefined)
            }}
            disabled={start_countdown}
          />
          <OTPButton
            onClick={() => {
              if (start_countdown) return
              if (!validate_email(email)) return set_error('Invalid email address.')
              send_otp()
            }}
            className={start_countdown || is_sending_OTP ? 'disable' : ''}
          >
            {start_countdown ? (
              <Countdown
                date={countdown_expired_at || Date.now() + 300 * 1000}
                renderer={renderCountdown}
                zeroPadTime={2}
              />
            ) : (
              <>{is_sending_OTP ? 'Loading...' : 'Send OTP'}</>
            )}
          </OTPButton>
        </InputLine>
        {start_countdown && <ReminderText>Please check the OTP in your email inbox</ReminderText>}
        <InputLine>
          <InputText type="text" placeholder="Enter OTP" onChange={(e) => set_otp(e.target.value)} />
        </InputLine>
        {error && <ErrorText>{error}</ErrorText>}
        <Action>
          {!email || !otp ? (
            <ConfirmButton className="disable">CONFIRM</ConfirmButton>
          ) : (
            <ConfirmButton onClick={verify_otp}>{is_verifing_OTP ? 'LOADING ...' : 'CONFIRM'}</ConfirmButton>
          )}
        </Action>
      </div>
    )
  }

  const renderSetupAccountForm = () => {
    return (
      <div>
        <InputLine>
          <InputText type="text" placeholder="Username" />
        </InputLine>
        <InputLine>
          <InputText type="password" placeholder="Password" />
        </InputLine>
        <Action>
          <ConfirmButton>CONFIRM</ConfirmButton>
        </Action>
      </div>
    )
  }

  if (fetching_data) {
    return (
      <MainContentNoAccount>
        <div style={{ textAlign: 'center', fontSize: 24 }}>
          <div>Loading data...</div>
          <div style={{ fontSize: 18 }}>Please sign the message on your wallet</div>
        </div>
      </MainContentNoAccount>
    )
  }

  return (
    <>
      {account_data ? (
        <MainContent>
          {account_data.email === null && (
            <SetupAccount>
              <Text>
                <Label>Connect email</Label>
                <Content>You need to connect your wallet - email to sync blockchain assets into game.</Content>
              </Text>
              <SetupButton onClick={() => set_open_connect_email_modal(true)}>Connect</SetupButton>
            </SetupAccount>
          )}
          <AccountInfo>
            <InformationWrapper>
              <InformationTitle>Account Information</InformationTitle>
              <InformationContainer>
                <InformationRow>
                  <InfoLabel span={6}>Wallet</InfoLabel>
                  <InfoContent span={18}>{account}</InfoContent>
                </InformationRow>
                <InformationRow>
                  <InfoLabel span={6}>Email</InfoLabel>
                  <InfoContent span={18}>
                    {account_data.email === null ? 'not connect' : account_data.email}
                  </InfoContent>
                </InformationRow>
                {/* <InformationRow>
                  <InfoLabel span={6}>Username</InfoLabel>
                  <InfoContent span={18}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      {account_data.needToSetup ? 'not setup' : account_data.userName}
                      {account_data.needToSetup && (
                        <SetupAccountText onClick={() => set_open_setup_account_modal(true)}>
                          Setup
                        </SetupAccountText>
                      )}
                    </div>
                  </InfoContent>
                </InformationRow> */}
              </InformationContainer>
            </InformationWrapper>
          </AccountInfo>
        </MainContent>
      ) : (
        <MainContentNoAccount>
          {change_account ? (
            <div style={{ textAlign: 'center', fontSize: 24 }}>
              <div>You have changed wallet address.</div>
              <div>Please sign message from your wallet to activate this page.</div>
            </div>
          ) : (
            <div style={{ textAlign: 'center', fontSize: 24 }}>
              {!account ? (
                <>
                  <div>Please connect wallet to activate this page.</div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <UnlockButton />
                  </div>
                </>
              ) : (
                <div>Please sign message from your wallet to activate this page.</div>
              )}
            </div>
          )}
        </MainContentNoAccount>
      )}
      {open_connect_email_modal && (
        <ROFIAccountModal
          className="connect-email-modal"
          title="CONNECT EMAIL"
          visible={open_connect_email_modal}
          onCancel={() => {
            set_open_connect_email_modal(false)
            set_error(undefined)
            set_start_countdown(false)
          }}
          footer={null}
        >
          {renderConnectWalletForm()}
        </ROFIAccountModal>
      )}
      {open_setup_account_modal && (
        <ROFIAccountModal
          className="setup-account-modal"
          title="SETUP ACCOUNT"
          visible={open_setup_account_modal}
          onCancel={() => set_open_setup_account_modal(false)}
          footer={null}
        >
          {renderSetupAccountForm()}
        </ROFIAccountModal>
      )}
    </>
  )
}
