import GoldBox from 'assets/pefi/gacha-box/5_Gold_Box.png'
import PlatinumBox from 'assets/pefi/gacha-box/6_Platinum_Box.png'
import HeraldBox from 'assets/pefi/gacha-box/7_Herald_Box.png'
import RubyBox from 'assets/pefi/gacha-box/8_Ruby_Box.png'
import DiamondBox from 'assets/pefi/gacha-box/9_Diamond_Box.png'

export const BoxConfig = [
  {
    box_id: 1,
    title: 'GACHA BOX',
    name: 'Gold Chest',
    img: GoldBox,
    price: 5999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 24,
  },
  {
    box_id: 2,
    title: 'GACHA BOX',
    name: 'Platinum Chest',
    img: PlatinumBox,
    price: 11999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 45,
  },
  {
    box_id: 3,
    title: 'GACHA BOX',
    name: 'Herald Chest',
    img: HeraldBox,
    price: 21999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 90,
  },
  {
    box_id: 4,
    title: 'GACHA BOX',
    name: 'Ruby Chest',
    img: RubyBox,
    price: 38999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 150,
  },
  {
    box_id: 5,
    title: 'GACHA BOX',
    name: 'Diamond Chest',
    img: DiamondBox,
    price: 68999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 330,
  },
  {
    box_id: 6,
    title: 'GACHA BOX',
    name: 'Gold Chest',
    img: GoldBox,
    price: 1399,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 24,
  },
  {
    box_id: 7,
    title: 'GACHA BOX',
    name: 'Platinum Chest',
    img: PlatinumBox,
    price: 2699,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 45,
  },
  {
    box_id: 8,
    title: 'GACHA BOX',
    name: 'Herald Chest',
    img: HeraldBox,
    price: 4999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 90,
  },
  {
    box_id: 9,
    title: 'GACHA BOX',
    name: 'Ruby Chest',
    img: RubyBox,
    price: 8999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 150,
  },
  {
    box_id: 10,
    title: 'GACHA BOX',
    name: 'Diamond Chest',
    img: DiamondBox,
    price: 15999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 330,
  },
  {
    box_id: 11,
    title: 'GACHA BOX',
    name: 'Gold Chest',
    img: GoldBox,
    price: 1399,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 24,
  },
  {
    box_id: 12,
    title: 'GACHA BOX',
    name: 'Platinum Chest',
    img: PlatinumBox,
    price: 2699,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 45,
  },
  {
    box_id: 13,
    title: 'GACHA BOX',
    name: 'Herald Chest',
    img: HeraldBox,
    price: 4999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 90,
  },
  {
    box_id: 14,
    title: 'GACHA BOX',
    name: 'Ruby Chest',
    img: RubyBox,
    price: 8999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 150,
  },
  {
    box_id: 15,
    title: 'GACHA BOX',
    name: 'Diamond Chest',
    img: DiamondBox,
    price: 15999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 330,
  },
]
