export const PieceConfig = [
  {
    box_id: 1,
    title: 'GACHA BOX',
    name: 'Gold Piece',
    img: 'https://plantempires-media.b-cdn.net/chest-piece/gold_piece.png',
    price: 5999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 24,
  },
  {
    box_id: 2,
    title: 'GACHA BOX',
    name: 'Platinum Piece',
    img: 'https://plantempires-media.b-cdn.net/chest-piece/platinum_piece.png',
    price: 11999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 45,
  },
  {
    box_id: 3,
    title: 'GACHA BOX',
    name: 'Herald Piece',
    img: 'https://plantempires-media.b-cdn.net/chest-piece/herald_piece.png',
    price: 21999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 90,
  },
  {
    box_id: 4,
    title: 'GACHA BOX',
    name: 'Ruby Piece',
    img: 'https://plantempires-media.b-cdn.net/chest-piece/ruby_piece.png',
    price: 38999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 150,
  },
  {
    box_id: 5,
    title: 'GACHA BOX',
    name: 'Diamond Piece',
    img: 'https://plantempires-media.b-cdn.net/chest-piece/diamond_piece.png',
    price: 68999,
    total: 0,
    opened: 0,
    stock: 0,
    bonus: 330,
  },
]
