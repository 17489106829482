import axios from 'axios'
import { HERO_MARKET_SERVER } from '../../constants/endpoints'
import { NftGraphEntity } from 'state/types'

export interface NftFilter {
  nftAddress: string
  chainId: number | undefined
  owner: string
}

export const getGraphNfts = async ({ nftAddress, owner, chainId }: NftFilter): Promise<NftGraphEntity[]> => {
  try {
    const response = await axios.get(`${HERO_MARKET_SERVER}/nfts/${owner}`)
    return response.data.data
  } catch (error) {
    console.error(error)
    return []
  }
}
