import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { fetchNfts } from '../state/nft'
import { NftFilter } from '../state/nft/getLotteriesData'

export default function useFetchNfts() {
  const dispatch = useAppDispatch()

  return useCallback(
    (filter: NftFilter) => {
      // @ts-ignore
      dispatch(fetchNfts(filter))
    },
    [dispatch]
  )
}
