import { WETH9, Token, Ether } from '@uniswap/sdk-core'
import { BUSD_ADDRESS, UNI_ADDRESS } from './addresses'
import { SupportedChainId } from './chains'

export const AMPL = new Token(
  SupportedChainId.MAINNET,
  '0x75c00cf8b6c932aad2e20fa238a57d389ae3842d',
  9,
  'AMPL',
  'Ampleforth'
)
export const DAI = new Token(
  SupportedChainId.MAINNET,
  '0xec5dcb5dbf4b114c9d0f65bccab49ec54f6a0867',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC = new Token(
  SupportedChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const USDC_ARBITRUM = new Token(
  SupportedChainId.ARBITRUM_ONE,
  '0xe865dF68133fcEd7c2285ff3896B406CAfAa2dB8',
  6,
  'USDC',
  'USD//C'
)
export const USDT = new Token(
  SupportedChainId.MAINNET,
  '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC = new Token(
  SupportedChainId.MAINNET,
  '0xf16ccc72a44edb06e75c652706eddae5736ef1b9',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const FEI = new Token(
  SupportedChainId.MAINNET,
  '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  18,
  'FEI',
  'Fei USD'
)
export const TRIBE = new Token(
  SupportedChainId.MAINNET,
  '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  18,
  'TRIBE',
  'Tribe'
)
export const FRAX = new Token(
  SupportedChainId.MAINNET,
  '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  18,
  'FRAX',
  'Frax'
)
export const FXS = new Token(
  SupportedChainId.MAINNET,
  '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  18,
  'FXS',
  'Frax Share'
)
export const renBTC = new Token(
  SupportedChainId.MAINNET,
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  8,
  'renBTC',
  'renBTC'
)
export const UMA = new Token(
  SupportedChainId.MAINNET,
  '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
  18,
  'UMA',
  'UMA Voting Token v1'
)
export const ETH2X_FLI = new Token(
  SupportedChainId.MAINNET,
  '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  18,
  'ETH2x-FLI',
  'ETH 2x Flexible Leverage Index'
)
// Mirror Protocol compat.
export const UST = new Token(
  SupportedChainId.MAINNET,
  '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
  18,
  'UST',
  'Wrapped UST'
)
export const MIR = new Token(
  SupportedChainId.MAINNET,
  '0x09a3ecafa817268f77be1283176b946c4ff2e608',
  18,
  'MIR',
  'Wrapped MIR'
)
export const UNI: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.RINKEBY, UNI_ADDRESS[4], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.BSC]: new Token(SupportedChainId.BSC, UNI_ADDRESS[56], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.BSC_TESTNET]: new Token(SupportedChainId.BSC_TESTNET, UNI_ADDRESS[97], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.ROPSTEN]: new Token(SupportedChainId.ROPSTEN, UNI_ADDRESS[3], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.GOERLI]: new Token(SupportedChainId.GOERLI, UNI_ADDRESS[5], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.KOVAN]: new Token(SupportedChainId.KOVAN, UNI_ADDRESS[42], 18, 'UNI', 'Uniswap'),
}

export const LZ: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, BUSD_ADDRESS[1], 18, 'BUSD', 'BUSD'),
  [SupportedChainId.BSC]: new Token(SupportedChainId.MAINNET, BUSD_ADDRESS[56], 18, 'BUSD', 'BUSD'),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.RINKEBY, BUSD_ADDRESS[4], 18, 'BUSD', 'BUSD'),
  [SupportedChainId.BSC_TESTNET]: new Token(SupportedChainId.BSC_TESTNET, BUSD_ADDRESS[97], 18, 'BUSD', 'BUSD'),
}

export const WETH9_EXTENDED: { [chainId: number]: Token } = {
  ...WETH9,
  [SupportedChainId.ARBITRUM_ONE]: new Token(
    SupportedChainId.ARBITRUM_ONE,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.ARBITRUM_RINKEBY]: new Token(
    SupportedChainId.ARBITRUM_RINKEBY,
    '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x120c5D1E07B7055eFEF9c94e7b2E3395df1EeC4a',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'Wrapped BNB'
  ),
  [SupportedChainId.BSC_TESTNET]: new Token(
    SupportedChainId.BSC_TESTNET,
    '0x120c5D1E07B7055eFEF9c94e7b2E3395df1EeC4a',
    18,
    'BNB-TEST',
    'BNB testnet'
  ),
}

export const HEROEGG_TOKEN: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0xcfBb1BfA710cb2ebA070CC3beC0C35226FeA4BAF',
    18,
    'HEROEGG',
    'HEROEGG'
  ),
}

export const ROFI_TOKEN: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64',
    18,
    'ROFI',
    'ROFI'
  ),
}

export const ESSENCE_POINT: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0x2fc5cd6e05a365ae39988ea4fe41b2e556b2b90f',
    18,
    'ESSPOINT',
    'ESSPOINT'
  ),
}

export const LGFI_TOKEN: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0xFfc78290f2ce29e7b3544F7FEef3426d32C9A946',
    18,
    'LGFI',
    'LGFI'
  ),
}

export const HFI_TOKEN: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0xB45b33dAb0c3E1287a2B689E3f717c1A775CF16d',
    18,
    'HFI',
    'HFI'
  ),
}

export const MP_TOKEN: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(SupportedChainId.BSC, '0x626Cf963Cd7BDa793B2E55679e3Ec3f36b51329D', 18, 'MP', 'MP'),
}

export const UNLOCK_LP: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0x46bC5607fC6aD331Ae7feC53ccb5Cf0865438363',
    18,
    'UNLOCKLP',
    'UNLOCKLP'
  ),
}

export const PEFI_TOKEN_ADDRESS: { [chainId: number]: Token } = {
  [SupportedChainId.BSC]: new Token(
    SupportedChainId.BSC,
    '0xa83Bfcf9E252Adf1F39937984A4E113Eda6E445b',
    18,
    'PEFI',
    'PEFI'
  ),
}

export class ExtendedEther extends Ether {
  public get wrapped(): Token {
    if (this.chainId in WETH9_EXTENDED) return WETH9_EXTENDED[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  private static _cachedEther: { [chainId: number]: ExtendedEther } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedEther[chainId] ?? (this._cachedEther[chainId] = new ExtendedEther(chainId))
  }
}
