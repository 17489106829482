import React from 'react'
import { Trans } from '@lingui/macro'
import Preview from 'components/PreviewCard/HolyPackCardPreview/Preview'
import styled from 'styled-components'
import { Nft } from 'constants/types'
import { ButtonConfirm } from 'components/Button'
import Text, { MagraText } from 'components/Text'
import Modal from './Modal'
import Close from 'assets/pefi/special-pack/close-button.png'

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  border-radius: 5px;
  padding: 20px 30px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr 1fr;
    padding: 15px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 20px;
  `}
`
const Container = styled.div`
  flex: 1;
  align-items: stretch;
  flex-direction: column;
  background: #026092;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  height: 100%;
  position: relative;
  &.empty {
    padding: 30px;
  }
`

const Title = styled.div`
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const HolyIngameCard = styled.div`
  overflow: hidden;
  position: relative;
`

const HolySelectButton = styled.div`
  background: #9ce315;
  padding: 7px 20px 3px;
  border-radius: 5px;
  margin: 15px auto 0;
  width: 100px;
  text-align: center;
  cursor: pointer;
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`

export default function SelectHeroForUpgradeStarModal({
  onConfirm,
  onDismiss,
  isOpen,
  nft,
  nfts,
  slot,
  materialHolySelected,
}: {
  isOpen: boolean
  onConfirm: (nft: Nft, slot: string) => void
  onDismiss: () => void
  nft: any | undefined
  nfts: any[] | []
  slot: string
  materialHolySelected: any
}) {
  const isMobile = window.innerWidth <= 500

  const isSelected = (nft: any) => {
    if (!materialHolySelected) return false
    const isSelected = Object.values(materialHolySelected).find((n: any) => n.tokenId === nft.tokenId)
    if (isSelected) {
      return true
    }
    return false
  }

  const match_className_holyType = (className: string) => {
    switch (className) {
      case 'DPS':
        return 'red'
      case 'Farmer':
        return 'blue'
      case 'Tanker':
        return 'yellow'
      case 'Supporter':
        return 'green'
      default:
        return ''
    }
  }

  const shownHoly = nft
    ? nfts.filter(
        (holy: any) =>
          holy.status === 'Available' && holy.holyType === match_className_holyType(nft.className) && !isSelected(holy)
      )
    : []

  const renderHolyCard = (nft: any) => {
    return (
      <HolyIngameCard>
        <Preview nft={nft} />
        <HolySelectButton onClick={() => onConfirm(nft, slot)}>Select</HolySelectButton>
      </HolyIngameCard>
    )
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {shownHoly.length === 0 ? (
        <Container className="empty">
          <Title className={`${isMobile ? ' mobile' : ''}`}>LIST EMPTY</Title>
          <div
            style={{
              color: '#fff8e8',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '30px 0',
              fontSize: isMobile ? 18 : 22,
              minHeight: '25vh',
              textAlign: 'center',
            }}
          >
            No HolyPacks available.
          </div>
          <ButtonConfirm
            onClick={onDismiss}
            disabled={false}
            style={{ margin: '10px auto', height: '57px', width: '30%' }}
          >
            {isMobile ? (
              <MagraText fontSize={18} fontWeight={600}>
                <Trans>GOT IT</Trans>
              </MagraText>
            ) : (
              <MagraText fontSize={28} fontWeight={600}>
                <Trans>GOT IT</Trans>
              </MagraText>
            )}
          </ButtonConfirm>
        </Container>
      ) : (
        <Container>
          <Title>Choose Material Holy</Title>
          <Main>
            {shownHoly.map((nft: any, index: any) => (
              <div key={index}>{renderHolyCard(nft)}</div>
            ))}
          </Main>
          <CloseIcon src={Close} onClick={onDismiss} />
        </Container>
      )}
    </Modal>
  )
}
