import confirmFilter from 'assets/pefi/my-assets/confirm-filter.png'
import cancelFilter from 'assets/pefi/my-assets/sell-modal/cancel.png'
import Text from 'components/Text'
import { PEBoxFilterConditions } from 'constants/types'
import React, { useState } from 'react'
import styled from 'styled-components'

const FilterCriterion = styled.p`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 28px;
  line-height: 34.61px;
  padding: 30px 0 5px;
`
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const StarLabel = styled.div`
  margin: 20px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export interface FilterProps {
  id: string
  onConfirm: (filter: PEBoxFilterConditions) => void
  onCancel?: () => void
}
const Checkbox = styled.input`
  background-color: #fff;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid #cecece;
  border-radius: 3px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
  :checked {
    appearance: none;
    border-radius: 5px;
    background-color: #0ac5d1;
  }
`
const CheckboxContent = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #cecece;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
const Action = styled(Row)`
  margin-top: 30px;
  height: 30px;
`

const ButtonConfirm = styled.img`
  margin: 0 auto;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    margin: 10px auto;
  `};
`
const Button = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const Filter = ({ onConfirm, id, onCancel }: FilterProps) => {
  const [gold, setGold] = useState<boolean>(false)
  const [platinum, setPlatinum] = useState<boolean>(false)
  const [herald, setHerald] = useState<boolean>(false)
  const [ruby, setRuby] = useState<boolean>(false)
  const [diamond, setDiamond] = useState<boolean>(false)

  const handleConfirm = () => {
    const rarityNameSet = new Set<string>()
    if (gold) {
      rarityNameSet.add('1')
      rarityNameSet.add('6')
      rarityNameSet.add('11')
    } else {
      rarityNameSet.delete('1')
      rarityNameSet.delete('6')
      rarityNameSet.delete('11')
    }
    if (platinum) {
      rarityNameSet.add('2')
      rarityNameSet.add('7')
      rarityNameSet.add('12')
    } else {
      rarityNameSet.delete('2')
      rarityNameSet.delete('7')
      rarityNameSet.delete('12')
    }
    if (herald) {
      rarityNameSet.add('3')
      rarityNameSet.add('8')
      rarityNameSet.add('13')
    } else {
      rarityNameSet.delete('3')
      rarityNameSet.delete('8')
      rarityNameSet.delete('13')
    }
    if (ruby) {
      rarityNameSet.add('4')
      rarityNameSet.add('9')
      rarityNameSet.add('14')
    } else {
      rarityNameSet.delete('4')
      rarityNameSet.delete('9')
      rarityNameSet.delete('14')
    }
    if (diamond) {
      rarityNameSet.add('5')
      rarityNameSet.add('10')
      rarityNameSet.add('15')
    } else {
      rarityNameSet.delete('5')
      rarityNameSet.delete('10')
      rarityNameSet.delete('15')
    }

    const newFilter = {
      rarityNames: Array.from(rarityNameSet),
    }
    onConfirm(newFilter)
  }
  return (
    <FilterWrapper>
      <div id={id} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <FilterCriterion>Rarity</FilterCriterion>
        <Row>
          <Checkbox name="water" type="checkbox" checked={gold} onClick={() => setGold(!gold)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Gold Chest</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="plant" type="checkbox" checked={platinum} onClick={() => setPlatinum(!platinum)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Platinum Chest</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="fire" type="checkbox" checked={herald} onClick={() => setHerald(!herald)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Herald Chest</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="thunder" type="checkbox" checked={ruby} onClick={() => setRuby(!ruby)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Ruby Chest</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="thunder" type="checkbox" checked={diamond} onClick={() => setDiamond(!diamond)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Diamond Chest</Text>
          </div>
        </Row>{' '}
      </div>
      <Action>
        {typeof onCancel === 'function' && (
          <Button style={{ background: '#f6c931' }} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button onClick={handleConfirm}>Confirm</Button>
      </Action>
    </FilterWrapper>
  )
}
export default Filter
