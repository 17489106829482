import { useMemo } from 'react'

import { useERC721TokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

function useTokenApproved(tokenAddress: string, tokenId?: string): string | undefined {
  const contract = useERC721TokenContract(tokenAddress, false)

  const inputs = useMemo(() => [tokenId], [tokenId])
  const addressApproved = useSingleCallResult(contract, 'getApproved', inputs).result

  return useMemo(
    () => (tokenAddress && addressApproved ? addressApproved.toString() : undefined),
    [tokenAddress, addressApproved]
  )
}

export default useTokenApproved
