import { useActiveWeb3React } from './web3'
import { BUSD_ADDRESS } from '../constants/addresses'
import { useMemo } from 'react'

export default function useBUSDAddress(): string | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (chainId) {
      return BUSD_ADDRESS[chainId]
    } else {
      return undefined
    }
  }, [chainId])
}
