import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import { MagraText } from 'components/Text'
import copy from 'copy-to-clipboard'
import step1 from 'assets/images/step-1.png'
import step2 from 'assets/images/step-2.png'
import step3 from 'assets/images/step-3.png'
import spinner from 'assets/images/Spinner.gif'

import './style.scss'
import { useActiveWeb3React } from 'hooks/web3'
import UnlockButton from './UnlockButton'
import Footer from 'components/Footer'
import axios from 'axios'

export default function AppBody() {
  const { account } = useActiveWeb3React()
  const [message, set_message] = useState('')
  const [signedSignature, set_signedSignature] = useState('')
  const [qrcode_url, set_qrcode_url] = useState('')
  const [copied, set_copied] = useState(false)

  useEffect(() => {
    window.ethereum &&
      window.ethereum.on('accountsChanged', () => {
        set_message('')
        set_signedSignature('')
      })
  }, [])

  const generate_code = async () => {
    const web3_current_provider = new Web3(window.ethereum)
    try {
      const message = new Date().getTime() + '.' + account + '.herofi.io'
      set_message(message)
      const signedSignature = await web3_current_provider.eth.personal.sign(message, account)
      set_signedSignature(signedSignature)
      renderQRcode(message, signedSignature)
    } catch (e) {
      console.log(e)
    }
  }

  const renderQRcode = (message, signature) => {
    const encoded = encodeURIComponent(`${message}-${signature}`)
    const url = `https://chart.googleapis.com/chart?cht=qr&chl=${encoded}&chs=200x200&chld=L|0`
    axios.get(url).then((response) => {
      if (response.status === 200) {
        set_qrcode_url(url)
      }
    })
  }

  const copy_code = (message) => {
    copy(message)
    set_copied(true)
    setTimeout(() => {
      set_copied(false)
    }, 2000)
  }

  if (!account) {
    return (
      <div className="app-body">
        <div className="container first">
          <div className="content">
            <div className="description">Connect your wallet using web3 wallet (Metamask, LZ wallet ...)</div>
            <div className="button-border">
              <UnlockButton />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (message !== '' && signedSignature !== '') {
    return (
      <>
        <div className="app-body">
          <div className="container">
            <div className="content">
              <div className="description">Code generated! Do not share this code to anyone else!</div>
              <img className="qr-code" src={qrcode_url === '' ? spinner : qrcode_url} />
              <MagraText fontWeight={700} fontSize={28}>
                OR
              </MagraText>
              {/* <div className="message">{message}</div> */}
              <div className="button-border">
                <div className="button" onClick={() => copy_code(`${message}-${signedSignature}`)}>
                  <MagraText fontWeight={700} fontSize={28}>
                    Copy Code
                  </MagraText>
                </div>
              </div>
              {copied && <div className="copied">Copied!</div>}
            </div>
            <div className="line"></div>
            <div className="steps">
              <div className="step">
                <img src={step1} alt />
                <div>Open the HeroFi game</div>
              </div>
              <div className="step">
                <img src={step2} alt />
                <div>Tap the menu, then tap the QR code</div>
              </div>
              <div className="step">
                <img src={step3} alt />
                <div>Point the camera at the QR code above</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
  return (
    <>
      <div className="app-body">
        <div className="container first">
          <div className="content">
            <div className="description">Generate Code here and use it to login to Plant Empires!</div>
            <div className="button-border">
              <div className="button" onClick={() => generate_code()}>
                <MagraText fontWeight={700} fontSize={28}>
                  Generate Code
                </MagraText>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
