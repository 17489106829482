import confirmFilter from 'assets/pefi/my-assets/confirm-filter.png'
import cancelFilter from 'assets/pefi/my-assets/sell-modal/cancel.png'
import Text from 'components/Text'
import { PEFilterConditions } from 'constants/types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const FilterCriterion = styled.div`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 28px;
  line-height: 34.61px;
  padding: 30px 0 5px;
  ${({ theme }) => theme.mediaWidth.upTo1440`
    font-size: 28px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 24px;
  `}
`
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const StarLabel = styled.div`
  margin: 20px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export interface FilterProps {
  id: string
  onConfirm: (filter: PEFilterConditions) => void
  onCancel?: () => void
}
const Checkbox = styled.input`
  background-color: #fff;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid #cecece;
  border-radius: 3px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
  :checked {
    appearance: none;
    border-radius: 5px;
    background-color: #0ac5d1;
  }
`
const CheckboxContent = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #cecece;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
const Action = styled(Row)`
  margin-top: 30px;
  height: 30px;
`

const ButtonConfirm = styled.img`
  margin: 0 auto;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    margin: 10px auto;
  `};
`

const Button = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const Filter = ({ onConfirm, id, onCancel }: FilterProps) => {
  const [farmer, setFarmer] = useState<boolean>(false)
  const [dps, setDPS] = useState<boolean>(false)
  const [tanker, setTanker] = useState<boolean>(false)
  const [supporter, setSupporter] = useState<boolean>(false)
  const [common, setCommon] = useState<boolean>(false)
  const [uncommon, setUncommon] = useState<boolean>(false)
  const [rare, setRare] = useState<boolean>(false)
  const [mythical, setMythical] = useState<boolean>(false)
  const [legendary, setLegendary] = useState<boolean>(false)
  const [starFrom, setStarFrom] = useState<number>(3)
  const [starTo, setStarTo] = useState<number>(6)
  useEffect(() => {
    // @ts-ignore
    const elem = $(`#${id} #star-slide-range`)
    elem.slider({
      range: true,
      min: 3,
      max: 6,
      values: [starFrom, starTo],
      // @ts-ignore
      slide: function (event, ui) {
        const [low, high] = ui.values
        setStarFrom(low)
        setStarTo(high)
      },
    })
  }, [])

  const handleConfirm = () => {
    const classNameSet = new Set<string>()
    if (farmer) {
      classNameSet.add('Farmer')
    } else {
      classNameSet.delete('Farmer')
    }
    if (dps) {
      classNameSet.add('DPS')
    } else {
      classNameSet.delete('DPS')
    }
    if (tanker) {
      classNameSet.add('Tanker')
    } else {
      classNameSet.delete('Tanker')
    }
    if (supporter) {
      classNameSet.add('Supporter')
    } else {
      classNameSet.delete('Supporter')
    }

    const rarityNameSet = new Set<string>()
    if (common) {
      rarityNameSet.add('Common')
    } else {
      rarityNameSet.delete('Common')
    }
    if (uncommon) {
      rarityNameSet.add('Uncommon')
    } else {
      rarityNameSet.delete('Uncommon')
    }
    if (rare) {
      rarityNameSet.add('Rare')
    } else {
      rarityNameSet.delete('Rare')
    }
    if (mythical) {
      rarityNameSet.add('Mythical')
    } else {
      rarityNameSet.delete('Mythical')
    }
    if (legendary) {
      rarityNameSet.add('Legendary')
    } else {
      rarityNameSet.delete('Legendary')
    }

    const newFilter = {
      classNames: Array.from(classNameSet),
      rarityNames: Array.from(rarityNameSet),
      star: {
        from: starFrom,
        to: starTo,
      },
    }
    onConfirm(newFilter)
  }
  return (
    <FilterWrapper>
      <div id={id} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <FilterCriterion>Rarity</FilterCriterion>
        {/* <Row>
          <Checkbox name="water" type="checkbox" checked={common} onClick={() => setCommon(!common)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Common</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="plant" type="checkbox" checked={uncommon} onClick={() => setUncommon(!uncommon)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Uncommon</Text>
          </div>
        </Row> */}
        <Row>
          <Checkbox name="fire" type="checkbox" checked={rare} onClick={() => setRare(!rare)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Rare</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="thunder" type="checkbox" checked={mythical} onClick={() => setMythical(!mythical)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Mythical</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="thunder" type="checkbox" checked={legendary} onClick={() => setLegendary(!legendary)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Legendary</Text>
          </div>
        </Row>{' '}
        {/* <Divider /> */}
        <FilterCriterion>Class</FilterCriterion>
        <Row>
          <Checkbox name="water" type="checkbox" checked={farmer} onClick={() => setFarmer(!farmer)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Farmer</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="plant" type="checkbox" checked={dps} onClick={() => setDPS(!dps)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>DPS</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="fire" type="checkbox" checked={tanker} onClick={() => setTanker(!tanker)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Tanker</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="thunder" type="checkbox" checked={supporter} onClick={() => setSupporter(!supporter)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Supporter</Text>
          </div>
        </Row>{' '}
        {/* <Divider /> */}
        <FilterCriterion>STARS</FilterCriterion>
        <div id="star-slide-range" style={{ marginTop: '10px', marginBottom: '10px', marginRight: '6px' }}></div>
        <StarLabel>
          <Text>3</Text>
          <Text>4</Text>
          <Text>5</Text>
          <Text>6</Text>
        </StarLabel>
      </div>
      <Action>
        {typeof onCancel === 'function' && (
          <Button style={{ background: '#f6c931' }} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button onClick={handleConfirm}>Confirm</Button>
      </Action>
    </FilterWrapper>
  )
}
export default Filter
