import React from 'react'
import HolyPackBlue from 'assets/pefi/holy/holypack_blue.png'
import HolyPackGreen from 'assets/pefi/holy/holypack_green.png'
import HolyPackRed from 'assets/pefi/holy/holypack_red.png'
import HolyPackYellow from 'assets/pefi/holy/holypack_yellow.png'
import { Text } from 'rebass'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
`

const Card = styled.div`
  background-color: transparent;
  overflow: hidden;
`

const CardBody = styled.div`
  overflow: hidden;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`
const PropertyRow = styled(Row)`
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 5px 13px;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const Property = styled.div`
  color: #fff8e8;
  display: flex;
  align-items: center;
`

const HolyPreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const NftID = styled.p`
  position: absolute;
  bottom: 0;
  margin: 10px 0px;
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  font-family: Gudea-Medium;
  text-align: center;
  align-self: center;
  color: #fff8e8;
`

export default function Item({ nft }: { nft: any }) {
  const parseHolyTypeToString = (type: number) => {
    switch (type) {
      case 1:
        return 'green'
      case 2:
        return 'red'
      case 3:
        return 'blue'
      default:
        return 'yellow'
    }
  }

  const selectHolyPackageImage = (type: string) => {
    switch (type) {
      case 'green':
        return HolyPackGreen
      case 'red':
        return HolyPackRed
      case 'blue':
        return HolyPackBlue
      case 'yellow':
        return HolyPackYellow
      default:
        return undefined
    }
  }

  const type = parseHolyTypeToString(nft.holyType)
  return (
    <Container>
      <Card>
        <div style={{ display: 'flex', position: 'relative' }}>
          <HolyPreview src={selectHolyPackageImage(type)} />
          <NftID>#{nft.tokenId}</NftID>
        </div>
      </Card>
    </Container>
  )
}
