import Footer from 'components/Footer'
import React, { useState } from 'react'
import styled from 'styled-components'
import AppBody from '../../AppBody'
import PEFI from './PEFI'
import { Redirect, RouteComponentProps } from 'react-router-dom'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const ShopBody = styled(AppBody)`
  display: flex;
  flex-direction: row;
`

const ShopItemArea = styled.div`
  flex: 4;
  padding: 15px;
  max-width: 70vw;
  margin: 50px auto 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100vw;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 90vw;
  `}
`

export default function Tokens({ setPageState }: { setPageState: (state: string) => void }) {
  // const server = localStorage.getItem('server')
  // if (!server) return <Redirect to={{ ...location, pathname: '/' }} />
  return (
    <>
      <ShopBody {...{ maxWidth: '2000px' }}>
        <ShopItemArea>
          <PEFI />
        </ShopItemArea>
      </ShopBody>
    </>
  )
}
