import React, { useState } from 'react'
import Text, { MagraText } from '../../../Text'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import { ButtonConfirm } from '../../../Button'
import { Nft } from '../../../../constants/types'
import { Dots } from '../../../../pages/styleds'
import { isAddress } from 'ethers/lib/utils'
import { useActiveWeb3React } from 'hooks/web3'
import buttonCancel from 'assets/pefi/my-assets/sell-modal/cancel.png'
import buttonSell from 'assets/pefi/my-assets/sell-modal/confirm.png'
import buttonSelling from 'assets/pefi/my-assets/sell-modal/loading.png'

const AddressInput = styled.input`
  border-radius: 5px;
  width: 100%;
  font-size: 1.25rem;
  outline: none;
  border: none;
  height: 40px;
  color: #2b222b;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  padding-right: 15%;
  padding-left: 21px;
  background-color: #fff;

  ::placeholder {
    color: #2b222b;
    font-family: Gudea-Medium;
    font-weight: 400;
    font-size: 18px;
  }
`
const Action = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const Content = styled.div`
  flex: 3;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
  `}
`
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: block;
`

const Error = styled.div`
  color: red;
  margin-top: 20px;
`
const ButtonSell = styled.img`
  cursor: pointer;
  margin-top: 20px;
`

export default function GiftDetail({
  onGift,
  nft,
  attemptingTxn,
  onDismiss,
}: {
  onGift: (nft: Nft, receiver: string) => void
  onDismiss: () => void
  nft: Nft
  attemptingTxn: boolean
}) {
  const { account } = useActiveWeb3React()
  const [receiverAddress, setReceiverAddress] = useState<string>('')
  const [error, setError] = useState<any>(undefined)

  const onChangeAddress = (value: any) => {
    setReceiverAddress(value)
    if (value.toLowerCase() === account?.toLowerCase()) {
      setError('Send to yourself. Please check again.')
      return
    }
    if (!isAddress(value)) {
      setError('Invalid address')
      return
    }
    if (value === '' || isAddress(value)) {
      setError(undefined)
      return
    }
  }
  return (
    <Container>
      <Content>
        <Text margin="0px 0px 30px 0px">
          You are about to gift this Hero to another person.
          <br />
          Please enter receiver&lsquo;s wallet address!
        </Text>
        <AddressInput
          placeholder="Receiver's wallet address"
          value={receiverAddress}
          onChange={(event) => onChangeAddress(event.target.value)}
        />
        {error && <Error>{error}</Error>}
      </Content>
      <Action>
        <ButtonSell src={buttonCancel} onClick={onDismiss} />
        {attemptingTxn ? (
          <ButtonSell src={buttonSelling} />
        ) : (
          <>
            {error || receiverAddress === '' ? (
              <ButtonSell src={buttonSell} style={{ cursor: 'not-allowed', opacity: '0.3' }} />
            ) : (
              <ButtonSell src={buttonSell} onClick={() => onGift(nft, receiverAddress)} />
            )}
          </>
        )}
      </Action>
    </Container>
  )
}
