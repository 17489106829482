import styled from 'styled-components'

const NftGrid = styled.div`
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 24px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-template-columns: 1fr 1fr 1fr;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 1fr 1fr;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `}
`

export default NftGrid
