import { TransactionResponse } from '@ethersproject/providers'
import { parseUnits } from '@ethersproject/units'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import PE_GEM_SHOP_ABI from 'abis/pe-gem-shop-abi.json'
import buttonApprove from 'assets/pefi/my-assets/sell-modal/approve.png'
import buttonApproving from 'assets/pefi/my-assets/sell-modal/approving.png'
import buttonBuy from 'assets/pefi/shop/button-buy.png'
import buttonLoading from 'assets/pefi/my-assets/sell-modal/loading.png'
import PEFITokenLogo from 'assets/pefi/pefi.png'
import BUSDLogo from 'assets/images/busd-logo.svg'
import discountBg from 'assets/pefi/shop/discount-bg.png'
import Footer from 'components/Footer'
import ConfirmModal from 'components/shoppage/BuyPackResult/ConfirmationModal'
import { PEFI_TOKEN_ADDRESS, PE_GEM_SHOP_ADDRESS, S2_GEM_SHOP_ADDRESS } from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { SHOP_ENDPOINT } from 'constants/mkpconfigs'
import { useCurrency as UseCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback as UseApproveCallback } from 'hooks/useApproveCallback'
import { useGemShopContract, useGemShopContractS2 } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import AppBody from 'pages/AppBody'
import React, { useMemo, useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { GEM_PACK } from './configShopData'
import UnlockButton from '../UnlockButton'
import { getPEFiPrice } from 'utils/getPefiPrice'
import { INGAME_ACCOUNT } from 'constants/mkpconfigs'
import axios from 'axios'
import { Modal } from 'antd'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const ShopBody = styled(AppBody)`
  display: flex;
  flex-direction: row;
  max-width: 2000px;
`

const ShopItemArea = styled.div`
  flex: 4;
  padding: 15px;
  max-width: 70vw;
  margin: 50px auto 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100vw;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 90vw;
  `}
`

const Container = styled.div`
  padding: 50px 0 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr 1fr!important;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr!important;
  `}
`

const PackItem = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const PackInfo = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  margin-bottom 20px;
  .pack-img {
    width: 100%;
    height: 140px;
    margin: 20px auto 0;
  }
  .bonus {
    color: #d47b20;
  }
`

const GemAmount = styled.div`
  position: absolute;
  top: 5px;
  .pack-number {
    font-size: 25px;
    font-weight: 600;
    padding: 0;
    color: #f2c32a;
    text-align: center;
    &.old {
      margin-top: -5px;
      font-size: 22px;
      font-weight: 500;
      padding: 0;
      text-decoration: line-through;
    }
  }
`

const Discount = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: url(${discountBg});
  position: absolute;
  width: 150px;
  height: 150px;
  top: -3px;
  left: -3px;
  transition: opacity 1s linear;
  object-fit: cover;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  z-index: 999;
`

const DiscountContent = styled.div`
  font-size: 35px;
  transform: rotate(-45deg);
  position: absolute;
  top: 23px;
  left: 23px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 30px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 25px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 23px;
  `}
`

const PackPrice = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  background: #026092;
  padding-top: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .price {
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
      .busd {
        font-weight: 400;
        font-size: 16px;
      }
    }
    img {
      max-width: 25px;
    }
  }
`

const ButtonSell = styled.img`
  cursor: pointer;
`

const ROFIAccountModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`
const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RewardDetail = styled.div`
  width: fit-content;
  position: relative;
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 0 20px;
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 23px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const CheckinButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &:hover,
  &.disable {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

export default function Shop() {
  const { chainId, account } = useActiveWeb3React()
  const [shownPacks, setShownPacks] = useState<any[]>(GEM_PACK)

  const [{ isShowModal, selectedPack }, setResult] = useState<{
    selectedPack: any
    isShowModal: boolean
  }>({
    selectedPack: undefined,
    isShowModal: false,
  })

  const [{ attemptingTxn, selectedPackId }, setAttemptingTxn] = useState<{
    attemptingTxn: any
    selectedPackId: any
  }>({
    attemptingTxn: false,
    selectedPackId: undefined,
  })

  const server = localStorage.getItem('server')

  const shopContractS1 = useGemShopContract()
  const shopContractS2 = useGemShopContractS2()
  const shopContract = server ? (server === 's1' ? shopContractS1 : shopContractS2) : shopContractS1

  const GEM_SHOP_ADDRESS = server ? (server === 's1' ? PE_GEM_SHOP_ADDRESS : S2_GEM_SHOP_ADDRESS) : PE_GEM_SHOP_ADDRESS

  const web3 = new Web3(
    chainId === 56 ? RPC_ENDPOINT_MAINNET : chainId === 97 ? RPC_ENDPOINT_TESTNET : 'https://bsc-dataseed1.ninicoin.io/' //others rpc mainnet
  )
  const shopCTF = new web3.eth.Contract(
    PE_GEM_SHOP_ABI as AbiItem[],
    chainId ? GEM_SHOP_ADDRESS[chainId] : '0x0cea99c73225b407a34ca50cf8822dbe68fd70dd'
  )

  const PEFI_ADDRESS = useMemo(() => {
    if (chainId) {
      return PEFI_TOKEN_ADDRESS[chainId]
    } else {
      return undefined
    }
  }, [chainId])
  const PEFICurrency = UseCurrency(PEFI_ADDRESS)
  const price = '10000000000000000000000'
  const typedValueParsed = parseUnits(price, PEFICurrency?.decimals).toString()
  let amount
  if (PEFICurrency instanceof Token) {
    amount = CurrencyAmount.fromRawAmount(PEFICurrency, JSBI.BigInt(typedValueParsed))
  }

  const [approvalPEFI, approvalPEFICallback] = UseApproveCallback(amount, shopContract?.address)
  const [show_remain_confirm_tx, set_show_remain_confirm_tx] = useState<boolean>(false)
  const [showNotAccount, setShowNotAccount] = useState<boolean>(false)

  useEffect(() => {
    if (approvalPEFI === ApprovalState.APPROVED || approvalPEFI === ApprovalState.PENDING) {
      set_show_remain_confirm_tx(false)
    }
  }, [approvalPEFI])

  const fetchGemPrice = async () => {
    const url = SHOP_ENDPOINT.PACKS
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.response
      } else {
        console.error(response)
        return []
      }
    } catch (error: any) {
      console.error(error)
    }
    return []
  }

  const updateGemPacks = async () => {
    const gemPacks = await fetchGemPrice()
    const pefiPrice = await getPEFiPrice()
    const shownPacks = updateGemPrice(gemPacks, pefiPrice)
    setShownPacks(shownPacks)
  }

  const updateGemPrice = (packs: any, pefiPrice: any) => {
    if (!packs) return GEM_PACK
    const shownPacksRef = []
    for (const pack of GEM_PACK) {
      const this_pack = packs.find((p: any) => p.packId === pack.pack_id)
      if (!this_pack) {
        shownPacksRef.push(pack)
      } else {
        const pack_ref = {
          ...pack,
          quantity: this_pack.gem,
          price: this_pack.pefi,
          discount: this_pack.saleOff,
          busd_price: this_pack.pefi * pefiPrice,
        }
        shownPacksRef.push(pack_ref)
      }
    }
    return shownPacksRef
  }

  useEffect(() => {
    updateGemPacks()
    setInterval(() => {
      updateGemPacks()
    }, 120000)
  }, [account])

  const showConfirmPopup = (item: any) => {
    setResult({
      isShowModal: true,
      selectedPack: item,
    })
  }

  const checkAccountIngame = async (account: any) => {
    const url = `${INGAME_ACCOUNT.HAS_ACCOUNT}/${account}`
    console.log(url)
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        const data = response.data.response
        return data
      }
    } catch (error: any) {
      console.error('checkAccountIngame', error)
    }
  }

  const handleBuyPack = async (item: any) => {
    const hasAccount = await checkAccountIngame(account)
    if (!hasAccount) {
      setShowNotAccount(true)
      return
    }
    buyPack(item)
  }

  const buyPack = async (item: any) => {
    console.log('item:', item)
    if (!shopContract) return
    const packId = item.pack_id
    const gemAmount = item.quantity
    const method: (...args: any) => Promise<TransactionResponse> = shopContract.buyPack
    const args: Array<string | string[] | number> = [packId, gemAmount]

    setAttemptingTxn({
      attemptingTxn: true,
      selectedPackId: packId,
    })
    method(...args, {})
      .then(async (response) => {
        console.log('txHash', response.hash)
        const txFull = await response.wait()
        if (txFull.status === 0) {
          setAttemptingTxn({
            attemptingTxn: false,
            selectedPackId: packId,
          })
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          return
        }
        setTimeout(() => {
          NotificationManager.success('Buy pack successful.', 'Success', 2000)
          setAttemptingTxn({
            attemptingTxn: false,
            selectedPackId: packId,
          })
          onDismissResult()
        }, 3000)
      })
      .catch((error) => {
        setAttemptingTxn({
          attemptingTxn: false,
          selectedPackId: packId,
        })
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
      })
  }

  const renderContent = () => {
    return (
      <>
        <Container className="gold">
          {shownPacks.map((item, index) => (
            <PackItem key={index}>
              <PackInfo>
                <GemAmount>
                  <div className="pack-number">{item.pack_name}</div>
                </GemAmount>
                <div>
                  <img className="pack-img" src={item.img} />
                  <div
                    className="pack-number"
                    style={{
                      fontSize: 25,
                      fontWeight: 600,
                      padding: 0,
                      color: '#f2c32a',
                      textAlign: 'center',
                      position: 'relative',
                      top: '-30px',
                    }}
                  >
                    x {item.quantity}
                  </div>
                </div>
                <PackPrice>
                  <div className="content">
                    <div className="price">Price: </div>
                    {/* <div className="price" style={{ textDecoration: 'line-through', color: '#e01224' }}>
                      {item.price * 2}
                    </div> */}
                    <div className="price">
                      <div>
                        {item.price}
                        <img src={PEFITokenLogo} style={{ height: 16, marginLeft: 5 }} />
                      </div>
                      {/* <div className="busd">
                        (~ {item.busd_price}
                        <img src={BUSDLogo} style={{ height: 14, marginLeft: 5 }} />)
                      </div> */}
                    </div>
                  </div>
                </PackPrice>
              </PackInfo>
              {!account ? (
                <UnlockButton />
              ) : (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {approvalPEFI !== ApprovalState.APPROVED ? (
                      <>
                        {show_remain_confirm_tx ? (
                          <ButtonSell style={{ cursor: 'not-allowed' }} src={buttonLoading} />
                        ) : (
                          <>
                            {approvalPEFI === ApprovalState.PENDING ? (
                              <ButtonSell style={{ cursor: 'not-allowed' }} src={buttonApproving} />
                            ) : (
                              <ButtonSell
                                src={buttonApprove}
                                onClick={() => {
                                  set_show_remain_confirm_tx(true)
                                  approvalPEFICallback().catch((error) => {
                                    set_show_remain_confirm_tx(false)
                                  })
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {attemptingTxn && selectedPackId === item.pack_id ? (
                          <ButtonSell src={buttonLoading} style={{ opacity: '0.5' }} />
                        ) : (
                          <ButtonSell src={buttonBuy} onClick={() => handleBuyPack(item)} />
                        )}
                      </>
                    )}
                  </div>
                  {show_remain_confirm_tx && (
                    <div style={{ textAlign: 'center', marginTop: '10px', color: '#fff' }}>
                      Please confirm tx on your wallet
                    </div>
                  )}
                </>
              )}
            </PackItem>
          ))}
        </Container>
      </>
    )
  }

  const onDismissResult = () => {
    setResult({
      isShowModal: false,
      selectedPack: undefined,
    })
  }

  return (
    <>
      <ShopBody {...{ maxWidth: '2000px' }}>
        <ShopItemArea>
          {renderContent()}
          {showNotAccount && (
            <ROFIAccountModal
              title="ATTENTION !!!"
              visible={showNotAccount}
              onCancel={() => setShowNotAccount(false)}
              footer={null}
            >
              <FlexLine>
                <RewardDetailTitle>You haven&apos;t logged in game.</RewardDetailTitle>
              </FlexLine>
              <FlexLine>
                <RewardDetail>Please login game, connect email with this wallet and try again.</RewardDetail>
              </FlexLine>
              <FlexLine>
                <RewardDetail>
                  <span>Link download game: {}</span>
                  <a
                    href={'https://plantempires.page.link/BAk5'}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#9ce315' }}
                  >
                    Plant Empires
                  </a>
                </RewardDetail>
              </FlexLine>
              <FlexLine>
                <CheckinButton onClick={() => setShowNotAccount(false)}>GOT IT</CheckinButton>
              </FlexLine>
            </ROFIAccountModal>
          )}
        </ShopItemArea>
      </ShopBody>
    </>
  )
}
