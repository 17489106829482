/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import axios from 'axios'
import { Modal } from 'antd'
import arrow from 'assets/pefi/combine/arrow.png'
import resultDemo from 'assets/pefi/combine/result_demo.png'
import tickIcon from 'assets/pefi/icon_tick.png'
import PreviewFull from 'components/PreviewCard/PlantCardPreview/Preview'
import Text from 'components/Text'
import {
  PE_PLANT_HERO_CHEST_ADDRESS,
  PE_PIECE_NFT_ADDRESS,
  S2_PLANT_HERO_CHEST_ADDRESS,
  S2_PIECE_NFT_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { CHEST_ENDPOINT, HERO_MARKET_SERVER } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft } from 'constants/types'
import { ApprovalState, useApproveERC721Callback } from 'hooks/useApproveCallback'
import { usePieceCombineContract, usePieceCombineContractS2 } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import { BoxConfig } from 'pages/SpecialPack/GachaBox/box-config'
import { PieceConfig } from 'pages/SpecialPack/GachaBox/piece-config'
import { Dots } from 'pages/styleds'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import Web3 from 'web3'
import NftGrid from './NftGrid'
import UnAuthorized from './UnAuthorized'

export interface NftCardProps {
  nft: Nft
  isSelected: boolean
}

const NFTArea = styled.div`
  flex: 4;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px;
  `};
`

const Card = styled.div`
  background-color: #071220;
  overflow: hidden;
  border: 1px solid #eec231;
  padding: 10px 10px 5px;
  position: relative;
  cursor: pointer;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5px;
  `};
`

const Selected = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000082;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  img {
    width: 50px;
    ${({ theme }) => theme.mediaWidth.upToExtraLarge`
      width: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToLarge`
      width: 20px;
    `};
  }
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background: transparent;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
`

const FusionContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 57%;
  gap: 40px;
  padding: 25px;
  border-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 60%;
    margin: 0 auto 30px;
    grid-template-columns: 1fr;
    padding: 0;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 80%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const SelectNFTs = styled.div`
  height: 100%;
  background: #14324c50;
  border-radius: 10px;
  padding: 20px;
`
const HolyBonus = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #49c3fd;
  max-height: 80vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c2c8d5;
  }
`

const LineResultContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`
const LineContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 5px;
    margin-bottom: 10px;
  `};
`

const OneCard = styled.div`
  width: 100%;
`

const ResultCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #03c5ff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #182f46;
`

const MaterialCard = styled(ResultCard)`
  display: block;
  border: 1px solid #eec231;
  padding: 0;
  height: fit-content;
  background: #071220;
`

const TextIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  padding: 20px;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 10px;
  `};
`

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HolySelectButton = styled.div`
  background: linear-gradient(180deg, #ffe89b 0%, #ffc400 100%);
  border-radius: 0px 0px 7px 7px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  color: #071220;
  &.approve {
    background: #f6c931;
  }
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 14px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

const ROFIAccountModal = styled(Modal)`
  top: 30vh !important;
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GachaBoxPreview = styled.img`
  width: 100%;
  max-width: 80px;
  margin: 0px auto;
`

const PieceMaterialPreview = styled(GachaBoxPreview)`
  padding: 20px 20px 0;
  max-width: unset;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 5px 5px 0;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 10px 10px 0;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5px 5px 0;
  `}
`

const BoxId = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff;
`

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 16px;
  height: 48px;
  padding: 0 24px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  min-width: 200px;
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export default function ChestCombine({ setPageState }: { setPageState: (state: string) => void }) {
  // Define state
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [listPiecesMaterial, setListPieceMaterial] = useState<any[]>([])

  const [show_remain_confirm_tx, set_show_remain_confirm_tx] = useState<boolean>(false)

  const [current_approving, set_current_approving] = useState<any>(undefined)

  const [{ showPieceCombineResult, chestTypeResult, chestIdResult }, setPieceCombineResult] = useState<{
    showPieceCombineResult: boolean
    chestTypeResult: any | undefined
    chestIdResult: any | undefined
  }>({
    showPieceCombineResult: false,
    chestTypeResult: undefined,
    chestIdResult: undefined,
  })

  const { chainId, account } = useActiveWeb3React()
  const server = localStorage.getItem('server')
  const pieceCombineS1 = usePieceCombineContract()
  const pieceCombineS2 = usePieceCombineContractS2()
  const pieceCombine = server ? (server === 's1' ? pieceCombineS1 : pieceCombineS2) : pieceCombineS1

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const pieceNftAddress = server
    ? server === 's1'
      ? PE_PIECE_NFT_ADDRESS[56]
      : S2_PIECE_NFT_ADDRESS[56]
    : PE_PIECE_NFT_ADDRESS[56]

  const chestNftAddress = server
    ? server === 's1'
      ? PE_PLANT_HERO_CHEST_ADDRESS[56]
      : S2_PLANT_HERO_CHEST_ADDRESS[56]
    : PE_PLANT_HERO_CHEST_ADDRESS[56]

  const [approvePieceMaterial1, approvalPieceMaterial1Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[0],
    pieceCombine?.address
  )
  const [approvePieceMaterial2, approvalPieceMaterial2Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[1],
    pieceCombine?.address
  )
  const [approvePieceMaterial3, approvalPieceMaterial3Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[2],
    pieceCombine?.address
  )
  const [approvePieceMaterial4, approvalPieceMaterial4Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[3],
    pieceCombine?.address
  )
  const [approvePieceMaterial5, approvalPieceMaterial5Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[4],
    pieceCombine?.address
  )
  const [approvePieceMaterial6, approvalPieceMaterial6Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[5],
    pieceCombine?.address
  )
  const [approvePieceMaterial7, approvalPieceMaterial7Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[6],
    pieceCombine?.address
  )
  const [approvePieceMaterial8, approvalPieceMaterial8Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[7],
    pieceCombine?.address
  )
  const [approvePieceMaterial9, approvalPieceMaterial9Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[8],
    pieceCombine?.address
  )
  const [approvePieceMaterial10, approvalPieceMaterial10Callback] = useApproveERC721Callback(
    pieceNftAddress,
    listPiecesMaterial[9],
    pieceCombine?.address
  )

  useEffect(() => {
    fetchAllNfts(account)
  }, [account])

  useEffect(() => {
    if (
      approvePieceMaterial1 === ApprovalState.APPROVED ||
      approvePieceMaterial1 === ApprovalState.PENDING ||
      approvePieceMaterial2 === ApprovalState.APPROVED ||
      approvePieceMaterial2 === ApprovalState.PENDING ||
      approvePieceMaterial3 === ApprovalState.APPROVED ||
      approvePieceMaterial3 === ApprovalState.PENDING ||
      approvePieceMaterial4 === ApprovalState.APPROVED ||
      approvePieceMaterial4 === ApprovalState.PENDING ||
      approvePieceMaterial5 === ApprovalState.APPROVED ||
      approvePieceMaterial5 === ApprovalState.PENDING ||
      approvePieceMaterial6 === ApprovalState.APPROVED ||
      approvePieceMaterial6 === ApprovalState.PENDING ||
      approvePieceMaterial7 === ApprovalState.APPROVED ||
      approvePieceMaterial7 === ApprovalState.PENDING ||
      approvePieceMaterial8 === ApprovalState.APPROVED ||
      approvePieceMaterial8 === ApprovalState.PENDING ||
      approvePieceMaterial9 === ApprovalState.APPROVED ||
      approvePieceMaterial9 === ApprovalState.PENDING ||
      approvePieceMaterial10 === ApprovalState.APPROVED ||
      approvePieceMaterial10 === ApprovalState.PENDING
    ) {
      set_show_remain_confirm_tx(false)
    }
  }, [
    approvePieceMaterial1,
    approvePieceMaterial2,
    approvePieceMaterial3,
    approvePieceMaterial4,
    approvePieceMaterial5,
    approvePieceMaterial6,
    approvePieceMaterial7,
    approvePieceMaterial8,
    approvePieceMaterial9,
    approvePieceMaterial10,
  ])

  const [myPlantsData, setNftsData] = useState<any[]>([])

  const fetchAllNfts = async (owner: any) => {
    const pieces = await getNftsOfOwner(owner)
    setNftsData(pieces)
  }

  const getNftsOfOwner = async (owner: any) => {
    const url = `${CHEST_ENDPOINT.PIECES}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      // NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const PlantList = ({ nfts }: { nfts: Nft[] }) => {
    if (nfts.length === 0) {
      return (
        <EmptyNft>
          <div>No plants available.</div>
        </EmptyNft>
      )
    }

    const available = nfts.filter((n: any) => n.status === 'Available')

    const Render_container = ({ nfts, title }: { nfts: any[]; title: string }) => {
      const selectingPieces = nfts.filter((nft: any) => nft.combine_status && nft.combine_status === 'selected')
      return (
        <NftGrid>
          {nfts.map((nft: any, index) => {
            // not piece selected yet
            const piece_img = PieceConfig.find((b: any) => b.box_id === nft.chestType)?.img
            if (!selectingPieces || selectingPieces?.length === 0) {
              return (
                <div key={index}>
                  <Card onClick={() => selectPieceMaterial(nft)}>
                    <GachaBoxPreview src={piece_img} />
                    <BoxId>#{nft.tokenId}</BoxId>
                  </Card>
                </div>
              )
            }
            const isSelected =
              nft.tokenId === selectingPieces[0]?.tokenId ||
              nft.tokenId === selectingPieces[1]?.tokenId ||
              nft.tokenId === selectingPieces[2]?.tokenId ||
              nft.tokenId === selectingPieces[3]?.tokenId ||
              nft.tokenId === selectingPieces[4]?.tokenId ||
              nft.tokenId === selectingPieces[5]?.tokenId ||
              nft.tokenId === selectingPieces[6]?.tokenId ||
              nft.tokenId === selectingPieces[7]?.tokenId ||
              nft.tokenId === selectingPieces[8]?.tokenId ||
              nft.tokenId === selectingPieces[9]?.tokenId
            if (nft.chestType !== selectingPieces[0].chestType) {
              return (
                <div key={index}>
                  <Card>
                    <Selected>{isSelected && <img src={tickIcon} alt="" />}</Selected>
                    <GachaBoxPreview src={piece_img} />
                    <BoxId>#{nft.tokenId}</BoxId>
                  </Card>
                </div>
              )
            }
            return (
              <div key={index}>
                <Card onClick={() => selectPieceMaterial(nft)}>
                  {isSelected && (
                    <Selected>
                      <img src={tickIcon} alt="" />
                    </Selected>
                  )}
                  <GachaBoxPreview src={piece_img} />
                  <BoxId>#{nft.tokenId}</BoxId>
                </Card>
              </div>
            )
          })}
        </NftGrid>
      )
    }

    return <>{Render_container({ nfts: available, title: 'Available' })}</>
  }

  const updateCanCombine = (nfts: any, selecteds: any) => {
    const array = []
    for (const nft of nfts) {
      let nftRef = nft
      if (selecteds.includes(nftRef.tokenId)) {
        nftRef = {
          ...nft,
          combine_status: 'selected',
        }
      } else {
        nftRef = {
          ...nft,
          combine_status: 'none',
        }
      }
      array.push(nftRef)
    }
    return array
  }

  const selectPieceMaterial = (nft: any) => {
    if (!nft) return
    const temp = listPiecesMaterial
    if (temp.includes(nft.tokenId)) {
      const selectIndex = temp.indexOf(nft.tokenId)
      temp.splice(selectIndex, 1)
    } else {
      if (temp.length === 10) return
      temp.push(nft.tokenId)
    }
    setListPieceMaterial(temp)
    const all_nfts = updateCanCombine(myPlantsData, temp)
    setNftsData(all_nfts)
  }

  const findPieceImage = (nft: any) => {
    return PieceConfig.find((b: any) => b.box_id === nft.chestType)?.img
  }

  const getApproveVariable = (slot: any) => {
    switch (slot) {
      case 1:
        return approvePieceMaterial1
      case 2:
        return approvePieceMaterial2
      case 3:
        return approvePieceMaterial3
      case 4:
        return approvePieceMaterial4
      case 5:
        return approvePieceMaterial5
      case 6:
        return approvePieceMaterial6
      case 7:
        return approvePieceMaterial7
      case 8:
        return approvePieceMaterial8
      case 9:
        return approvePieceMaterial9
      case 10:
        return approvePieceMaterial10
      default:
        return null
    }
  }
  const getApproveCallback = (slot: any) => {
    switch (slot) {
      case 1:
        return approvalPieceMaterial1Callback
      case 2:
        return approvalPieceMaterial2Callback
      case 3:
        return approvalPieceMaterial3Callback
      case 4:
        return approvalPieceMaterial4Callback
      case 5:
        return approvalPieceMaterial5Callback
      case 6:
        return approvalPieceMaterial6Callback
      case 7:
        return approvalPieceMaterial7Callback
      case 8:
        return approvalPieceMaterial8Callback
      case 9:
        return approvalPieceMaterial9Callback
      case 10:
        return approvalPieceMaterial10Callback
      default:
        return approvalPieceMaterial1Callback
    }
  }

  const renderOnePieceMaterialCard = (nft: any, slot: any) => {
    const approvePieceMaterial = getApproveVariable(slot)
    const approveCallback = getApproveCallback(slot)
    return (
      <OneCard>
        {nft ? (
          <MaterialCard>
            <PieceMaterialPreview src={findPieceImage(nft)} />
            <BoxId>#{nft.tokenId}</BoxId>
            {nft && approvePieceMaterial !== ApprovalState.APPROVED && !attemptingTxn && (
              <ActionButtons>
                {show_remain_confirm_tx ? (
                  <HolySelectButton className="disable">
                    <Dots>Loading</Dots>
                  </HolySelectButton>
                ) : (
                  <HolySelectButton
                    onClick={() => {
                      set_show_remain_confirm_tx(true)
                      set_current_approving(nft.tokenId)
                      approveCallback().catch(() => {
                        set_show_remain_confirm_tx(false)
                        set_current_approving(undefined)
                      })
                    }}
                    className={
                      current_approving === nft.tokenId && approvePieceMaterial === ApprovalState.PENDING
                        ? 'disable'
                        : ''
                    }
                  >
                    {approvePieceMaterial === ApprovalState.UNKNOWN ? (
                      <Dots>Waiting</Dots>
                    ) : current_approving === nft.tokenId && approvePieceMaterial === ApprovalState.PENDING ? (
                      <Dots>Approving</Dots>
                    ) : (
                      <>Approve</>
                    )}
                  </HolySelectButton>
                )}
              </ActionButtons>
            )}
          </MaterialCard>
        ) : (
          <MaterialCard>
            <TextIcon>+</TextIcon>
          </MaterialCard>
        )}
      </OneCard>
    )
  }

  const renderPiecesMaterial = (selectingPieces: any) => {
    return (
      <>
        <LineContent>
          <>{renderOnePieceMaterialCard(selectingPieces[0], 1)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[1], 2)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[2], 3)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[3], 4)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[4], 5)}</>
        </LineContent>
        <LineContent>
          <>{renderOnePieceMaterialCard(selectingPieces[5], 6)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[6], 7)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[7], 8)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[8], 9)}</>
          <>{renderOnePieceMaterialCard(selectingPieces[9], 10)}</>
        </LineContent>
      </>
    )
  }

  const selectingPieces = myPlantsData.filter((n: any) => n.combine_status && n.combine_status === 'selected')

  const decode_log = async (logs: any) => {
    const log = logs[logs.length - 1]
    const inputs = [
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint8', name: 'boxType', type: 'uint8' },
      { indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256' },
    ]

    const decoded = web3.eth.abi.decodeLog(inputs, log.data, [log.topics[1]])
    const new_chest_tokenId = decoded.tokenId
    const new_chest_type = decoded.boxType
    return {
      new_chest_tokenId,
      new_chest_type,
    }
  }

  const onPieceCombine = () => {
    console.log('on combine piece')
    setAttemptingTxn(true)
    if (!pieceCombine || listPiecesMaterial.length === 0) {
      setAttemptingTxn(false)
      NotificationManager.error('Please try again later.', 'Error', 2000)
      return
    }
    const chestPieceIds = listPiecesMaterial

    const method: (...args: any) => Promise<TransactionResponse> = pieceCombine.convert
    const args: Array<string | string[] | number> = [chestPieceIds]
    console.log('args: ', args)
    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        console.log('txHash', txFull.transactionHash)
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        const logs = txFull.logs
        const log_chest_mint = logs.filter((log: any) => log.address === chestNftAddress)
        const result = await decode_log(log_chest_mint)
        console.log('result:', result)
        await fetchAllNfts(account)
        const chest_img = BoxConfig.find((b: any) => b.box_id == result.new_chest_type)?.img
        setPieceCombineResult({
          showPieceCombineResult: true,
          chestTypeResult: chest_img,
          chestIdResult: result.new_chest_tokenId,
        })
      })
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const enableFusionButton = () => {
    if (approvePieceMaterial1 === ApprovalState.NOT_APPROVED || approvePieceMaterial1 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial2 === ApprovalState.NOT_APPROVED || approvePieceMaterial2 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial3 === ApprovalState.NOT_APPROVED || approvePieceMaterial3 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial4 === ApprovalState.NOT_APPROVED || approvePieceMaterial4 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial5 === ApprovalState.NOT_APPROVED || approvePieceMaterial5 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial6 === ApprovalState.NOT_APPROVED || approvePieceMaterial6 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial7 === ApprovalState.NOT_APPROVED || approvePieceMaterial7 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial8 === ApprovalState.NOT_APPROVED || approvePieceMaterial8 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial9 === ApprovalState.NOT_APPROVED || approvePieceMaterial9 === ApprovalState.PENDING) {
      return false
    }
    if (approvePieceMaterial10 === ApprovalState.NOT_APPROVED || approvePieceMaterial10 === ApprovalState.PENDING) {
      return false
    }
    return true
  }

  const renderResult = () => {
    if (!selectingPieces[0]) {
      return (
        <ResultCard>
          <img src={resultDemo} style={{ width: '80%' }} />
        </ResultCard>
      )
    }
    const chestType = selectingPieces[0].chestType
    const chest_img = BoxConfig.find((b: any) => b.box_id === chestType)?.img
    return (
      <ResultCard>
        <img src={chest_img} style={{ width: '100%' }} />
      </ResultCard>
    )
  }

  if (!account) return <UnAuthorized />

  return (
    <PageContainer className="main-content">
      <NFTArea>
        <FusionContainer>
          <SelectNFTs>
            <LineResultContent>
              <div></div>
              <div>
                <div>{renderResult()}</div>
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                  <img src={arrow} />
                </div>
              </div>
              <div></div>
            </LineResultContent>
            <>{renderPiecesMaterial(selectingPieces)}</>
            {selectingPieces.length === 10 ? (
              <>
                {!enableFusionButton() && (
                  <div style={{ textAlign: 'center', marginTop: 20, color: '#fff' }}>
                    You have to approve all of pieces
                  </div>
                )}
                {show_remain_confirm_tx && (
                  <div style={{ textAlign: 'center', marginBottom: '-10px', marginTop: 10, color: '#fff' }}>
                    Please confirm tx on your wallet
                  </div>
                )}
                <ActionButtons style={{ marginTop: 20 }}>
                  {enableFusionButton() ? (
                    <Button
                      onClick={() => {
                        if (attemptingTxn) return
                        onPieceCombine()
                      }}
                      className={attemptingTxn ? 'disable' : ''}
                    >
                      {attemptingTxn ? <Dots>Loading</Dots> : <>COMBINE</>}
                    </Button>
                  ) : (
                    <Button className="disable">{'COMBINE'}</Button>
                  )}
                </ActionButtons>
              </>
            ) : (
              <ActionButtons style={{ marginTop: 20 }}>
                <Button className="disable">{'COMBINE'}</Button>
              </ActionButtons>
            )}
          </SelectNFTs>
          <HolyBonus>
            <NFTArea style={{ padding: 20 }}>
              <PlantList nfts={myPlantsData} />
            </NFTArea>
          </HolyBonus>
        </FusionContainer>
      </NFTArea>

      {showPieceCombineResult && (
        <ROFIAccountModal
          title={'CONGRATULATION'}
          visible={showPieceCombineResult}
          onCancel={() => {
            setPieceCombineResult({
              showPieceCombineResult: false,
              chestTypeResult: undefined,
              chestIdResult: undefined,
            })
          }}
          footer={null}
        >
          {chestTypeResult ? (
            <>
              <FlexLine>
                <div style={{ marginBottom: 20 }}>
                  <img src={chestTypeResult} style={{ maxWidth: 200 }} />
                  <BoxId>#{chestIdResult}</BoxId>
                </div>
              </FlexLine>
              <FlexLine>
                <Button
                  onClick={() => {
                    setPieceCombineResult({
                      showPieceCombineResult: false,
                      chestTypeResult: undefined,
                      chestIdResult: false,
                    })
                  }}
                >
                  GOT IT
                </Button>
              </FlexLine>
            </>
          ) : (
            <FlexLine>
              <Dots style={{ color: '#fff' }}>Loading data</Dots>
            </FlexLine>
          )}
        </ROFIAccountModal>
      )}
    </PageContainer>
  )
}
