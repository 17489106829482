import React, { ReactNode } from 'react'
import { RowBetween } from 'components/Row'
import Close from 'assets/pefi/special-pack/close-button.png'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #026092;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    height: 100%;
  `}
`

const ModalTitle = styled.div`
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const LeftSection = styled(AutoColumn)<{ inline?: boolean }>`
  flex: 1;
  padding-right: 20px;
`
const RightSection = styled(LeftSection)`
  padding: 20px;
  flex: 2;
  height: 100%;
  background-color: #01517a;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      height: auto;
  `}
`

const Container = styled(RowBetween)`
  position: relative;
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`

export default function SellModalContent({
  onDismiss,
  content,
}: {
  onDismiss: () => void
  content: () => ReactNode | undefined
}) {
  return (
    <Wrapper>
      <ModalTitle>SELL ORB</ModalTitle>
      <ModalContent>{content()}</ModalContent>
      <CloseIcon src={Close} onClick={onDismiss} />
    </Wrapper>
  )
}
