export interface Address {
  97?: string
  56: string
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
  busdPrice?: string
}

export type Images = {
  lg: string
  md?: string
  sm?: string
  ipfs?: string
}

export type NftImages = {
  blur?: string
} & Images

export type NftVideo = {
  webm: string
  mp4: string
}

export type NftSource = {
  [key in NftType]: {
    address: Address
    identifierKey: string
  }
}

export enum NftType {
  ARMOR = 'armor',
  WEAPON = 'weapon',
  HERO = 'hero',
  LG_ESSENCE = 'LG_essence',
}

export type Order = {
  description: string
  name: string
  sex: string
  skill: string
  owner: string
  reproductionTime: number
  star: number
  className: string
  level: number
  generation: string
  price: string
  nftAddress: string
  tokenId: string
  health: number
  intelligence: number
  agility: number
  precision: number
  image: string
  type: NftType
  video: string
  skills: [{ name: string }]
  rare?: string
  tribe?: string
  // Uniquely identifies the nft.
  // Used for matching an NFT from the config with the data from the NFT's tokenURI
  identifier?: string

  // Used to be "bunnyId". Used when minting NFT
  variationId?: number | string
  legendGuardian?: any
  itemType?: string
  detail?: any
  heroFi?: any
  rarityName?: any
  plantId?: any
  holyType?: any
}

export type Transaction = {
  orderId: string
  nftAddress: string
  price: string
  seller: string
  tokenId: string
  nft: {
    name: string
    className: string
    level: number
    generation: string
    health: number
    intelligence: number
    agility: number
    star: number
    bornAt: string
    tokenId: string
    precision: number
    image: string
    video: string
    skills: [{ name: string }]
    sex: string
    detail?: any
  }
  buyer: string
  blockNumber: number
}

export type Nft = {
  description: string
  name: string
  sex: string
  skill: string
  owner: string
  reproductionTime: number
  level: number
  generation: string
  nftAddress: string
  skills: [{ name: string }]
  tokenId: string
  health: number
  intelligence: number
  agility: number
  precision: number
  image: string
  video: string
  type: NftType
  rare?: number
  tribe?: string
  price: string
  status: string
  // Uniquely identifies the nft.
  // Used for matching an NFT from the config with the data from the NFT's tokenURI
  identifier?: string
  // Used to be "bunnyId". Used when minting NFT
  variationId?: number | string
  legendGuardian?: any
  itemType?: any
  primary?: any
  secondary?: any
  detail?: any
  herofi?: any
  isOnOldMarket?: any
  //PE
  star: number
  bornAt: string
  rarity: number
  rarityName: string
  classType: number
  className: string
  nftId?: string
}

export type LGHero = {
  detail: {
    armor: number
    attackSpeed: number
    class: string
    combatPower: number
    critDamage: number
    critRate: number
    damage: number
    evasion: number
    gender: string
    group: number
    heroId: number
    heroType: string
    hp: number
    initAt: string
    level: number
    magicResist: number
    name: string
    nftId: number
    race: string
  }
  heroType?: string
  star: number
  ticketId?: string
  generation: string
  status?: string
  heroFi?: any
  nftAddress?: any
  price?: any
  isOnOldMarket?: any
  tokenId: string
  level: number
}

export enum OrderStatus {
  OPEN = 'Open',
  CLOSE = 'Close',
  SOLED = 'Sold',
}

export interface FilterConditions {
  classNames: string[]
  sexes: string[]
  generations: string[]
  star: { from: number; to: number }
  reproductionTime: { from: number; to: number }
}
export interface PEFilterConditions {
  classNames: string[]
  rarityNames: string[]
  star: { from: number; to: number }
}
export interface PEBoxFilterConditions {
  rarityNames: string[]
}
export interface LGFilterConditions {
  classNames: string[]
  sexes: string[]
  star: { from: number; to: number }
}
export interface FilterEssencesConditions {
  star: { from: number; to: number }
}
export interface ItemsFilterConditions {
  slot: string[]
  primary: string[]
  star: { from: number; to: number }
}

export type HPack = {
  name: string
  image: string
  price?: string
}
