import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
import MenuMobile from 'assets/herofi/menu-mobile.svg'
import bscIcon from 'assets/pefi/bsc-icon.png'
import HeaderBg from 'assets/pefi/header/header-bg.png'
import Logo from 'assets/pefi/header/header-logo.png'
import PEFITokenLogo from 'assets/pefi/pefi.png'
import serverFrame from 'assets/pefi/server-frame.png'
import { HOW_TO_BUY } from 'constants/endpoints'
import { PEFI_TOKEN_ADDRESS } from 'constants/tokens'
import { useActiveWeb3React } from 'hooks/web3'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { YellowCard } from '../Card'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'

import { Modal } from 'antd'
const HeaderFrame = styled.div<{ showBackground: boolean }>`
  background: #182f46;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 61px;
  top: 0;
  position: relative;
  z-index: 21;
`

const HeaderFrameMobile = styled.div`
  background: url(${HeaderBg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 61px;
  background: #182f46;
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  height: 61px;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)``

const HeaderMenu = styled.div``

const HeaderLinks = styled(Row)`
  height: 61px;
  width: fit-content;
  margin-left: 30px;
  padding: 4px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    margin-left: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upTo1440`
    margin-left: 0px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    height: auto;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: inherit;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px !important;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-left: 10px!important;
  `};
  .logo {
    position: absolute;
    left: -3px;
    width: 40px;
  }
  .balance {
    background: #026092;
    border-radius: 5px;
    padding: 7px 25px 5px 35px;
    &.claim {
      background: #bb660f;
      font-weight: bold;
      cursor: pointer;
    }
    .arrow-down {
      position: relative;
      right: -10px;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 5px 15px 5px 30px;
    `}
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  padding-left: 15px;
  position: relative;
  :hover {
    // transform: rotate(-5deg);
  }
  img {
    height: 61px;
    width: auto;
  }
`

const SwapServer = styled.div`
  width: 200px;
  background: url(${serverFrame});
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-left: 10px;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    height: 30px;
    font-size: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding: 0 35px 0 15px;
    height: 25px;
  `};
`
const SelectServer = styled.div`
  width: 360px;
  height: 50px;
  background: url(${serverFrame});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
  cursor: pointer;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff8e8;
  font-size: 20px;
  line-height: 30px;
  width: fit-content;
  overflow: hidden;
  font-family: Gudea-Medium;
  padding: 0 15px;
  word-break: break-word;
  border-right: 1px solid #fff;

  &.${activeClassName} {
    color: #47ced2;
  }

  :hover {
    color: #47ced2;
  }

  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 15px;
    padding: 0 15px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upTo1440`
    margin: 0;
    padding: 0 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    margin: 0 5px;
    padding: 5px 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    float: right;
  `};
`

const StyledLink = styled.a`
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff8e8;
  font-size: 20px;
  line-height: 30px;
  width: fit-content;
  overflow: hidden;
  font-family: Gudea-Medium;
  padding: 0 15px;
  word-break: break-word;
  align-items: center;
  display: flex;

  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 15px;
    margin: 0 5px;
    padding: 0 10px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upTo1440`
    font-size: 16px;
    margin: 0;
    padding: 0 7px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 16px;
    margin: 0 5px;
    padding: 0 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    text-align: right;
    display: flex;
    align-items: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
    padding: 0 10px;
    height: auto;
    width: 90vw;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 auto;
    border: none;
  `};
`
export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const LogOut = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  margin: 0 15px 0 0;
  font-size: 16px;
  padding: 7px 10px 5px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  :hover,
  :focus {
    color: #000;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin: 0 10px;
  `};
`

const TutorialIcon = styled.div`
  border: 3px solid #4f444f;
  border-radius: 50%;
  color: #4f444f;
  font-size: 25px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  margin-right: 15px;
  :hover,
  :focus {
    border: 1px solid #d47b1f;
    box-shadow: 0 0 0 1pt #d47b1f;
    color: #d47b20;
    padding: 20px;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 10px;
  `};
`

const MobileMenu = styled.div`
  background: #14324c;
  padding-bottom: 20px;
  .account {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .balance {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .menu-items {
    display: grid;
    padding: 10px 0;
    .nav-link {
      height: auto;
      width: 90vw;
      justify-content: flex-end;
      margin: 0 auto;
      border: none;
    }
  }
  .log-out {
    width: 200px;
    margin: 0 auto;
  }
`

const QRLink = styled.a`
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff8e8;
  font-size: 20px;
  height: 57px;
  width: fit-content;
  overflow: hidden;
  font-family: Gudea-Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    margin: 0 5px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    float: right;
  `};
`

const ROFIAccountModal = styled(Modal)`
  &.select-server {
    top: 30vh !important;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      top: 30vh !important;
    `};
  }
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609295 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Header() {
  const { deactivate } = useWeb3React()

  const { account, chainId } = useActiveWeb3React()

  const PEFIBalance = useTokenBalance(account ?? undefined, chainId ? PEFI_TOKEN_ADDRESS[chainId] : undefined)

  const [showMenuMobile, setShowMenuMobile] = useState(false)
  const [showChangeServer, setShowChangeServer] = useState(false)
  const [server, setServer] = useState<any>(undefined)
  const scrollY = useScrollPosition()

  const isMobile = window.innerWidth < 500
  const isTablet = window.innerWidth >= 500 && window.innerWidth < 1440

  useEffect(() => {
    let server = localStorage.getItem('server')
    if (!server) server = 's2'
    setServer(server)
  }, [])

  const getLogo = () => {
    if (!server) return 'https://plantempires-media.b-cdn.net/logo/server2.png'
    if (server === 's1') return 'https://plantempires-media.b-cdn.net/logo/server1.png'
    return 'https://plantempires-media.b-cdn.net/logo/server2.png'
  }

  const onSelectServer = (server: any) => {
    localStorage.setItem('server', server)
    window.location.reload()
  }

  if (isMobile) {
    return (
      <>
        <HeaderFrameMobile>
          <HeaderRow>
            <Title>
              <UniIcon>
                <img src={getLogo()} alt="logo" />
              </UniIcon>
              {server && (
                <SwapServer onClick={() => setShowChangeServer(!showChangeServer)}>
                  <div>{server === 's1' ? 'S1 - Milky Way' : 'S2 - Andromeda'}</div>
                  <div>
                    <img src={bscIcon} width={20} alt="" />
                  </div>
                </SwapServer>
              )}
            </Title>
          </HeaderRow>
          {server && (
            <>
              {/* <QRLink href={ROFI_AUTH_URL} target="_blank" rel="noreferrer">
                <img className={'logo'} src={QRIcon} color={'white'} width={30} height={30} />
              </QRLink> */}
              <HeaderMenu
                onClick={() => {
                  setShowMenuMobile(!showMenuMobile)
                }}
              >
                <img src={MenuMobile} alt="" />
              </HeaderMenu>
            </>
          )}
        </HeaderFrameMobile>
        <div className="account">
          <Web3Status isInnerStatusShow={false} />
        </div>
        {showMenuMobile && (
          <MobileMenu>
            <>
              {account ? (
                <>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', paddingTop: 10 }}>
                    <div className="account">
                      <Web3Status isInnerStatusShow={true} />
                    </div>
                    <div>
                      {PEFIBalance && (
                        <div className="balance">
                          <BalanceText
                            style={{ flexShrink: 0 }}
                            pl="0.75rem"
                            pr="0.5rem"
                            fontWeight={500}
                            color="#FFF8E8"
                          >
                            <img className="logo" src={PEFITokenLogo} />
                            <div className="balance">{PEFIBalance?.toSignificant(6, { groupSeparator: ',' }) || 0}</div>
                          </BalanceText>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="menu-items">
                    <StyledNavLink className="nav-link" to={'/specialpacks'} onClick={() => setShowMenuMobile(false)}>
                      SPECIAL PACK
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" to={'/marketplace'} onClick={() => setShowMenuMobile(false)}>
                      MARKETPLACE
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" to={'/shop'} onClick={() => setShowMenuMobile(false)}>
                      SHOP
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" to={'/account'} onClick={() => setShowMenuMobile(false)}>
                      ACCOUNT
                    </StyledNavLink>
                    <StyledLink href={HOW_TO_BUY} target="_blank" rel="noreferrer">
                      HOW TO BUY
                    </StyledLink>
                  </div>
                  <LogOut className="log-out" onClick={() => deactivate()}>
                    LOG OUT
                  </LogOut>
                </>
              ) : (
                <>
                  <div className="menu-items">
                    <StyledNavLink className="nav-link" to={'/specialpacks'} onClick={() => setShowMenuMobile(false)}>
                      SPECIAL PACK
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" to={'/marketplace'} onClick={() => setShowMenuMobile(false)}>
                      MARKETPLACE
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" to={'/shop'} onClick={() => setShowMenuMobile(false)}>
                      SHOP
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" to={'/account'} onClick={() => setShowMenuMobile(false)}>
                      ACCOUNT
                    </StyledNavLink>
                    <StyledLink href={HOW_TO_BUY} target="_blank" rel="noreferrer">
                      HOW TO BUY
                    </StyledLink>
                  </div>
                  <div className="account" style={{ marginTop: 20, marginBottom: 0, padding: '0 20px' }}>
                    <Web3Status />
                  </div>
                </>
              )}
            </>
          </MobileMenu>
        )}
        {showChangeServer && (
          <ROFIAccountModal
            title="SELECT SERVER"
            visible={showChangeServer}
            onCancel={() => setShowChangeServer(false)}
            footer={null}
            className="select-server"
          >
            <FlexLine>
              <RewardDetailTitle>Select server you want to connect</RewardDetailTitle>
            </FlexLine>
            <FlexLine>
              <SelectServer onClick={() => onSelectServer('s1')}>
                <div>S1 - Milky Way</div>
                <div>
                  <img src={bscIcon} width={30} alt="" />
                </div>
              </SelectServer>
            </FlexLine>
            <FlexLine>
              <SelectServer onClick={() => onSelectServer('s2')}>
                <div>S2 - Andromeda</div>
                <div>
                  <img src={bscIcon} width={30} alt="" />
                </div>
              </SelectServer>
            </FlexLine>
          </ROFIAccountModal>
        )}
      </>
    )
  }

  if (isTablet) {
    return (
      <div style={{ display: 'block' }}>
        <HeaderFrameMobile style={{ width: '100vw' }}>
          <HeaderRow>
            <Title>
              <UniIcon>
                <img src={getLogo()} alt="logo" />
              </UniIcon>
              {server && (
                <SwapServer onClick={() => setShowChangeServer(!showChangeServer)}>
                  <div>{server === 's1' ? 'S1 - Milky Way' : 'S2 - Andromeda'}</div>
                  <div>
                    <img src={bscIcon} width={20} alt="" />
                  </div>
                </SwapServer>
              )}
            </Title>
          </HeaderRow>
          <div className="account" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Web3Status isInnerStatusShow={false} />
          </div>
          {server && (
            <>
              {account && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {PEFIBalance && (
                    <>
                      <div className="balance" style={{ display: 'flex' }}>
                        <BalanceText
                          style={{ flexShrink: 0 }}
                          pl="0.75rem"
                          pr="0.5rem"
                          fontWeight={500}
                          color="#FFF8E8"
                        >
                          <img className="logo" src={PEFITokenLogo} />
                          <div className="balance">{PEFIBalance?.toSignificant(6, { groupSeparator: ',' }) || 0}</div>
                        </BalanceText>
                      </div>
                    </>
                  )}
                </div>
              )}
              {/* <QRLink href={ROFI_AUTH_URL} target="_blank" rel="noreferrer">
                <img className={'logo'} src={QRIcon} color={'white'} width={30} height={30} />
              </QRLink> */}
              <StyledLink className="nav-link" href={HOW_TO_BUY} target="_blank" rel="noreferrer">
                HOW TO BUY
              </StyledLink>
              <HeaderMenu
                onClick={() => {
                  setShowMenuMobile(!showMenuMobile)
                }}
              >
                <img src={MenuMobile} alt="" />
              </HeaderMenu>
            </>
          )}
        </HeaderFrameMobile>
        {showMenuMobile && (
          <MobileMenu>
            <div className="menu-items" style={{ marginTop: 0 }}>
              <StyledNavLink className="nav-link" to={'/specialpacks'} onClick={() => setShowMenuMobile(false)}>
                SPECIAL PACK
              </StyledNavLink>
              <StyledNavLink className="nav-link" to={'/marketplace'} onClick={() => setShowMenuMobile(false)}>
                MARKETPLACE
              </StyledNavLink>
              <StyledNavLink className="nav-link" to={'/shop'} onClick={() => setShowMenuMobile(false)}>
                SHOP
              </StyledNavLink>
              <StyledNavLink className="nav-link" to={'/account'} onClick={() => setShowMenuMobile(false)}>
                ACCOUNT
              </StyledNavLink>
            </div>
            {account ? (
              <LogOut className="log-out" onClick={() => deactivate()}>
                LOG OUT
              </LogOut>
            ) : (
              <div className="account" style={{ width: '40%', margin: '0 auto' }}>
                <Web3Status />
              </div>
            )}
          </MobileMenu>
        )}
        {showChangeServer && (
          <ROFIAccountModal
            title="SELECT SERVER"
            visible={showChangeServer}
            onCancel={() => setShowChangeServer(false)}
            footer={null}
            className="select-server"
          >
            <FlexLine>
              <RewardDetailTitle>Select server you want to connect</RewardDetailTitle>
            </FlexLine>
            <FlexLine>
              <SelectServer onClick={() => onSelectServer('s1')}>
                <div>S1 - Milky Way</div>
                <div>
                  <img src={bscIcon} width={30} alt="" />
                </div>
              </SelectServer>
            </FlexLine>
            <FlexLine>
              <SelectServer onClick={() => onSelectServer('s2')}>
                <div>S2 - Andromeda</div>
                <div>
                  <img src={bscIcon} width={30} alt="" />
                </div>
              </SelectServer>
            </FlexLine>
          </ROFIAccountModal>
        )}
      </div>
    )
  }

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <HeaderRow>
        <Title>
          <UniIcon>
            <img src={getLogo()} alt="logo" />
          </UniIcon>
          {server && (
            <SwapServer onClick={() => setShowChangeServer(!showChangeServer)}>
              <div>{server === 's1' ? 'S1 - Milky Way' : 'S2 - Andromeda'}</div>
              <div>
                <img src={bscIcon} width={20} alt="" />
              </div>
            </SwapServer>
          )}
        </Title>
      </HeaderRow>
      {server && (
        <>
          <HeaderLinks>
            <StyledNavLink to={'/specialpacks'}>SPECIAL PACKS</StyledNavLink>
            <StyledNavLink to={'/marketplace'}>MARKETPLACE</StyledNavLink>
            <StyledNavLink to={'/shop'}>SHOP</StyledNavLink>
            <StyledNavLink to={'/account'}>ACCOUNT</StyledNavLink>
            <StyledLink href={HOW_TO_BUY} target="_blank" rel="noreferrer">
              HOW TO BUY
            </StyledLink>
          </HeaderLinks>
          <HeaderControls>
            <HeaderElement>
              {/* <QRLink href={ROFI_AUTH_URL} target="_blank" rel="noreferrer">
                <img className={'logo'} src={QRIcon} color={'white'} width={30} height={30} />
              </QRLink> */}
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                <Web3Status />
                {account ? (
                  PEFIBalance ? (
                    <>
                      <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500} color="#FFF8E8">
                        <img className="logo" src={PEFITokenLogo} />
                        <div className="balance">{PEFIBalance?.toSignificant(6, { groupSeparator: ',' }) || 0}</div>
                      </BalanceText>
                      <LogOut
                        onClick={() => {
                          deactivate()
                          localStorage.setItem('isAuthorized', '')
                        }}
                      >
                        LOG OUT
                      </LogOut>
                    </>
                  ) : (
                    <>
                      <LogOut
                        onClick={() => {
                          deactivate()
                          localStorage.setItem('isAuthorized', '')
                        }}
                      >
                        LOG OUT
                      </LogOut>
                    </>
                  )
                ) : null}
              </AccountElement>
            </HeaderElement>
          </HeaderControls>
        </>
      )}
      {showChangeServer && (
        <ROFIAccountModal
          title="SELECT SERVER"
          visible={showChangeServer}
          onCancel={() => setShowChangeServer(false)}
          footer={null}
          className="select-server"
        >
          <FlexLine>
            <RewardDetailTitle>Select server you want to connect</RewardDetailTitle>
          </FlexLine>
          <FlexLine>
            <SelectServer onClick={() => onSelectServer('s1')}>
              <div>S1 - Milky Way</div>
              <div>
                <img src={bscIcon} width={30} alt="" />
              </div>
            </SelectServer>
          </FlexLine>
          <FlexLine>
            <SelectServer onClick={() => onSelectServer('s2')}>
              <div>S2 - Andromeda</div>
              <div>
                <img src={bscIcon} width={30} alt="" />
              </div>
            </SelectServer>
          </FlexLine>
        </ROFIAccountModal>
      )}
    </HeaderFrame>
  )
}
