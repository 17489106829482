import ChestB from 'assets/pefi/shop/chest/B_Chest.png'
import ChestA from 'assets/pefi/shop/chest/A_Chest.png'
import ChestS from 'assets/pefi/shop/chest/S_Chest.png'
import ChestSS from 'assets/pefi/shop/chest/SS_Chest.png'
import Combo1 from 'assets/pefi/shop/chest/chest-02.png'
import Combo2 from 'assets/pefi/shop/chest/chest-03.png'
import Combo3 from 'assets/pefi/shop/chest/chest-04.png'
import Combo4 from 'assets/pefi/shop/chest/chest-05.png'
import Combo5 from 'assets/pefi/shop/chest/chest-06.png'

export const CHEST_PACK = [
  {
    pack_id: 7,
    pack_name: 'Chest B',
    number_of_gems: 0,
    price: 2500,
    busd_price: 1.5,
    pack_img: ChestB,
    discount: -50,
  },
  {
    pack_id: 8,
    pack_name: 'Chest A',
    number_of_gems: 0,
    price: 4500,
    busd_price: 2,
    pack_img: ChestA,
    discount: -50,
  },
  {
    pack_id: 9,
    pack_name: 'Chest S',
    number_of_gems: 0,
    price: 8500,
    busd_price: 5,
    pack_img: ChestS,
    discount: -50,
  },
  {
    pack_id: 10,
    pack_name: 'Chest SS',
    number_of_gems: 0,
    price: 20000,
    busd_price: 12,
    pack_img: ChestSS,
    discount: -50,
  },
]

export const COMBO_PACK = [
  {
    pack_id: 11,
    pack_name: '2 Chest B + 1 Gold Ticket',
    number_of_gems: 0,
    price: 2000,
    busd_price: 7,
    pack_img: Combo1,
    discount: -65,
  },
  {
    pack_id: 12,
    pack_name: '2 Chest A + 1 Platinum Ticket',
    number_of_gems: 0,
    price: 3000,
    busd_price: 12,
    pack_img: Combo2,
    discount: -65,
  },
  {
    pack_id: 13,
    pack_name: '2 Chest S + 1 Platinum Ticket',
    number_of_gems: 0,
    price: 5600,
    busd_price: 16,
    pack_img: Combo3,
    discount: -65,
  },
  {
    pack_id: 14,
    pack_name: '2 Chest SS + 1 Herald Ticket',
    number_of_gems: 0,
    price: 9000,
    busd_price: 35,
    pack_img: Combo4,
    discount: -65,
  },
  {
    pack_id: 15,
    pack_name: '2 Chest SS + 1 Ruby Ticket',
    number_of_gems: 0,
    price: 15000,
    busd_price: 45,
    pack_img: Combo5,
    discount: -65,
  },
]
