import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import previous from 'assets/images/previous-page.png'
import next from 'assets/images/next-page.png'

export interface PaginationProps {
  onChange: (page: number) => void
  currentPage: number
  totalPage: number
}

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`
const Previous = styled.button`
  background: transparent url(${previous}) center/cover no-repeat;
  outline: none;
  border: none;
  width: 79px;
  height: 42px;
`
const Next = styled.button`
  background: transparent url(${next}) center/cover no-repeat;
  outline: none;
  border: none;
  width: 79px;
  height: 42px;
`
const PageTextContainer = styled.div`
  border-bottom: 2px solid #79a1b8;
  margin: 0px 15px;
  font-size: 25px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 20px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `}
`

const Pagination = ({ onChange, currentPage, totalPage }: PaginationProps) => {
  return (
    <StyledPagination>
      <Previous onClick={() => currentPage >= 2 && onChange(currentPage - 1)}></Previous>
      <PageTextContainer>
        <Text fontWeight={700} margin="5px 15px">
          {`Page ${currentPage}/${totalPage}`}
        </Text>
      </PageTextContainer>
      <Next onClick={() => currentPage < totalPage && onChange(currentPage + 1)}></Next>
    </StyledPagination>
  )
}

export default Pagination
