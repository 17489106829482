import React from 'react'
import { useWalletModalToggle } from 'state/application/hooks'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import MagraText from 'components/Text'
import { HOW_TO_BUY } from 'constants/endpoints'

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 14px;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin: 0 auto 10px;
  width: 100%;
`

const HowToBuy = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const UnlockButton = () => {
  const toggleWalletModal = useWalletModalToggle()

  return (
    <>
      <Button onClick={toggleWalletModal}>
        <MagraText fontSize={16} fontWeight={600}>
          CONNECT WALLET
        </MagraText>
      </Button>
      <HowToBuy>
        <a style={{ color: '#fff' }} href={HOW_TO_BUY} target="_blank" rel="noreferrer">
          HOW TO BUY
        </a>
      </HowToBuy>
    </>
  )
}

export default UnlockButton
