import busd_logo from 'assets/images/busd-logo.svg'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import Text from 'components/Text'
import { PLANT_ENDPOINT } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Transaction as TransactionType } from 'constants/types'
import { useActiveWeb3React } from 'hooks/web3'
import React, { useEffect, useState } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import styled from 'styled-components'
import { CloseIcon } from 'theme'
import { shortenAddress } from 'utils'
import { getHeroRarity } from 'utils/getPlantHeroDetail'
import Pagination from '../../Pagination'
import TransactionSummary from '../TransactionSummary'
import Modal from './Modal'
import times from 'lodash/times'
import star from 'assets/pefi/special-pack/star.png'

const blockDiffToText = function (fromBlock: number, toBlock: number): string {
  const diff = toBlock - fromBlock
  if (diff > 28800) {
    return `${Math.floor(diff / 28800)} days ago`
  } else {
    return `${Math.floor(diff / 1200)} hours ago`
  }
}
const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #215574;
`
const TransactionList = styled.div`
  background: linear-gradient(to bottom left, #0d5a87, #094162);
  margin-top: 20px;
  padding: 5px 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5px 10px;
  `};
`
const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
  overflow: hidden;
`
const TitleContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`
const TransactionContainer = styled.div`
  padding: 20px 30px;
  background-color: inherit;
  border-radius: 5px;
  overflow-y: scroll;
  height: 50vh;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c2c8d5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
  `};
`

const Transaction = styled.div`
  padding: 10px;
  width: 100%;
  text-overflow: ellipsis;
  border-radius: 10px;
  background-color: #026092;
  border: 2px solid #03c5ff;
  opacity: 100%;
  margin: 10px 0px;
  display: flex;
`
const PriceContainer = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`
const PriceWrapperSecondary = styled.div`
  display: none;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
  `};
`
const ItemContainer = styled.div`
  width: 75%;
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`
const HeroIconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px 0px 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px 0px 0px 4px;
  `};
  margin-right: 20px;
`
const HeroTransactionsButton = styled.img`
  position: absolute;
  top: -4px;
  right: -6px;
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40px;
    height: 40px;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: -4px;
    right: -8px;
    width: 40px;
    height: 40px;
  `}
`
const HeroAvatar = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
  }
`
const Icon = styled.img`
  object-fit: contain;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #03c5ff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 84px;
    height: 84px;
  `}
`
const PaginationContainer = styled.div`
  margin-top: 10px;
  //width: 100%;
`
const TransactionDetailContainer = styled.div`
  flex: 4;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-evenly;
`
const Info = styled.div`
  display: flex;
`
const StyledCloseIcon = styled(CloseIcon)`
  align-self: flex-end;
`
const TitleContent = styled.div`
  align-items: center;
  display: flex;
  font-size: 25px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    font-size: 18px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    font-size: 16px;
  `};
`
const InfoTitleText = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  padding-right: 5px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
  `}
`
const InfoContentText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
  `}
`
const PriceText = styled(Text)`
  font-size: 30px;
  margin: 0px 10px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 26px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 23px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px;
  `}
`

const StarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`

const StarWrapperContainer = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  `}
`

const StarMobile = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
`

const Star = styled.img`
  width: 26px;
  height: 24px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 20px;
    height: 18px;
  `}
`

const HeroName = styled.div`
  color: #fff;
  font-size: 26px;
  padding: 20px 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    padding: 10px 0;
  `}
`

const HeroId = styled.div`
  color: #fff8e8;
  text-align: center;
  font-size: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    position: relative;
    top: -5px;
  `}
`

export default function TransactionHistoryModal({
  onDismiss,
  isOpen,
  statistics,
  onOpenTransactionDetail,
}: {
  isOpen: boolean
  onDismiss: () => void
  onOpenTransactionDetail: (transaction: TransactionType) => void
  statistics: { type: string; totalSale: string; totalVolume: string; heroesSold: string }[]
}) {
  const [transactions, setTransactions] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    axios.get(PLANT_ENDPOINT.TRANSACTIONS + currentPage).then((response) => {
      setTotalPages(response.data.paginator.totalPages)
      setTransactions(response.data.data)
    })
  }, [currentPage])
  const { chainId } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18
  const currentBlockNumber = useBlockNumber()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Container>
        <TransactionSummary
          isButtonShow={false}
          onClickButton={() => console.log('buttonclick')}
          statistics={statistics}
        />
        <TransactionList>
          <TitleContainer>
            <TitleContent>
              <Text>RECENTLY SOLD</Text>
            </TitleContent>
            <PaginationContainer>
              <Pagination totalPage={totalPages} currentPage={currentPage} onChange={setCurrentPage} />
            </PaginationContainer>
            {/*<StyledCloseIcon onClick={onDismiss} />*/}
          </TitleContainer>
          <Divider />
          <TransactionContainer>
            {transactions.map((transaction: any) => {
              const {
                orderId,
                price,
                buyer,
                seller,
                blockNumber,
                nft: { tokenId, star: numberOfStar, detail, rarity, plantId },
              } = transaction
              const humanReadablePrice = new BigNumber(price).div(new BigNumber(10).pow(decimals)).toFixed(2)
              const name = detail ? detail.name : '...'
              const rarityName = getHeroRarity(rarity)
              const avatar = `https://plantempires-media.b-cdn.net/icon-plant/${plantId}_${numberOfStar}.png`
              return (
                <Transaction key={orderId}>
                  <ItemContainer>
                    <HeroIconContainer>
                      <HeroAvatar>
                        <Icon src={avatar} />
                        <HeroId>#{tokenId}</HeroId>
                      </HeroAvatar>
                      <PriceWrapperSecondary>
                        <img src={busd_logo} width={'20px'} height={'20px'} />
                        <Text fontSize={20} margin="0px 10px">
                          {humanReadablePrice}
                        </Text>
                      </PriceWrapperSecondary>
                    </HeroIconContainer>
                    <TransactionDetailContainer>
                      <StarWrapperContainer>
                        <HeroName style={{ padding: 0, marginRight: 10 }}>{rarityName}</HeroName>
                        <div>
                          {times(numberOfStar, (index) => (
                            <Star src={star} key={index}></Star>
                          ))}
                        </div>
                      </StarWrapperContainer>
                      <Info>
                        <InfoTitleText>Buyer:&nbsp;</InfoTitleText>
                        <InfoContentText>{buyer ? shortenAddress(buyer) : '...'}</InfoContentText>
                      </Info>
                      <Info>
                        <InfoTitleText>Seller:&nbsp;</InfoTitleText>
                        <InfoContentText>{seller ? shortenAddress(seller) : '...'}</InfoContentText>
                      </Info>
                      <Info>
                        <InfoTitleText>Date:&nbsp;</InfoTitleText>
                        <InfoContentText>
                          {currentBlockNumber && blockDiffToText(blockNumber, currentBlockNumber)}
                        </InfoContentText>
                      </Info>
                    </TransactionDetailContainer>
                  </ItemContainer>
                  <PriceContainer>
                    <StarContainer>
                      <HeroName style={{ padding: 0 }}>{rarityName}</HeroName>
                    </StarContainer>
                    <StarContainer>
                      <div>
                        {times(numberOfStar, (index) => (
                          <Star src={star} key={index}></Star>
                        ))}
                      </div>
                    </StarContainer>
                    <PriceWrapper>
                      <img src={busd_logo} width={'30px'} height={'30px'} />
                      <PriceText>{humanReadablePrice}</PriceText>
                    </PriceWrapper>
                  </PriceContainer>
                </Transaction>
              )
            })}
          </TransactionContainer>
        </TransactionList>
      </Container>
    </Modal>
  )
}
