import { HERO_MARKET_SERVER } from '../../constants/endpoints'
import { OrderGraphEntity } from 'state/types'
import axios from 'axios'

export interface OrderFilter {
  nftAddress: string
  chainId: number | undefined
  priceFrom?: string
  priceTo?: string
}

export const getGraphOrders = async ({
  nftAddress,
  priceFrom,
  priceTo,
  chainId,
}: OrderFilter): Promise<OrderGraphEntity[]> => {
  try {
    const response = await axios.get(`${HERO_MARKET_SERVER}/orders`)
    return response.data.data
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getGraphEssenceOrders = async ({
  nftAddress,
  priceFrom,
  priceTo,
  chainId,
}: OrderFilter): Promise<OrderGraphEntity[]> => {
  try {
    const response = await axios.get(`${HERO_MARKET_SERVER}/essence-orders`)
    return response.data.data
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getGraphItemsOrders = async ({
  nftAddress,
  priceFrom,
  priceTo,
  chainId,
}: OrderFilter): Promise<OrderGraphEntity[]> => {
  try {
    const response = await axios.get(`${HERO_MARKET_SERVER}/herofi/item-orders`)
    return response.data.data
  } catch (error) {
    console.error(error)
    return []
  }
}
