import axios from 'axios'
import { useActiveWeb3React } from 'hooks/web3'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import UnlockButton from 'pages/AccountPage/MyAssets/UnlockButton'
import { ROFI_ACCOUNT, CHECKIN } from 'constants/mkpconfigs'
import tickIcon from 'assets/pefi/icon_tick.png'
import { useCheckinContract, useCheckinContractS2 } from 'hooks/useContract'
import { NotificationManager } from 'react-notifications'
import { TransactionResponse } from '@ethersproject/providers'
import { LINK_DOWNLOAD } from 'constants/endpoints'
import Countdown from 'react-countdown'

const MainContent = styled.div`
  padding: 30px;
  min-height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainText = styled.div`
  text-align: center;
  font-size: 24px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
  `};
`

const CheckinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`

const CheckinWrapper = styled.div`
  width: 100%;
  background: rgb(2, 96, 146);
  border: 2px solid rgb(3, 197, 255);
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0 auto;
  `};
`

const CheckinTitle = styled.div`
  text-align: center;
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    padding: 5px 0;
  `};
`

const CheckinSubtitle = styled.div`
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
    padding: 5px 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    padding: 5px 10px;
  `};
`

const CheckinArea = styled.div`
  padding: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px;
  `};
`

const CheckinTable = styled.div``
const CheckinReward = styled.div`
  padding-left: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-left: 0;
    padding-top: 20px;
  `};
`

const RewardContent = styled.div`
  background-color: rgb(1, 81, 122);
  border-radius: 20px;
  padding: 20px;
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RewardInDay = styled.div`
  text-align: center;
  position: relative;
`

const RewardImage = styled.img`
  width: 50px;
  margin-top: 10px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 40px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 50px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 30px;
  `};
`

const NumberOfReward = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 14px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const RewardDescription = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 0 20px;
  color: #fff;
`

const RewardDetail = styled.div`
  padding: 20px;
  background: rgb(2, 96, 146);
  border: 2px solid rgb(3, 197, 255);
  border-radius: 10px;
  width: fit-content;
  position: relative;
`

const CheckinButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &:hover,
  &.disable {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px 10px;
  `};
`

const CheckinItem = styled.div`
  background: rgb(3 113 169);
  border: 2px solid rgb(3, 197, 255);
  border-radius: 10px;
  padding: 5px 10px 10px;
  position: relative;
`

const CheckedIn = styled.div`
  background: #00000052;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const DayTitle = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  padding: 0 10px;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 0 5px;
  `};
`

const TitleText = styled.div`
  font-size: 14px;
  text-align: center;
  background: #0099e2;
  padding: 0 3px;
  border-radius: 5px;
  line-height: 23px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 11px;
    padding: 0;
  `};
`

const ROFIAccountModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`

export default function Checkin({ setPageState }: { setPageState: (state: string) => void }) {
  const { account } = useActiveWeb3React()
  const token_api = localStorage.getItem('token_api')
  const [currentReward, setCurrentReward] = useState<any>(undefined)
  const [allowClaim, setAllowClaim] = useState<boolean>(false)
  const [rewards, setRewards] = useState<any[]>([])
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [openResult, setOpenResult] = useState<boolean>(false)
  const [changeAccount, setChangeAccount] = useState<boolean>(false)
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [notConnectWallet, setNotConnectWallet] = useState<boolean>(false)
  const [notHaveDataInGame, setNotHaveDataInGame] = useState<boolean>(false)
  const [canNotFetchAccountData, setCanNotFetchAccountData] = useState<boolean>(false)

  const server = localStorage.getItem('server')

  const checkinContractS1 = useCheckinContract()
  const checkinContractS2 = useCheckinContractS2()
  const checkinContract = server ? (server === 's1' ? checkinContractS1 : checkinContractS2) : checkinContractS1

  useEffect(() => {
    fetchCheckinData(account)
    const { ethereum } = window
    ethereum &&
      ethereum.on &&
      ethereum?.on('accountsChanged', () => {
        localStorage.removeItem('token_api')
        setChangeAccount(true)
        setPageState('dashboard')
      })
  }, [account])

  const fetchCheckinData = async (account: any) => {
    if (!account) return
    const url = `${CHECKIN.CHECKINDAY}/${account}`
    try {
      const response = await axios.get(url)
      const this_day = response.data.response.data.day
      const allowClaim = response.data.response.data.allowClaim
      setAllowClaim(allowClaim)
      const rewards = response.data.response.data.rewards
      setRewards(rewards)
      const currentReward = rewards.find((r: any) => r.day === this_day)
      if (currentReward) setCurrentReward(currentReward)
      setIsLoadingData(false)
      return response.data.response
    } catch (error) {
      console.error(error)
      fetchAccountData()
      return undefined
    }
  }

  const fetchAccountData = async () => {
    const token_api = localStorage.getItem('token_api')
    if (!token_api) {
      setCanNotFetchAccountData(true)
      setIsLoadingData(false)
      return
    }
    const url = `${ROFI_ACCOUNT.INFO}`
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          Authorization: `Bearer ${token_api}`,
        },
        data: {},
      })
      if (response.status === 200) {
        const rofi_id = response.data.data
        if (!rofi_id || !rofi_id.email) {
          setNotConnectWallet(true)
          setIsLoadingData(false)
          return
        }
        setNotHaveDataInGame(true)
        setIsLoadingData(false)
        return
      } else {
        console.error(response)
        setCanNotFetchAccountData(true)
        setIsLoadingData(false)
        return
      }
    } catch (error) {
      setCanNotFetchAccountData(true)
      setIsLoadingData(false)
      return
    }
  }

  const checkin = async () => {
    console.log('on checkin')
    if (!checkinContract || !account) return
    const data = {
      address: account,
    }
    axios({
      method: 'post',
      url: CHECKIN.GET_SIGNATURE,
      headers: {},
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          const signed = response.data.data
          const method: (...args: any) => Promise<TransactionResponse> = checkinContract.checkin
          const args: Array<string | string[] | number> = [signed.nonce, signed.signature]
          setAttemptingTxn(true)
          return method(...args, {})
            .then(async (response) => {
              const txFull = await response.wait()
              console.log('txHash', txFull.transactionHash)
              if (txFull.status === 0) {
                NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
                setAttemptingTxn(false)
                return
              }
              setAllowClaim(false)
              const internal = setInterval(async () => {
                const updateCheckinData = await fetchCheckinData(account)
                if (updateCheckinData && !updateCheckinData.data.allowClaim) {
                  setOpenResult(true)
                  setAttemptingTxn(false)
                  clearInterval(internal)
                }
              }, 3000)
            })
            .catch((error) => {
              setAttemptingTxn(false)
              console.error(error)
              NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            })
        }
        NotificationManager.error('Can not get signature.', 'Error', 2000)
        return
      })
      .catch((error) => {
        console.error(error)
      })
  }

  if (!account) {
    return (
      <MainContent>
        <MainText>
          <div>Please connect wallet to activate this page</div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <UnlockButton />
          </div>
        </MainText>
      </MainContent>
    )
  }

  const renderCountdown = ({ seconds, completed }: { seconds: any; completed: boolean }) => {
    if (completed) {
      setPageState('dashboard')
      return <></>
    } else {
      return <div>{seconds}s</div>
    }
  }

  if (changeAccount) {
    return (
      <MainContent>
        <MainText>
          <div>You have changed wallet address</div>
          <div>
            Redirect to Dashboard in
            <span>
              <Countdown date={Date.now() + 5 * 1000} renderer={renderCountdown} zeroPadTime={2} />
            </span>
          </div>
        </MainText>
      </MainContent>
    )
  }

  if (notConnectWallet) {
    return (
      <MainContent>
        <MainText>
          <div>You haven&apos;t connected your account yet</div>
          <div>
            Redirect to Dashboard in
            <span>
              <Countdown date={Date.now() + 5 * 1000} renderer={renderCountdown} zeroPadTime={2} />
            </span>
          </div>
        </MainText>
      </MainContent>
    )
  }

  if (canNotFetchAccountData) {
    return (
      <MainContent>
        <MainText>
          <div>Can not fetch account data</div>
          <div>
            Redirect to Dashboard in
            <span>
              <Countdown date={Date.now() + 5 * 1000} renderer={renderCountdown} zeroPadTime={2} />
            </span>
          </div>
        </MainText>
      </MainContent>
    )
  }

  if (notHaveDataInGame) {
    return (
      <MainContent>
        <MainText>
          <div>You haven&apos;t logged in game.</div>
          <div>Please login game and try again.</div>
          <div>
            Link download game: {}
            <a href={LINK_DOWNLOAD} target="_blank" rel="noreferrer" style={{ color: '#9ce315' }}>
              Plant Empires
            </a>
          </div>
        </MainText>
      </MainContent>
    )
  }

  return (
    <>
      <MainContent>
        {isLoadingData ? (
          <MainText>
            <div>Loading data...</div>
          </MainText>
        ) : (
          <CheckinContainer>
            <CheckinWrapper>
              <CheckinTitle>DAILY CHECK-IN</CheckinTitle>
              <CheckinSubtitle>
                If you don&apos;t check-in daily, your rewards will be reset back to day 1
              </CheckinSubtitle>
              <CheckinArea>
                <CheckinTable>
                  <TableGrid>
                    {rewards.map((r: any, index: any) => (
                      <CheckinItem key={index}>
                        <DayTitle>
                          <TitleText>Day {r.day}</TitleText>
                        </DayTitle>
                        <RewardInDay style={{ textAlign: 'center' }}>
                          <RewardImage
                            src={`https://plantempires-media.b-cdn.net/checkin-reward/${r.type}.png`}
                            alt=""
                          />
                          <NumberOfReward>x{r.amount}</NumberOfReward>
                        </RewardInDay>
                        {currentReward && (r.day < currentReward.day || (r.day === currentReward.day && !allowClaim)) && (
                          <CheckedIn>
                            <img style={{ width: 30 }} src={tickIcon} alt="" />
                          </CheckedIn>
                        )}
                      </CheckinItem>
                    ))}
                  </TableGrid>
                </CheckinTable>
                <CheckinReward>
                  <RewardContent>
                    <RewardDetailTitle>Check-in every day to get reward</RewardDetailTitle>
                    <FlexLine>
                      <RewardDetail>
                        <img
                          style={{ width: 100 }}
                          src={`https://plantempires-media.b-cdn.net/checkin-reward/${currentReward.type}.png`}
                          alt=""
                        />
                        <NumberOfReward style={{ bottom: 0, fontSize: 18 }}>x{currentReward.amount}</NumberOfReward>
                      </RewardDetail>
                    </FlexLine>
                    <FlexLine>
                      {allowClaim ? (
                        <CheckinButton onClick={checkin} className={attemptingTxn ? 'disable' : ''}>
                          {attemptingTxn ? 'LOADING' : 'CHECK-IN'}
                        </CheckinButton>
                      ) : (
                        <CheckinButton className={'disable'}>CHECKED</CheckinButton>
                      )}
                    </FlexLine>
                    <FlexLine>
                      <RewardDescription>Reward will be send to mail box in-game</RewardDescription>
                    </FlexLine>
                  </RewardContent>
                </CheckinReward>
              </CheckinArea>
            </CheckinWrapper>
          </CheckinContainer>
        )}
      </MainContent>
      {openResult && (
        <ROFIAccountModal
          title="CONGRATULATION"
          visible={openResult}
          onCancel={() => setOpenResult(false)}
          footer={null}
        >
          <FlexLine>
            <RewardDetailTitle>REWARD</RewardDetailTitle>
          </FlexLine>
          <FlexLine>
            <RewardDetail>
              <img
                style={{ width: 100 }}
                src={`https://plantempires-media.b-cdn.net/checkin-reward/${currentReward.type}.png`}
                alt=""
              />
              <NumberOfReward style={{ bottom: 0, fontSize: 18 }}>x{currentReward.amount}</NumberOfReward>
            </RewardDetail>
          </FlexLine>
          <FlexLine style={{ marginTop: 20 }}>
            <RewardDescription>Reward will be send to mail box in-game</RewardDescription>
          </FlexLine>
          <FlexLine>
            <CheckinButton onClick={() => setOpenResult(false)}>GOT IT</CheckinButton>
          </FlexLine>
        </ROFIAccountModal>
      )}
    </>
  )
}
