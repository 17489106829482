import React, { useMemo, useState } from 'react'
import Text from 'components/Text'
import total_sale from 'assets/images/total-sale.png'
import total_volume from 'assets/images/Icon_TotalVolume.png'
import hero_sold from 'assets/images/hero-sold.png'
import button_info from 'assets/pefi/button-info.png'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks/web3'
import { LZ } from 'constants/tokens'
import BigNumber from 'bignumber.js'
import busd_logo from 'assets/images/busd-logo.svg'

const TabNavigation = styled.div`
  border-radius: 5px;
  display: flex;
`
const TabNavigationContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 20px;
  border: 1px solid #596f79;
  border-radius: 5px;
  background: linear-gradient(to bottom, #024a77, #023a5e);
  padding: 10px 0;
`

const TabItem = styled.a`
  font-weight: 700;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 20px;
  font-family: Gudea-Medium;
  color: #fff8e8;

  &.active {
    border-bottom: 3px solid #55992c;
    color: #55992c;
  }

  &.first {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  :hover {
    color: #55992c;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `}
`
const StaticWrapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 70%;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`

const StatisticContainer = styled.div`
  overflow: inherit;
  position: relative;
  display: flex;
  border: 1px solid #638aa2;
  border-bottom: 2px solid #a1b2b0;
  justify-content: space-around;
  background-color: #00436d;
  border-radius: 5px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 0px 25px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px 15px;
  `}
`
const StatisticInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 5px 0px;
  `}
`
const InfoButtonContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: absolute;
    top: -26px;
    right: -10px;
  `}
`
const InfoButtonTab = styled.div`
  padding: 10px;
  display: none;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    top: -26px;
    right: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`
const StatisticName = styled.div`
  padding: 5px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
`
const StatisticValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StatisticNameText = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
  color: white;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
  `}
`
const StatisticValueText = styled(Text)`
  font-size: 44px;
  color: #8cdd1b;
  margin: 0px 0px 0px 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 34px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 30px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 28px;
  `}
`

const BUSDLogo = styled.img`
  width: 30px;
  height: 30px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 28px;
    height: 28px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 23px;
    height: 23px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 20px;
    height: 20px;
  `}
`

const StyledTransactionSummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`
const ButtonInfo = styled.img`
  width: 40px;
  height: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 30px;
    height: 30px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 25px;
    height: 25px;
  `}
`

export default function TransactionSummary({
  statistics,
  isButtonShow,
  onClickButton,
}: {
  statistics: { info: { totalSale: string; totalVolume: string; orbsSold: string }; type: string }[]
  isButtonShow: boolean
  onClickButton: () => void
}) {
  const [tab, setTab] = useState('ORB_ONE_DAYS')
  const InfoButton = useMemo(
    () => <ButtonInfo src={button_info} onClick={onClickButton} style={{ cursor: 'pointer' }} />,
    [isButtonShow, onClickButton]
  )
  const { chainId } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18
  const { totalSale, totalVolume, orbsSold } = useMemo(() => {
    const statistic = statistics.find((s) => s.type == tab) || {
      info: {
        totalSale: '0',
        totalVolume: '0',
        orbsSold: '0',
      },
    }
    return {
      totalSale: statistic.info.totalSale,
      totalVolume: new BigNumber(statistic.info.totalVolume).div(new BigNumber(10).pow(decimals)).toFixed(0),
      orbsSold: statistic.info.orbsSold,
    }
  }, [tab, statistics])

  return (
    <StyledTransactionSummary>
      <TabNavigation>
        <TabNavigationContainer>
          <TabItem className={`first${tab === 'ORB_ONE_DAYS' ? ' active' : ''}`} onClick={() => setTab('ORB_ONE_DAYS')}>
            Last 24h
          </TabItem>
          <TabItem className={`${tab === 'ORB_SEVEN_DAYS' ? 'active' : ''}`} onClick={() => setTab('ORB_SEVEN_DAYS')}>
            7 days
          </TabItem>
          <TabItem
            className={`last${tab === 'ORB_THIRTY_DAYS' ? ' active' : ''}`}
            onClick={() => setTab('ORB_THIRTY_DAYS')}
          >
            30 days
          </TabItem>
          {/* <InfoButtonTab>{isButtonShow ? InfoButton : undefined}</InfoButtonTab> */}
        </TabNavigationContainer>
      </TabNavigation>
      <StaticWrapper>
        <StatisticContainer>
          <StatisticInfo>
            <StatisticName>
              <StatisticNameText>TOTAL SALE</StatisticNameText>
            </StatisticName>
            <StatisticValue>
              <StatisticValueText>{totalSale}</StatisticValueText>
            </StatisticValue>
          </StatisticInfo>
          <StatisticInfo>
            <StatisticName>
              <StatisticNameText>TOTAL VOLUME</StatisticNameText>
            </StatisticName>
            <StatisticValue>
              <StatisticValueText>
                {totalVolume}
                <span style={{ paddingLeft: 10 }}>
                  <BUSDLogo src={busd_logo} />
                </span>
              </StatisticValueText>
            </StatisticValue>
          </StatisticInfo>
          {/* <StatisticInfo>
            <StatisticName>
              <StatisticNameText>ORBS SOLD</StatisticNameText>
            </StatisticName>
            <StatisticValue>
              <StatisticValueText>{orbsSold}</StatisticValueText>
            </StatisticValue>
          </StatisticInfo> */}
          <InfoButtonContainer>{isButtonShow ? InfoButton : undefined}</InfoButtonContainer>
        </StatisticContainer>
      </StaticWrapper>
    </StyledTransactionSummary>
  )
}
