import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NftGraphEntity, NftState } from 'state/types'
import { getGraphNfts, NftFilter } from './getLotteriesData'

const initialState: NftState = {
  nftsData: [],
}

export const fetchNfts = createAsyncThunk<NftGraphEntity[], NftFilter>(
  'nft/fetchOwnerNfts',
  async ({ nftAddress, owner, chainId }: NftFilter) => {
    const lotteries = await getGraphNfts({ nftAddress, owner, chainId })
    return lotteries
  }
)

export const NftSlice = createSlice({
  name: 'Nft',
  initialState,
  reducers: {
    setLotteryPublicData: (state, action) => {
      state = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNfts.fulfilled, (state, action: PayloadAction<NftGraphEntity[]>) => {
      state.nftsData = action.payload
    })
  },
})

// Actions
export const { setLotteryPublicData } = NftSlice.actions

export default NftSlice.reducer
