import confirmFilter from 'assets/pefi/my-assets/confirm-filter.png'
import cancelFilter from 'assets/pefi/my-assets/sell-modal/cancel.png'
import Text from 'components/Text'
import { PEBoxFilterConditions } from 'constants/types'
import React, { useState } from 'react'
import styled from 'styled-components'

const FilterCriterion = styled.p`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 25px;
  line-height: 34.61px;
  margin-top: 30px;
`
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const StarLabel = styled.div`
  margin: 20px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export interface FilterProps {
  id: string
  onConfirm: (filter: PEBoxFilterConditions) => void
  onCancel?: () => void
}
const Checkbox = styled.input`
  background-color: #fff;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid #cecece;
  border-radius: 3px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
  :checked {
    appearance: none;
    border-radius: 5px;
    background-color: #0ac5d1;
  }
`
const CheckboxContent = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #cecece;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
const Action = styled(Row)`
  margin-top: 30px;
  height: 30px;
`

const ButtonConfirm = styled.img`
  margin: 0 auto;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    margin: 10px auto;
  `};
`

const Button = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const Filter = ({ onConfirm, id, onCancel }: FilterProps) => {
  const [green, setGreen] = useState<boolean>(false)
  const [red, setRed] = useState<boolean>(false)
  const [blue, setBlue] = useState<boolean>(false)
  const [yellow, setYellow] = useState<boolean>(false)
  const [diamond, setDiamond] = useState<boolean>(false)

  const handleConfirm = () => {
    const rarityNameSet = new Set<string>()
    if (green) {
      rarityNameSet.add('green')
    } else {
      rarityNameSet.delete('green')
    }
    if (red) {
      rarityNameSet.add('red')
    } else {
      rarityNameSet.delete('red')
    }
    if (blue) {
      rarityNameSet.add('blue')
    } else {
      rarityNameSet.delete('blue')
    }
    if (yellow) {
      rarityNameSet.add('yellow')
    } else {
      rarityNameSet.delete('yellow')
    }

    const newFilter = {
      rarityNames: Array.from(rarityNameSet),
    }
    onConfirm(newFilter)
  }
  return (
    <FilterWrapper>
      <div id={id} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <FilterCriterion>Holy Pack NFTs</FilterCriterion>
        <Row>
          <Checkbox name="water" type="checkbox" checked={green} onClick={() => setGreen(!green)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Green (for Supporter class)</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="plant" type="checkbox" checked={red} onClick={() => setRed(!red)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Red (for DPS class)</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="fire" type="checkbox" checked={blue} onClick={() => setBlue(!blue)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Blue (for Fammer class)</Text>
          </div>
        </Row>
        <Row>
          <Checkbox name="thunder" type="checkbox" checked={yellow} onClick={() => setYellow(!yellow)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text>Yellow (for Tanker class)</Text>
          </div>
        </Row>{' '}
      </div>
      <Action>
        {typeof onCancel === 'function' && (
          <Button style={{ background: '#f6c931' }} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button onClick={handleConfirm}>Confirm</Button>
      </Action>
    </FilterWrapper>
  )
}
export default Filter
