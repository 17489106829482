import React, { useState } from 'react'
import HeroesMarket from './PlantMarket'
import OrbsMaket from './OrbsMarket'
import HolyMarket from './HolyMarket'
import PieceMarket from './PieceMarket'

export default function Marketplace({
  setUniverse,
  currentUniverse,
}: {
  setUniverse: (state: string) => void
  currentUniverse: string
}) {
  const [pageState, setPageState] = useState<string>('heroesmarket')

  const server = localStorage.getItem('server')

  if (pageState === 'heroesmarket') {
    return <HeroesMarket currentUniverse={currentUniverse} setUniverse={setUniverse} setPageState={setPageState} />
  }

  if (pageState === 'orbsmarket') {
    return <OrbsMaket currentUniverse={currentUniverse} setUniverse={setUniverse} setPageState={setPageState} />
  }

  if (pageState === 'holymarket') {
    return <HolyMarket currentUniverse={currentUniverse} setUniverse={setUniverse} setPageState={setPageState} />
  }

  if (pageState === 'piecemarket') {
    return <PieceMarket currentUniverse={currentUniverse} setUniverse={setUniverse} setPageState={setPageState} />
  }

  return <HeroesMarket currentUniverse={currentUniverse} setUniverse={setUniverse} setPageState={setPageState} />
}
