import React, { useState } from 'react'
import styled from 'styled-components'
import Plants from './Plants'
import Orbs from './Orbs'

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
  background: #222938;
  &.rewards {
    grid-template-columns: 25% 50% 25%;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 100%;
  `}
`
const SideBar = styled.div`
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `}
`
const HeaderNavbar = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`
const NavItem = styled.div`
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-family: Gudea-Medium;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  &.active,
  &:hover {
    background: #14324c;
  }
`

export default function Herofi() {
  const [pageState, setPageState] = useState<string>('plant')

  const renderContent = (pageState: any) => {
    if (pageState === 'plant') {
      return <Plants setPageState={setPageState} />
    }

    if (pageState === 'orb') {
      return <Orbs setPageState={setPageState} />
    }

    return <Plants setPageState={setPageState} />
  }

  return <>{renderContent(pageState)}</>
}
