import styled from 'styled-components'

const NftGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 24% 24% 24% 24%;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upTo1440`
    grid-template-columns: 32% 32% 32%;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-template-columns: 30% 30% 30%;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 30px;
    grid-template-columns: 50% 50%;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 20px;
    grid-template-columns: 1fr 1fr;
  `}
`

export default NftGrid
