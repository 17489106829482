import rare_ticket from 'assets/pefi/shop/pvpticket/rare_ticket.png'
import mythic_ticket from 'assets/pefi/shop/pvpticket/mythic_ticket.png'
import legend_ticket from 'assets/pefi/shop/pvpticket/legend_ticket.png'

export const GEM_PACK = [
  {
    pack_id: 16,
    pack_name: 'Rare Ticket',
    quantity: 100,
    price: 500,
    img: rare_ticket,
    discount: 50,
  },
  {
    pack_id: 17,
    pack_name: 'Rare Ticket',
    quantity: 500,
    price: 2500,
    img: rare_ticket,
    discount: 50,
  },
  {
    pack_id: 18,
    pack_name: 'Rare Ticket',
    quantity: 1000,
    price: 5000,
    img: rare_ticket,
    discount: 50,
  },
  {
    pack_id: 19,
    pack_name: 'Mythical Ticket',
    quantity: 100,
    price: 1000,
    img: mythic_ticket,
    discount: 50,
  },
  {
    pack_id: 20,
    pack_name: 'Mythical Ticket',
    quantity: 500,
    price: 5000,
    img: mythic_ticket,
    discount: 50,
  },
  {
    pack_id: 21,
    pack_name: 'Mythical Ticket',
    quantity: 1000,
    price: 10000,
    img: mythic_ticket,
    discount: 50,
  },
  {
    pack_id: 22,
    pack_name: 'Legendary Ticket',
    quantity: 100,
    price: 1500,
    img: legend_ticket,
    discount: 50,
  },
  {
    pack_id: 23,
    pack_name: 'Legendary Ticket',
    quantity: 500,
    price: 7500,
    img: legend_ticket,
    discount: 50,
  },
  {
    pack_id: 24,
    pack_name: 'Legendary Ticket',
    quantity: 1000,
    price: 15000,
    img: legend_ticket,
    discount: 50,
  },
]
