/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import PE_HOLY_PACKAGE_ABI from 'abis/pe-holy-package-abi.json'
import PE_HOLY_MARKET_ABI from 'abis/pe-holy-market-abi.json'
import PE_HOLY_MARKET_OLD_ABI from 'abis/pe-holy-market-old-abi.json'
import button_info from 'assets/pefi/button-info.png'
import buttonCancel from 'assets/pefi/my-assets/cancel-button.png'
import buttonCancelling from 'assets/pefi/my-assets/cancelling-button.png'
import buttonSell from 'assets/pefi/my-assets/sell-button.png'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import buttonUpdate from 'assets/pefi/my-assets/update-price-button.png'
import addIcon from 'assets/pefi/special-pack/add-icon.png'
import subIcon from 'assets/pefi/special-pack/sub-icon.png'
import axios from 'axios'
import BigNumber from 'bignumber.js'
// import FilterModal from 'components/FilterModal'
import Footer from 'components/Footer'
import Preview from 'components/PreviewCard/HolyCardPreview/Preview'
import PreviewHolyPack from 'components/PreviewCard/HolyPackCardPreview/Preview'
import Filter from 'components/Filters/HolyFilter/Filter'
import GiftModal from 'components/MyAssets/Holy/GiftModal'
import SellModal from 'components/MyAssets/Holy/Sell/SellModal'
import UpdatePriceModal from 'components/MyAssets/Holy/Sell/UpdatePriceModal'
import GiftBox from 'components/MyAssets/Holy/GiftBox'
import Text, { MagraText } from 'components/Text'
import {
  PE_HOLY_PACKAGE_ADDRESS,
  PE_HOLY_MARKET_ADDRESS,
  PE_HOLY_MARKET_OLD_ADDRESS,
  S2_HOLY_PACKAGE_ADDRESS,
  S2_HOLY_MARKET_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { HOLY_ENDPOINT, MINT_TICKET } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft, PEBoxFilterConditions } from 'constants/types'
import {
  useHolyPackageContract,
  useHolyMarketContract,
  useHolyMarketOldContract,
  useHolyPackageContractS2,
  useHolyMarketContractS2,
} from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import includes from 'lodash/includes'
import { Dots } from 'pages/styleds'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { getDecimalAmount } from 'utils/formatBalance'
import paginate from 'utils/paginate'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import NftGrid from './NftGrid'
import Pagination from '../Pagination'
import UnlockButton from '../../UnlockButton'
import minus from 'assets/pefi/orbs/minus.png'
import plus from 'assets/pefi/orbs/plus.png'
import { Modal } from 'antd'
import mintTicketImage from 'assets/pefi/mint-ticket.png'
import Countdown from 'react-countdown'
import box from 'assets/pefi/holy/box.png'
export interface NftCardProps {
  nft: Nft
  refresh: () => void
}

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const NUMBER_PER_PAGE = 8
const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`

const FilterPanel = styled.div`
  flex: 1;
  background: #14324c50;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
  padding: 30px;
`
const General = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

const NFTArea = styled.div`
  flex: 4;
  padding: 30px;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const FilterTitle = styled.div`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #fff;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const RowTwo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const Checkbox = styled.input`
  background-color: #fff;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid #cecece;
  border-radius: 3px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  top: 3px;
  :checked {
    appearance: none;
    border-radius: 5px;
    background-color: #0ac5d1;
  }
`

const TokenLogo = styled.img`
  margin-right: 5px;
  position: relative;
  top: 2px;
`
const UnAuthorizedContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 500px;
  flex-direction: column;
  justify-content: space-evenly;
`

const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
`

const PageContainer = styled.div`
  display: block;
  background: transparent;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
`

const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 30px 3px;
  max-width: 200px;
  margin: 30px auto 0;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 25%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
  `}
`

const ButtonSell = styled.img`
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const ListHeroContainer = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #14324c50;
  border-radius: 10px;
`

const StatusTitle = styled.div`
  margin-bottom: 25px;
  font-size: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MintTicketCount = styled.span`
  margin-left: 10px;
  padding: 0 10px;
  background: #013b59;
  border-radius: 15px;
  border: 2px solid #03c5ff;
  display: flex;
  align-items: center;
`

const CountdownText = styled.div`
  font-weight: 500;
  padding-left: 20px;
  color: #f2c32a;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 0;
    font-size: 16px;
  `};
`

const HolyIngameCard = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HolyPreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const HolyId = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

const InputBox = styled.div``

const CardDescription = styled.div`
  display: block;
  width: 100%;
  &.stock {
    margin-top: -35px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: auto;
    padding: 0;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: auto;
    padding: 0 15px;
  `};
`

const ChangeButton = styled.img`
  cursor: pointer;
`

const InputAmount = styled.div`
  padding: 0 10px;
  height: 36px;
  display: flex;
  align-items: center;
  background-color: #013b59;
`

const AmountInput = styled.input`
  background-color: transparent;
  width: 75px;
  color: #f2c32a;
  appearance: none;
  font-family: Gudea-Medium;
  font-size: 30px;
  font-weight: bold;
  height: 36px;
  border: none;
  outline: none;
  text-align: center;
  padding-top: 7px;
  padding-left: 15px;
  ::-webkit-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-ms-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-moz-placeholder {
    font-family: Gudea-Medium;

    ::-moz-placeholder {
      font-family: Gudea-Medium;
    }
`

const HolyPackTitle = styled.div`
  font-family: Gudea-Medium;
  width: 100%;
  text-align: center;
  margin: 10px auto;
  position: relative;
`

const InfoIcon = styled.img`
  height: 20px;
  margin-left: 10px;
  position: relative;
  top: 5px;
  cursor: pointer;
  &:hover {
    + .tips {
      display: block;
    }
  }
`

const Tips = styled.div`
  display: none;
  position: absolute;
  right: 0;
  color: #fff;
  width: 150px;
  background: #121212;
  z-index: 2;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`

const MintHolyButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto 0;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 20px;
  `};
`

const HolyName = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #f2c32a;
  font-family: Montio;
  text-transform: uppercase;
`

const ROFIAccountModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const RewardDescription = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 0 20px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const CheckinButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const CountdownContent = styled.div`
  font-size: 14px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const FilterModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609299 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

const UnAuthorized = () => {
  return (
    <UnAuthorizedContainer>
      <MagraText fontSize={35} fontWeight={600}>
        NOTHING HERE YET...
      </MagraText>
      <Text fontSize={18} fontWeight={400}>
        Please connect your wallet to activate this page
      </Text>
      <UnlockButton />
    </UnAuthorizedContainer>
  )
}

export default function Heroes() {
  // Define state
  const [{ showConfirm, buyErrorMessage, nftDetailSelected }, setShowDetail] = useState<{
    showConfirm: boolean
    buyErrorMessage: string | undefined
    nftDetailSelected: Nft | undefined
  }>({
    showConfirm: false,
    buyErrorMessage: undefined,
    nftDetailSelected: undefined,
  })
  const [{ showSell, sellErrorMessage, nftSellSelected }, setSellState] = useState<{
    showSell: boolean
    sellErrorMessage: string | undefined
    nftSellSelected: any | undefined
  }>({
    showSell: false,
    sellErrorMessage: undefined,
    nftSellSelected: undefined,
  })
  const [cannotSell, setCannotSell] = useState<boolean>(false)
  const [cannotMate, setCannotMate] = useState<boolean>(false)

  const [{ showUpdatePrice, nftUpdateSelected }, setUpdatePriceState] = useState<{
    showUpdatePrice: boolean
    nftUpdateSelected: any | undefined
  }>({
    showUpdatePrice: false,
    nftUpdateSelected: undefined,
  })
  const [{ showUpgradeStar, upgradeStarErrorMessage, mainHeroSelected, materialHeroSelected }, setUpgradeStar] =
    useState<{
      showUpgradeStar: boolean
      upgradeStarErrorMessage: string | undefined
      mainHeroSelected: Nft | undefined
      materialHeroSelected: any
    }>({
      showUpgradeStar: false,
      upgradeStarErrorMessage: undefined,
      mainHeroSelected: undefined,
      materialHeroSelected: {},
    })
  const [{ upgradeResult, showUpgradeResult }, setUpgradeResult] = useState<{
    upgradeResult: Nft | undefined
    showUpgradeResult: boolean
  }>({
    upgradeResult: undefined,
    showUpgradeResult: false,
  })
  const [{ showGiftBox, giftBoxErrorMessage }, setShowGiftBox] = useState<{
    showGiftBox: boolean
    giftBoxErrorMessage?: string | undefined
  }>({
    showGiftBox: false,
    giftBoxErrorMessage: undefined,
  })
  const [{ showGift, giftErrorMessage, nftGiftSelected }, setGiftState] = useState<{
    showGift: boolean
    giftErrorMessage: string | undefined
    nftGiftSelected: any | undefined
  }>({
    showGift: false,
    giftErrorMessage: undefined,
    nftGiftSelected: undefined,
  })
  const [orderCanceling, setOrderCanceling] = useState<{
    tokenId: string | undefined
    isCanceling: boolean
  }>({
    tokenId: undefined,
    isCanceling: false,
  })

  const [openConfirmMint, setOpenConfirmMint] = useState<boolean>(false)
  const [checkedConfirm, setCheckedConfirm] = useState<boolean>(false)
  const [holyWillMint, setHolyWillMint] = useState<any>(undefined)

  const [nfts, setNfts] = useState<Nft[]>([])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  const { chainId, account } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18

  const marketOld = useHolyMarketOldContract()

  const server = localStorage.getItem('server')

  const marketS1 = useHolyMarketContract()
  const marketS2 = useHolyMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const holyContractS1 = useHolyPackageContract()
  const holyContractS2 = useHolyPackageContractS2()
  const holyContract = server ? (server === 's1' ? holyContractS1 : holyContractS2) : holyContractS1

  const nftAddress = server
    ? server === 's1'
      ? PE_HOLY_PACKAGE_ADDRESS[56]
      : S2_HOLY_PACKAGE_ADDRESS[56]
    : PE_HOLY_PACKAGE_ADDRESS[56]

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const HOLY_PACKAGE_ADDRESS = server
    ? server === 's1'
      ? PE_HOLY_PACKAGE_ADDRESS
      : S2_HOLY_PACKAGE_ADDRESS
    : PE_HOLY_PACKAGE_ADDRESS

  const HOLY_MARKET_ADDRESS = server
    ? server === 's1'
      ? PE_HOLY_MARKET_ADDRESS
      : S2_HOLY_MARKET_ADDRESS
    : PE_HOLY_MARKET_ADDRESS

  const holyCTF = new web3.eth.Contract(
    PE_HOLY_PACKAGE_ABI as AbiItem[],
    chainId ? HOLY_PACKAGE_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )
  const oldMarketCTF = new web3.eth.Contract(
    PE_HOLY_MARKET_OLD_ABI as AbiItem[],
    chainId ? PE_HOLY_MARKET_OLD_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )
  const marketCTF = new web3.eth.Contract(
    PE_HOLY_MARKET_ABI as AbiItem[],
    chainId ? HOLY_MARKET_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )

  useEffect(() => {
    if (account === null) return
    getHolyIngame(account)
    fetchAllNfts(account)
    fetchMintTicketData(account)
    // checkCanReceiveGift(account)
    // getRequiredTime()
  }, [account])

  const [myNftsData, setMyNftsData] = useState<any[]>([])
  const [holyIngame, setHolyIngame] = useState<any[]>([])
  const [amountHPGreen, setAmountHPGreen] = useState<any>(1)
  const [amountHPRed, setAmountHPRed] = useState<any>(1)
  const [amountHPBlue, setAmountHPBlue] = useState<any>(1)
  const [amountHPYellow, setAmountHPYellow] = useState<any>(1)
  const [requiredTime, setRequiredTime] = useState<any>(1)
  const [mintTicket, setMintTicket] = useState<any>(0)
  const [showHolyIngame, setShowHolyIngame] = useState<boolean>(true)
  const [canReceiveGift, setCanReceiveGift] = useState<boolean>(false)
  const [mintableAt, setMintAbleAt] = useState<any>(undefined)

  const checkCanReceiveGift = async (owner: any) => {
    const interval = setInterval(async () => {
      if (!owner) return
      const url = `${HOLY_ENDPOINT.ALLOW_GIFT}/${owner}`
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          setCanReceiveGift(response.data.data.result)
          clearInterval(interval)
          return
        } else {
          setCanReceiveGift(false)
          clearInterval(interval)
          return
        }
      } catch (error: any) {
        console.error('checkCanReceiveGift', error)
        setCanReceiveGift(false)
        clearInterval(interval)
        return
      }
    }, 500)
  }

  const fetchMintTicketData = async (account: any) => {
    const url = `${MINT_TICKET.MINT_TICKET}/${account}`
    try {
      const response = await axios.get(url)
      const tickets = response.data.data.ticket
      setMintTicket(tickets)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAllNfts = async (owner: any) => {
    const nfts = await getNftsOfOwner(owner)
    const nfts_updated_price = await updatePrice(nfts)
    setMyNftsData(nfts_updated_price)
  }

  const getRequiredTime = async () => {
    const required_time = await holyCTF.methods.getRequiredTime().call()
    setRequiredTime(required_time)
  }

  const updatePrice = async (sortedMyNftsData: any) => {
    const array = []
    for (const nft of sortedMyNftsData) {
      const price = await getPriceInMarket(nft)
      const nftRef = {
        ...nft,
        price: price,
      }
      array.push(nftRef)
    }
    return array
  }

  const getPriceInMarket = async (nft: any) => {
    const useCTF = nft.isOnOldMarket ? oldMarketCTF : marketCTF
    if (!useCTF || nft.status !== 'Selling') return undefined
    const saleInfo = await useCTF.methods.getSale(nftAddress, Number(nft.tokenId)).call()
    if (!saleInfo) return 0
    return Number(saleInfo.price / 10 ** 18).toFixed(2)
  }

  const getHolyIngame = async (owner: any) => {
    const url = HOLY_ENDPOINT.INGAME + owner
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        setHolyIngame(response.data.data.holies)
        const mintableAt = response.data.data.info.mintableAt
        const now = Date.now()
        if (now - mintableAt * 1000 < 0) {
          setMintAbleAt(response.data.data.info.mintableAt)
        } else {
          setMintAbleAt(undefined)
        }
        return response.data.data.holies
      }
    } catch (error: any) {
      console.error('getHolyIngame', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const getNftsOfOwner = async (owner: any) => {
    const url = `${HOLY_ENDPOINT.NFTS}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const [filter, setFilter] = useState<PEBoxFilterConditions>({
    rarityNames: [],
  })
  const filteredOrders = useMemo(() => {
    if (filter.rarityNames.length === 0) {
      return myNftsData
    }
    return myNftsData.filter((order) => {
      return filter.rarityNames.length > 0 ? includes(filter.rarityNames, order.holyType.toString()) : true
    })
  }, [myNftsData, filter])

  useEffect(() => {
    ;(async () => {
      setNfts(filteredOrders)
    })()
  }, [filteredOrders])

  const renderHolyState = (nft: any) => {
    const { status, price } = nft
    switch (status) {
      case 'Available':
        return (
          <Row style={{ justifyContent: 'center' }}>
            <ButtonSell
              src={buttonSell}
              onClick={() => {
                // NotificationManager.info('Coming soon.', 'Feature', 2000)
                setSellState({
                  sellErrorMessage: undefined,
                  showSell: true,
                  nftSellSelected: nft,
                })
              }}
            />
            {/* <ButtonSell
              src={buttonGift}
              onClick={() => {
                // NotificationManager.info('Coming soon.', 'Feature', 2000)
                setGiftState({
                  giftErrorMessage: undefined,
                  showGift: true,
                  nftGiftSelected: nft,
                })
              }}
            /> */}
          </Row>
        )
      case 'Selling':
        return (
          <>
            <SellingStatus>
              <Text fontSize={20} fontWeight={700} color={'#000'}>
                {price && <span>{price} BUSD</span>}
              </Text>
            </SellingStatus>
            <RowTwo style={{ marginTop: 10 }}>
              <ButtonSell
                src={buttonUpdate}
                onClick={() => {
                  if (nft.isOnOldMarket) {
                    return
                  }
                  setUpdatePriceState({
                    showUpdatePrice: true,
                    nftUpdateSelected: nft,
                  })
                }}
                className={nft.isOnOldMarket ? 'disable' : ''}
                style={{ width: '100%' }}
              />
              {orderCanceling.tokenId === nft.tokenId && orderCanceling.isCanceling ? (
                <ButtonSell src={buttonCancelling} style={{ width: '100%' }} />
              ) : (
                <ButtonSell src={buttonCancel} style={{ width: '100%' }} onClick={() => onCancelOrderClick(nft)} />
              )}
            </RowTwo>
          </>
        )
      default:
        return null
    }
  }

  const handleSellDismiss = useCallback(() => {
    setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
  }, [buyErrorMessage])

  const handleUpdatePriceDismiss = useCallback(() => {
    setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
  }, [])

  const getQuantityHolyToMint = (holy: any) => {
    switch (holy.type) {
      case 'green':
        return amountHPGreen * 5
      case 'red':
        return amountHPRed * 5
      case 'blue':
        return amountHPBlue * 5
      case 'yellow':
        return amountHPYellow * 5
      default:
        return 0
    }
  }

  async function mintHolyPackage() {
    console.log('on mint holy package')
    if (!holyContract || !holyWillMint || !account) return
    const quantity = getQuantityHolyToMint(holyWillMint)
    const holyType = holyWillMint.type
    const data = {
      address: account,
      type: holyType,
      amount: quantity,
    }
    axios({
      method: 'post',
      url: HOLY_ENDPOINT.MINT,
      headers: {},
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          const signed = response.data.data
          const method: (...args: any) => Promise<TransactionResponse> = holyContract.mint
          const args: Array<string | string[] | number> = [quantity, holyType, signed.nonce, signed.signature]
          setAttemptingTxn(true)
          return method(...args, {})
            .then(async (response) => {
              const txFull = await response.wait()
              console.log('txHash', txFull.transactionHash)
              if (txFull.status === 0) {
                NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
                setAttemptingTxn(false)
                return
              }
              await getHolyIngame(account)
              await fetchAllNfts(account)
              await fetchMintTicketData(account)
              setTimeout(() => {
                NotificationManager.success('Mint Holy Pack success. Please check in NFTs tab', 'Success', 2000)
                setAttemptingTxn(false)
                setOpenConfirmMint(false)
              }, 9000)
            })
            .catch((error) => {
              setAttemptingTxn(false)
              if (error?.data?.message === 'execution reverted: Must wait') {
                return NotificationManager.error(
                  `Only mint one time each ${requiredTime / 60} minutes. Please try again later.`,
                  'Error',
                  5000
                )
              }
              if (error?.data?.message === 'execution reverted: Request expired') {
                return NotificationManager.error('Mint request expired. Please try again', 'Error', 5000)
              }
              NotificationManager.error('Can not get data. Please try again', 'Error', 2000)
              console.error(error)
            })
        }
        NotificationManager.error('Can not get signature.', 'Error', 2000)
        return
      })
      .catch((error) => {
        console.error(error)
        NotificationManager.error('Can not get signature.', 'Error', 2000)
      })
  }

  async function onSellConfirm(price: string) {
    console.log('on sell item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftSellSelected) return
    const method: (...args: any) => Promise<TransactionResponse> = market.placeOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSellSelected.tokenId, decimal_price]

    setAttemptingTxn(true)
    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        console.log('txHash', txFull.transactionHash)
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        await fetchAllNfts(account)
        setTimeout(() => {
          NotificationManager.success('Sell holy success.', 'Success', 2000)
          setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
          setAttemptingTxn(false)
        }, 9000)
      })
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
      })
  }

  async function onUpdatePriceConfirm(price: string) {
    console.log('on update price item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftUpdateSelected) return
    const estimate = market.estimateGas.updatePrice
    const method: (...args: any) => Promise<TransactionResponse> = market.updatePrice
    const args: Array<string | string[] | number> = [nftAddress, nftUpdateSelected.tokenId, decimal_price]
    setAttemptingTxn(true)
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            await fetchAllNfts(account)
            setTimeout(() => {
              NotificationManager.success('Update price success.', 'Success', 2000)
              setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
              setAttemptingTxn(false)
            }, 9000)
          })
          .catch((error) => {
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onCancelOrderClick(nftSelected: Nft) {
    console.log('on cancel item')
    const useContract = nftSelected.isOnOldMarket ? marketOld : market
    if (!useContract || !nftSelected) return
    const estimate = useContract.estimateGas.cancelOrder
    const method: (...args: any) => Promise<TransactionResponse> = useContract.cancelOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSelected.tokenId]

    setOrderCanceling({
      tokenId: nftSelected.tokenId,
      isCanceling: true,
    })
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            await fetchAllNfts(account)
            setTimeout(() => {
              NotificationManager.success('Cancel order success.', 'Success', 2000)
              setOrderCanceling({
                tokenId: nftSelected.tokenId,
                isCanceling: false,
              })
            }, 9000)
          })
          .catch((error) => {
            setOrderCanceling({
              tokenId: nftSelected.tokenId,
              isCanceling: false,
            })
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error) => {
        setOrderCanceling({
          tokenId: nftSelected.tokenId,
          isCanceling: false,
        })
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onGiftConfirm(nft: Nft, receiver: string) {
    if (!holyContract || !nft || !account) return
    setAttemptingTxn(true)

    const method: (...args: any) => Promise<TransactionResponse> = holyContract.transferFrom
    const args: Array<string | string[] | number> = [account, receiver, nft.tokenId]

    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        await fetchAllNfts(account)
        setTimeout(() => {
          setAttemptingTxn(false)
          setGiftState({
            nftGiftSelected: undefined,
            showGift: false,
            giftErrorMessage,
          })
          NotificationManager.success('Gift Holy Pack completed.', 'Success', 2000)
        }, 2000)
      })
      .catch((error) => {
        setAttemptingTxn(false)
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const receiveHolies = async () => {
    const url = `${HOLY_ENDPOINT.GIFT_HOLY}/${account}`
    try {
      const response = await axios({
        method: 'GET',
        url: url,
        validateStatus: (status: number) => {
          return status === 500 || status === 200
        },
      })
      if (response.status === 200) {
        const result = `You received ${response.data.data.result.amount} ${response.data.data.result.color} holies.`
        NotificationManager.success(result, 'Success', 3000)
        await checkCanReceiveGift(account)
        await getHolyIngame(account)
        setShowGiftBox({
          showGiftBox: false,
          giftBoxErrorMessage: undefined,
        })
        return
      } else {
        setShowGiftBox({
          showGiftBox: true,
          giftBoxErrorMessage: response.data.message,
        })
        return
      }
    } catch (error: any) {
      console.error('checkCanReceiveGift', error)
      setShowGiftBox({
        showGiftBox: true,
        giftBoxErrorMessage: error.data ? error.data.message : error.message,
      })
      return
    }
  }

  const isMobile = window.innerWidth <= 500

  const renderInputAmountChest = (holy: any) => {
    switch (holy.type) {
      case 'green':
        return (
          <CardDescription>
            <div style={{ display: 'flex', justifyContent: 'center', padding: isMobile ? '0 5px' : '0 20px' }}>
              <ChangeButton
                src={subIcon}
                onClick={() => Number(amountHPGreen) > 1 && setAmountHPGreen(Number(amountHPGreen) - 1)}
              />
              <InputAmount>
                <AmountInput type="number" value={amountHPGreen} disabled={true} />
              </InputAmount>
              <ChangeButton
                src={addIcon}
                onClick={() =>
                  Number(amountHPGreen) < Math.floor(holy.amount / 5) && setAmountHPGreen(Number(amountHPGreen) + 1)
                }
              />
            </div>
          </CardDescription>
        )
      case 'red':
        return (
          <CardDescription>
            <div style={{ display: 'flex', justifyContent: 'center', padding: isMobile ? '0 5px' : '0 20px' }}>
              <ChangeButton
                src={subIcon}
                onClick={() => Number(amountHPRed) > 1 && setAmountHPRed(Number(amountHPRed) - 1)}
              />
              <InputAmount>
                <AmountInput type="number" value={amountHPRed} disabled={true} />
              </InputAmount>
              <ChangeButton
                src={addIcon}
                onClick={() =>
                  Number(amountHPRed) < Math.floor(holy.amount / 5) && setAmountHPRed(Number(amountHPRed) + 1)
                }
              />
            </div>
          </CardDescription>
        )
      case 'blue':
        return (
          <CardDescription>
            <div style={{ display: 'flex', justifyContent: 'center', padding: isMobile ? '0 5px' : '0 20px' }}>
              <ChangeButton
                src={subIcon}
                onClick={() => Number(amountHPBlue) > 1 && setAmountHPBlue(Number(amountHPBlue) - 1)}
              />
              <InputAmount>
                <AmountInput type="number" value={amountHPBlue} disabled={true} />
              </InputAmount>
              <ChangeButton
                src={addIcon}
                onClick={() =>
                  Number(amountHPBlue) < Math.floor(holy.amount / 5) && setAmountHPBlue(Number(amountHPBlue) + 1)
                }
              />
            </div>
          </CardDescription>
        )
      case 'yellow':
        return (
          <CardDescription>
            <div style={{ display: 'flex', justifyContent: 'center', padding: isMobile ? '0 5px' : '0 20px' }}>
              <ChangeButton
                src={subIcon}
                onClick={() => Number(amountHPYellow) > 1 && setAmountHPYellow(Number(amountHPYellow) - 1)}
              />
              <InputAmount>
                <AmountInput type="number" value={amountHPYellow} disabled={true} />
              </InputAmount>
              <ChangeButton
                src={addIcon}
                onClick={() =>
                  Number(amountHPYellow) < Math.floor(holy.amount / 5) && setAmountHPYellow(Number(amountHPYellow) + 1)
                }
              />
            </div>
          </CardDescription>
        )
      default:
        return null
    }
  }

  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: boolean
  }) => {
    if (completed) {
      setMintAbleAt(undefined)
      return null
    } else {
      return (
        <CountdownContent>
          You can mint after {minutes}m {seconds}s
        </CountdownContent>
      )
    }
  }

  const HolyIngameList = ({ holies, title }: { holies: any[]; title: string }) => {
    if (holies.length === 0) {
      return <></>
    }

    return (
      <ListHeroContainer style={{ marginTop: 0 }}>
        <StatusTitle>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {title}
            <MintTicketCount>
              <span style={{ color: mintTicket === 0 ? 'red' : '#fff' }}>{mintTicket}</span>/2
              <span>
                <img style={{ width: 40 }} src={mintTicketImage} alt="" />
              </span>
            </MintTicketCount>
          </span>
          <img
            style={{ cursor: 'pointer' }}
            src={showHolyIngame ? minus : plus}
            onClick={() => setShowHolyIngame(!showHolyIngame)}
          />
        </StatusTitle>
        {showHolyIngame && (
          <NftGrid>
            {holies.map((holy: any, index) => {
              return (
                <HolyIngameCard key={index}>
                  <Preview nft={holy} />
                  <HolyPackTitle>
                    HOLY PACKS TO MINT
                    <span>
                      <InfoIcon src={button_info} />
                      <Tips className="tips">One pack equals five holies.</Tips>
                    </span>
                  </HolyPackTitle>
                  <InputBox>{renderInputAmountChest(holy)}</InputBox>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {holy.amount < 5 || openConfirmMint || mintTicket === 0 || (mintTicket > 0 && mintableAt) ? (
                      <MintHolyButton className="disable">MINT</MintHolyButton>
                    ) : (
                      <MintHolyButton
                        onClick={() => {
                          setHolyWillMint(holy)
                          setOpenConfirmMint(true)
                        }}
                      >
                        MINT
                      </MintHolyButton>
                    )}
                  </div>
                  {mintTicket === 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                      You have run out of turns to mint this week
                    </div>
                  )}
                  {mintTicket > 0 && mintableAt && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                      <Countdown date={mintableAt * 1000} renderer={renderCountdown} zeroPadTime={2} />
                    </div>
                  )}
                </HolyIngameCard>
              )
            })}
          </NftGrid>
        )}
      </ListHeroContainer>
    )
  }

  const NftList = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
    if (nfts.length === 0) {
      return <></>
    }

    const available = nfts.filter((n: any) => n.status === 'Available')
    const selling = nfts.filter((n: any) => n.status === 'Selling')

    const Render_container = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
      const [show_this_nfts, set_show_this_nfts] = useState<boolean>(true)
      const [currentPage, setCurrentPage] = useState<number>(1)
      const currentOrderList = useMemo(() => paginate(nfts, NUMBER_PER_PAGE, currentPage), [nfts, currentPage])
      return (
        <ListHeroContainer>
          <StatusTitle>
            {title}
            <img
              style={{ cursor: 'pointer' }}
              src={show_this_nfts ? minus : plus}
              onClick={() => set_show_this_nfts(!show_this_nfts)}
            />
          </StatusTitle>
          {show_this_nfts && (
            <>
              <NftGrid>
                {currentOrderList.map((holy: any, index) => {
                  return (
                    <HolyIngameCard key={index}>
                      <PreviewHolyPack nft={holy} />
                      <>{renderHolyState(holy)}</>
                    </HolyIngameCard>
                  )
                })}
              </NftGrid>
              <General>
                <TabWrapper>
                  {nfts.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      onChange={setCurrentPage}
                      totalPage={Math.ceil(nfts.length / NUMBER_PER_PAGE)}
                    />
                  )}
                </TabWrapper>
              </General>
            </>
          )}
        </ListHeroContainer>
      )
    }

    return (
      <>
        {available.length > 0 && <>{Render_container({ nfts: available, title: 'NFTs Available' })}</>}
        {selling.length > 0 && <>{Render_container({ nfts: selling, title: 'NFTs Selling' })}</>}
      </>
    )
  }

  const maintain = () => {
    NotificationManager.info('Please try again later.', 'MAINTENANCE', 2000)
  }

  const renderModals = () => {
    return (
      <>
        <FilterModal
          title="FILTER"
          visible={showFilterModal}
          onCancel={() => setShowFilterModal(false)}
          footer={null}
          className="select-server"
        >
          <div style={{ padding: '0 30px 30px', margin: 'auto', width: '100%' }}>
            <Filter
              id="filter-dialog"
              onConfirm={(newFilter: PEBoxFilterConditions) => {
                setFilter(newFilter)
                setShowFilterModal(false)
              }}
              onCancel={() => {
                setShowFilterModal(false)
              }}
            />
          </div>
        </FilterModal>
        <SellModal
          nft={nftSellSelected}
          isOpen={showSell}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onSellConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleSellDismiss}
        />
        <UpdatePriceModal
          nft={nftUpdateSelected}
          isOpen={showUpdatePrice}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={''}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onUpdatePriceConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleUpdatePriceDismiss}
        />
        <GiftModal
          onGift={onGiftConfirm}
          attemptingTxn={attemptingTxn}
          isOpen={showGift}
          onDismiss={() => setGiftState({ nftGiftSelected: undefined, showGift: false, giftErrorMessage })}
          nft={nftGiftSelected}
        />
        <GiftBox
          isOpen={showGiftBox}
          error={giftBoxErrorMessage}
          onDismiss={() => setShowGiftBox({ showGiftBox: false, giftBoxErrorMessage: undefined })}
          onConfirm={receiveHolies}
        />
      </>
    )
  }

  const modalConfirm = () => {
    return (
      <ROFIAccountModal
        title="ATTENTION !!!"
        visible={openConfirmMint}
        onCancel={() => setOpenConfirmMint(false)}
        footer={null}
      >
        <FlexLine style={{ marginTop: 20 }}>
          <RewardDescription>
            When you click on the CONFIRM button, your in-game holies and a mint ticket will be subtracted, even reject
            blockchain transaction on wallet.
          </RewardDescription>
        </FlexLine>
        <FlexLine style={{ marginTop: 10 }}>
          <RewardDescription>
            Make sure you want to mint this Holy pack and have enough BNB (~0.0015) for transaction fee.
          </RewardDescription>
        </FlexLine>
        <FlexLine style={{ marginTop: 10 }}>
          <RewardDescription>(Only support in-case have blockchain transaction)</RewardDescription>
        </FlexLine>
        <FlexLine style={{ marginTop: 20 }}>
          <RewardDescription>
            <span>
              <Checkbox type="checkbox" onChange={() => setCheckedConfirm(!checkedConfirm)} />
            </span>
            Read and got it.
          </RewardDescription>
        </FlexLine>
        {attemptingTxn ? (
          <FlexLine>
            <CheckinButton className="disable">CANCEL</CheckinButton>
            <CheckinButton className="disable">
              <Dots>LOADING</Dots>
            </CheckinButton>
          </FlexLine>
        ) : (
          <FlexLine>
            <CheckinButton style={{ background: '#f6c931' }} onClick={() => setOpenConfirmMint(false)}>
              CANCEL
            </CheckinButton>
            <CheckinButton
              className={checkedConfirm ? '' : 'disable'}
              onClick={() => {
                if (!checkedConfirm) return
                mintHolyPackage()
              }}
            >
              CONFIRM
            </CheckinButton>
          </FlexLine>
        )}
      </ROFIAccountModal>
    )
  }
  const modalMoreThanTicket = (amount: any) => {
    return (
      <ROFIAccountModal
        title="ATTENTION !!!"
        visible={openConfirmMint}
        onCancel={() => setOpenConfirmMint(false)}
        footer={null}
      >
        <FlexLine style={{ marginTop: 20 }}>
          <RewardDescription>You can mint only {mintTicket} turn on this week.</RewardDescription>
        </FlexLine>
        <FlexLine style={{ marginTop: 20 }}>
          <RewardDescription>
            Make sure you want to mint this Holy pack and have enough BNB for transaction fee.
          </RewardDescription>
        </FlexLine>
        <FlexLine>
          <CheckinButton onClick={() => setOpenConfirmMint(false)}>GOT IT</CheckinButton>
        </FlexLine>
      </ROFIAccountModal>
    )
  }

  return (
    <>
      <PageContainer className="main-content">
        {renderModals()}
        {nfts.length > 0 && (
          <StyledFilterButton onClick={() => setShowFilterModal(true)}>
            <Text fontWeight={700} fontSize={22}>
              FILTER
            </Text>
          </StyledFilterButton>
        )}
        <NFTArea>
          <HolyIngameList holies={holyIngame} title="Holies (in-game)" />
          <NftList nfts={nfts} title="Holy Pack NFTs" />
        </NFTArea>
      </PageContainer>
      {openConfirmMint && (
        <>
          {getQuantityHolyToMint(holyWillMint) / 5 <= mintTicket
            ? modalConfirm()
            : modalMoreThanTicket(getQuantityHolyToMint(holyWillMint))}
        </>
      )}
    </>
  )
}
