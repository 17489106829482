/* eslint-disable react/prop-types */
import { Modal } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import Piece from './Piece'
import Chest from './Chest'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'

const NUMBER_PER_PAGE = 12
const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`
const General = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

const RowTwo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
const NFTArea = styled.div`
  flex: 4;
  padding: 30px;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const HeaderItem = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin-top: 30px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
  `}
`
const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
`

const ButtonSell = styled.img`
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const ItemType = styled.div`
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  font-family: Gudea-Medium;
  border: 2px solid #03c5ff;
  padding: 7px 10px 3px;
  border-radius: 10px;
  min-width: 200px;
  color: #fff;
  background: #1b405c;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 0;
    font-size: 20px;
  `}
  &.active {
    border: 2px solid #9ce315;
    background: #9ce315;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background-color: transparent;
  width: 100%;
  max-width: 100vw;
  &.rewards {
    grid-template-columns: 25% 50% 25%;
  }
  &.main-content {
    ${({ theme }) => theme.mediaWidth.upToMedium`
      grid-template-columns: 100%;
    `}
  }
`

const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 30px 3px;
  max-width: 200px;
  margin: 30px auto 0;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 25%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
  `}
`

const ListHeroContainer = styled.div``

const ChestCardContainer = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const GachaBoxPreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const ActionButtons = styled.div`
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 16px;
  height: 35px;
  padding: 7px 15px 5px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
  }
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxName = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #f2c32a;
  font-family: Montio;
`

const BoxId = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

const FilterModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609299 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

export default function ChestPage() {
  const server = localStorage.getItem('server')

  const [pageState, setPageState] = useState<string>('chest')
  const renderContent = () => {
    if (pageState === 'chest') return <Chest />

    if (pageState === 'piece') return <Piece />
    return <Chest />
  }

  // if (server === 's1') return <>{renderContent()}</>

  return (
    <>
      <HeaderItem>
        <ItemType className={pageState === 'chest' ? 'active' : ''} onClick={() => setPageState('chest')}>
          CHEST
        </ItemType>
        <ItemType className={pageState === 'piece' ? 'active' : ''} onClick={() => setPageState('piece')}>
          Piece
        </ItemType>
      </HeaderItem>
      {renderContent()}
    </>
  )
}
