import React, { useEffect, useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks/web3'
import { Modal } from 'antd'
import { INGAME_ACCOUNT } from 'constants/mkpconfigs'
import Web3 from 'web3'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { HERO_MARKET_SERVER } from 'constants/mkpconfigs'
import axios from 'axios'
import JSBI from 'jsbi'
import { Token, CurrencyAmount } from '@uniswap/sdk-core'
import { parseUnits } from '@ethersproject/units'
import { useLuckySpinContract, useLuckySpinContractS2 } from 'hooks/useContract'
import { ApprovalState, useApproveCallback as UseApproveCallback } from 'hooks/useApproveCallback'
import { PE_LUCKY_SPIN_ADDRESS, S2_LUCKY_SPIN_ADDRESS, PEFI_TOKEN_ADDRESS } from 'constants/addresses'
import { useCurrency as UseCurrency } from 'hooks/Tokens'
import './style.scss'
import Lottery from './Lottery'
import 'react-lottery/dist/index.css'
import _, { assign } from 'lodash'
import Text from 'components/Text'
import { shortenAddress } from 'utils/index'
import NFTTag from 'assets/pefi/special-pack/bab/nft.png'

const PageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 61px);
  width: 80vw;
  margin: 0 auto 50px;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 100vw;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 1fr;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100vw;
    padding: 20px;
  `};
`

const Container = styled.div`
  margin: 50px auto 100px;
`

const ROFIAccountModal = styled(Modal)`
  top: 30vh !important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 30vh !important;
  `};
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RewardDetail = styled.div`
  width: fit-content;
  position: relative;
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 0 20px;
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 23px;
  `};
`

const CheckinButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  &:hover,
  &.disable {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const PageTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
  `};
`
const PageSubTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const RuleEvent = styled.div`
  margin: auto;
  background-color: #07122050;
  border: 1px solid #ffffff31;
  border-radius: 10px;
`

const RuleHeader = styled.div`
  background-color: #026092;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px 10px 0 0;
`

const OneTitle = styled.div`
  font-weight: bold;
  font-size: 23px;
  background-color: #071220;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: #026092;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
  `};
`

const RuleContent = styled.div`
  padding: 30px;
  text-align: left;
  height: 60vh;
  width: 60vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c2c8d5;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 25px;
    width: 50vh;
    div {
      font-size: 14px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
    width: 80vw;
    div {
      font-size: 12px !important;
    }
  `};
`

const NftTag = styled.img`
  height: 40px;
`

const checkAccountIngame = async (account) => {
  const url = `${INGAME_ACCOUNT.HAS_ACCOUNT}/${account}`
  try {
    const response = await axios.get(url)
    if (response.status === 200) {
      const data = response.data.response
      return data
    }
  } catch (error) {
    console.error('checkAccountIngame', error)
  }
}

export default function LuckySpin() {
  const server = localStorage.getItem('server')
  const { chainId, account } = useActiveWeb3React()
  const [contentState, setContentState] = useState('result')
  const [spinResult, setSpinResult] = useState([])
  const [showNotAccount, setShowNotAccount] = useState(false)
  const [lottery, setLottery] = useState({
    index: -1,
    count: 0,
    timer: 0,
    speed: 20,
    times: 0,
    cycle: 50,
    prize: -1,
  })

  const [result, setResult] = useState(undefined)

  const luckySpinContractS1 = useLuckySpinContract()
  const luckySpinContractS2 = useLuckySpinContractS2()

  const luckySpinContract = server ? (server === 's1' ? luckySpinContractS1 : luckySpinContractS2) : luckySpinContractS1

  const PEFI_ADDRESS = useMemo(() => {
    if (chainId) {
      return PEFI_TOKEN_ADDRESS[chainId]
    } else {
      return undefined
    }
  }, [chainId])

  const PEFICurrency = UseCurrency(PEFI_ADDRESS)
  const price = '10000000000000000000000'
  const typedValueParsed = parseUnits(price, PEFICurrency?.decimals).toString()
  let amount
  if (PEFICurrency instanceof Token) {
    amount = CurrencyAmount.fromRawAmount(PEFICurrency, JSBI.BigInt(typedValueParsed))
  }

  const [approvalPEFI, approvePEFICallback] = UseApproveCallback(amount, luckySpinContract?.address)

  const web3 = new Web3(
    chainId === 56 ? RPC_ENDPOINT_MAINNET : chainId === 97 ? RPC_ENDPOINT_TESTNET : 'https://bsc-dataseed1.ninicoin.io/' //others rpc mainnet
  )

  useEffect(() => {
    fetchSpinResult()
    setInterval(() => {
      fetchSpinResult()
    }, 30000)
  }, [account])

  const fetchSpinResult = async () => {
    const url = `${HERO_MARKET_SERVER}/plant-empire/spins`
    const response = await axios.get(url)
    if (response.status === 200) {
      setSpinResult(response.data.data)
    }
  }

  const lotteryRoll = () => {
    var index = lottery.index
    var count = lottery.count
    index += 1
    if (index > count - 1) {
      index = 0
    }
    setLottery({
      ...lottery,
      index,
    })
    console.log(index)
    return false
  }

  const roll = () => {
    var times = lottery.times
    times += 1
    lotteryRoll()
    setLottery({
      ...lottery,
      times,
    })
  }

  const isTable = window.innerWidth <= 1024

  const renderBesideContent = () => {
    return (
      <RuleEvent>
        <RuleHeader>
          <OneTitle
            className={contentState === 'result' && 'active'}
            onClick={() => setContentState('result')}
            style={{
              borderRadius: '10px 0 0 0',
            }}
          >
            SPIN HISTORY
          </OneTitle>
          <OneTitle
            className={contentState === 'rules' && 'active'}
            onClick={() => setContentState('rules')}
            style={{
              borderRadius: '0 10px 0 0',
            }}
          >
            RULES
          </OneTitle>
        </RuleHeader>
        <>
          {contentState === 'result' ? (
            <RuleContent>
              {spinResult.map((r, index) => (
                <Text key={index} style={{ lineHeight: '40px' }}>
                  <span style={{ color: '#49c3fd' }}>{shortenAddress(r.walletAddress)} </span>
                  has recently received <span style={{ color: '#ffc400' }}>{r.prize} </span>
                  <span>{renderNumberOfRewards(r.prize)}</span>
                  {isNFTReward(r.prize) && <NftTag src={NFTTag} alt="" />}
                </Text>
              ))}
            </RuleContent>
          ) : (
            <RuleContent>
              <Text>This event will last 16 days from 3AM UTC, December 16th to 10AM UTC December 31st.</Text>
              <Text>🔸5 first Spin will cost 200 $PEFI, from the sixth turn each will cost 150 $PEFI</Text>
              <Text>🔸Players will have unlimited spins throughout the event </Text>
              <Text>🔸Use the trial turn to experience first, but you won&apos;t receive any rewards </Text>
              <Text>🔸Collect 10 NFT Pieces to exchange the rewards (NFT Chest) </Text>
              <Text>
                🔸Lucky Spin rewards include NFT Chests, NFT Chest&apos;s Pieces, $PEFI and In-game items ( Non-NFT
                Chests, Gem and Gold)
              </Text>
            </RuleContent>
          )}
        </>
      </RuleEvent>
    )
  }

  const renderNumberOfRewards = (name) => {
    switch (name) {
      case 'Gold':
        return 'x10,000'
      case 'Gem':
        return 'x100'
      case 'Arena Ticket':
        return 'x50'
      case 'PEFI':
        return 'x500'
      default:
        return 'x1'
    }
  }

  const isNFTReward = (name) => {
    const nfts = [
      'Gold Piece',
      'Platinum Piece',
      'Ruby Piece',
      'Herald Piece',
      'Diamond Piece',
      'Gold Chest',
      'Platinum Chest',
      'Ruby Chest',
      'Herald Chest',
      'Diamond Chest',
    ]
    if (nfts.includes(name)) return true
    return false
  }

  return (
    <>
      <PageContent>
        {!isTable && <>{renderBesideContent()}</>}
        <Container>
          <React.Fragment>
            <PageTitle>LUCKY SPIN FOR HOLIDAY SEASON</PageTitle>
            <PageSubTitle>Unlimited Spins - Valuable NFT and in-game rewards</PageSubTitle>
            <Lottery
              web3={web3}
              LUCKY_SPIN_ADDRESS={server == 's1' ? PE_LUCKY_SPIN_ADDRESS : S2_LUCKY_SPIN_ADDRESS}
              chainId={chainId}
              account={account}
              checkAccountIngame={checkAccountIngame}
              setShowNotAccount={setShowNotAccount}
              luckySpinContract={luckySpinContract}
              approvalPEFI={approvalPEFI}
              approvePEFICallback={approvePEFICallback}
              rowCount={6}
              setResult={setResult}
              itemStyle={(item, index, isActive) => {
                return {
                  background: item.color,
                }
              }}
              validate={(next) => {
                next(true)
              }}
            />
          </React.Fragment>
        </Container>
        {isTable && <>{renderBesideContent()}</>}
      </PageContent>
      {showNotAccount && (
        <ROFIAccountModal
          title="ATTENTION !!!"
          visible={showNotAccount}
          onCancel={() => setShowNotAccount(false)}
          footer={null}
        >
          <FlexLine>
            <RewardDetailTitle>You do not have game account.</RewardDetailTitle>
          </FlexLine>
          <FlexLine>
            <RewardDetail>Please login game, connect email with this wallet and try again.</RewardDetail>
          </FlexLine>
          <FlexLine>
            <RewardDetail>
              <span>Link download game: {}</span>
              <a
                href={'https://plantempires.page.link/BAk5'}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#9ce315' }}
              >
                Plant Empires
              </a>
            </RewardDetail>
          </FlexLine>
          <FlexLine>
            <CheckinButton onClick={() => setShowNotAccount(false)}>GOT IT</CheckinButton>
          </FlexLine>
        </ROFIAccountModal>
      )}
      {result && (
        <>
          {result === 'failed' ? (
            <ROFIAccountModal
              title="FAILED"
              visible={result !== undefined}
              onCancel={() => setResult(undefined)}
              footer={null}
            >
              <FlexLine>
                <RewardDetailTitle>Oops!</RewardDetailTitle>
              </FlexLine>
              <FlexLine>
                <RewardDetail>Your transaction failed on the blockchain</RewardDetail>
              </FlexLine>
              <FlexLine>
                <RewardDetail>Please try again!</RewardDetail>
              </FlexLine>
              <FlexLine>
                <CheckinButton
                  onClick={() => {
                    setResult(undefined)
                    location.reload()
                  }}
                >
                  GOT IT
                </CheckinButton>
              </FlexLine>
            </ROFIAccountModal>
          ) : (
            <ROFIAccountModal
              title="CONGRATULATION"
              visible={result !== undefined}
              onCancel={() => setResult(undefined)}
              footer={null}
            >
              <FlexLine>
                <RewardDetail>You received a reward</RewardDetail>
              </FlexLine>
              <FlexLine>
                <RewardDetailTitle>{result.name}</RewardDetailTitle>
              </FlexLine>
              <FlexLine>
                <RewardDetail>
                  <img style={{ width: 100 }} src={result.img} alt="" />
                </RewardDetail>
              </FlexLine>
              <FlexLine>
                <RewardDetail>{renderNumberOfRewards(result.name)}</RewardDetail>
              </FlexLine>
              <FlexLine>
                <CheckinButton onClick={() => setResult(undefined)}>GOT IT</CheckinButton>
              </FlexLine>
            </ROFIAccountModal>
          )}
        </>
      )}
    </>
  )
}
