import React from 'react'
import styled from 'styled-components'
import { PieceConfig } from '../../../pages/SpecialPack/GachaBox/piece-config'

interface PreviewProps {
  nft: any
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HolyId = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

const HolyPreview = styled.img`
  width: 100%;
`

const PiecePreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const HolyName = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #f2c32a;
  font-family: Montio;
  text-transform: uppercase;
`

const LockText = styled.div`
  position: absolute;
  bottom: 35px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  background: #00000080;
  padding: 5px 10px;
  color: red;
  font-weight: bold;
  border-radius: 10px;
`

export default function Preview({ nft }: PreviewProps) {
  const box = PieceConfig.find((b: any) => b.box_id === nft.chestType)
  if (!box) return null
  return (
    <Container>
      <HolyName>{box.name}</HolyName>
      <PiecePreview src={box.img} />
      {nft && nft.isOnOldMarket && (
        <LockText>
          <Content>ON OLD MARKET</Content>
        </LockText>
      )}
      <HolyId>#{nft.tokenId}</HolyId>
    </Container>
  )
}
