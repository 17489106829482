import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderGraphEntity, OrderState, EssenceOrderGraphEntity, ItemOrderGraphEntity } from 'state/types'
import { getGraphOrders, getGraphEssenceOrders, getGraphItemsOrders, OrderFilter } from './getLotteriesData'

const initialState: OrderState = {
  ordersData: [],
  essenceOrdersData: [],
  itemOrdersData: [],
}

export const fetchOrders = createAsyncThunk<OrderGraphEntity[], OrderFilter>(
  'lottery/fetchHerofiOrders',
  async ({ nftAddress, priceFrom, priceTo, chainId }: OrderFilter) => {
    const lotteries = await getGraphOrders({ nftAddress, priceFrom, priceTo, chainId })
    return lotteries
  }
)
export const fetchEssenceOrders = createAsyncThunk<EssenceOrderGraphEntity[], OrderFilter>(
  'lottery/fetchEssenceOrders',
  async ({ nftAddress, priceFrom, priceTo, chainId }: OrderFilter) => {
    const lotteries = await getGraphEssenceOrders({ nftAddress, priceFrom, priceTo, chainId })
    return lotteries
  }
)
export const fetchItemsOrders = createAsyncThunk<ItemOrderGraphEntity[], OrderFilter>(
  'lottery/fetchItemsOrders',
  async ({ nftAddress, priceFrom, priceTo, chainId }: OrderFilter) => {
    const lotteries = await getGraphItemsOrders({ nftAddress, priceFrom, priceTo, chainId })
    return lotteries
  }
)

export const LotterySlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    setLotteryPublicData: (state, action) => {
      state = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.fulfilled, (state, action: PayloadAction<OrderGraphEntity[]>) => {
        state.ordersData = action.payload
      })
      .addCase(fetchEssenceOrders.fulfilled, (state, action: PayloadAction<EssenceOrderGraphEntity[]>) => {
        state.essenceOrdersData = action.payload
      })
      .addCase(fetchItemsOrders.fulfilled, (state, action: PayloadAction<ItemOrderGraphEntity[]>) => {
        state.itemOrdersData = action.payload
      })
  },
})

// Actions
export const { setLotteryPublicData } = LotterySlice.actions

export default LotterySlice.reducer
