import React from 'react'
import { useWalletModalToggle } from 'state/application/hooks'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import { ButtonConfirm } from 'components/Button'
import Text, { MagraText } from 'components/Text'

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 14px;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
`

const UnlockButton = () => {
  const toggleWalletModal = useWalletModalToggle()

  return (
    <Button onClick={toggleWalletModal}>
      <MagraText fontSize={16} fontWeight={600}>
        CONNECT WALLET
      </MagraText>
    </Button>
  )
}

export default UnlockButton
