import { useActiveWeb3React } from './web3'
import { PEFI_TOKEN_ADDRESS } from '../constants/addresses'
import { useMemo } from 'react'

export default function UsePEFIAddress(): string | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (chainId) {
      return PEFI_TOKEN_ADDRESS[chainId]
    } else {
      return undefined
    }
  }, [chainId])
}
