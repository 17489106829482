import Gem1 from 'assets/pefi/shop/pack_gem1.png'
import Gem2 from 'assets/pefi/shop/pack_gem2.png'
import Gem3 from 'assets/pefi/shop/pack_gem3.png'
import Gem4 from 'assets/pefi/shop/pack_gem4.png'
import Gem5 from 'assets/pefi/shop/pack_gem5.png'
import Gem6 from 'assets/pefi/shop/pack_gem6.png'

export const GEM_PACK = [
  {
    pack_id: 1,
    pack_name: 'gem_1',
    number_of_gems: 0,
    rofi_price: 1200,
    gem_img: Gem1,
    discount: 50,
  },
  {
    pack_id: 2,
    pack_name: 'gem_2',
    number_of_gems: 0,
    rofi_price: 2500,
    gem_img: Gem2,
    discount: 50,
  },
  {
    pack_id: 3,
    pack_name: 'gem_3',
    number_of_gems: 0,
    rofi_price: 5000,
    gem_img: Gem3,
    discount: 50,
  },
  {
    pack_id: 4,
    pack_name: 'gem_4',
    number_of_gems: 0,
    rofi_price: 10000,
    gem_img: Gem4,
    discount: 50,
  },
  {
    pack_id: 5,
    pack_name: 'gem_5',
    number_of_gems: 0,
    rofi_price: 25000,
    gem_img: Gem5,
    discount: 50,
  },
  {
    pack_id: 6,
    pack_name: 'gem_6',
    number_of_gems: 0,
    rofi_price: 40000,
    gem_img: Gem6,
    discount: 50,
  },
]
