import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import previous from 'assets/pefi/my-assets/back-page.png'
import next from 'assets/pefi/my-assets/next-page.png'

export interface PaginationProps {
  onChange: (page: number) => void
  currentPage: number
  totalPage: number
}

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: calc(100% - 50px);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 10px auto 0;
  `}
`
const Previous = styled.img`
  height: 40px;
`
const Next = styled.img`
  height: 40px;
`

const Pagination = ({ onChange, currentPage, totalPage }: PaginationProps) => {
  return (
    <StyledPagination>
      <Previous src={previous} onClick={() => currentPage >= 2 && onChange(currentPage - 1)} />
      <Text fontWeight={700} fontSize={25} margin="5px 20px">
        {`Page ${currentPage}/${totalPage}`}
      </Text>
      <Next src={next} onClick={() => totalPage >= 2 && currentPage < totalPage && onChange(currentPage + 1)} />
    </StyledPagination>
  )
}

export default Pagination
