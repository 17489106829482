import { Trans } from '@lingui/macro'
import Preview from 'components/PreviewCard/OrbCardPreview/Preview'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Nft } from 'constants/types'
import { ButtonConfirm } from 'components/Button'
import Text, { MagraText } from 'components/Text'
import { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import Modal from './Modal'
import UpgradeStarModalContent from './EvolveModalContent'
import PE_ORB_EVOLVE_ABI from 'abis/pe-orb-evolve-abi.json'
import Web3 from 'web3'
import { RPC_ENDPOINT_MAINNET } from 'constants/endpoints'
import { AbiItem } from 'web3-utils'
import { PE_ORB_EVOLVE_ADDRESS, S2_ORB_EVOLVE_ADDRESS } from 'constants/addresses'

const Card = styled.div`
  background-color: transparent;
  // overflow: hidden;
  padding: 15px;
  flex: 1;
  .select-hero {
    background: linear-gradient(180deg, #4a3f4b 0%, #3f3640 100%);
    box-shadow: 0px 4px 7.212px rgb(0 0 0 / 44%);
    color: #fff8e8;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto -30px;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
  }
  .hero-type {
    font-size: 22px;
    color: #fff8e8;
    text-align: center;
    margin-bottom: 10px;
  }
  &.mobile {
    padding: 10px;
    .select-hero {
      margin-top: 15px;
      width: 100%;
    }
  }
`
const CardWithoutHero = styled.div`
  background-color: transparent;
  border: 2px solid #4f4450;
  display: flex;
  align-items: center;
  justify-content: center;
  .description {
    font-size: 20px;
    color: #fff8e8;
  }
  .select-hero {
    background: linear-gradient(180deg, #4a3f4b 0%, #3f3640 100%);
    box-shadow: 0px 4px 7.212px rgb(0 0 0 / 44%);
    color: #fff8e8;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
    margin: 30px auto;
  }
  &.mobile {
    padding: 10px;
    .select-hero {
      margin-bottom: -25px;
      width: 100%;
    }
  }
`

const PreviewContainer = styled.div`
  display: flex;
  position: relative;
`

const Divider = styled.div`
  height: 3px;
  width: 100%;
  background-color: #41393e;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const PropertyRow = styled(Row)`
  background-color: #121212;
  margin: 6px 2px;
  border-radius: 5px;
  width: 100%;
  padding: 0px 13px;
`
const Property = styled.div`
  display: flex;
  align-items: center;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
`
const CardBody = styled.div`
  overflow: hidden;
  margin-top: 20px;
`
const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #fff;
  &.mobile {
    padding: 10px;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  &.failed {
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px 30px;
    min-height: 25vh;
    font-size: 22px;
  }
`
const Container = styled.div`
  flex: 1;
  align-items: stretch;
  flex-direction: column;
  padding: 20px 0;
  &.failed {
    padding: 30px;
    min-height: 40vh;
    text-align: center;
    border-radius: 5px;
  }
`
const NftImage = styled.div`
  padding-top: 4px;
  flex: 2;
`
const Action = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  &.mobile {
    gap: 0;
    padding: 10px 50px;
  }
`
const Title = styled.div`
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
  &.mobile {
    padding: 0 10px;
    font-size: 25px;
  }
`
const PriceContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
`
const PriceContent = styled.div`
  background-color: #252024;
  padding: 15px;
  border: none;
  border-radius: 6px;
`
const PriceContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const PriceInput = styled.input`
  background-color: #CDC2B7;
  border-radius: 6px;
  width: 152px;
  color: #2b222b;
  appearance: none;
  font-family: Gudea-Medium;
  font-size: 20px;
  height: 40px;
  border: none;
  outline: none;
  padding: 5px 18px;

  ::-webkit-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-ms-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-moz-placeholder {
    font-family: Gudea-Medium;

    ::-moz-placeholder {
      font-family: Gudea-Medium;
    }
`

const UpgradeStarState = styled.div`
  padding: 25px;
  margin: 0 auto;
  color: #fff8e8;
  display: grid;
  gap: 50px;
  .block {
    border: 2px solid #4f4450;
    padding: 25px;
    text-align: center;
    border-radius: 5px;
    .label {
      font-size: 22px;
    }
    img {
      margin: 30px auto;
    }
    .content {
      font-size: 22px;
      .have-rofi.red {
        color: red;
      }
    }
  }
`

const Result = styled.div`
  color: #fff8e8;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  .label {
    margin-right: 10px;
  }
`

const Star = styled.img`
  width: 26px;
  height: 24px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
  &.mobile {
    width: 17px;
    height: 15px;
  }
`

const UpgradeStarState2 = styled.div`
  padding: 25px;
  margin: 0 auto;
  color: #fff8e8;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  .block {
    position: absolute;
    text-align: center;
    .label {
      font-size: 22px;
      font-weight: 700;
    }
    .content {
      margin-top: 20px;
      font-size: 18px;
      line-height: 36px;
      ul {
        list-style-type: circle;
        li {
          text-align: left;
        }
      }
    }
  }
`

function UpgradeStarSuccess({
  onDismiss,
  onRefreshData,
  nft,
}: {
  onDismiss: () => void
  onRefreshData: () => void
  nft: Nft | undefined
}): JSX.Element {
  const isMobile = window.innerWidth <= 500
  const isTablet = window.innerWidth > 500 && window.innerWidth <= 1024

  const renderHeroCard = (type: string, nft?: Nft) => {
    if (!nft) {
      return <></>
    }

    const newNft = {
      ...nft,
      star: nft.star + 1,
    }
    return (
      <Card className={`${isMobile ? ' mobile' : ''}`}>
        <PreviewContainer>
          <Preview nft={newNft} />
        </PreviewContainer>
      </Card>
    )
  }

  return (
    <Container>
      <Title className={`${isMobile ? ' mobile' : ''}`}>SUCCESSFULLY!</Title>
      <Main className={`${isMobile ? ' mobile' : ''}`}>
        <div></div>
        <div>{renderHeroCard('1', nft)}</div>
        <div></div>
      </Main>
      <Action className={`${isMobile ? ' mobile' : ''}`}>
        <div></div>
        <ButtonConfirm
          onClick={() => {
            onDismiss()
            onRefreshData()
          }}
          disabled={false}
          style={{ margin: '10px 0 0 0', height: '57px' }}
          width="100%"
        >
          <MagraText fontSize={isMobile ? 18 : 28} fontWeight={600}>
            GREAT
          </MagraText>
        </ButtonConfirm>
        <div></div>
      </Action>
    </Container>
  )
}

const UpgradeStarFailed = ({
  onDismiss,
  onRefreshData,
  nft,
}: {
  onDismiss: () => void
  onRefreshData: () => void
  nft: any
}) => {
  const isMobile = window.innerWidth <= 500
  const [randomRate, setRandomRate] = useState<any>(0)

  useEffect(() => {
    getNextSuccessPercent(nft.nftId)
  }, [nft])

  const web3 = new Web3(RPC_ENDPOINT_MAINNET)

  const server = localStorage.getItem('server')

  const EVOLVE_ADDRESS = server
    ? server === 's1'
      ? PE_ORB_EVOLVE_ADDRESS[56]
      : S2_ORB_EVOLVE_ADDRESS[56]
    : PE_ORB_EVOLVE_ADDRESS[56]

  const evolveCTF = new web3.eth.Contract(PE_ORB_EVOLVE_ABI as AbiItem[], EVOLVE_ADDRESS)

  const getNextSuccessPercent = async (heroId: any) => {
    if (!evolveCTF || !heroId) return undefined
    const next = await evolveCTF.methods.getNextSuccessPercent(heroId).call()
    setRandomRate(next)
    return
  }

  return (
    <Container className="failed">
      <Title className={`${isMobile ? ' mobile' : ''}`}>UPGRADE FAILED!</Title>
      <Main className={`${isMobile ? ' mobile' : ''} failed`}>
        <div style={{ color: '#fff' }}>Next random rate: {randomRate}%</div>
        <div style={{ color: '#fff' }}>Get orb back and try again.</div>
      </Main>
      <ButtonConfirm
        onClick={() => {
          onDismiss()
          onRefreshData()
        }}
        disabled={false}
        style={{ margin: '10px auto', height: '57px', width: '30%' }}
      >
        <MagraText fontSize={isMobile ? 18 : 28} fontWeight={600}>
          OKAY
        </MagraText>
      </ButtonConfirm>
    </Container>
  )
}

export default function UpgradeStarModal({
  onDismiss,
  upgradeStarErrorMessage,
  isOpen,
  upgradeResult,
  nft,
  onRefreshData,
}: {
  isOpen: boolean
  upgradeStarErrorMessage: ReactNode | undefined
  onDismiss: () => void
  onRefreshData: () => void
  upgradeResult: boolean
  nft: Nft | undefined
}) {
  const content = useCallback(() => {
    return upgradeResult ? (
      <UpgradeStarSuccess nft={nft} onDismiss={onDismiss} onRefreshData={onRefreshData} />
    ) : (
      <UpgradeStarFailed nft={nft} onDismiss={onDismiss} onRefreshData={onRefreshData} />
    )
  }, [upgradeStarErrorMessage])

  const confirmationContent = useCallback(
    () =>
      upgradeStarErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={upgradeStarErrorMessage} />
      ) : (
        <UpgradeStarModalContent onDismiss={onDismiss} content={content} />
      ),
    [onDismiss, content, upgradeStarErrorMessage]
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {confirmationContent()}
    </Modal>
  )
}
