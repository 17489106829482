import React, { useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import PEFI from './PEFI'

export default function MyCharacter({ location }: RouteComponentProps) {
  const [universe, setUniverse] = useState<string>('herofi')
  const server = localStorage.getItem('server')
  if (!server) return <Redirect to={{ ...location, pathname: '/' }} />
  return <PEFI currentUniverse={universe} setUniverse={setUniverse} />
}
