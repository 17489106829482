import styled from 'styled-components'

const NftGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 24% 24% 24% 24%;
  padding-bottom: 24px;
  padding-top: 24px;

  ${({ theme }) => theme.mediaWidth.upTo1440`
    grid-template-columns: 32% 32% 32%;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-template-columns: 31% 31% 31%;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 30px;
    grid-template-columns: 50% 50%;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 20px;
    grid-template-columns: 48% 48%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 30px 0px;
    grid-template-columns: 100%;
    gap: 20px;
  `}
`

export default NftGrid
