import React, { useEffect, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import styled from 'styled-components'
import { ButtonPrimary, ButtonSelect } from 'components/Button'
import Close from 'assets/herofi/close-icon.svg'
import Preview from './ResultPreview'
import newHeroBg from 'assets/herofi/breed-hero-bg.png'
import Text from 'components/Text'
import resultCardBg from 'assets/pefi/special-pack/result-card-bg.png'
import { getHeroRarity, getHeroClassName } from 'utils/getPlantHeroDetail'
interface ResultProps {
  heroDetails: any
  reload: () => void
}

const ResultStyle = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  display: flex;
  align-items: flext-start;
  justify-content: center;
  background: #323232d9;
  opacity: 1;
  z-index: 99;
  top: 61px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const Main = styled.div`
  display: block;
  text-align: center;
  border-radius: 5px;
  padding-top: 15vh;
`

const ItemPreview = styled.div`
  background: #02689b;
  border: 2px solid rgb(3, 197, 255);
  border-radius: 20px;
  position: relative;
  padding: 20px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const PropertyRow = styled(Row)`
  margin: 3px 0 5px;
  width: 100%;
  overflow: hidden;
  background: rgb(1, 82, 130);
  border-radius: 10px;
`

const Property = styled.div`
  display: flex;
  align-items: center;
`

const PropertyName = styled(Text)`
  font-weight: 600;
  font-size: 15px;
  padding-right: 5px;
`

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 16px;
  padding: 7px 10px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &.accept-all {
    background: #e3ce15;
    color: #000;
    margin-left: 20px;
  }
`

export default function Result({ onDismiss, items }: { onDismiss: () => void; items: any[] | [] }) {
  const [currentShownIndex, setCurrentShownIndex] = useState<any>(0)
  if (items[currentShownIndex] === null) return <></>
  return (
    <ResultStyle>
      <Main>
        <ItemPreview>
          <Preview nft={items[currentShownIndex]} />
          <PropertyRow>
            <Property>
              <PropertyName>Rarity:&nbsp;</PropertyName>
              <Text style={{ color: '#9ce315', fontSize: 16 }}>{getHeroRarity(items[currentShownIndex].rarity)}</Text>
            </Property>
            <div style={{ padding: '0 5px' }}>|</div>
            <Property>
              <PropertyName>Class:&nbsp;</PropertyName>
              <Text style={{ color: '#9ce315', fontSize: 16 }}>
                {getHeroClassName(items[currentShownIndex].classType)}
              </Text>
            </Property>
          </PropertyRow>
          <ButtonContainer className={items.length === 1 ? 'one' : 'two'}>
            <Button
              onClick={() => {
                if (currentShownIndex === items.length - 1) {
                  return onDismiss()
                }
                return setCurrentShownIndex(currentShownIndex + 1)
              }}
            >
              {currentShownIndex === items.length - 1 ? 'CLOSE' : 'NEXT'}
            </Button>
            {items.length > 1 && (
              <Button className="accept-all" onClick={onDismiss}>
                ACCEPT ALL ({items.length})
              </Button>
            )}
          </ButtonContainer>
        </ItemPreview>
      </Main>
    </ResultStyle>
  )
}
