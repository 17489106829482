import React, { useState } from 'react'
import GachaBox from './GachaBox'
import BABEvent from './BABEvent/countdown'
import LuckySpin from './LuckySpin'
import styled, { keyframes } from 'styled-components'
import Footer from 'components/Footer'
import { Redirect, RouteComponentProps } from 'react-router-dom'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const AppBody = styled.div`
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
  text-align: center;
  background: url(${filterBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #14324c;
  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   padding: 0 50px;
  // `};
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   padding: 0 20px 50px;
  // `};
`

export default function SpecialPack({ location }: RouteComponentProps) {
  const server = localStorage.getItem('server')
  if (!server) {
    localStorage.setItem('server', 's2')
    // return <Redirect to={{ ...location, pathname: '/specialpacks' }} />
  }

  const renderContent = () => {
    // if (server === 's1') return <GachaBox />
    return <LuckySpin />
  }

  return (
    <>
      <AppBody>
        {/* <HeaderPacks>
          <ButtonSelect
            isSelected={pageState === 'gachabox'}
            onClick={() => setPageState('gachabox')}
            style={button_styles}
          >
            <MagraText fontWeight={600} fontSize={isMobile ? 20 : 25}>
              GACHA BOX
            </MagraText>
          </ButtonSelect>
        </HeaderPacks> */}
        {renderContent()}
      </AppBody>
      <Footer />
    </>
  )
}
