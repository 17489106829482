import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0xd9d86e5d2cbb3637e078119745f737e21e4e8ab1')

export const MULTICALL2_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x5ba1e12693dc8f9c48aad8770482f4739beed696'),
  [SupportedChainId.BSC]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  [SupportedChainId.BSC_TESTNET]: '0x5D9f61773e37154a3216798a5D570aFC70158823',
  [SupportedChainId.ARBITRUM_ONE]: '0x021CeAC7e681dBCE9b5039d2535ED97590eB395c',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0x334f67349c1cB3A8fF1268c3eC43FF1D3De246C6',
}
const BSC_V2_FACTORY_ADDRESS = '0x0293e286319Ec83e517B4F22427aE4A4E260aA56'
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(BSC_V2_FACTORY_ADDRESS)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x910d0795dcDE4052BB0B6bC5fc61506D30432430')

// most current governance contract address should always be the 0 index
// only support governance on mainnet
export const GOVERNANCE_ADDRESSES: AddressMap[] = [
  {
    [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
  },
  {
    [SupportedChainId.MAINNET]: '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F',
  },
]
export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V3_FACTORY_ADDRESS, [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [SupportedChainId.ARBITRUM_ONE, SupportedChainId.ARBITRUM_RINKEBY]
)
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])
export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])

export const BUSD_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  [SupportedChainId.RINKEBY]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.BSC]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  [SupportedChainId.BSC_TESTNET]: '0x775F88B053a9299b11CFd2C5B0BeD7a8C36916aB',
}

// PLANT EMPIRES
export const ROFI_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  [SupportedChainId.RINKEBY]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.BSC]: '0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64',
  [SupportedChainId.BSC_TESTNET]: '0x775F88B053a9299b11CFd2C5B0BeD7a8C36916aB',
}

export const PEFI_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xa83Bfcf9E252Adf1F39937984A4E113Eda6E445b',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PLANT_HERO_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xa0a35d7e24a1Da4598Fc829fC645bc85C0deBE6C',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PLANT_HERO_CNFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x88899650572Ad811FD8b0339E159b79C1F99bb0c',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PLANT_HERO_CHEST_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x698CB65465b8958f6edD7ed4A30E00b9EDC7Cd22',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PLANT_HERO_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x740ce54101f4778A65D156C34f1a3538B2bA03C7',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_GEM_SHOP_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x41497D5D13307E9dFbD7Dd9987b98490f0a70fb9',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_CONVERT_GPEFI_OLD_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x2a9F2c6DE4Ea7dCC3Ec578c164cE573C9b2A554e',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}
export const PE_CONVERT_GPEFI_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x9f10A0a00389f5f3bbFa4150243C5E1d8Cc3c1Aa',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_ORB_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xB5A6b2e99EABF8baE6e157253EB9cb747Eab9786',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}
export const PE_MINT_ORB_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x24a047d5092e04015a8cAC6C37FcbB86978Fe46a',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_ORB_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x2B3C057b251c6C9B9366E8D00D7ffDBaDb25ebe2',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PLANT_EVOLVE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x45b2f936EeC8De09dB620b1Bb3bA510F6EE5e33a',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_HOLY_PACKAGE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xC92aa2205fe826A26D0F5Ac6dE09Ea3B6FafbB74',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_HOLY_MARKET_OLD_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x245CF61b0ba4D7111e628df6Ae96c7540664f659',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_HOLY_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x6867e04BB2DA09A806a29cC03CFBF69c93ECf0d7',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_ORB_EVOLVE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x4B025D5832e833ec8E40c82cBd995abca3C0C241',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_CHECKIN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xB386E0aa8D5e917764d385f3738d2a7Cfa991B11',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_CLAIM_MINT_TICKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x445B84255B5F59174F0089f970Ca88ab24F80A00',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PLANT_FUSION_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x4Ec9F794f882f85B466B9Ebc96ceFf544CA21fBC',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_ORB_FUSION_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x2731195a51C31bEd2B19F44e1721674F6D8c643e',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_LUCKY_SPIN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x815D2Ea8BaEcbf13A819f05f11536a0042D7998B',
  [SupportedChainId.BSC_TESTNET]: '0x9b53fe8543dba5506a31B641D40390512002F2F9',
}

export const PE_PIECE_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x5d94A55CF6AddA7F7EE1bBA497bbae41d7093014',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PIECE_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x88c62d2C21D1B9EfDe4E6EcECfab537bE3a070BB',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x2DD1D1577f96444563ddE3faF9AC0E16bE20C46b',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const PE_PIECE_COMBINE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x71362fFb98C8041CE2Aa893185891cA0ab7c19Bf',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

// PLANT EMPIRES S2
// =======>>>>

export const S2_PLANT_HERO_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xDD1037F5d3f78f78b76CE558f54756c982175bD4',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PLANT_HERO_CNFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xdd06Ec5513B2273C591813C169EbDb53C5F34950',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PLANT_HERO_CHEST_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x61BD76E839662d9b320b565c5Fe63cD63BecAB50',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PLANT_HERO_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xD7eD3EB06DA83B113f9acb7f901F2E082805b00f',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PLANT_EVOLVE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x81e0A04A18c374BC64DADAa907dC7F2dC5567E52',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_GEM_SHOP_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xF9862E0C505B9ae2b2676411520bce4c8D86b9B3',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_CONVERT_GPEFI_OLD_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xdAe68aD0Fa030367F85bD4940E6Caa759bDfE653',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_CONVERT_GPEFI_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x2b8cE99609008597fEA8Dd684493A86A919D10D6',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_ORB_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x8DDDa130d49AfD93311Ba0ebf50d37631E7d12D6',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}
export const S2_MINT_ORB_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x26726437ba2288e626B170e4b5564f162130956E',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_ORB_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x78DbF3104B3C2ea4c0585177B469Fe7069951734',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_ORB_EVOLVE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x2B897B193A90Cd9E22CDa52c4b5Fa5375196f41a',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_HOLY_PACKAGE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x21Ff9ae6917C6167e7A66e91d27Ab841A2291319',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_HOLY_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xD4CaC609d7B5b018417bF60600516c3396b64F18',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_CHECKIN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x07C4EC4a9b13E285463CEa9FD5152Ab39752D148',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_CLAIM_MINT_TICKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xfACEabD8B2B52a9560A6041B7e3691e7d33cF1e5',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PLANT_FUSION_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x202a4fB650a8f81BDe28Eb36448733FD9aC52cCB',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_ORB_FUSION_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xD9FD804c8c87968e708747EdA444e396ebd3249c',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_LUCKY_SPIN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xd35497B85b40FDd09C8C6f6cCd8c7dD3997f69aD',
  [SupportedChainId.BSC_TESTNET]: '0x9b53fe8543dba5506a31B641D40390512002F2F9',
}

export const S2_PIECE_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xc9E01D9e49656dF9CF0159bA0E7a546264095F5e',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PIECE_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0xB107D3CC1ea4b7240401E66Ad8aC2CDdF78194ac',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}

export const S2_PIECE_COMBINE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x937525d718f86525827D88EBf2729aF9F76dc7c3',
  [SupportedChainId.RINKEBY]: '0x7c8c0262B4475C3Bfc9111f6f1F5eEa89843C3Bf',
  [SupportedChainId.BSC]: '0x161B38C1af412Bc0737158b45f70eEa56d3E6A3e',
  [SupportedChainId.BSC_TESTNET]: '0xEFda01a38cB8D4BfBCA402c7100CAe75c1cDC92f',
}
