import React from 'react'
import Close from 'assets/pefi/special-pack/close-button.png'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { useActiveWeb3React } from 'hooks/web3'
import { useTokenBalance } from 'state/wallet/hooks'
import { PEFI_TOKEN_ADDRESS } from 'constants/tokens'
import styled from 'styled-components'
import Modal from './Modal'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #026092;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `}
`

const ModalTitle = styled.div`
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const ModalContent = styled.div`
  padding: 50px;
  text-align: center;
  font-size: 24px;
`

const BalanceText = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`

const RewardText = styled.div`
  font-size: 20px;
  &.disable {
    opacity: 0.3;
  }
`

const LeftSection = styled(AutoColumn)<{ inline?: boolean }>`
  flex: 1;
  padding-right: 20px;
`
const RightSection = styled(LeftSection)`
  padding: 20px;
  flex: 2;
  height: 100%;
  background-color: #01517a;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      height: auto;
  `}
`

const Container = styled(RowBetween)`
  position: relative;
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`

const CustomButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 50px auto 0;
  &:hover {
    background: #f6c931;
  }
`

export default function GiftModal({
  isOpen,
  error,
  onConfirm,
  onDismiss,
}: {
  isOpen: boolean
  error: string | undefined
  onConfirm: () => void
  onDismiss: () => void
}) {
  const { account, chainId } = useActiveWeb3React()
  const PEFIBalance = useTokenBalance(account ?? undefined, chainId ? PEFI_TOKEN_ADDRESS[chainId] : undefined)
  const enough100PEFI = Number(PEFIBalance?.toSignificant(6)) >= 100
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>
        <ModalTitle>CONGRATULATION!</ModalTitle>
        {error ? (
          <ModalContent>
            <div style={{ fontSize: 18, paddingTop: 20, color: 'red' }}>Error: {error}</div>
            <div style={{ fontSize: 18, marginTop: 10 }}>Please download game, connect your account and try again.</div>
            <div style={{ fontSize: 18, marginTop: 10 }}>
              <a
                style={{ color: '#fff' }}
                href="https://apps.apple.com/us/app/plant-empires-arena-game/id1631074766?peref=PEFI"
              >
                App Store
              </a>
            </div>
            <div style={{ fontSize: 18, marginTop: 10 }}>
              <a
                style={{ color: '#fff' }}
                href="https://play.google.com/store/apps/details?id=com.rofi.plant.empires&peref=PEFI"
              >
                Google Play
              </a>
            </div>
            <div style={{ fontSize: 18, marginTop: 10 }}>
              <a style={{ color: '#fff' }} href="https://pefi.sgp1.cdn.digitaloceanspaces.com/apk/PE1.0.22b60.apk">
                APK FILE
              </a>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CustomButton onClick={onDismiss}>GOT IT</CustomButton>
            </div>
          </ModalContent>
        ) : (
          <ModalContent>
            <BalanceText>
              Your PEFI balance: {PEFIBalance?.toSignificant(6, { groupSeparator: ',' }) || 0} PEFI
            </BalanceText>
            <div style={{ marginBottom: 10, fontSize: 30 }}>You will</div>
            <RewardText className={enough100PEFI ? '' : 'disable'}>
              + Receive 3 Holies with random color (have at least 100 $PEFI)
              {enough100PEFI && <span style={{ color: '#44d944', marginLeft: 10 }}>✔</span>}
            </RewardText>
            <RewardText className={enough100PEFI ? 'disable' : ''}>
              + Only receive 1 Holy with random color (have less than 100 $PEFI)
              {!enough100PEFI && <span style={{ color: '#44d944', marginLeft: 10 }}>✔</span>}
            </RewardText>
            <div style={{ fontSize: 18, marginTop: 10 }}>(The holies will send to mail-box in-game)</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CustomButton onClick={onConfirm}>RECEIVE</CustomButton>
            </div>
          </ModalContent>
        )}
        <CloseIcon src={Close} onClick={onDismiss} />
      </Wrapper>
    </Modal>
  )
}
