import React from 'react'
import styled from 'styled-components'
import HolyGreen from './700_1.png'
import HolyBlue from './700_2.png'
import HolyRed from './700_3.png'
import HolyYellow from './700_4.png'

interface PreviewProps {
  nft: any
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HolyId = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

const HolyPreview = styled.img`
  width: 100%;
`

const HolyName = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #f2c32a;
  font-family: Montio;
  text-transform: uppercase;
`

const selectHolyImage = (type: string) => {
  switch (type) {
    case 'green':
      return HolyGreen
    case 'red':
      return HolyRed
    case 'blue':
      return HolyBlue
    case 'yellow':
      return HolyYellow
    default:
      return undefined
  }
}

export default function Preview({ nft }: PreviewProps) {
  return (
    <Container>
      <HolyName>{nft.type} holy</HolyName>
      <HolyPreview src={selectHolyImage(nft.type)} />
      <HolyId>Balance: {nft.amount}</HolyId>
    </Container>
  )
}
