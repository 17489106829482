import React from 'react'
import styled from 'styled-components'
import { PieceConfig } from '../../../pages/SpecialPack/GachaBox/piece-config'

interface PreviewProps {
  nft: any
}

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`

const HolyId = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `}
`

const HolyPreview = styled.img`
  width: 100px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid #03c5ff;
  background: #013b59;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 60px;
    border-radius: 10px;
    padding: 5px;
  `}
`

export default function Preview({ nft }: PreviewProps) {
  const box = PieceConfig.find((b: any) => b.box_id === nft.chestType)
  if (!box) return null
  return (
    <Container>
      <HolyPreview src={box.img} />
      <HolyId>#{nft.tokenId}</HolyId>
    </Container>
  )
}
