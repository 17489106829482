import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import PEFIToken from 'assets/pefi/pefi.png'
import star from 'assets/pefi/special-pack/star.png'
import Preview from 'components/PreviewCard/OrbCardPreview/Preview'
import PreviewMobile from 'components/PreviewCard/OrbCardPreview/PreviewMobile'
import HolyPackPreview from 'components/PreviewCard/HolyMaterialPreview/Preview'
import {
  PE_HOLY_PACKAGE_ADDRESS,
  PE_ORB_EVOLVE_ADDRESS,
  PE_ORB_NFT_ADDRESS,
  S2_HOLY_PACKAGE_ADDRESS,
  S2_ORB_EVOLVE_ADDRESS,
  S2_ORB_NFT_ADDRESS,
} from 'constants/addresses'
import { PEFI_TOKEN_ADDRESS } from 'constants/tokens'
import { Nft } from 'constants/types'
import { useCurrency as UseCurrency } from 'hooks/Tokens'
import {
  ApprovalState,
  useApproveCallback as UseApproveCallback,
  useApproveERC721Callback,
} from 'hooks/useApproveCallback'
import { useOrbEvolveContract, useOrbEvolveContractS2 } from 'hooks/useContract'
import UsePEFIAddress from 'hooks/usePEFIAddress'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import times from 'lodash/times'
import { Dots } from 'pages/styleds'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import Text, { MagraText } from 'components/Text'
import { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { SwapCallbackError } from '../../styleds'
import Modal from './Modal'
import UpgradeStarModalContent from './EvolveModalContent'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'

const Card = styled.div`
  background-color: transparent;
  padding: 15px;
  flex: 1;
  border: 2px solid #4f4450;
  .select-hero {
    background: linear-gradient(180deg, #4a3f4b 0%, #3f3640 100%);
    box-shadow: 0px 4px 7.212px rgb(0 0 0 / 44%);
    color: #fff8e8;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto -30px;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
  }
  .hero-type {
    font-size: 22px;
    color: #fff8e8;
    text-align: center;
    margin-bottom: 10px;
  }
  &.mobile {
    padding: 10px;
    .select-hero {
      margin-top: 15px;
      width: 100%;
    }
  }
  &.main-hero {
    border: 2px solid #03c5ff;
    border-radius: 20px;
  }
`
const CardMaterial = styled.div`
  background-color: transparent;
  border: 2px solid #03c5ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  margin-top: 15px;
  &.mobile {
    padding: 10px;
    .select-hero {
      margin-bottom: -25px;
      width: 100%;
    }
  }
`

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MaterialSelectHeroColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0 30px;
`

const MaterialSelectHeroRow = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
`

const PreviewContainer = styled.div`
  display: flex;
  position: relative;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const PropertyRow = styled(Row)`
  background-color: #015282;
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  padding: 5px 13px;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const Property = styled.div`
  color: #fff8e8;
  display: flex;
  align-items: center;
`
const CardBody = styled.div`
  overflow: hidden;
`
const Main = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  border-radius: 5px;
  padding: 30px 30px 0;
  &.mobile {
    width: 100%;
    padding: 15px;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
`
const Container = styled.div`
  flex: 1;
  align-items: stretch;
  flex-direction: column;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  padding: 0 30px;
  &.mobile {
    padding: 0;
    margin: 0 0 10px;
  }
`
const Title = styled.p`
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
  margin: 0 0 20px;
  &.mobile {
    padding: 0 10px;
    font-size: 25px;
  }
`

const UpgradeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const UpgradeStarState = styled.div`
  margin: 0 auto;
  color: #fff8e8;
  .block {
    border: 2px solid #03c5ff;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    width: 100%;
    .label {
      font-size: 18px;
    }
    .content {
      font-size: 18px;
      .have-rofi.red {
        color: red;
      }
    }
  }
  &.mobile {
    padding: 0;
    .block {
      padding: 15px;
      .label {
        font-size: 18px;
      }
      img {
        margin: 10px auto;
      }
      .content {
        font-size: 18px;
        .have-rofi.red {
          color: red;
        }
      }
    }
  }
`

const Result = styled.div`
  color: #fff8e8;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  .label {
    margin-bottom: 10px;
  }
  &.mobile {
    font-size: 18px;
    margin: 10px 0;
  }
`

const Star = styled.img`
  width: 26px;
  height: 24px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
`

const Icon = styled.img`
  object-fit: contain;
  width: 90px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 84px;
    height: 84px;
  `}
  &.mobile {
    width: 50px;
    height: 50px;
  }
`

const TokenIcon = styled.img`
  width: 20px;
  margin-left: 5px;
  position: relative;
  top: 5px;
`

const TokenRequiredValue = styled.div``

const HolySelectButton = styled.div`
  background: #9ce315;
  padding: 7px 0 3px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  width: 100px;
  &.approve {
    background: #f6c931;
  }
  &.approving {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 60px;
    font-size: 12px;
  `}
`

const EvolveButton = styled.div`
  background: #9ce315;
  padding: 10px 20px 5px;
  border-radius: 5px;
  margin: 15px auto 0;
  text-align: center;
  cursor: pointer;
  width: 200px;
  font-size: 18px;
  color: #fff;
  &.approve {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    width: 150px;
  `}
`

const HolyIngameCard = styled.div`
  position: relative;
`

function UpgradeStarItem({
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  onDismiss,
  showSelectHero,
  onRefreshData,
  nft,
  materialHolySelected,
  upgradeSuccess,
  attemptingTxn,
  randomRate,
}: {
  onConfirm: () => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
  attemptingTxn: boolean
  onDismiss: () => void
  showSelectHero: (slot: string) => void
  onRefreshData: () => void
  nft: Nft
  materialHolySelected: any
  upgradeSuccess: boolean
  randomRate: any
}): JSX.Element {
  const { chainId, account } = useActiveWeb3React()

  const server = localStorage.getItem('server')

  const evolveContractS1 = useOrbEvolveContract()
  const evolveContractS2 = useOrbEvolveContractS2()
  const evolveContract = server ? (server === 's1' ? evolveContractS1 : evolveContractS2) : evolveContractS1

  const orbAddress = server
    ? server === 's1'
      ? PE_ORB_NFT_ADDRESS[56]
      : S2_ORB_NFT_ADDRESS[56]
    : PE_ORB_NFT_ADDRESS[56]

  const holyAddress = server
    ? server === 's1'
      ? PE_HOLY_PACKAGE_ADDRESS[56]
      : S2_HOLY_PACKAGE_ADDRESS[56]
    : PE_HOLY_PACKAGE_ADDRESS[56]

  const [approvalPlant, approvalPlantCallback] = useApproveERC721Callback(
    orbAddress,
    nft?.nftId ? nft.nftId : '0',
    evolveContract?.address
  )
  const [approvalMaterialHoly1, approvalMaterialHoly1Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['1']?.tokenId : null,
    evolveContract?.address
  )
  const [approvalMaterialHoly2, approvalMaterialHoly2Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['2']?.tokenId : null,
    evolveContract?.address
  )
  const [approvalMaterialHoly3, approvalMaterialHoly3Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['3']?.tokenId : null,
    evolveContract?.address
  )

  const isMobile = window.innerWidth <= 768

  const renderHolyCard = (nft: any) => {
    return (
      <HolyIngameCard>
        <HolyPackPreview nft={nft} />
      </HolyIngameCard>
    )
  }

  const match_className_holyType = (classType: any) => {
    switch (classType) {
      case 1:
        return 'blue'
      case 2:
        return 'red'
      case 3:
        return 'yellow'
      case 4:
        return 'green'
      default:
        return ''
    }
  }

  const renderSlotOfMaterialHero = (nft: Nft, materialHolySelected: any) => {
    if (!nft) return

    switch (nft.star) {
      case 3:
        return (
          <CardMaterial className={`${isMobile ? ' mobile' : ''}`}>
            <CardContainer>
              <div style={{ textAlign: 'center', position: 'absolute', top: 10 }}>
                HolyPack required:
                <span style={{ textTransform: 'capitalize', paddingLeft: 5 }}>
                  {match_className_holyType(nft.classType)}
                </span>
              </div>
              <div style={{ width: isMobile ? '100%' : 350 }}>
                <MaterialSelectHeroColumn>
                  {materialHolySelected && materialHolySelected['1'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['1'])}
                      <HolySelectButton onClick={() => showSelectHero('1')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly1 !== ApprovalState.APPROVED ? (
                        <HolySelectButton
                          onClick={approvalMaterialHoly1Callback}
                          className={approvalMaterialHoly1 === ApprovalState.PENDING ? 'approving' : 'approve'}
                        >
                          {approvalMaterialHoly1 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('1')}>Select HolyPack</HolySelectButton>
                  )}
                </MaterialSelectHeroColumn>
              </div>
              <div style={{ textAlign: 'center', marginTop: 30, position: 'absolute', bottom: 5 }}>
                All materials will be consumed
              </div>
            </CardContainer>
          </CardMaterial>
        )
      case 4:
        return (
          <CardMaterial className={`${isMobile ? ' mobile' : ''}`}>
            <CardContainer>
              <div style={{ textAlign: 'center', position: 'absolute', top: 10 }}>
                HolyPack required:
                <span style={{ textTransform: 'capitalize', paddingLeft: 5 }}>
                  {match_className_holyType(nft.classType)}
                </span>
              </div>
              <div style={{ width: isMobile ? '100%' : 350 }}>
                <MaterialSelectHeroColumn>
                  {materialHolySelected && materialHolySelected['1'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['1'])}
                      <HolySelectButton onClick={() => showSelectHero('1')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly1 !== ApprovalState.APPROVED ? (
                        <HolySelectButton
                          onClick={approvalMaterialHoly1Callback}
                          className={approvalMaterialHoly1 === ApprovalState.PENDING ? 'approving' : 'approve'}
                        >
                          {approvalMaterialHoly1 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('1')}>Select HolyPack</HolySelectButton>
                  )}
                  {materialHolySelected && materialHolySelected['2'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['2'])}
                      <HolySelectButton onClick={() => showSelectHero('2')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly2 !== ApprovalState.APPROVED ? (
                        <HolySelectButton
                          onClick={approvalMaterialHoly2Callback}
                          className={approvalMaterialHoly2 === ApprovalState.PENDING ? 'approving' : 'approve'}
                        >
                          {approvalMaterialHoly2 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('2')}>Select HolyPack</HolySelectButton>
                  )}
                </MaterialSelectHeroColumn>
              </div>
              <div style={{ textAlign: 'center', marginTop: 30, position: 'absolute', bottom: 5 }}>
                All materials will be consumed
              </div>
            </CardContainer>
          </CardMaterial>
        )
      case 5:
        return (
          <CardMaterial className={`${isMobile ? ' mobile' : ''}`}>
            <CardContainer>
              <div style={{ textAlign: 'center', position: 'absolute', top: 10 }}>
                HolyPack required:
                <span style={{ textTransform: 'capitalize', paddingLeft: 5 }}>
                  {match_className_holyType(nft.classType)}
                </span>
              </div>
              <div style={{ width: isMobile ? '100%' : 350 }}>
                <MaterialSelectHeroColumn>
                  {materialHolySelected && materialHolySelected['1'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['1'])}
                      <HolySelectButton onClick={() => showSelectHero('1')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly1 !== ApprovalState.APPROVED ? (
                        <HolySelectButton
                          onClick={approvalMaterialHoly1Callback}
                          className={approvalMaterialHoly1 === ApprovalState.PENDING ? 'approving' : 'approve'}
                        >
                          {approvalMaterialHoly1 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('1')}>Select HolyPack</HolySelectButton>
                  )}
                  {materialHolySelected && materialHolySelected['2'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['2'])}
                      <HolySelectButton onClick={() => showSelectHero('2')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly2 !== ApprovalState.APPROVED ? (
                        <HolySelectButton
                          onClick={approvalMaterialHoly2Callback}
                          className={approvalMaterialHoly2 === ApprovalState.PENDING ? 'approving' : 'approve'}
                        >
                          {approvalMaterialHoly2 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('2')}>Select HolyPack</HolySelectButton>
                  )}
                </MaterialSelectHeroColumn>
                <MaterialSelectHeroColumn>
                  {materialHolySelected && materialHolySelected['3'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['3'])}
                      <HolySelectButton onClick={() => showSelectHero('3')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly3 !== ApprovalState.APPROVED ? (
                        <HolySelectButton
                          onClick={approvalMaterialHoly3Callback}
                          className={approvalMaterialHoly3 === ApprovalState.PENDING ? 'approving' : 'approve'}
                        >
                          {approvalMaterialHoly3 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('3')}>Select HolyPack</HolySelectButton>
                  )}
                </MaterialSelectHeroColumn>
              </div>
            </CardContainer>
            <div style={{ textAlign: 'center', marginTop: 30, position: 'absolute', bottom: 5 }}>
              All materials will be consumed
            </div>
          </CardMaterial>
        )
      default:
        return (
          <CardMaterial className={`${isMobile ? ' mobile' : ''}`}>
            <CardContainer>
              <div style={{ textAlign: 'center', position: 'absolute', top: 10 }}>
                HolyPack required:
                <span style={{ textTransform: 'capitalize', paddingLeft: 5 }}>
                  {match_className_holyType(nft.classType)}
                </span>
              </div>
              <div style={{ width: '100%' }}>
                <MaterialSelectHeroRow>
                  {materialHolySelected && materialHolySelected['1'] ? (
                    <div>
                      {renderHolyCard(materialHolySelected['1'])}
                      <HolySelectButton onClick={() => showSelectHero('1')}>Change</HolySelectButton>
                      {!attemptingTxn && approvalMaterialHoly1 !== ApprovalState.APPROVED ? (
                        <HolySelectButton onClick={approvalMaterialHoly1Callback} className="approve">
                          {approvalMaterialHoly1 === ApprovalState.PENDING ? 'Approving' : 'Approve'}
                        </HolySelectButton>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <HolySelectButton onClick={() => showSelectHero('1')}>Select HolyPack</HolySelectButton>
                  )}
                </MaterialSelectHeroRow>
              </div>
              <div style={{ textAlign: 'center', marginTop: 30, position: 'absolute', bottom: 5 }}>
                All materials will be consumed
              </div>
            </CardContainer>
          </CardMaterial>
        )
    }
  }

  const renderHeroCard = (type: string, nft?: Nft, isMainHero?: boolean) => {
    if (!nft) return
    const rarityName = getHeroRarity(nft.rarity)
    const className = getHeroClassName(nft.classType)
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Card className={`${isMobile ? ' mobile' : ''} ${isMainHero ? 'main-hero' : ''}`}>
          <PreviewContainer>{isMobile ? <PreviewMobile nft={nft} /> : <Preview nft={nft} />}</PreviewContainer>
          {!isMobile && (
            <CardBody>
              <PropertyRow>
                <Property>
                  <PropertyName>Rarity:&nbsp;</PropertyName>
                  <Text style={{ color: '#9ce315', fontSize: 16 }}>{rarityName}</Text>
                </Property>
                <div style={{ padding: '0 5px' }}>|</div>
                <Property>
                  <PropertyName>Class:&nbsp;</PropertyName>
                  <Text style={{ color: '#9ce315', fontSize: 16 }}>{className}</Text>
                </Property>
              </PropertyRow>
            </CardBody>
          )}
        </Card>
      </div>
    )
  }

  const renderExpectCard = (type: string, nft?: Nft, isMainHero?: boolean) => {
    if (!nft) return
    const { star } = nft
    const newNft = {
      ...nft,
      star: star + 1,
    }
    const rarityName = getHeroRarity(nft.rarity)
    const className = getHeroClassName(nft.classType)
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Card className={`${isMobile ? ' mobile' : ''} ${isMainHero ? 'main-hero' : ''}`}>
          <PreviewContainer>{isMobile ? <PreviewMobile nft={newNft} /> : <Preview nft={newNft} />}</PreviewContainer>
          {!isMobile && (
            <CardBody>
              <PropertyRow>
                <Property>
                  <PropertyName>Rarity:&nbsp;</PropertyName>
                  <Text style={{ color: '#9ce315', fontSize: 16 }}>{rarityName}</Text>
                </Property>
                <div style={{ padding: '0 5px' }}>|</div>
                <Property>
                  <PropertyName>Class:&nbsp;</PropertyName>
                  <Text style={{ color: '#9ce315', fontSize: 16 }}>{className}</Text>
                </Property>
              </PropertyRow>
            </CardBody>
          )}
        </Card>
      </div>
    )
  }

  const PEFIBalanceRaw = useTokenBalance(account ?? undefined, chainId ? PEFI_TOKEN_ADDRESS[chainId] : undefined)
  const PEFIBalance = PEFIBalanceRaw?.toSignificant(6, { groupSeparator: ',' })
  const PEFIRequiredToUpgrade = (nft: Nft) => {
    const star = nft.star
    const rarityName = getHeroRarity(nft.rarity)
    if (star === 3) {
      if (rarityName === 'Rare') return 0
      if (rarityName === 'Mythical') return 500
      return 1500
    }
    if (star === 4) {
      if (rarityName === 'Rare') return 500
      if (rarityName === 'Mythical') return 1500
      return 3000
    }
    if (star === 5) {
      if (rarityName === 'Rare') return 1000
      if (rarityName === 'Mythical') return 3000
      return 6000
    }
    return 0
  }
  const PEFIRequired = PEFIRequiredToUpgrade(nft)
  const isEnoughPEFI = Number(PEFIBalanceRaw?.toSignificant(6)) >= PEFIRequired

  const PEFI_ADDRESS = UsePEFIAddress()
  const PEFiCurrency = UseCurrency(PEFI_ADDRESS)
  const PEFItypedValueParsed = parseUnits('1000000000000', PEFiCurrency?.decimals).toString()
  let PEFIamount
  if (PEFiCurrency instanceof Token) {
    PEFIamount = CurrencyAmount.fromRawAmount(PEFiCurrency, JSBI.BigInt(PEFItypedValueParsed))
  }

  const ORB_EVOLVE_ADDRESS = server
    ? server === 's1'
      ? PE_ORB_EVOLVE_ADDRESS
      : S2_ORB_EVOLVE_ADDRESS
    : PE_ORB_EVOLVE_ADDRESS

  const [approveEvolveUsePefi, approveEvolveUsePefiCallback] = UseApproveCallback(
    PEFIamount,
    ORB_EVOLVE_ADDRESS[chainId || 56]
  )

  const renderActionButton = (nft: Nft) => {
    if (!attemptingTxn && approvalPlant !== ApprovalState.APPROVED) {
      return (
        <EvolveButton
          onClick={approvalPlantCallback}
          className={approvalPlant === ApprovalState.PENDING ? 'disable' : ''}
        >
          {approvalPlant === ApprovalState.UNKNOWN ? (
            <Dots>WAITING</Dots>
          ) : approvalPlant === ApprovalState.PENDING ? (
            <Dots>APPROVING</Dots>
          ) : (
            <>APPROVE ORB</>
          )}
        </EvolveButton>
      )
    }
    switch (nft.star) {
      case 3:
        return (
          <>
            {approvalPlant === ApprovalState.APPROVED && approvalMaterialHoly1 === ApprovalState.APPROVED ? (
              <>
                {approveEvolveUsePefi !== ApprovalState.APPROVED ? (
                  <EvolveButton
                    onClick={approveEvolveUsePefiCallback}
                    className={
                      approveEvolveUsePefi === ApprovalState.UNKNOWN ||
                      approveEvolveUsePefi === ApprovalState.PENDING ||
                      approveEvolveUsePefi !== ApprovalState.NOT_APPROVED
                        ? 'disable'
                        : ''
                    }
                  >
                    {approveEvolveUsePefi === ApprovalState.UNKNOWN ? (
                      <Dots>WAITING</Dots>
                    ) : approveEvolveUsePefi === ApprovalState.PENDING ? (
                      <Dots>APPROVING</Dots>
                    ) : (
                      <>APPROVE PEFI</>
                    )}
                  </EvolveButton>
                ) : (
                  <EvolveButton onClick={onConfirm} className={attemptingTxn ? 'disable' : ''}>
                    {attemptingTxn ? <Dots>EVOLVING</Dots> : <>EVOLVE</>}
                  </EvolveButton>
                )}
              </>
            ) : (
              <EvolveButton className="disable">EVOLVE</EvolveButton>
            )}
          </>
        )
      case 4:
        return (
          <>
            {approvalPlant === ApprovalState.APPROVED &&
            approvalMaterialHoly1 === ApprovalState.APPROVED &&
            approvalMaterialHoly2 === ApprovalState.APPROVED ? (
              <>
                {approveEvolveUsePefi !== ApprovalState.APPROVED ? (
                  <EvolveButton
                    onClick={approveEvolveUsePefiCallback}
                    className={
                      approveEvolveUsePefi === ApprovalState.UNKNOWN ||
                      approveEvolveUsePefi === ApprovalState.PENDING ||
                      approveEvolveUsePefi !== ApprovalState.NOT_APPROVED
                        ? 'disable'
                        : ''
                    }
                  >
                    {approveEvolveUsePefi === ApprovalState.UNKNOWN ? (
                      <Dots>WAITING</Dots>
                    ) : approveEvolveUsePefi === ApprovalState.PENDING ? (
                      <Dots>APPROVING</Dots>
                    ) : (
                      <>APPROVE PEFI</>
                    )}
                  </EvolveButton>
                ) : (
                  <EvolveButton onClick={onConfirm} className={attemptingTxn ? 'disable' : ''}>
                    {attemptingTxn ? <Dots>EVOLVING</Dots> : <>EVOLVE</>}
                  </EvolveButton>
                )}
              </>
            ) : (
              <EvolveButton className="disable">EVOLVE</EvolveButton>
            )}
          </>
        )
      case 5:
        return (
          <>
            {approvalPlant === ApprovalState.APPROVED &&
            approvalMaterialHoly1 === ApprovalState.APPROVED &&
            approvalMaterialHoly2 === ApprovalState.APPROVED &&
            approvalMaterialHoly3 === ApprovalState.APPROVED ? (
              <>
                {approveEvolveUsePefi !== ApprovalState.APPROVED ? (
                  <EvolveButton
                    onClick={approveEvolveUsePefiCallback}
                    className={
                      approveEvolveUsePefi === ApprovalState.UNKNOWN ||
                      approveEvolveUsePefi === ApprovalState.PENDING ||
                      approveEvolveUsePefi !== ApprovalState.NOT_APPROVED
                        ? 'disable'
                        : ''
                    }
                  >
                    {approveEvolveUsePefi === ApprovalState.UNKNOWN ? (
                      <Dots>WAITING</Dots>
                    ) : approveEvolveUsePefi === ApprovalState.PENDING ? (
                      <Dots>APPROVING</Dots>
                    ) : (
                      <>APPROVE PEFI</>
                    )}
                  </EvolveButton>
                ) : (
                  <EvolveButton onClick={onConfirm} className={attemptingTxn ? 'disable' : ''}>
                    {attemptingTxn ? <Dots>EVOLVING</Dots> : <>EVOLVE</>}
                  </EvolveButton>
                )}
              </>
            ) : (
              <EvolveButton className="disable">EVOLVE</EvolveButton>
            )}
          </>
        )
      default:
        return <></>
    }
  }

  if (isMobile) {
    return (
      <Container className="mobile">
        <Main className="mobile">
          <div style={{ width: '100%' }}>{renderHeroCard('1', nft, true)}</div>
          <div style={{ width: '100%' }}>{renderExpectCard('1', nft, true)}</div>
        </Main>
        <div style={{ padding: '0 15px' }}>
          <UpgradeStarState>
            <div className="block time">
              <div className="label">Token required</div>
              <TokenRequiredValue className="content">
                <span>
                  <span className="have-rofi">{!PEFIBalance ? '...' : PEFIRequiredToUpgrade(nft)}</span>
                  <span>
                    <TokenIcon src={PEFIToken} alt="" />
                  </span>
                </span>
              </TokenRequiredValue>
            </div>
          </UpgradeStarState>
          <UpgradeStarState className="mobile">{renderSlotOfMaterialHero(nft, materialHolySelected)}</UpgradeStarState>
        </div>
        <div style={{ width: '100%', textAlign: 'center', paddingTop: '10px', fontSize: 14 }}>
          Blockchain actions might take a few seconds.
        </div>
        <Action className="mobile">
          <div style={{ marginBottom: 10 }}>{renderActionButton(nft)}</div>
        </Action>
      </Container>
    )
  }

  return (
    <Container>
      <Main>
        {renderHeroCard('1', nft, true)}
        <UpgradeInfo>
          <div>
            <UpgradeStarState>
              <div className="block time">
                <div className="label">Token required</div>
                <TokenRequiredValue className="content">
                  <span>
                    <span className="have-rofi">{!PEFIBalance ? '...' : PEFIRequiredToUpgrade(nft)}</span>
                    <span>
                      <TokenIcon src={PEFIToken} alt="" />
                    </span>
                  </span>
                </TokenRequiredValue>
              </div>
            </UpgradeStarState>
            <UpgradeStarState>{renderSlotOfMaterialHero(nft, materialHolySelected)}</UpgradeStarState>
          </div>
        </UpgradeInfo>
        {renderExpectCard('1', nft, true)}
      </Main>
      <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
        <div style={{ fontSize: 20, color: '#fff' }}>Success Rate: {randomRate}%</div>
      </div>
      {isEnoughPEFI && <Action>{renderActionButton(nft)}</Action>}
      {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      <div style={{ width: '100%', textAlign: 'center', padding: '20px 0 10px', color: '#fff' }}>
        Please wait! Blockchain actions might take a few seconds.
      </div>
    </Container>
  )
}

export default function UpgradeStarModal({
  onConfirm,
  onDismiss,
  showSelectHero,
  upgradeStarErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  nft,
  materialHolySelected,
  upgradeSuccess,
  onRefreshData,
  randomRate,
}: {
  isOpen: boolean
  originalTrade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent | undefined
  onAcceptChanges: () => void
  onConfirm: () => void
  upgradeStarErrorMessage: ReactNode | undefined
  onDismiss: () => void
  showSelectHero: (slot: string) => void
  onRefreshData: () => void
  nft: Nft | undefined
  materialHolySelected: any
  upgradeSuccess: boolean
  randomRate: any
}) {
  if (txHash) onDismiss()
  const content = useCallback(() => {
    return nft ? (
      <UpgradeStarItem
        onConfirm={onConfirm}
        disabledConfirm={false}
        swapErrorMessage={upgradeStarErrorMessage}
        nft={nft}
        materialHolySelected={materialHolySelected}
        onDismiss={onDismiss}
        showSelectHero={showSelectHero}
        upgradeSuccess={upgradeSuccess}
        attemptingTxn={attemptingTxn}
        onRefreshData={onRefreshData}
        randomRate={randomRate}
      />
    ) : null
  }, [onConfirm, upgradeStarErrorMessage])

  const confirmationContent = useCallback(
    () =>
      upgradeStarErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={upgradeStarErrorMessage} />
      ) : (
        <UpgradeStarModalContent onDismiss={onDismiss} content={content} />
      ),
    [onDismiss, content, upgradeStarErrorMessage]
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {confirmationContent()}
    </Modal>
  )
}
