import AppleLottery from 'react-lottery'
import { isFunction, assign } from 'lodash'
import styled from 'styled-components'
import UnlockButton from './UnlockButton'
import React from 'react'
import axios from 'axios'
import * as cx from 'classnames'
import BgSVG1 from './assets/bg-1.svg'
import { Dots } from 'components/market/styleds'
import { NotificationManager } from 'react-notifications'
import { LUCKY_SPIN } from 'constants/mkpconfigs'
import { BigNumber } from 'bignumber.js'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import Countdown from 'react-countdown'
import { ApprovalState, useApproveCallback as UseApproveCallback } from 'hooks/useApproveCallback'
import PEFITokenLogo from 'assets/pefi/pefi.png'
import Text from 'components/Text'

const Wrap1 = styled.div`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrap2 = styled.div`
  // background-image: url(${(props) => props.bg});
`

const Button = styled.button`
  font-weight: 600;
  color: #fff;
  background: #9ce315;
  font-size: 18px;
  height: 48px;
  padding: 0 24px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  min-width: 200px;
  &.disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const ActionButtons = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  display: grid;

  @media screen and (min-width: 480px) {
    display: flex;
  }
`

const FeeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    margin-left: 4px;
    width: 20px;
    height: auto;
  }
`

const getCoordinate = (n) => {
  const length = 4 * (n - 1)
  const arr = []
  for (let index = 0; index < length; index++) {
    if (index < n) {
      arr.push({
        x: index,
        y: 0,
      })
    } else if (index >= n && index < 2 * n - 1) {
      arr.push({
        x: n - 1,
        y: index - n + 1,
      })
    } else if (index >= 2 * n - 1 && index < 3 * n - 2) {
      arr.push({
        x: 3 * n - 3 - index,
        y: n - 1,
      })
    } else {
      arr.push({
        x: 0,
        y: length - index,
      })
    }
  }
  return arr
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export default class Lottery extends AppleLottery {
  static defaultProps = {
    list: [
      { name: 'Chest S', img: 'https://plantempires-media.b-cdn.net/chest/S_Chest.png' },
      { name: 'Arena Ticket', img: 'https://plantempires-media.b-cdn.net/pvp-ticket/free_ticket.png' },
      { name: 'Ruby Chest', img: 'https://plantempires-media.b-cdn.net/chest/8_Ruby_Box.png' },
      // { name: 'Ruby Ticket', img: 'https://plantempires-media.b-cdn.net/bonus-ticket/ruby_ticket.png' },
      { name: 'PEFI', img: 'https://app.plantempires.io/static/media/pefi.5d321eb1.png' },
      { name: 'Herald Piece', img: 'https://plantempires-media.b-cdn.net/chest-piece/herald_piece.png' },
      { name: 'Diamond Chest', img: 'https://plantempires-media.b-cdn.net/chest/9_Diamond_Box.png' },
      { name: 'Gold Chest', img: 'https://plantempires-media.b-cdn.net/chest/5_Gold_Box.png' },
      { name: 'Gem', img: 'https://plantempires-media.b-cdn.net/gem_pack.png' },
      { name: 'Gold Piece', img: 'https://plantempires-media.b-cdn.net/chest-piece/gold_piece.png' },
      { name: 'Chest SS', img: 'https://plantempires-media.b-cdn.net/chest/SS_Chest.png' },
      { name: 'Gold Ticket', img: 'https://plantempires-media.b-cdn.net/bonus-ticket/gold_ticket.png' },
      { name: 'Gold', img: 'https://plantempires-media.b-cdn.net/gold_pack.png' },
      { name: 'Ruby Piece', img: 'https://plantempires-media.b-cdn.net/chest-piece/ruby_piece.png' },
      { name: 'Herald Chest', img: 'https://plantempires-media.b-cdn.net/chest/7_Herald_Box.png' },
      { name: 'Platinum Ticket', img: 'https://plantempires-media.b-cdn.net/bonus-ticket/platinum_ticket.png' },
      { name: 'Platinum Chest', img: 'https://plantempires-media.b-cdn.net/chest/6_Platinum_Box.png' },
      { name: 'Platinum Piece', img: 'https://plantempires-media.b-cdn.net/chest-piece/platinum_piece.png' },
      { name: 'Chest A', img: 'https://plantempires-media.b-cdn.net/chest/A_Chest.png' },
      { name: 'Herald Ticket', img: 'https://plantempires-media.b-cdn.net/bonus-ticket/herald_ticket.png' },
      { name: 'Diamond Piece', img: 'https://plantempires-media.b-cdn.net/chest-piece/diamond_piece.png' },
    ],
    rowCount: 3,
    initialSpeed: 100,
    slowAcceleration: 20,
    stopAcceleration: 40,
    validate: (next) => next(true),
    onLotteryComplete: () => {
      window.console.log('complete')
    },
    onLotteryStart: () => {
      window.console.log('start')
    },

    btnIamge: '',
    prefixClass: 'react-lottery',
    style: {},
    btnStyle: {},
    itemStyle: {},
    itemNameStyle: {},
  }

  state = {
    currentIndex: 0,
    prizeIndex: -1,
    attemptingTxn: false,
    startCountdown: false,
    isSpinTry: false,
    show_remain_confirm_tx: false,
    fee: 0,
  }

  speed = this.props.initialSpeed
  type = 'wait'
  raf = null
  lastTime = null
  awardIndex = null
  margin
  unit

  constructor(props) {
    super(props)
    const { rowCount } = props
    this.margin = Math.floor(10 / rowCount)
    this.unit = Number(((100 - (rowCount - 1) * this.margin) / rowCount).toFixed(1))
  }

  handleStart = () => {
    if (this.type !== 'wait') {
      return
    }
    const { initialSpeed, validate } = this.props
    validate((verify) => {
      if (verify) {
        this.speed = initialSpeed
        this.type = 'infinity'
        this.onLotteryStart(this.handleStop, this.type)
        requestAnimationFrame(this.handleChange)
      }
    })
  }

  handleStop = (index) => {
    if (index < 0) return this.type === 'stop'
    this.type = 'slow'
    this.awardIndex = index
    const second = (this.props.rowCount + Math.random() - 0.5) * 1000
    setTimeout(() => (this.type = 'stop'), second)
  }

  handleComplete = (index) => {
    const { list } = this.props
    this.onLotteryComplete(index, list[index])
  }

  handleChange = () => {
    const { list, slowAcceleration, stopAcceleration } = this.props
    const { currentIndex } = this.state
    const len = list.length
    if (!this.lastTime || Date.now() - this.lastTime >= this.speed) {
      if (this.type === 'stop' && this.awardIndex === currentIndex) {
        this.handleComplete(this.awardIndex)
        this.type = 'wait'
        return
      }
      const typeToAdd = {
        infinity: 0,
        slow: slowAcceleration,
        stop: stopAcceleration,
      }
      this.speed += typeToAdd[this.type] || 0
      this.lastTime = Date.now()
      const index = currentIndex === len - 1 ? 0 : currentIndex + 1
      this.setState({
        currentIndex: index,
      })
    }
    requestAnimationFrame(this.handleChange)
  }

  validate = (next) => {
    next(true)
  }

  onLotteryStart = async (complete, state) => {
    console.log('start')
  }

  onLotteryComplete = async (index, item) => {
    this.props.setResult(item)
    if (this.state.isSpinTry) {
      this.setState({
        attemptingTxn: false,
        isSpinTry: false,
      })
    } else {
      this.setState({
        attemptingTxn: false,
        startCountdown: true,
      })
    }
    await this.getSpinFee()
  }

  decodeLog = async (logs) => {
    const log = logs[logs.length - 1]
    const inputs = [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'chestId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'chestPieceId',
        type: 'uint256',
      },
    ]
    const decoded = await this.props.web3.eth.abi.decodeLog(inputs, log.data, [log.topics[1]])
    return decoded
  }

  spin = async () => {
    let { account, checkAccountIngame, setShowNotAccount, luckySpinContract, LUCKY_SPIN_ADDRESS, chainId } = this.props

    this.setState({
      attemptingTxn: true,
    })

    const hasAccount = await checkAccountIngame(account)

    if (!hasAccount) {
      setShowNotAccount(true)
      this.setState({
        attemptingTxn: false,
      })
      return
    }

    try {
      // // Get signature
      // let response = await axios({
      //   method: 'post',
      //   url: LUCKY_SPIN.SIGN,
      //   data: {
      //     address: account,
      //   },
      //   validateStatus: (status) => {
      //     return status === 500 || status === 200
      //   },
      // })

      // if (response.status === 500) {
      //   this.setState({
      //     attemptingTxn: false,
      //   })
      //   if (response.data.message === 'Must wait') {
      //     this.setState({
      //       startCountdown: true,
      //     })
      //     return NotificationManager.error(`Please wait for 2 minutes and try again.`, 'Error', 5000)
      //   }
      //   if (response.data.message === 'Out of turns') {
      //     return NotificationManager.error(`Only 2 turns per day.`, 'Error', 5000)
      //   }
      //   NotificationManager.error(`Something went wrong. Please try again!`, 'Error', 5000)
      // }

      // let { nonce, signature } = response.data.data

      const estimatedGas = await luckySpinContract.estimateGas.spin().catch(() => {
        return luckySpinContract.estimateGas.spin()
      })

      let tx = await luckySpinContract.spin({
        gasLimit: calculateGasMargin(estimatedGas),
      })

      this.handleStart()

      let txFull = await tx.wait()

      console.log(txFull)

      if (txFull.status === 0) {
        this.props.setResult('failed')
        this.setState({
          attemptingTxn: false,
          prizeIndex: -1,
          currentIndex: -1,
        })
        // NotificationManager.error('Transaction failed.', 'Error', 2000)
        return
      }

      //call to decrease spin turns.
      // await axios.post(`${LUCKY_SPIN.TX_SUCCESS}`, { address: account })

      let logs = txFull.logs
      let this_contract_log = logs.filter((log) => log.address === LUCKY_SPIN_ADDRESS[chainId || 56])
      let decoded = await this.decodeLog(this_contract_log)
      let { name } = decoded
      console.log(name)

      let prizeIndex = _.findIndex(this.props.list, (prize) => prize.name == name)

      this.handleStop(prizeIndex)
    } catch (error) {
      this.setState({
        attemptingTxn: false,
      })
      console.error(error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
  }

  generateRandom = (min, max) => {
    var num = Math.floor(Math.random() * (max - min + 1)) + min
    return [2, 5, 6, 13, 15].includes(num) ? this.generateRandom(min, max) : num
  }

  trySpin = async () => {
    this.setState({
      attemptingTxn: true,
      isSpinTry: true,
    })
    this.handleStart()
    let prizeIndex = this.generateRandom(0, 19)
    setTimeout(() => this.handleStop(prizeIndex), 3000)
  }

  style = () => {
    return {
      position: 'relative',
      padding: 0,
      margin: '0 auto',
    }
  }

  renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      this.setState({
        startCountdown: false,
      })
      return null
    } else {
      return (
        <Dots>
          Wait {minutes}m {seconds}s
        </Dots>
      )
    }
  }

  renderActionButton = () => {
    return (
      <ActionButtons>
        <Button className="disable">EVENT END</Button>
      </ActionButtons>
    )
    if (this.props.approvalPEFI !== ApprovalState.APPROVED) {
      return (
        <>
          {this.state.show_remain_confirm_tx ? (
            <ActionButtons>
              <Button className="disable">
                <Dots>LOADING</Dots>
              </Button>
            </ActionButtons>
          ) : (
            <ActionButtons>
              <Button
                onClick={() => {
                  if (
                    this.props.approvalPEFI === ApprovalState.UNKNOWN ||
                    this.props.approvalPEFI === ApprovalState.PENDING ||
                    this.props.approvalPEFI !== ApprovalState.NOT_APPROVED
                  ) {
                    return
                  }
                  this.setState({
                    show_remain_confirm_tx: true,
                  })
                  this.props.approvePEFICallback().catch((error) => {
                    this.setState({
                      show_remain_confirm_tx: false,
                    })
                  })
                }}
                className={
                  this.props.approvalPEFI === ApprovalState.UNKNOWN ||
                  this.props.approvalPEFI === ApprovalState.PENDING ||
                  this.props.approvalPEFI !== ApprovalState.NOT_APPROVED
                    ? 'disable'
                    : ''
                }
              >
                {this.props.approvalPEFI === ApprovalState.UNKNOWN ? (
                  <Dots>WAITING</Dots>
                ) : this.props.approvalPEFI === ApprovalState.PENDING ? (
                  <Dots>APPROVING</Dots>
                ) : (
                  <>APPROVE PEFI</>
                )}
              </Button>
            </ActionButtons>
          )}
        </>
      )
    }
    // if (this.state.startCountdown) {
    //   const target = Date.now() + 2 * 60 * 1000
    //   localStorage.setItem('countdownTarget', target.toString())
    //   return (
    //     <ActionButtons>
    //       <Button className="disable">
    //         <Countdown date={target} renderer={this.renderCountdown} zeroPadTime={2} />
    //       </Button>
    //     </ActionButtons>
    //   )
    // }
    if (!this.props.luckySpinContract) {
      return (
        <ActionButtons>
          <Button className="disable">
            <Dots>Waiting</Dots>
          </Button>
        </ActionButtons>
      )
    }
    if (this.state.attemptingTxn) {
      return (
        <ActionButtons>
          <Button className="disable">
            <Dots>Loading</Dots>
          </Button>
        </ActionButtons>
      )
    }
    return (
      <>
        {/* <Text fontSize={14}>Please wait about 2 minutes between 2 turns</Text> */}
        <ActionButtons>
          <div>
            <Button onClick={this.spin}>
              SPIN
              {this.state.fee > 0 && (
                <span>
                  <span>
                    <img style={{ width: 16, margin: '0 3px 0 10px' }} src={PEFITokenLogo} />
                    <span style={{ fontSize: 14 }}>{this.state.fee}</span>
                  </span>
                </span>
              )}
            </Button>
          </div>
          <div>
            <Button onClick={this.trySpin}>
              TRY SPIN
              <span style={{ fontSize: 14, marginLeft: 10 }}>Free</span>
            </Button>
          </div>
        </ActionButtons>
      </>
    )
  }

  isNFTReward = (name) => {
    const nfts = [
      'Gold Piece',
      'Platinum Piece',
      'Ruby Piece',
      'Herald Piece',
      'Diamond Piece',
      'Gold Chest',
      'Platinum Chest',
      'Ruby Chest',
      'Herald Chest',
      'Diamond Chest',
    ]
    if (nfts.includes(name)) return true
    return false
  }

  getSpinFee = async () => {
    let { luckySpinContract, account } = this.props
    if (!luckySpinContract || !account) return
    let fee = await luckySpinContract.getFee(account)
    if (fee) {
      this.setState({
        fee: new BigNumber(fee._hex).div(new BigNumber(10).pow(18)).toFixed(),
      })
    }
  }

  componentDidMount = async () => {
    await this.getSpinFee()
  }

  render() {
    const { rowCount, style, prefixClass, list, account } = this.props
    const computedStyle = isFunction(style) ? style() : style
    return (
      <>
        <Wrap1 bg={BgSVG1}>
          <div className={`${prefixClass}-apple`} style={computedStyle}>
            <div className="centered-image">
              <img src="https://cdn.shopify.com/s/files/1/2202/3265/files/p5.png?v=1668069864"></img>
            </div>
            {rowCount > 0 && list.map(this.renderItem)}
          </div>
        </Wrap1>
        <div style={{ marginTop: '20px;' }}>
          {!account ? (
            <ActionButtons>
              <UnlockButton />
            </ActionButtons>
          ) : (
            <>{this.renderActionButton()}</>
          )}
        </div>
      </>
    )
  }

  itemRenderer = (item, computedStyle) => {
    let { currentIndex } = this.state
    let { rowCount, list } = this.props
    let margin = Math.floor(10 / rowCount)
    let unit = Number(((100 - (rowCount - 1) * margin) / rowCount).toFixed(1))
    let index = _.findIndex(list, item)
    let coordinate = getCoordinate(rowCount)[index]
    let style = assign(
      {},
      {
        width: `${unit}%`,
        height: `${unit}%`,
        left: `${coordinate.x * (unit + margin)}%`,
        top: `${coordinate.y * (unit + margin)}%`,
      },
      computedStyle
    )

    let className = currentIndex >= 0 && currentIndex == index ? 'item active' : 'item'
    if (this.isNFTReward(item.name)) {
      className += ' ' + 'nft'
    }
    return (
      <div className={className} style={style}>
        <img src={item.img} alt={item.name} />
      </div>
    )
  }

  itemNameStyle = (item, index, isActive) => {
    if (isActive) {
      return {
        border: '2px solid rgb(0, 196, 255)',
      }
    }
    return {}
  }

  renderItem = (item, index) => {
    const { rowCount, itemStyle, itemImageStyle, prefixClass } = this.props
    const cls = cx(`${prefixClass}-apple-item`, {
      [`${prefixClass}-apple-item-active`]: index === this.state.currentIndex && this.type !== 'wait',
    })
    const coordinate = getCoordinate(rowCount)[index]
    const margin = this.margin
    const unit = this.unit
    const style = assign(
      {},
      {
        width: `${unit}%`,
        height: `${unit}%`,
        left: `${coordinate.x * (unit + margin)}%`,
        top: `${coordinate.y * (unit + margin)}%`,
      },
      isFunction(itemStyle) ? itemStyle(item, index, index === this.state.currentIndex) : itemStyle
    )
    const innerStyle = assign(
      {},
      item.img
        ? {
            backgroundImage: `url(${item.img})`,
          }
        : {},
      isFunction(itemImageStyle) ? itemImageStyle(item, index, index === this.state.currentIndex) : itemImageStyle
    )
    const computedNameStyle = isFunction(this.itemNameStyle)
      ? this.itemNameStyle(item, index, index === this.state.currentIndex)
      : {}
    if (this.itemRenderer) {
      return this.itemRenderer(item, computedNameStyle)
    }
    return (
      <div className={cls} style={style} key={`${item.name}-${index}`}>
        <div className={`${prefixClass}-apple-item-image`} style={innerStyle} />
        <p style={computedNameStyle}>{item.name}</p>
      </div>
    )
  }
}
