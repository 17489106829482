import level from 'assets/pefi/special-pack/level-bg.png'
import star from 'assets/pefi/special-pack/star.png'
import times from 'lodash/times'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getOrbCdn } from 'utils/getPlantHeroDetail'
import lockIcon from 'assets/pefi/my-assets/icon_lock.png'
import Countdown from 'react-countdown'
interface PreviewProps {
  nft: any
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
`

const NftID = styled.p`
  position: absolute;
  bottom: 0;
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  font-family: Gudea-Medium;
  text-align: center;
  align-self: center;
  color: #fff8e8;
  text-transform: uppercase;
  background: #323232d9;
  padding-top: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
const StarContainer = styled.div`
  position: absolute;
  top: 20px;
  margin: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.non-nft {
    top: 10px;
  }
`

const Star = styled.img`
  width: 16px;
  height: 14px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
`

const Title = styled.p`
  position: absolute;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
  margin: 10px 0;
`

const HolyPreview = styled.img`
  width: 100%;
`

const Lock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${lockIcon});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 40px;
  width: 30px;
  height: 30px;
  left: 0;
  transition: opacity 1s linear;
  object-fit: cover;
  border-top-right-radius: 5px;
  z-index: 2;
`

const LockText = styled.div`
  position: absolute;
  bottom: 35px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  background: #00000080;
  padding: 5px 10px;
  color: red;
  font-weight: bold;
  border-radius: 10px;
`

const CountdownContent = styled.div`
  width: 100%;
  position: absolute;
  top: 45px;
  font-size: 14px;
  font-weight: bold;
  background: #00000080;
  padding: 2px;
  border-radius: 5px;
`

export default function Preview({ nft }: PreviewProps) {
  const { nftId, star: numberOfStar, name, lockToTime } = nft
  const cdn = getOrbCdn(nft.skinId, nft.rarity)
  const isLockIngame = lockToTime ? Date.parse(lockToTime) > Date.now() : false

  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: boolean
  }) => {
    if (completed) {
      return null
    } else {
      return (
        <>
          <CountdownContent>
            {hours}H {minutes}M
          </CountdownContent>
        </>
      )
    }
  }

  return (
    <Container className={`preview-container`}>
      {nftId !== '' && <Title>#{nftId}</Title>}
      <StarContainer className={`${nftId ? 'nft' : 'non-nft'}`}>
        {times(numberOfStar, (index) => (
          <Star src={star} key={index}></Star>
        ))}
      </StarContainer>
      {lockToTime && isLockIngame && (
        <Lock>
          <Countdown date={Date.parse(lockToTime)} renderer={renderCountdown} zeroPadTime={2} />
        </Lock>
      )}
      <HolyPreview src={cdn} />
      {lockToTime && isLockIngame && (
        <LockText>
          <Content>LOCK In-game</Content>
        </LockText>
      )}
      <NftID>{name}</NftID>
    </Container>
  )
}
