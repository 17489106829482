import footerLogoMobile from 'assets/pefi/footer/footer-logo.png'
import footerLogoTablet from 'assets/pefi/footer/footer-logo.png'
import footerLogo from 'assets/pefi/footer/footer-logo.png'
import footerBg from 'assets/pefi/footer/footer-bg.png'
import telegram from 'assets/herofi/telegram-icon.svg'
import tiktok from 'assets/herofi/tiktok-icon.svg'
import twitter from 'assets/herofi/twitter-icon.svg'
import React from 'react'
import styled from 'styled-components'

const email = 'contact@plantempires.io'
const whitePaperURL = 'https://whitepaper.plantempires.io/overall-information/introduction'
const twitterURL = 'https://twitter.com/PlantEmpires'
const telegramGlobalAnn = 'https://t.me/Plantempiresann'
const tiktokURL = 'https://www.tiktok.com/@plantempiresofficial'
const telegramGlobalURL = 'https://t.me/plantempiresofficial'
const telegramVietNamURL = 'https://t.me/plantempiresvn'

const FooterStyle = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  background: url(${footerBg});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  width: 100%;
  color: #fff;
  padding: 60px 0;
  .logo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .widget {
    .title {
      font-family: 'Gudea-Bold';
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
    .content {
      font-size: 18px;
      line-height: 22px;
      display: block;
      a {
        color: #fff;
        text-decoration: none;
        :hover {
          color: #d47b20;
        }
      }
      .link {
        margin-bottom: 20px;
      }
      .social {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        .icon {
          margin-right: 10px;
        }
      }
    }
  }
`

const FooterStyleTablet = styled.div`
  background: url(${footerBg});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  width: 100%;
  color: #fff;
  padding: 20px 30px;
  text-align: center;
  .logo {
    margin-bottom: 30px;
  }
  .widget-container {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    .widget {
      text-align: left;
      padding: 0 5px;
      .title {
        font-family: 'Gudea-Bold';
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .content {
        font-size: 12px;
        line-height: 14px;
        display: block;
        a {
          color: #fff;
          text-decoration: none;
          :hover {
            color: #d47b20;
          }
        }
        .link {
          margin-bottom: 20px;
        }
        .social {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
          .icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
`

const FooterStyleMobile = styled.div`
  background: url(${footerBg});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  width: 100%;
  color: #fff;
  padding: 20px 30px;
  text-align: center;
  .logo {
    margin-bottom: 30px;
  }
  .widget-container {
    display: flex;
    justify-content: space-between;
    .widget {
      text-align: left;
      padding: 0 5px;
      .title {
        font-family: 'Gudea-Bold';
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .content {
        font-size: 12px;
        line-height: 14px;
        display: block;
        a {
          color: #fff;
          text-decoration: none;
          :hover {
            color: #d47b20;
          }
        }
        .link {
          margin-bottom: 20px;
        }
        .social {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
          .icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
`

const Footer = () => {
  const isMobile = window.innerWidth <= 500
  const isTablet = window.innerWidth > 500 && window.innerWidth <= 1024

  const getLogo = () => {
    const server = localStorage.getItem('server')
    if (!server) return 'https://plantempires-media.b-cdn.net/logo/server1.png'
    if (server === 's1') return 'https://plantempires-media.b-cdn.net/logo/server1.png'
    return 'https://plantempires-media.b-cdn.net/logo/server2.png'
  }

  if (isMobile) {
    return (
      <FooterStyleMobile>
        <div className="logo">
          <img style={{ width: '100%', maxWidth: 200 }} src={getLogo()} alt="" />
        </div>
        <div className="widget-container">
          <div className="widget">
            <div className="title">Contact Us</div>
            <div className="content">
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
          <div className="widget">
            <div className="title">About PEFI</div>
            <div className="content">
              <div className="link">
                <a className="item" href={whitePaperURL} target="_blank" rel="noreferrer">
                  White Paper
                </a>
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="title">Social</div>
            <div className="content">
              <div className="social">
                <img className="icon" src={telegram} alt="" />
                <div className="url">
                  <a className="item" href={telegramGlobalAnn} target="_blank" rel="noreferrer">
                    Global Telegram Announcements
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={telegram} alt="" />
                <div className="url">
                  <a className="item" href={telegramGlobalURL} target="_blank" rel="noreferrer">
                    Global Telegram Community
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={telegram} alt="" />
                <div className="url">
                  <a className="item" href={telegramVietNamURL} target="_blank" rel="noreferrer">
                    Vietnamese Community
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={twitter} alt="" />
                <div className="url">
                  <a className="item" href={twitterURL} target="_blank" rel="noreferrer">
                    Twitter
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={tiktok} alt="" />
                <div className="url">
                  <a className="item" href={tiktokURL} target="_blank" rel="noreferrer">
                    Tiktok
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterStyleMobile>
    )
  }

  if (isTablet) {
    return (
      <FooterStyleTablet>
        <div className="logo">
          <img src={getLogo()} alt="" style={{ width: 300 }} />
        </div>
        <div className="widget-container">
          <div className="widget">
            <div className="title">Contact Us</div>
            <div className="content">
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
          <div className="widget">
            <div className="title">About PEFI</div>
            <div className="content">
              <div className="link">
                <a className="item" href={whitePaperURL} target="_blank" rel="noreferrer">
                  White Paper
                </a>
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="title">Social</div>
            <div className="content">
              <div className="social">
                <img className="icon" src={telegram} alt="" />
                <div className="url">
                  <a className="item" href={telegramGlobalAnn} target="_blank" rel="noreferrer">
                    Global Telegram Announcements
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={telegram} alt="" />
                <div className="url">
                  <a className="item" href={telegramGlobalURL} target="_blank" rel="noreferrer">
                    Global Telegram Community
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={telegram} alt="" />
                <div className="url">
                  <a className="item" href={telegramVietNamURL} target="_blank" rel="noreferrer">
                    Vietnamese Community
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={twitter} alt="" />
                <div className="url">
                  <a className="item" href={twitterURL} target="_blank" rel="noreferrer">
                    Twitter
                  </a>
                </div>
              </div>
              <div className="social">
                <img className="icon" src={tiktok} alt="" />
                <div className="url">
                  <a className="item" href={tiktokURL} target="_blank" rel="noreferrer">
                    Tiktok
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterStyleTablet>
    )
  }

  return (
    <FooterStyle>
      <div className="logo">
        <img src={getLogo()} alt="" style={{ width: '100%', maxWidth: 300 }} />
      </div>
      <div className="widget">
        <div className="title">Contact Us</div>
        <div className="content">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
      <div className="widget">
        <div className="title">About PEFi</div>
        <div className="content">
          <div className="link">
            <a className="item" href={whitePaperURL} target="_blank" rel="noreferrer">
              White Paper
            </a>
          </div>
        </div>
      </div>
      <div className="widget">
        <div className="title">Social</div>
        <div className="content">
          <div className="social">
            <img className="icon" src={telegram} alt="" />
            <div className="url">
              <a className="item" href={telegramGlobalAnn} target="_blank" rel="noreferrer">
                Global Telegram Announcements
              </a>
            </div>
          </div>
          <div className="social">
            <img className="icon" src={telegram} alt="" />
            <div className="url">
              <a className="item" href={telegramGlobalURL} target="_blank" rel="noreferrer">
                Global Telegram Community
              </a>
            </div>
          </div>
          <div className="social">
            <img className="icon" src={telegram} alt="" />
            <div className="url">
              <a className="item" href={telegramVietNamURL} target="_blank" rel="noreferrer">
                Vietnamese Community
              </a>
            </div>
          </div>
          <div className="social">
            <img className="icon" src={twitter} alt="" />
            <div className="url">
              <a className="item" href={twitterURL} target="_blank" rel="noreferrer">
                Twitter
              </a>
            </div>
          </div>
          <div className="social">
            <img className="icon" src={tiktok} alt="" />
            <div className="url">
              <a className="item" href={tiktokURL} target="_blank" rel="noreferrer">
                Tiktok
              </a>
            </div>
          </div>
        </div>
      </div>
    </FooterStyle>
  )
}

export default Footer
