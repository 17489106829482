import Preview from 'components/PreviewCard/OrbCardPreview/Preview'
import React, { ReactNode } from 'react'
import { Text } from 'rebass'
import styled, { keyframes } from 'styled-components'
import { Nft } from 'constants/types'
import { SwapCallbackError } from 'components/MyAssets/styleds'

const Container = styled.div`
  flex: 1;
`

const NftActions = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`
const NftImage = styled.div`
  padding-top: 4px;
  flex: 2;
`

const UpgradeButton = styled.div`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 23px;
  padding: 7px 20px 3px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto 0;
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
  }
`

export default function SellItem({
  onSellClick,
  onUpgradeStarClick,
  onWithdrawClick,
  swapErrorMessage,
  disabledConfirm,
  nft,
}: {
  onSellClick: () => void
  onUpgradeStarClick: () => void
  onWithdrawClick: () => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
  nft: Nft
}) {
  return (
    <Container>
      <NftImage>{nft && <Preview nft={nft}></Preview>}</NftImage>
      {nft.nftId !== '' && nft.star < 6 && (
        <NftActions>
          <UpgradeButton onClick={onUpgradeStarClick} className={disabledConfirm ? 'disable' : ''}>
            <Text fontSize={20} fontWeight={700}>
              EVOLVE ORB
            </Text>
          </UpgradeButton>
        </NftActions>
      )}
      {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
    </Container>
  )
}
