import React from 'react'
import styled from 'styled-components'
import times from 'lodash/times'
import level from 'assets/pefi/special-pack/level-bg.png'
import star from 'assets/pefi/special-pack/star.png'
import Text from 'components/Text'
import { isIOS } from 'react-device-detect'
import loadingPreview from 'assets/pefi/my-assets/loading-preview.png'
interface PreviewProps {
  nft: {
    image: string
    name: string
    level: number
    tokenId: string
    star: number
    video: string
    generation: string
    legendGuardian?: any
    detail?: any
    plantId?: string
  }
  isOwned?: boolean
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  transition: opacity 1s linear;
  object-fit: cover;
`

const Level = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${level});
  background-size: cover;
  background-repeat: no-repeat;
  height: 70px;
  width: 45px;
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 1s linear;
  object-fit: cover;
  border-top-right-radius: 5px;
  z-index: 2;
`

const Essence = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${level});
  width: 55px;
  height: 71px;
  position: absolute;
  top: 58px;
  right: 0;
  transition: opacity 1s linear;
  object-fit: cover;
  border-top-right-radius: 5px;
`

const NftID = styled.p`
  position: absolute;
  bottom: 0;
  margin: 10px 0px;
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  font-family: Gudea-Medium;
  text-align: center;
  align-self: center;
  color: #fff8e8;
  text-transform: uppercase;
  background: #323232d9;
  padding-top: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
const StarContainer = styled.div`
  position: absolute;
  top: 30px;
  margin: 10px 0px;
  width: 100%;
  text-align: center;
  align-self: center;
  z-index: 99;
`

const Star = styled.img`
  width: 26px;
  height: 24px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
`

const StyledVideo = styled.video`
  width: 100%;
  position: relative;
  border-radius: 20px;
`

const Title = styled.p`
  position: absolute;
  width: 100%;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
  margin: 10px 0;
  z-index: 99;
`

export default function Preview({ nft, isOwned = false }: PreviewProps) {
  const { detail, level, tokenId, star: numberOfStar, plantId } = nft
  const { name, assetUrl } = detail
  const url_webm = `https://plantempires-media.b-cdn.net/webm/${plantId}_${numberOfStar}.webm`
  const url_mp4 = `https://plantempires-media.b-cdn.net/${plantId}_${numberOfStar}.mp4`
  return (
    <Container className={`preview-container`}>
      <Title>#{tokenId}</Title>
      <StarContainer>
        {times(numberOfStar, (index) => (
          <Star src={star} key={index}></Star>
        ))}
      </StarContainer>
      <Level>
        <Text fontWeight={700} fontSize={14} margin="0px" style={{ color: '#fff8e8' }}>
          LV
        </Text>
        <Text fontSize={28} fontWeight={700} margin="0px" style={{ color: '#fff8e8' }}>
          {level || 1}
        </Text>
      </Level>
      <StyledVideo key={url_webm} autoPlay playsInline controls={false} loop muted poster={loadingPreview}>
        {!isIOS && <source src={`${url_webm}`} type="video/webm" />}
        <source src={`${url_mp4}`} type="video/mp4" />
      </StyledVideo>
      <NftID>{name}</NftID>
    </Container>
  )
}
