import Close from 'assets/pefi/special-pack/close-button.png'
import lineBg from 'assets/pefi/special-pack/line-bg.png'
import lineColor from 'assets/pefi/special-pack/line-color.png'
import { BoxConfig } from 'pages/SpecialPack/GachaBox/box-config'
import React, { useState, useEffect } from 'react'
import { Dots } from 'components/market/styleds'
import styled from 'styled-components'
import { ApprovalState, useApproveERC721Callback } from 'hooks/useApproveCallback'
import { usePlantHeroBoxContract, usePlantHeroBoxContractS2 } from 'hooks/useContract'
import { PE_PLANT_HERO_CHEST_ADDRESS, S2_PLANT_HERO_CHEST_ADDRESS } from 'constants/addresses'

interface ResultProps {
  heroDetails: any
  reload: () => void
}

const ResultStyle = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 61px;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #14324c99;
  opacity: 1;
  z-index: 99;
  padding-top: 20vh;
`

const Main = styled.div`
  display: block;
  text-align: center;
  border-radius: 5px;
  padding-top: 50px;
  width: 503px;
  border-radius: 5px;
  padding: 25px;
  position: relative;
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 80vw;
  `};
`

const Title = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  font-family: Montio;
  margin: 10px 0 0;
  border-bottom: 2px solid #fff;
`

const StarRateArea = styled.div``
const StarRateLine = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`
const Rate = styled.div`
  font-size: 14px;
  color: #fff8e8;
  font-weight: bold;
`
const RateArea = styled.div`
  padding: 25px 0;
`
const RateLine = styled.div`
  margin-bottom: 10px;
`
const Line = styled.div`
  width: 100%;
  background: url(${lineBg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 22px;
  img {
    padding: 1px 0 1px 1px;
    border-radius: 10px;
    height: 20px;
  }
`
const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
`
const BoxImage = styled.img`
  width: 100%;
`

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 16px;
  height: 35px;
  padding: 7px 15px 5px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
    background: #f6c931;
  }
`

const BoxId = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

export default function DetailBox({
  onConfirm,
  onDismiss,
  chest,
  attemptingTxn,
}: {
  onConfirm: (chest: any) => void
  onDismiss: (chest: any) => void
  chest: any
  attemptingTxn: boolean
}) {
  const [show_remain_confirm_tx, set_show_remain_confirm_tx] = useState<boolean>(false)

  const server = localStorage.getItem('server')

  const boxContract = usePlantHeroBoxContract()
  const boxContractS2 = usePlantHeroBoxContractS2()

  const useContract = server ? (server === 's1' ? boxContract : boxContractS2) : boxContract

  const CHEST_CONTRACT_ADDRESS = server
    ? server === 's1'
      ? PE_PLANT_HERO_CHEST_ADDRESS
      : S2_PLANT_HERO_CHEST_ADDRESS
    : PE_PLANT_HERO_CHEST_ADDRESS

  const nftAddress = CHEST_CONTRACT_ADDRESS[56]

  const [approvalBox, approvalBoxCallback] = useApproveERC721Callback(nftAddress, chest.tokenId, useContract?.address)

  useEffect(() => {
    if (approvalBox === ApprovalState.APPROVED || approvalBox === ApprovalState.PENDING) {
      set_show_remain_confirm_tx(false)
    }
  }, [approvalBox])

  const selected_chest = BoxConfig.find((box) => box.box_id === chest.boxType)
  if (!selected_chest) {
    return null
  }

  const render_random_rate = (chest: any) => {
    switch (chest.boxType) {
      case 1:
      case 6:
        return (
          <StarRateArea>
            <StarRateLine>
              <div style={{ padding: 20 }}>
                <BoxImage src={selected_chest.img} />
                <BoxId>#{chest.tokenId}</BoxId>
              </div>
              <RateArea>
                <RateLine>
                  <Rate>Rare 75%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '75%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Mythic 20%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '20%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Legend 5%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '5%' }} />
                  </Line>
                </RateLine>
              </RateArea>
            </StarRateLine>
          </StarRateArea>
        )
      case 2:
      case 7:
        return (
          <StarRateArea>
            <StarRateLine>
              <div style={{ padding: 20 }}>
                <BoxImage src={selected_chest.img} />
                <BoxId>#{chest.tokenId}</BoxId>
              </div>
              <RateArea>
                <RateLine>
                  <Rate>Rare 35%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '35%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Mythic 55%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '55%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Legend 10%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '10%' }} />
                  </Line>
                </RateLine>
              </RateArea>
            </StarRateLine>
          </StarRateArea>
        )
      case 3:
      case 8:
        return (
          <StarRateArea>
            <StarRateLine>
              <div style={{ padding: 20 }}>
                <BoxImage src={selected_chest.img} />
                <BoxId>#{chest.tokenId}</BoxId>
              </div>
              <RateArea>
                <RateLine>
                  <Rate>Rare 15%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '15%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Mythic 65%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '65%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Legend 20%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '20%' }} />
                  </Line>
                </RateLine>
              </RateArea>
            </StarRateLine>
          </StarRateArea>
        )
      case 4:
      case 9:
        return (
          <StarRateArea>
            <StarRateLine>
              <div style={{ padding: 20 }}>
                <BoxImage src={selected_chest.img} />
                <BoxId>#{chest.tokenId}</BoxId>
              </div>
              <RateArea>
                <RateLine>
                  <Rate>Rare 7%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '7%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Mythic 53%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '53%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Legend 40%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '40%' }} />
                  </Line>
                </RateLine>
              </RateArea>
            </StarRateLine>
          </StarRateArea>
        )
      case 5:
      case 10:
        return (
          <StarRateArea>
            <StarRateLine>
              <div style={{ padding: 20 }}>
                <BoxImage src={selected_chest.img} />
                <BoxId>#{chest.tokenId}</BoxId>
              </div>
              <RateArea>
                <RateLine>
                  <Rate>Mythic 25%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '25%' }} />
                  </Line>
                </RateLine>
                <RateLine>
                  <Rate>Legend 75%</Rate>
                  <Line>
                    <img src={lineColor} style={{ width: '75%' }} />
                  </Line>
                </RateLine>
              </RateArea>
            </StarRateLine>
          </StarRateArea>
        )
      default:
        return <></>
    }
  }
  return (
    <ResultStyle>
      <Main>
        <Title>{selected_chest.name}</Title>
        {render_random_rate(chest)}
        <ActionButtons>
          {!attemptingTxn && approvalBox !== ApprovalState.APPROVED ? (
            <>
              {show_remain_confirm_tx ? (
                <Button className={'disable'}>
                  <Dots>LOADING</Dots>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (
                      approvalBox === ApprovalState.UNKNOWN ||
                      approvalBox === ApprovalState.PENDING ||
                      approvalBox !== ApprovalState.NOT_APPROVED
                    ) {
                      return
                    }
                    set_show_remain_confirm_tx(true)
                    approvalBoxCallback().catch((error) => {
                      set_show_remain_confirm_tx(false)
                    })
                  }}
                  className={
                    approvalBox === ApprovalState.UNKNOWN ||
                    approvalBox === ApprovalState.PENDING ||
                    approvalBox !== ApprovalState.NOT_APPROVED
                      ? 'disable'
                      : ''
                  }
                >
                  {approvalBox === ApprovalState.UNKNOWN ? (
                    <Dots>WAITING</Dots>
                  ) : approvalBox === ApprovalState.PENDING ? (
                    <Dots>APPROVING</Dots>
                  ) : (
                    <>APPROVE NFT</>
                  )}
                </Button>
              )}
            </>
          ) : (
            <Button onClick={() => onConfirm(chest)} className={attemptingTxn ? 'disable' : ''}>
              {attemptingTxn ? <Dots>WAITING</Dots> : 'OPEN'}
            </Button>
          )}
        </ActionButtons>
        {show_remain_confirm_tx && (
          <div style={{ textAlign: 'center', marginTop: '10px', color: '#fff' }}>
            Please confirm approve tx on your wallet
          </div>
        )}
        {attemptingTxn && (
          <>
            <div style={{ marginTop: 15 }}>Using Chainlink Randomness...</div>
            <div>It might take many seconds.</div>
          </>
        )}
        <CloseIcon src={Close} onClick={onDismiss} />
      </Main>
    </ResultStyle>
  )
}
