import React from 'react'
import styled from 'styled-components'
import HolyGreen from 'assets/pefi/holy/holypack_green.png'
import HolyBlue from 'assets/pefi/holy/holypack_blue.png'
import HolyRed from 'assets/pefi/holy/holypack_red.png'
import HolyYellow from 'assets/pefi/holy/holypack_yellow.png'

interface PreviewProps {
  nft: any
}

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`

const HolyId = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `}
`

const HolyPreview = styled.img`
  width: 100px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid #03c5ff;
  background: #013b59;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 60px;
    border-radius: 10px;
    padding: 5px;
  `}
`

const selectHolyImage = (type: string) => {
  switch (type) {
    case 'green':
      return HolyGreen
    case 'red':
      return HolyRed
    case 'blue':
      return HolyBlue
    case 'yellow':
      return HolyYellow
    default:
      return undefined
  }
}

export default function Preview({ nft }: PreviewProps) {
  return (
    <Container>
      <HolyPreview src={selectHolyImage(nft.holyType)} />
      <HolyId>#{nft.tokenId}</HolyId>
    </Container>
  )
}
