import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import PE_CONVERT_GPEFI_ABI from 'abis/pe-convert-gpefi-abi.json'
import ArrowIcon from 'assets/pefi/token/arrow.png'
import PEFI_TOKEN_ICON from 'assets/pefi/pefi.png'
import OrderBackground from 'assets/images/background-orders.png'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import RequestsList from 'components/MyAssets/TokensModal/RequestsListModal'
import Text, { MagraText } from 'components/Text'
import {
  PE_CONVERT_GPEFI_ADDRESS,
  S2_CONVERT_GPEFI_ADDRESS,
  PE_CONVERT_GPEFI_OLD_ADDRESS,
  S2_CONVERT_GPEFI_OLD_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { PLANT_ENDPOINT, TOKEN_ENDPOINT } from 'constants/mkpconfigs'
import {
  useConvertGPEFIContract,
  useConvertGPEFIContractS2,
  useConvertGPEFIOldContract,
  useConvertGPEFIOldContractS2,
} from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import AppBody from 'pages/AppBody'
import UnlockButton from 'pages/AccountPage/TokensPage/UnlockButton'
import { Dots } from 'pages/styleds'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { isMobile } from 'react-device-detect'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import { getDecimalAmount } from 'utils/formatBalance'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

const HeaderShop = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`

const ShopBody = styled(AppBody)`
  display: flex;
  flex-direction: row;
  max-width: 2000px;
  background: url(${OrderBackground});
`

const ShopItemArea = styled.div`
  flex: 4;
  padding: 15px;
  max-width: 70vw;
  margin: 50px auto 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100vw;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 90vw;
  `}
`

const Container = styled.div`
  padding: 100px 0 100px;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  padding: 50px 0;
  width: 65%;
  margin: 0 auto;
  gap: 0;
  ${({ theme }) => theme.mediaWidth.upTo1440`
    width: 80%;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr!important;
    gap: 50px;
    padding: 50px 0 50px;
    width: 100%;
  `}
`

const PageSubtitle = styled.div`
  font-size: 18px;
  color: #fff8e8;
  line-height: 36px;
  margin-top: 20px;
  text-align: center;
`

const button_styles = {
  maxWidth: isMobile ? '40vw' : '220px',
  margin: '0 auto',
}

const button_styles_disable = {
  maxWidth: isMobile ? '40vw' : '220px',
  margin: '0 auto',
  opacity: 0.3,
  cursor: 'not-allowed',
}

const TokenCard = styled.div`
  text-align: center;
  margin-bottom: 20px;
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const TokenInfo = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
`

const TokenInfoOut = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
`

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: Gudea-Medium;
  color: #9ce32a;
  margin: 10px auto;
`

const ConvertIconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 90px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    img {
      width: 60px;
    }
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      transform: rotate(90deg)
    }
  `}
`

const LPInfo = styled.div`
  border-bottom: 2px solid #41393e;
  padding-bottom: 20px;
  &.result {
    border: none;
  }
`
const RofiTokenLogo = styled.img`
  padding: 0 0 30px;
  width: 120px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 80px;
  `}
`
const LPBalance = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .label {
    font-size: 18px;
    padding-right: 10px;
  }
  .value {
    font-size: 18px;
  }
`
const InputArea = styled.div``
const InputRow = styled.div`
  padding: 20px 0;
`
const SelectRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

const SelectButton = styled.div`
  padding: 5px 30px;
  background: #9ce315;
  border-radius: 5px;
  cursor: pointer;
  &:hover,
  &.active {
    background: #f6c931;
  }
`

const HistoryButton = styled.div`
  padding: 5px 30px;
  background: #9ce315;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  margin-top: 50px;
  &:hover,
  &.active {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.3;
  }
`
const ActionButton = styled.div`
  padding: 5px 40px;
  background: #9ce315;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  &:hover,
  &.active {
    background: #f6c931;
  }
  &.disable {
    cursor: not-allowed;
    opacity: 0.3;
  }
`
const PriceInput = styled.input`
  background-color: #00436d;
  border-radius: 6px;
  width: 152px;
  color: #fff;
  appearance: none;
  font-family: Gudea-Medium;
  font-size: 20px;
  height: 30px;
  border: none;
  outline: none;
  padding: 7px 15px;
  text-align: right;
`
const UniverseLogo = styled.img`
  cursor: pointer;
  margin: 10px;
  &.active,
  &:hover {
    border: 4px solid #d47b1f;
    border-radius: 10px;
    margin: 6px;
    box-shadow: 1px 10px 20px 5px #000;
  }
`

export default function Shop({ changePageState }: { changePageState: () => void }) {
  const { chainId, account } = useActiveWeb3React()
  const [gPEFIBalance, setgPEFIBalance] = useState<number>(0)
  const [isClaimable, setIsClaimable] = useState<boolean>(true)
  const [convertAmount, setConvertAmount] = useState<any>('0')
  const [selectedOption, setSelectedOption] = useState('')
  const [isRequestgPEFI, setIsRequestgPEFI] = useState<boolean>(false)
  const [showRequestsList, setShowRequestsList] = useState<boolean>(false)
  const [countdownComplete, setCountdownComplete] = useState<boolean>(false)
  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  const [requestsList, setRequestsList] = useState<any>([])
  const [currentRequest, setCurrentRequest] = useState<any>(undefined)

  const getRequestList = async (account: any) => {
    const url = `${TOKEN_ENDPOINT.CONVERT_REQUEST}/${account}`
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {},
        data: {},
      })
      if (response.status === 200) {
        const requests = response.data.data.requests
        const currentRequest = requests.find((request: any) => !request.claimed)
        setCurrentRequest(currentRequest)
        setRequestsList(requests)
      }
    } catch (error: any) {
      console.error(error)
    }
    return []
  }

  const getGPEFIBalance = async (account: any) => {
    const url = `${TOKEN_ENDPOINT.GTOKEN_BALANCE}/${account}/balance`
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {},
        data: {},
      })
      if (response.status === 200) {
        const balance = response.data.data.balance
        const claimable = response.data.data.claimable
        setgPEFIBalance(balance)
        setIsClaimable(claimable)
      }
    } catch (error: any) {
      console.error(error)
    }
    return 0
  }

  useEffect(() => {
    if (account === null) return
    getRequestList(account)
    getGPEFIBalance(account)
  }, [account])

  const sendRequest = async () => {
    setIsRequestgPEFI(true)
    const url = `${TOKEN_ENDPOINT.CONVERT_REQUEST}`
    try {
      const response = await axios({
        method: 'post',
        url: url,
        headers: {},
        data: {
          address: account,
          amount: convertAmount,
        },
        validateStatus: (status: number) => {
          return status === 500 || status === 200
        },
      })
      if (response.status === 200) {
        setIsRequestgPEFI(false)
        setSelectedOption('')
        await getRequestList(account)
        await getGPEFIBalance(account)
        setConvertAmount('0')
        NotificationManager.success('Send request successful.')
        return
      }
      setIsRequestgPEFI(false)
      NotificationManager.error(response.data.message, 'Error', 1000)
    } catch (error: any) {
      console.error('error', error)
      setIsRequestgPEFI(false)
      NotificationManager.error(error.data ? error.data.message : 'Please try again later.', 'Error', 1000)
    }
    return []
  }

  function thousands_separators(num: number) {
    const num_parts = num.toString().split('.')
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return num_parts.join('.')
  }

  const regex_only_integer = /^\d+$/

  const handleOnChange = (e: any) => {
    const value = e.target.value
    if (!regex_only_integer.test(value)) return
    if (Number(value) < 0) return
    setConvertAmount(value)
    setSelectedOption('')
  }

  const isInvalid =
    convertAmount === '' ||
    Number(convertAmount) == 0 ||
    Number(convertAmount) > Number(gPEFIBalance) ||
    Number(convertAmount) < 5000 ||
    Number(convertAmount) > 100000 ||
    (currentRequest && !currentRequest.proof) ||
    !isClaimable

  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: boolean
  }) => {
    if (completed) {
      setCountdownComplete(true)
      return (
        <div style={{ textAlign: 'center', margin: '-30px 0 20px', color: '#f2c32a' }}>
          Your request: convert {thousands_separators(currentRequest.total)} gPEFI to PEFI is completed. Click CLAIM to
          receiver PEFI token.
        </div>
      )
    } else {
      return (
        <div style={{ textAlign: 'center', margin: '-30px 0 20px', color: '#f2c32a' }}>
          You have 1 pending request: convert {thousands_separators(currentRequest.total)} gPEFI to PEFI ({days}d,{` `}
          {hours}h {minutes}m left)
        </div>
      )
    }
  }

  const renderActionButtons = (isInvalid: any) => {
    if (!account) return <UnlockButton />
    if (currentRequest) {
      if (countdownComplete) {
        return (
          <ActionButton onClick={claimHFI} className={isClaiming ? 'disable' : ''}>
            {isClaiming ? (
              <MagraText fontSize={23} fontWeight={600}>
                <Dots>
                  <Trans>LOADING</Trans>
                </Dots>
              </MagraText>
            ) : (
              <Text fontSize={isMobile ? 20 : 28} fontWeight={700}>
                CLAIM
              </Text>
            )}
          </ActionButton>
        )
      }
      return (
        <ActionButton className="disable">
          <Text fontSize={isMobile ? 20 : 28} fontWeight={700}>
            CLAIM
          </Text>
        </ActionButton>
      )
    }
    if (isInvalid) {
      return (
        <ActionButton className="disable">
          {isRequestgPEFI ? (
            <MagraText fontSize={23} fontWeight={600}>
              <Dots>
                <Trans>LOADING</Trans>
              </Dots>
            </MagraText>
          ) : (
            <Text fontSize={isMobile ? 20 : 28} fontWeight={700}>
              REQUEST
            </Text>
          )}
        </ActionButton>
      )
    }
    return (
      <ActionButton onClick={sendRequest} className={isRequestgPEFI ? 'disable' : ''}>
        {isRequestgPEFI ? (
          <MagraText fontSize={23} fontWeight={600}>
            <Dots>
              <Trans>LOADING</Trans>
            </Dots>
          </MagraText>
        ) : (
          <Text fontSize={isMobile ? 20 : 28} fontWeight={700}>
            REQUEST
          </Text>
        )}
      </ActionButton>
    )
  }

  const web3 = new Web3(
    chainId === 56 ? RPC_ENDPOINT_MAINNET : chainId === 97 ? RPC_ENDPOINT_TESTNET : 'https://bsc-dataseed1.ninicoin.io/' //others rpc mainnet
  )

  const server = localStorage.getItem('server')

  const ConvertGPEFIContractS1Old = useConvertGPEFIOldContract()
  const ConvertGPEFIContractS2Old = useConvertGPEFIOldContractS2()
  const ConvertGPEFIContractOld = server
    ? server === 's1'
      ? ConvertGPEFIContractS1Old
      : ConvertGPEFIContractS2Old
    : ConvertGPEFIContractS1Old

  const ConvertGPEFIContractS1 = useConvertGPEFIContract()
  const ConvertGPEFIContractS2 = useConvertGPEFIContractS2()
  const ConvertGPEFIContract = server
    ? server === 's1'
      ? ConvertGPEFIContractS1
      : ConvertGPEFIContractS2
    : ConvertGPEFIContractS1

  const CONVERT_GPEFI_ADDRESS_OLD = server
    ? server === 's1'
      ? PE_CONVERT_GPEFI_OLD_ADDRESS
      : S2_CONVERT_GPEFI_OLD_ADDRESS
    : PE_CONVERT_GPEFI_OLD_ADDRESS

  const CONVERT_GPEFI_ADDRESS = server
    ? server === 's1'
      ? PE_CONVERT_GPEFI_ADDRESS
      : S2_CONVERT_GPEFI_ADDRESS
    : PE_CONVERT_GPEFI_ADDRESS

  const decode_log = async (logs: any) => {
    const log = logs[logs.length - 1]
    const inputs = [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'requestId', type: 'uint256' },
    ]

    const decoded = web3.eth.abi.decodeLog(inputs, log.data, [log.topics[1]])
    const user = decoded.user
    return user
  }

  const claimHFI = async () => {
    const { timestamp, total, requestId, claimableAt, proof } = currentRequest
    const useContract = timestamp <= 1669767000 ? ConvertGPEFIContractOld : ConvertGPEFIContract
    const CONVER_CONTRACT_ADDRESS = timestamp <= 1669767000 ? CONVERT_GPEFI_ADDRESS_OLD : CONVERT_GPEFI_ADDRESS
    if (!useContract) return
    setIsClaiming(true)
    const decimal_total = getDecimalAmount(new BigNumber(total), 18).toFixed()
    try {
      const method: (...args: any) => Promise<TransactionResponse> = useContract.convert
      const args: Array<string | string[] | number> = [timestamp, requestId, decimal_total, claimableAt, proof]
      console.log('params', args)
      method(...args)
        .then(async (response) => {
          const txFull = await response.wait()
          console.log('txFull', txFull)
          if (txFull.status === 0) {
            setIsClaiming(false)
            NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            return
          }

          const logs = txFull.logs
          const log_convert_token = logs.filter((log: any) => log.address === CONVER_CONTRACT_ADDRESS[chainId || 56])
          const result = await decode_log(log_convert_token)
          console.log('result:', result)
          if (!result) {
            setIsClaiming(false)
            NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            return
          }
          await getRequestList(account)
          setTimeout(() => {
            setIsClaiming(false)
            NotificationManager.success('Claim token success.', 'Success', 2000)
          }, 500)
        })
        .catch((error: any) => {
          console.log('here', error)
          setIsClaiming(false)
          NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } catch (error: any) {
      setIsClaiming(false)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
      if (error?.code !== 4001) {
        console.error('try-catch', error)
      }
    }
  }

  return (
    <>
      <PageSubtitle>
        <div style={{ marginBottom: '20px' }}>Requirements for converting gPEFI (in-game) to PEFI (wallet)</div>
        <div>+ You must have at least 5,000 gPEFI (but not exceeding 100,000 gPEFI)</div>
        <div>+ You must own at least 1 NFT Plant or 30 days old account.</div>
      </PageSubtitle>
      <Container className="herofi game-web">
        <TokenCard>
          <TokenInfo>
            <CardTitle>In-Game</CardTitle>
            <LPInfo>
              <RofiTokenLogo src={PEFI_TOKEN_ICON} />
              <LPBalance>
                <span className="label">Balance:</span>
                <span className="value">{thousands_separators(Number(Number(gPEFIBalance).toFixed(3)))} (gPEFI)</span>
              </LPBalance>
            </LPInfo>
          </TokenInfo>
          <InputArea>
            <InputRow>
              <PriceInput value={convertAmount} type="text" onChange={handleOnChange} />
            </InputRow>
            <SelectRow>
              <SelectButton
                onClick={() => {
                  setConvertAmount(gPEFIBalance >= 5000 ? 5000 : Math.floor(gPEFIBalance))
                  setSelectedOption('min')
                }}
                className={`${selectedOption === 'min' && 'active'}`}
              >
                MIN
              </SelectButton>
              <SelectButton
                onClick={() => {
                  setConvertAmount(gPEFIBalance >= 100000 ? 100000 : Math.floor(gPEFIBalance))
                  setSelectedOption('max')
                }}
                className={`${selectedOption === 'max' && 'active'}`}
              >
                MAX
              </SelectButton>
            </SelectRow>
          </InputArea>
        </TokenCard>
        <ConvertIconArea>
          <img src={ArrowIcon} />
        </ConvertIconArea>
        <TokenCard className="result">
          <TokenInfoOut>
            <CardTitle>Wallet</CardTitle>
            <LPInfo className="result">
              <RofiTokenLogo src={PEFI_TOKEN_ICON} />
              <LPBalance>
                <div className="value">~ {thousands_separators(Number(Number(convertAmount).toFixed(3)))} (PEFI)</div>
              </LPBalance>
            </LPInfo>
          </TokenInfoOut>
          <HistoryButton onClick={() => setShowRequestsList(true)}>HISTORY</HistoryButton>
        </TokenCard>
      </Container>
      {!currentRequest && !isClaimable ? (
        <div style={{ textAlign: 'center', color: 'red', margin: '-10px 0 20px', fontSize: 16 }}>
          Your account does not meet the token withdrawal condititons.
        </div>
      ) : (
        <div style={{ textAlign: 'center', margin: '-30px 0 20px', fontSize: 16 }}>
          Approving period: 3 days (maximum 1 convert request at a time)
        </div>
      )}
      {isClaimable && Number(convertAmount) > Number(gPEFIBalance) && (
        <div style={{ textAlign: 'center', color: 'red', margin: '-10px 0 20px', fontSize: 16 }}>
          Not enough gPEFI balance
        </div>
      )}
      <div style={{ paddingBottom: '50px', display: 'flex', justifyContent: 'center' }}>
        {renderActionButtons(isInvalid)}
      </div>
      {currentRequest && (
        <Countdown date={Number(currentRequest.claimableAt) * 1000} renderer={renderCountdown} zeroPadTime={2} />
      )}
      <RequestsList
        isOpen={showRequestsList}
        onDismiss={() => setShowRequestsList(false)}
        requestsList={requestsList.filter((req: any) => req.claimed)}
        onRefreshData={() => {
          console.log('please update rewards')
        }}
        tokenType="PEFI"
      />
    </>
  )
}
