/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import button_sort from 'assets/images/button-sort.png'
import iconDPS from 'assets/pefi/fusion/icon_class_nuker.png'
import iconSupporter from 'assets/pefi/fusion/icon_class_supporter.png'
import iconTanker from 'assets/pefi/fusion/icon_class_tanker.png'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import axios from 'axios'
// import FilterModal from 'components/FilterModal'
import { parseUnits } from '@ethersproject/units'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Modal } from 'antd'
import tickIcon from 'assets/pefi/icon_tick.png'
import PEFITokenLogo from 'assets/pefi/pefi.png'
import SelectHolyForFusionModal from 'components/MyAssets/Fusion/SelectHolyForEvolveModal'
import PreviewHoly from 'components/PreviewCard/HolyMaterialPreview/PreviewImageOnly'
import PreviewOrb from 'components/PreviewCard/OrbCardPreview/PreviewSmall'
import PreviewFull from 'components/PreviewCard/OrbCardPreview/Preview'
import Text from 'components/Text'
import {
  PE_HOLY_PACKAGE_ADDRESS,
  PE_ORB_FUSION_ADDRESS,
  PE_ORB_NFT_ADDRESS,
  S2_HOLY_PACKAGE_ADDRESS,
  S2_ORB_FUSION_ADDRESS,
  S2_ORB_NFT_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { HERO_MARKET_SERVER, HOLY_ENDPOINT, ORBS_ENDPOINT } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft } from 'constants/types'
import { useCurrency as UseCurrency } from 'hooks/Tokens'
import {
  ApprovalState,
  useApproveCallback as UseApproveCallback,
  useApproveERC721Callback,
} from 'hooks/useApproveCallback'
import { useOrbFusionContract, useOrbFusionContractS2 } from 'hooks/useContract'
import UsePEFIAddress from 'hooks/usePEFIAddress'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import { Dots } from 'pages/styleds'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'
import Web3 from 'web3'
import NftGrid from './NftGrid'
import BgMythical from 'assets/pefi/fusion/bg_mythic.png'
import BgLegend from 'assets/pefi/fusion/bg_legend.png'
export interface NftCardProps {
  nft: Nft
  isSelected: boolean
}

const NFTArea = styled.div`
  flex: 4;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px;
  `};
`

const Card = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5px;
  `};
`

const Selected = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000082;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background: transparent;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
`

const FusionContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 57%;
  gap: 40px;
  padding: 25px;
  border-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
    padding: 0;
  `};
`

const SelectNFTs = styled.div`
  height: 100%;
  background: #14324c50;
  border-radius: 10px;
`
const HolyBonus = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #03c5ff;
  max-height: 80vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c2c8d5;
  }
`

const LineContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`

const OneCard = styled.div`
  width: 100%;
  min-height: 240px;
  padding: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    min-height: 200px;
  `};
`

const NFTCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 2px solid #03c5ff;
  cursor: pointer;
`

const TextIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  font-weight: bold;
`

const HeaderNavbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222938;
`
const NavItem = styled.div`
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-family: Gudea-Medium;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  &.active,
  &:hover {
    background: #14324c;
  }
`

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const HolySelectButton = styled.div`
  background: #9ce315;
  padding: 7px 0 3px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  width: 100px;
  color: #fff;
  &.approve {
    background: #f6c931;
  }
  &.disable {
    background: #f6c931;
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 60px;
    font-size: 12px;
  `}
`

const FusionInformation = styled.div`
  border: 2px solid #03c5ff;
  border-radius: 10px;
  padding: 10px;
  background: rgb(14, 45, 73);
  margin-bottom: 20px;
`
const TitleInfo = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`
const HolySlots = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
`
const OneSlots = styled.div`
  border: 2px solid #03c5ff;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
`

const ClearIcon = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #03c5ff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`

const ClassRate = styled.div`
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`

const OneClassRate = styled.div`
  text-align: center;
`
const ClassRateValue = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const HolyPackId = styled.div`
  font-size: 13px;
  color: #fff;
  text-align: center;
  background: #9ce315;
  border-radius: 5px;
  width: fit-content;
  padding: 0 5px;
  margin: 5px auto 0;
  cursor: pointer;
  &.disable {
    cursor: auto;
    opacity: 0.5;
  }
`

const ROFIAccountModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: rgb(2, 96, 146);
    border: 2px solid rgb(3, 197, 255);
    border-radius: 20px !important;
    .ant-modal-close {
      color: #fff !important;
      display: none !important;
    }
  }
`

const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RewardDetailTitle = styled.div`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const CardBody = styled.div`
  overflow: hidden;
  padding-bottom: 30px;
  max-width: 240px;
`

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const Property = styled.div`
  display: flex;
  align-items: center;
`

const PropertyRow = styled(Row)`
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 0px 5px;
`

const PropertyName = styled(Text)`
  font-weight: 600;
  font-size: 15px;
  padding-right: 5px;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 15px;
  `};
`

export default function Heroes({ setPageState }: { setPageState: (state: string) => void }) {
  // Define state
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [listOrbsMaterial, setListOrbsMaterial] = useState<any[]>([])

  const [showSelectHeroForUpgradeStar, setShowSelectHolyForFusion] = useState<boolean>(false)
  const [selectingSlotHeroForUpgradeStar, setSelectingSlotHoly] = useState<string>('0')
  const [materialHolySelected, setMaterialHolySelected] = useState<any>({})
  const [show_remain_confirm_tx, set_show_remain_confirm_tx] = useState<boolean>(false)

  const [{ showPlantFusionResult, plantResult, plantFusionResult }, setPlantFusionResult] = useState<{
    showPlantFusionResult: boolean
    plantResult: Nft | undefined
    plantFusionResult: boolean
  }>({
    showPlantFusionResult: false,
    plantResult: undefined,
    plantFusionResult: false,
  })

  const { chainId, account } = useActiveWeb3React()

  const server = localStorage.getItem('server')

  const orbFusionS1 = useOrbFusionContract()
  const orbFusionS2 = useOrbFusionContractS2()
  const orbFusion = server ? (server === 's1' ? orbFusionS1 : orbFusionS2) : orbFusionS1

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const PEFI_ADDRESS = UsePEFIAddress()
  const PEFiCurrency = UseCurrency(PEFI_ADDRESS)
  const PEFItypedValueParsed = parseUnits('1000000000000', PEFiCurrency?.decimals).toString()
  let PEFIamount
  if (PEFiCurrency instanceof Token) {
    PEFIamount = CurrencyAmount.fromRawAmount(PEFiCurrency, JSBI.BigInt(PEFItypedValueParsed))
  }

  const ORB_FUSION_ADDRESS = server
    ? server === 's1'
      ? PE_ORB_FUSION_ADDRESS
      : S2_ORB_FUSION_ADDRESS
    : PE_ORB_FUSION_ADDRESS

  const [approveOrbFusionUsePefi, approveOrbFusionUsePefiCallback] = UseApproveCallback(
    PEFIamount,
    ORB_FUSION_ADDRESS[chainId || 56]
  )

  const orbAddress = server
    ? server === 's1'
      ? PE_ORB_NFT_ADDRESS[56]
      : S2_ORB_NFT_ADDRESS[56]
    : PE_ORB_NFT_ADDRESS[56]

  const holyAddress = server
    ? server === 's1'
      ? PE_HOLY_PACKAGE_ADDRESS[56]
      : S2_HOLY_PACKAGE_ADDRESS[56]
    : PE_HOLY_PACKAGE_ADDRESS[56]

  const [approveOrbMaterial1, approvalOrbMaterial1Callback] = useApproveERC721Callback(
    orbAddress,
    listOrbsMaterial[0],
    orbFusion?.address
  )
  const [approveOrbMaterial2, approvalOrbMaterial2Callback] = useApproveERC721Callback(
    orbAddress,
    listOrbsMaterial[1],
    orbFusion?.address
  )

  const [approvalMaterialHoly1, approvalMaterialHoly1Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['1']?.tokenId : null,
    orbFusion?.address
  )
  const [approvalMaterialHoly2, approvalMaterialHoly2Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['2']?.tokenId : null,
    orbFusion?.address
  )
  const [approvalMaterialHoly3, approvalMaterialHoly3Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['3']?.tokenId : null,
    orbFusion?.address
  )
  const [approvalMaterialHoly4, approvalMaterialHoly4Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['4']?.tokenId : null,
    orbFusion?.address
  )
  const [approvalMaterialHoly5, approvalMaterialHoly5Callback] = useApproveERC721Callback(
    holyAddress,
    materialHolySelected ? materialHolySelected['5']?.tokenId : null,
    orbFusion?.address
  )

  useEffect(() => {
    fetchAllHeroes(account)
  }, [account])

  useEffect(() => {
    if (
      approveOrbMaterial1 === ApprovalState.APPROVED ||
      approveOrbMaterial1 === ApprovalState.PENDING ||
      approveOrbMaterial2 === ApprovalState.APPROVED ||
      approveOrbMaterial2 === ApprovalState.PENDING ||
      approvalMaterialHoly1 === ApprovalState.APPROVED ||
      approvalMaterialHoly1 === ApprovalState.PENDING ||
      approvalMaterialHoly2 === ApprovalState.APPROVED ||
      approvalMaterialHoly2 === ApprovalState.PENDING ||
      approvalMaterialHoly3 === ApprovalState.APPROVED ||
      approvalMaterialHoly3 === ApprovalState.PENDING ||
      approvalMaterialHoly4 === ApprovalState.APPROVED ||
      approvalMaterialHoly4 === ApprovalState.PENDING ||
      approvalMaterialHoly5 === ApprovalState.APPROVED ||
      approvalMaterialHoly5 === ApprovalState.PENDING
    ) {
      set_show_remain_confirm_tx(false)
    }
  }, [
    approveOrbMaterial1,
    approveOrbMaterial2,
    approvalMaterialHoly1,
    approvalMaterialHoly2,
    approvalMaterialHoly3,
    approvalMaterialHoly4,
    approvalMaterialHoly5,
  ])

  const [myOrbsData, setMyOrbsData] = useState<any[]>([])
  const [myHolyData, setMyHolyData] = useState<any[]>([])

  const fetchAllHeroes = async (owner: any) => {
    const holy = await getHolyOfOwner(owner)
    setMyHolyData(holy)
    const all_orbs = await getOrbsIngame(owner)
    const fusionAvailable = await getOrbsFusionAvailable(owner)
    const orbs = all_orbs.filter((nft: any) => {
      const available = fusionAvailable.find((n: any) => n.nftId === nft.nftId)
      if (!available) return false
      return nft
    })
    const orbs_without_legend = orbs.filter((nft: any) => nft.rarity === 3 || nft.rarity === 4)
    setMyOrbsData(orbs_without_legend)
  }

  const getHolyOfOwner = async (owner: any) => {
    const url = `${HOLY_ENDPOINT.NFTS}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getHolyOfOwner', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const getOrbsIngame = async (owner: any) => {
    const url = ORBS_ENDPOINT.INGAME + owner
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        const all_orbs = response.data.data.orbs
        const nfts = all_orbs.filter((orb: any) => orb.nftId !== '')
        setMyOrbsData(nfts)
        return nfts
      }
    } catch (error: any) {
      console.error('getOrbsIngame', error)
    }
    return []
  }
  const getOrbsFusionAvailable = async (owner: any) => {
    const url = ORBS_ENDPOINT.FUSION_AVAILABLE + owner
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        const data = response.data.response
        return data
      }
    } catch (error: any) {
      console.error('getOrbsIngame', error)
    }
    return []
  }

  const OrbList = ({ nfts }: { nfts: any[] }) => {
    if (nfts.length === 0) {
      return (
        <EmptyNft>
          <div>No orbs available.</div>
        </EmptyNft>
      )
    }

    const available = nfts.filter((n: any) => n.status === 'unused')

    const Render_container = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
      const selectingOrbs = nfts.filter((nft: any) => nft.fusion_status && nft.fusion_status === 'selected')
      return (
        <NftGrid>
          {nfts.map((nft: any, index) => {
            if (!selectingOrbs || selectingOrbs?.length === 0) {
              return (
                <div key={index}>
                  <Card onClick={() => selectOrbMaterial(nft)}>
                    <PreviewOrb nft={nft} />
                  </Card>
                </div>
              )
            }
            const isSelected = nft.nftId === selectingOrbs[0]?.nftId || nft.nftId === selectingOrbs[1]?.nftId
            if (nft.rarity !== selectingOrbs[0].rarity) {
              return (
                <div key={index}>
                  <Card>
                    <Selected>{isSelected && <img style={{ width: 50 }} src={tickIcon} alt="" />}</Selected>
                    <PreviewOrb nft={nft} />
                  </Card>
                </div>
              )
            }
            return (
              <div key={index}>
                <Card onClick={() => selectOrbMaterial(nft)}>
                  {isSelected && (
                    <Selected>
                      <img style={{ width: 50 }} src={tickIcon} alt="" />
                    </Selected>
                  )}
                  <PreviewOrb nft={nft} />
                </Card>
              </div>
            )
          })}
        </NftGrid>
      )
    }

    return <>{Render_container({ nfts: available, title: 'Available' })}</>
  }

  const updateOrbCanFusion = (nfts: any, selecteds: any) => {
    const array = []
    for (const nft of nfts) {
      let nftRef = nft
      if (selecteds.includes(nftRef.nftId)) {
        nftRef = {
          ...nft,
          fusion_status: 'selected',
        }
      } else {
        nftRef = {
          ...nft,
          fusion_status: 'none',
        }
      }
      array.push(nftRef)
    }
    return array
  }

  const selectOrbMaterial = (nft: any) => {
    if (!nft) return
    const temp = listOrbsMaterial
    if (temp.includes(nft.nftId)) {
      const selectIndex = temp.indexOf(nft.nftId)
      temp.splice(selectIndex, 1)
    } else {
      if (temp.length === 2) return
      temp.push(nft.nftId)
    }
    setListOrbsMaterial(temp)
    const all_nfts = updateOrbCanFusion(myOrbsData, temp)
    setMyOrbsData(all_nfts)
  }

  function onSelectMaterialHero(nft: any, slot: string) {
    setShowSelectHolyForFusion(false)
    const refMaterialHeroSelected = {
      ...materialHolySelected,
    }
    refMaterialHeroSelected[slot] = nft
    setMaterialHolySelected(refMaterialHeroSelected)
  }

  const showSelectHolyWithSlot = (slot: string) => {
    setShowSelectHolyForFusion(true)
    setSelectingSlotHoly(slot)
  }

  const renderOrbMaterial = (selectingOrbs: any) => {
    const nft_1 = selectingOrbs[0]
    const nft_2 = selectingOrbs[1]
    return (
      <LineContent>
        <OneCard>
          {nft_1 ? (
            <>
              <PreviewOrb nft={nft_1} />
            </>
          ) : (
            <NFTCard>
              <TextIcon>+</TextIcon>
            </NFTCard>
          )}
          {nft_1 && approveOrbMaterial1 !== ApprovalState.APPROVED && !attemptingTxn && (
            <ActionButtons>
              {show_remain_confirm_tx ? (
                <HolySelectButton className="disable">
                  <Dots>Loading</Dots>
                </HolySelectButton>
              ) : (
                <HolySelectButton
                  onClick={() => {
                    set_show_remain_confirm_tx(true)
                    approvalOrbMaterial1Callback().catch(() => {
                      set_show_remain_confirm_tx(false)
                    })
                  }}
                  className={approveOrbMaterial1 === ApprovalState.PENDING ? 'disable' : ''}
                >
                  {approveOrbMaterial1 === ApprovalState.UNKNOWN ? (
                    <Dots>Waiting</Dots>
                  ) : approveOrbMaterial1 === ApprovalState.PENDING ? (
                    <Dots>Approving</Dots>
                  ) : (
                    <>Approve</>
                  )}
                </HolySelectButton>
              )}
            </ActionButtons>
          )}
        </OneCard>
        <OneCard>
          <TextIcon>+</TextIcon>
        </OneCard>
        <OneCard>
          {nft_2 ? (
            <PreviewOrb nft={nft_2} />
          ) : (
            <NFTCard>
              <TextIcon>+</TextIcon>
            </NFTCard>
          )}
          {nft_2 && approveOrbMaterial2 !== ApprovalState.APPROVED && !attemptingTxn && (
            <ActionButtons>
              {show_remain_confirm_tx ? (
                <HolySelectButton className="disable">
                  <Dots>Loading</Dots>
                </HolySelectButton>
              ) : (
                <HolySelectButton
                  onClick={() => {
                    set_show_remain_confirm_tx(true)
                    approvalOrbMaterial2Callback().catch(() => {
                      set_show_remain_confirm_tx(false)
                    })
                  }}
                  className={approveOrbMaterial2 === ApprovalState.PENDING ? 'disable' : ''}
                >
                  {approveOrbMaterial2 === ApprovalState.UNKNOWN ? (
                    <Dots>Waiting</Dots>
                  ) : approveOrbMaterial2 === ApprovalState.PENDING ? (
                    <Dots>Approving</Dots>
                  ) : (
                    <>Approve</>
                  )}
                </HolySelectButton>
              )}
            </ActionButtons>
          )}
        </OneCard>
      </LineContent>
    )
  }

  const selectingOrbs = myOrbsData.filter((n: any) => n.fusion_status && n.fusion_status === 'selected')

  const removeHolyMaterial = (slot: string) => {
    delete materialHolySelected[slot]
  }

  const renderHolyMaterialSelect = () => {
    const slots = ['1', '2', '3', '4', '5']
    return (
      <>
        {slots.map((slot: any, index: number) => {
          const available = slot === '1' ? true : materialHolySelected[slot - 1] ? true : false
          const approveHolyMaterial =
            slot === '1'
              ? approvalMaterialHoly1
              : slot === '2'
              ? approvalMaterialHoly2
              : slot === '3'
              ? approvalMaterialHoly3
              : slot === '4'
              ? approvalMaterialHoly4
              : approvalMaterialHoly5
          const approvalHolyMaterialCallback =
            slot === '1'
              ? approvalMaterialHoly1Callback
              : slot === '2'
              ? approvalMaterialHoly2Callback
              : slot === '3'
              ? approvalMaterialHoly3Callback
              : slot === '4'
              ? approvalMaterialHoly4Callback
              : approvalMaterialHoly5Callback
          return (
            <div style={{ height: '100%' }} key={index}>
              {materialHolySelected[slot] ? (
                <>
                  <OneSlots onClick={() => showSelectHolyWithSlot(slot)}>
                    <PreviewHoly nft={materialHolySelected[slot]} />
                    <ClearIcon onClick={() => removeHolyMaterial(slot)}>x</ClearIcon>
                  </OneSlots>
                  {approveHolyMaterial !== ApprovalState.APPROVED && !attemptingTxn && (
                    <>
                      {show_remain_confirm_tx ? (
                        <HolyPackId className="disable">Loading</HolyPackId>
                      ) : (
                        <HolyPackId
                          onClick={() => {
                            set_show_remain_confirm_tx(true)
                            approvalHolyMaterialCallback().catch(() => {
                              set_show_remain_confirm_tx(false)
                            })
                          }}
                          className={approveHolyMaterial === ApprovalState.PENDING ? 'disable' : ''}
                        >
                          {approveHolyMaterial === ApprovalState.UNKNOWN ? (
                            <Dots>Waiting</Dots>
                          ) : approveHolyMaterial === ApprovalState.PENDING ? (
                            <Dots>Approving</Dots>
                          ) : (
                            <> Approve #{materialHolySelected[slot].tokenId}</>
                          )}
                        </HolyPackId>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {available ? (
                    <OneSlots onClick={() => showSelectHolyWithSlot(slot)}>
                      <TextIcon style={{ fontSize: 80, paddingTop: 5 }}>+</TextIcon>
                    </OneSlots>
                  ) : (
                    <OneSlots style={{ opacity: '0.5' }}>
                      <TextIcon style={{ fontSize: 80, paddingTop: 5 }}>+</TextIcon>
                    </OneSlots>
                  )}
                </>
              )}
            </div>
          )
        })}
      </>
    )
  }

  const getCurrentHolyType = () => {
    if (materialHolySelected['1']) return materialHolySelected['1'].holyType
    if (materialHolySelected['2']) return materialHolySelected['2'].holyType
    if (materialHolySelected['3']) return materialHolySelected['3'].holyType
    if (materialHolySelected['4']) return materialHolySelected['4'].holyType
    if (materialHolySelected['5']) return materialHolySelected['5'].holyType
    return true
  }

  const renderClassRate = () => {
    const icons = [iconDPS, iconSupporter, iconTanker]
    const calculateRate = () => {
      const numberOfHolies = Object.keys(materialHolySelected).length
      if (numberOfHolies === 0) {
        return [33.33, 33.33, 33.33]
      }
      const holyType = getCurrentHolyType()

      if (holyType === 'red') {
        const rateOfThisType = 33.34 + 3 * numberOfHolies
        const other = (100 - rateOfThisType) / 2
        return [rateOfThisType, other.toFixed(2), other.toFixed(2)]
      }
      if (holyType === 'yellow') {
        const rateOfThisType = 33.34 + 3 * numberOfHolies
        const other = (100 - rateOfThisType) / 2
        return [other.toFixed(2), other.toFixed(2), rateOfThisType]
      }
      const rateOfThisType = 33.34 + 3 * numberOfHolies
      const other = (100 - rateOfThisType) / 2
      return [other.toFixed(2), rateOfThisType, other.toFixed(2)]
    }
    return (
      <>
        {icons.map((icon: any, index: number) => (
          <OneClassRate key={index}>
            <img src={icon} alt="" />
            <ClassRateValue>{calculateRate()[index]}%</ClassRateValue>
          </OneClassRate>
        ))}
      </>
    )
  }

  const calculateFusionSuccessRate = () => {
    const type = selectingOrbs
    const this_rarity = type[0].rarity
    let bonus = 0
    let rate = 0
    if (this_rarity === 4) {
      bonus = Object.keys(materialHolySelected).length * 2
      rate = 15 + bonus
    } else {
      bonus = Object.keys(materialHolySelected).length * 8
      rate = 40 + bonus
    }
    return (
      <>
        Fusion success rate: {rate}% {bonus > 0 && `(${bonus}% from HolyPack)`}
      </>
    )
  }

  const calculateFusionFee = () => {
    const type = selectingOrbs
    const this_rarity = type[0].rarity
    if (this_rarity === 4) return 2000
    return 250
  }

  const decode_log = async (logs: any) => {
    const log = logs[logs.length - 1]
    const inputs = [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'isSuccess', type: 'bool' },
      { indexed: false, internalType: 'uint256[]', name: 'orbIds', type: 'uint256[]' },
      { indexed: false, internalType: 'uint256', name: 'newOrbId', type: 'uint256' },
    ]

    const decoded = web3.eth.abi.decodeLog(inputs, log.data, [log.topics[1]])
    const orbId = decoded.newOrbId
    const isSuccess = decoded.isSuccess
    return {
      orbId,
      isSuccess: isSuccess.toString(),
    }
  }

  const getOrbById = async (heroId: any) => {
    const url = `${HERO_MARKET_SERVER}/plant-empire/game/orbs/${heroId}`
    try {
      const response = await axios.get(url)
      if (response.status !== 200 || !response.data || response.data.response === null) return undefined
      return response.data.response
    } catch (error: any) {
      console.error(error)
      return undefined
    }
  }

  const onOrbFusion = () => {
    console.log('on orb fusion')
    setAttemptingTxn(true)
    if (!orbFusion || listOrbsMaterial.length === 0) {
      setAttemptingTxn(false)
      NotificationManager.error('Please try again later.', 'Error', 2000)
      return
    }
    const heroIds = listOrbsMaterial
    const holyPackageIds: any[] = []
    for (const holy of Object.values(materialHolySelected)) {
      if (!holy) return
      // @ts-ignore
      holyPackageIds.push(holy.tokenId)
    }

    const method: (...args: any) => Promise<TransactionResponse> = orbFusion.fusion
    const args: Array<string | string[] | number> = [heroIds, holyPackageIds]
    console.log('args: ', args)
    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        console.log('txHash', txFull.transactionHash)
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        const logs = txFull.logs
        const this_contract_log = logs.filter((log: any) => log.address === ORB_FUSION_ADDRESS[chainId || 56])
        const fusion_result = await decode_log(this_contract_log)
        const interval = setInterval(async () => {
          const plant = await getOrbById(fusion_result.orbId)
          if (!plant) return
          setAttemptingTxn(false)
          await fetchAllHeroes(account)
          setPlantFusionResult({
            showPlantFusionResult: true,
            plantResult: plant,
            plantFusionResult: fusion_result.isSuccess === 'true' ? true : false,
          })
          clearInterval(interval)
        }, 3000)
      })
      .catch((error) => {
        setAttemptingTxn(false)
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const enableFusionButton = () => {
    if (approveOrbMaterial1 === ApprovalState.NOT_APPROVED || approveOrbMaterial1 === ApprovalState.PENDING) {
      return false
    }
    if (approveOrbMaterial2 === ApprovalState.NOT_APPROVED || approveOrbMaterial2 === ApprovalState.PENDING) {
      return false
    }
    if (materialHolySelected['1']) {
      if (approvalMaterialHoly1 === ApprovalState.NOT_APPROVED || approvalMaterialHoly1 === ApprovalState.PENDING) {
        return false
      }
    }
    if (materialHolySelected['2']) {
      if (approvalMaterialHoly2 === ApprovalState.NOT_APPROVED || approvalMaterialHoly2 === ApprovalState.PENDING) {
        return false
      }
    }
    if (materialHolySelected['3']) {
      if (approvalMaterialHoly3 === ApprovalState.NOT_APPROVED || approvalMaterialHoly3 === ApprovalState.PENDING) {
        return false
      }
    }
    if (materialHolySelected['4']) {
      if (approvalMaterialHoly4 === ApprovalState.NOT_APPROVED || approvalMaterialHoly4 === ApprovalState.PENDING) {
        return false
      }
    }
    if (materialHolySelected['5']) {
      if (approvalMaterialHoly5 === ApprovalState.NOT_APPROVED || approvalMaterialHoly5 === ApprovalState.PENDING) {
        return false
      }
    }
    return true
  }

  const renderResult = () => {
    const type = selectingOrbs
    if (!type[0]) {
      return (
        <NFTCard style={{ cursor: 'auto', background: '#182f46' }}>
          <TextIcon>?</TextIcon>
        </NFTCard>
      )
    }
    const this_rarity = type[0].rarity
    if (this_rarity === 4) {
      return (
        <NFTCard style={{ cursor: 'auto', backgroundImage: `url(${BgLegend}`, backgroundSize: 'contain' }}>
          <TextIcon>?</TextIcon>
        </NFTCard>
      )
    }
    return (
      <NFTCard style={{ cursor: 'auto', backgroundImage: `url(${BgMythical}`, backgroundSize: 'contain' }}>
        <TextIcon>?</TextIcon>
      </NFTCard>
    )
  }

  return (
    <PageContainer className="main-content">
      <NFTArea>
        <FusionContainer>
          <SelectNFTs>
            <LineContent>
              <div></div>
              <OneCard>{renderResult()}</OneCard>
              <div></div>
            </LineContent>
            <>{renderOrbMaterial(selectingOrbs)}</>
            {selectingOrbs.length === 2 ? (
              <>
                <FusionInformation>
                  <TitleInfo>Helps increase success rate (optional)</TitleInfo>
                  <HolySlots>{renderHolyMaterialSelect()}</HolySlots>
                  <TitleInfo>Random class rate:</TitleInfo>
                  <ClassRate>{renderClassRate()}</ClassRate>
                </FusionInformation>
                <TitleInfo>{calculateFusionSuccessRate()}</TitleInfo>
                <TitleInfo>
                  Fee: {calculateFusionFee()}
                  <img src={PEFITokenLogo} style={{ height: 20, marginLeft: 5 }} />
                </TitleInfo>
                <ActionButtons>
                  {approveOrbFusionUsePefi !== ApprovalState.APPROVED ? (
                    <HolySelectButton
                      onClick={approveOrbFusionUsePefiCallback}
                      className={
                        approveOrbFusionUsePefi === ApprovalState.UNKNOWN ||
                        approveOrbFusionUsePefi === ApprovalState.PENDING ||
                        approveOrbFusionUsePefi !== ApprovalState.NOT_APPROVED
                          ? 'disable'
                          : ''
                      }
                    >
                      {approveOrbFusionUsePefi === ApprovalState.UNKNOWN ? (
                        <Dots>Waiting</Dots>
                      ) : approveOrbFusionUsePefi === ApprovalState.PENDING ? (
                        <Dots>Approving</Dots>
                      ) : (
                        <>Approve PEFI</>
                      )}
                    </HolySelectButton>
                  ) : (
                    <>
                      {enableFusionButton() ? (
                        <HolySelectButton
                          onClick={() => {
                            if (attemptingTxn) return
                            onOrbFusion()
                          }}
                          className={attemptingTxn ? 'disable' : ''}
                        >
                          {attemptingTxn ? <Dots>Loading</Dots> : <>Fusion</>}
                        </HolySelectButton>
                      ) : (
                        <HolySelectButton className="disable">{'Fusion'}</HolySelectButton>
                      )}
                    </>
                  )}
                </ActionButtons>
              </>
            ) : (
              <ActionButtons>
                <HolySelectButton className="disable">{'Fusion'}</HolySelectButton>
              </ActionButtons>
            )}
          </SelectNFTs>
          <HolyBonus>
            <HeaderNavbar>
              <NavItem onClick={() => setPageState('plant')}>PLANTS</NavItem>
              <NavItem className={`active`}>ORBS</NavItem>
            </HeaderNavbar>
            <NFTArea style={{ padding: 20 }}>
              <OrbList nfts={myOrbsData} />
            </NFTArea>
          </HolyBonus>
        </FusionContainer>
      </NFTArea>

      <SelectHolyForFusionModal
        isOpen={showSelectHeroForUpgradeStar}
        materialHolySelected={materialHolySelected}
        slot={selectingSlotHeroForUpgradeStar}
        onConfirm={onSelectMaterialHero}
        onDismiss={() => setShowSelectHolyForFusion(false)}
        nfts={Object.values(myHolyData).filter((holy: any) => holy.holyType !== 'blue')}
      />

      {showPlantFusionResult && (
        <ROFIAccountModal
          title={plantFusionResult ? 'CONGRATULATION' : 'FAILED'}
          visible={showPlantFusionResult}
          onCancel={() => {
            setPlantFusionResult({
              showPlantFusionResult: false,
              plantResult: undefined,
              plantFusionResult: false,
            })
          }}
          footer={null}
        >
          {plantResult ? (
            <>
              <FlexLine>
                <div style={{ maxWidth: 240 }}>
                  <PreviewFull nft={plantResult} />
                </div>
              </FlexLine>
              <FlexLine>
                <CardBody>
                  <PropertyRow>
                    <Property>
                      <PropertyName>Rarity:&nbsp;</PropertyName>
                      <PropertyName style={{ color: '#9ce315' }}>{getHeroRarity(plantResult.rarity)}</PropertyName>
                    </Property>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>|</div>
                    <Property>
                      <PropertyName>Class:&nbsp;</PropertyName>
                      <PropertyName style={{ color: '#9ce315' }}>
                        {getHeroClassName(plantResult.classType)}
                      </PropertyName>
                    </Property>
                  </PropertyRow>
                </CardBody>
              </FlexLine>
              <FlexLine>
                <HolySelectButton
                  onClick={() => {
                    setPlantFusionResult({
                      showPlantFusionResult: false,
                      plantResult: undefined,
                      plantFusionResult: false,
                    })
                  }}
                >
                  GOT IT
                </HolySelectButton>
              </FlexLine>
            </>
          ) : (
            <FlexLine>
              <Dots>Loading data</Dots>
            </FlexLine>
          )}
        </ROFIAccountModal>
      )}
    </PageContainer>
  )
}
