import styled from 'styled-components'

interface TextProps {
  fontWeight?: number
  fontSize?: number
  margin?: string
  color?: string
}

const Text = styled.div<TextProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '18px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  font-family: Gudea-Medium;
  color: ${({ color }) => (color ? `${color}` : '#fff8e8')};
  margin: ${({ margin }) => (margin ? `${margin}` : '6px 0px')};
`

export const MagraText = styled.div<TextProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '18px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  font-family: Montio;
  color: #fff8e8;
  margin: ${({ margin }) => (margin ? `${margin}` : '6px 0px')};
`
export default Text
