import React, { ReactNode } from 'react'
import { RowBetween } from '../../Row'
import { CloseIcon } from '../../../theme'
import styled from 'styled-components'
import { AutoColumn } from '../../Column'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `}
`
const Item = styled.div`
  flex: 1;
  padding: 0px 30px 30px 30px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0;
  `}
`
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Divider = styled.div`
  height: 3px;
  width: 100%;
  background-color: #41393e;
`
const Action = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled(RowBetween)`
  position: relative;
`

export default function MateModalContent({
  onDismiss,
  content,
}: {
  onDismiss: () => void
  content: () => ReactNode | undefined
}) {
  return (
    <Wrapper>
      <Item>{content()}</Item>
    </Wrapper>
  )
}
