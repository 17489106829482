const Spin1 = 'https://plantempires-media.b-cdn.net/shop/farm/pack_spin1.png'
const Spin2 = 'https://plantempires-media.b-cdn.net/shop/farm/pack_spin2.png'
const Spin3 = 'https://plantempires-media.b-cdn.net/shop/farm/pack_spin3.png'
const Water1 = 'https://plantempires-media.b-cdn.net/shop/farm/pack_water1.png'
const Water2 = 'https://plantempires-media.b-cdn.net/shop/farm/pack_water2.png'
const Water3 = 'https://plantempires-media.b-cdn.net/shop/farm/pack_water3.png'

export const PACKS = [
  {
    pack_id: 25,
    ingame_pack_id: 'spinTurn1',
    pack_name: 'spinTurn1',
    number_of_items: 0,
    pefi_price: 500,
    pack_img: Spin1,
    discount: 50,
  },
  {
    pack_id: 26,
    ingame_pack_id: 'spinTurn2',
    pack_name: 'spinTurn2',
    number_of_items: 0,
    pefi_price: 1250,
    pack_img: Spin2,
    discount: 50,
  },
  {
    pack_id: 27,
    ingame_pack_id: 'spinTurn3',
    pack_name: 'spinTurn3',
    number_of_items: 0,
    pefi_price: 2500,
    pack_img: Spin3,
    discount: 50,
  },
  {
    pack_id: 28,
    ingame_pack_id: 'water1',
    pack_name: 'water1',
    number_of_items: 0,
    pefi_price: 5000,
    pack_img: Water1,
    discount: 50,
  },
  {
    pack_id: 29,
    ingame_pack_id: 'water2',
    pack_name: 'water2',
    number_of_items: 0,
    pefi_price: 12500,
    pack_img: Water2,
    discount: 50,
  },
  {
    pack_id: 30,
    ingame_pack_id: 'water3',
    pack_name: 'water3',
    number_of_items: 0,
    pefi_price: 20000,
    pack_img: Water3,
    discount: 50,
  },
]
