import React from 'react'
import PEFI from './PEFI'
import UnAuthorized from './UnAuthorized'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useActiveWeb3React } from 'hooks/web3'

export default function MyCharacter({ setPageState }: { setPageState: (state: string) => void }) {
  const { account } = useActiveWeb3React()
  // const server = localStorage.getItem('server')
  // if (!server) return <Redirect to={{ ...location, pathname: '/' }} />
  return account ? <PEFI /> : <UnAuthorized />
}
