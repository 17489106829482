import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { RPC_ENDPOINT_MAINNET } from 'constants/endpoints'
import PANCAKE_PAIR from 'abis/pancake-pair.json'

export async function getPEFiPrice() {
  try {
    const PEFI_BUSD_ADDRESS = '0xed93ef24c9e1d9f6afa2b4810ea3ab311973645e'
    const PEFI_TOKEN_ADDRESS = '0xa83Bfcf9E252Adf1F39937984A4E113Eda6E445b'
    const web3 = new Web3(RPC_ENDPOINT_MAINNET || 'https://bsc-dataseed1.binance.org')
    const contract = new web3.eth.Contract(PANCAKE_PAIR as AbiItem[], PEFI_BUSD_ADDRESS)
    const token_0_address = await contract.methods.token0().call()
    const reserver = await contract.methods.getReserves().call()
    const amount_0 = reserver._reserve0
    const amount_1 = reserver._reserve1
    if (token_0_address === PEFI_TOKEN_ADDRESS) {
      return (amount_1 / amount_0).toFixed(3)
    }
    return (amount_0 / amount_1).toFixed(3)
  } catch (error) {
    console.error('error', error)
    return null
  }
}
