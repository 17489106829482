import React, { ReactNode } from 'react'
import { RowBetween } from 'components/Row'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import Close from 'assets/pefi/special-pack/close-button.png'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #026092;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    height: 100%;
  `}
`

const ModalTitle = styled.div`
  background: #0099e2;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `}
`

const LeftSection = styled(AutoColumn)<{ inline?: boolean }>`
  flex: 1;
  padding-right: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `}
`
const RightSection = styled(LeftSection)`
  padding: 20px;
  flex: 2;
  height: 100%;
  background-color: #01517a;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    margin-top: 20px;
    padding: 20px;
  `}
`

const Container = styled(RowBetween)`
  position: relative;
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`

export default function BuyModalContent({
  rightContent,
  onDismiss,
  leftContent,
}: {
  onDismiss: () => void
  leftContent: () => ReactNode | undefined
  rightContent: () => ReactNode | undefined
}) {
  return (
    <Wrapper>
      <ModalTitle>DETAILS</ModalTitle>
      <ModalContent>
        <LeftSection>{leftContent()}</LeftSection>
        <RightSection gap="12px">{rightContent()}</RightSection>
      </ModalContent>
      <CloseIcon src={Close} onClick={onDismiss} />
    </Wrapper>
  )
}
