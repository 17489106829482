/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import PE_PLANT_EVOLVE_ABI from 'abis/pe-plant-evolve-abi.json'
import PE_PLANT_HERO_MARKET_ABI from 'abis/pe-plant-hero-market-abi.json'
import button_sort from 'assets/images/button-sort.png'
import buttonCancel from 'assets/pefi/my-assets/cancel-button.png'
import buttonCancelling from 'assets/pefi/my-assets/cancelling-button.png'
import buttonGift from 'assets/pefi/my-assets/gift-button.png'
import buttonSell from 'assets/pefi/my-assets/sell-button.png'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import buttonUpdate from 'assets/pefi/my-assets/update-price-button.png'
import axios from 'axios'
import BigNumber from 'bignumber.js'
// import FilterModal from 'components/FilterModal'
import { Modal } from 'antd'
import Preview from 'components/PreviewCard/PlantCardPreview/Preview'
import Filter from 'components/Filters/HeroFilter/Filter'
import DetailModal from 'components/MyAssets/Plant/DetailModal/DetailModal'
import GiftModal from 'components/MyAssets/Plant/GiftModal'
import SelectHolyForUpgradeStarModal from 'components/MyAssets/Plant/Evolve/SelectHolyForEvolveModal'
import UpgradeResultModal from 'components/MyAssets/Plant/Evolve/EvolveResultModal'
import UpgradeStarModal from 'components/MyAssets/Plant/Evolve/EvolveModal'
import SellModal from 'components/MyAssets/Plant/Sell/SellModal'
import UpdatePriceModal from 'components/MyAssets/Plant/Sell/UpdatePriceModal'
import Text, { MagraText } from 'components/Text'
import { TextInput } from 'components/TextInput'
import {
  PE_PLANT_EVOLVE_ADDRESS,
  PE_PLANT_HERO_MARKET_ADDRESS,
  PE_PLANT_HERO_NFT_ADDRESS,
  S2_PLANT_EVOLVE_ADDRESS,
  S2_PLANT_HERO_MARKET_ADDRESS,
  S2_PLANT_HERO_NFT_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { PLANT_ENDPOINT, HOLY_ENDPOINT } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft, PEFilterConditions } from 'constants/types'
import {
  usePlantEvolveContract,
  usePlantHeroMarketContract,
  usePlantHeroNFTContract,
  usePlantHeroMarketContractS2,
  usePlantHeroNFTContractS2,
  usePlantEvolveContractS2,
} from 'hooks/useContract'
import useFetchNfts from 'hooks/useFetchNfts'
import { useActiveWeb3React } from 'hooks/web3'
import countBy from 'lodash/countBy'
import includes from 'lodash/includes'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { getDecimalAmount } from 'utils/formatBalance'
import { getHeroClassName, getHeroRarity, check_can_be_evolve } from 'utils/getPlantHeroDetail'
import paginate from 'utils/paginate'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import NftGrid from './NftGrid'
import Pagination from '../Pagination'
import button_info from 'assets/pefi/arrow-up.png'
export interface NftCardProps {
  nft: Nft
  refresh: () => void
}

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const NUMBER_PER_PAGE = 12
const TabWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 50% 30%;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    width: 50%;
    margin: 0 auto;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
  `};
`
const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin-bottom: 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
    margin: 10px auto;
  `};
`

const PropertyName = styled(Text)`
  font-weight: 600;
  font-size: 15px;
  padding-right: 5px;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 15px;
  `};
`
const FilterPanel = styled.div`
  flex: 1;
  background: #14324c50;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
  padding: 30px;
`
const General = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

const NFTArea = styled.div`
  flex: 4;
  padding: 30px;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const FilterTitle = styled.div`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #fff;
`

const Card = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    padding: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 20px;
  `};
`

const CardBody = styled.div`
  overflow: hidden;
  padding-bottom: 30px;
`

const Select = styled.select`
  background: transparent url(${button_sort}) no-repeat 16px;
  appearance: none;
  outline: none;
  flex: 1;
  background-position: 95%;
  font-family: Gudea-Medium;
  font-weight: 400;
  font-size: 24px;
  color: #fff8e8;
  border: 2px solid #999999;
  border-radius: 5px;
  width: 90%;
  padding: 7px 20px;
  height: 40px;
  :after {
    borr: solid rgba(245, 199, 22, 0.18);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin-bottom: 10px;
  `};
`

const Option = styled.option`
  background-color: #121212;
  font-family: Gudea-Medium;
  font-weight: 400;
  border-radius: 5px;
  font-size: 24px;
  color: #fff8e8;
  padding: 7px 20px;
  margin-top: 10px;
`

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const PropertyRow = styled(Row)`
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 0px 5px;
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const Property = styled.div`
  display: flex;
  align-items: center;
`

const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: contain;
  background-repeat: no-repeat;
`
const FarmingStatus = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 39px;
  width: 100%;
  align-self: center;
  border-radius: 10px;
  border: 2px solid #f4c22c;
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const ButtonDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #03c5ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 0;
  ${({ theme }) => theme.mediaWidth.upTo1600`
    font-size: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
  `};
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background: transparent;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
`

const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  cursor: pointer;
`

const ButtonSell = styled.img`
  cursor: pointer;
  width: 100%;
  height: auto;
`

const ListHeroContainer = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #14324c50;
  border-radius: 10px;
`

const StatusTitle = styled.div`
  margin-bottom: 25px;
  font-size: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid #cecece;
`

const InfoIcon = styled.img`
  height: 20px;
  margin-left: 5px;
  position: relative;
  cursor: pointer;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  &:hover {
    + .tips {
      display: block;
    }
  }
`

const Tips = styled.div`
  display: none;
  position: absolute;
  top: -60px;
  right: 0;
  color: #fff;
  width: 150px;
  background: #121212;
  z-index: 2;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`

const FilterModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609299 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

export default function Heroes() {
  // Define state
  const [{ showConfirm, buyErrorMessage, nftDetailSelected }, setShowDetail] = useState<{
    showConfirm: boolean
    buyErrorMessage: string | undefined
    nftDetailSelected: Nft | undefined
  }>({
    showConfirm: false,
    buyErrorMessage: undefined,
    nftDetailSelected: undefined,
  })
  const [{ showSell, sellErrorMessage, nftSellSelected }, setSellState] = useState<{
    showSell: boolean
    sellErrorMessage: string | undefined
    nftSellSelected: Nft | undefined
  }>({
    showSell: false,
    sellErrorMessage: undefined,
    nftSellSelected: undefined,
  })
  const [cannotSell, setCannotSell] = useState<boolean>(false)

  const [{ showUpdatePrice, nftUpdateSelected }, setUpdatePriceState] = useState<{
    showUpdatePrice: boolean
    nftUpdateSelected: Nft | undefined
  }>({
    showUpdatePrice: false,
    nftUpdateSelected: undefined,
  })
  const [{ showUpgradeStar, upgradeStarErrorMessage, plantSelected, materialHolySelected }, setUpgradeStar] = useState<{
    showUpgradeStar: boolean
    upgradeStarErrorMessage: string | undefined
    plantSelected: Nft | undefined
    materialHolySelected: any
  }>({
    showUpgradeStar: false,
    upgradeStarErrorMessage: undefined,
    plantSelected: undefined,
    materialHolySelected: {},
  })
  const [{ upgradeResult, nftResult, showUpgradeResult }, setUpgradeResult] = useState<{
    upgradeResult: boolean
    nftResult: Nft | undefined
    showUpgradeResult: boolean
  }>({
    upgradeResult: false,
    nftResult: undefined,
    showUpgradeResult: false,
  })
  const [{ showGift, giftErrorMessage, nftGiftSelected }, setGiftState] = useState<{
    showGift: boolean
    giftErrorMessage: string | undefined
    nftGiftSelected: Nft | undefined
  }>({
    showGift: false,
    giftErrorMessage: undefined,
    nftGiftSelected: undefined,
  })
  const [orderCanceling, setOrderCanceling] = useState<{
    tokenId: string | undefined
    isCanceling: boolean
  }>({
    tokenId: undefined,
    isCanceling: false,
  })

  const [showSelectHeroForUpgradeStar, setShowSelectHeroForUpgradeStar] = useState<boolean>(false)
  const [selectingSlotHeroForUpgradeStar, setSelectingSlotHeroForUpgradeStar] = useState<string>('0')
  const [nfts, setNfts] = useState<Nft[]>([])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  const fetchNfts = useFetchNfts()
  const { chainId, account } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18

  const server = localStorage.getItem('server')

  const marketS1 = usePlantHeroMarketContract()
  const marketS2 = usePlantHeroMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const heroNftContractS1 = usePlantHeroNFTContract()
  const heroNftContractS2 = usePlantHeroNFTContractS2()
  const heroNftContract = server ? (server === 's1' ? heroNftContractS1 : heroNftContractS2) : heroNftContractS1

  const evolveContractS1 = usePlantEvolveContract()
  const evolveContractS2 = usePlantEvolveContractS2()
  const evolveContract = server ? (server === 's1' ? evolveContractS1 : evolveContractS2) : evolveContractS1

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const EVOLVE_ADDRESS = server
    ? server === 's1'
      ? PE_PLANT_EVOLVE_ADDRESS
      : S2_PLANT_EVOLVE_ADDRESS
    : PE_PLANT_EVOLVE_ADDRESS

  const evolveCTF = new web3.eth.Contract(
    PE_PLANT_EVOLVE_ABI as AbiItem[],
    chainId ? EVOLVE_ADDRESS[chainId] : '0xd44598A5E7089ae600ccAed6AffEc79BA14368c0'
  )

  const PLANT_MARKET_ADDRESS = server
    ? server === 's1'
      ? PE_PLANT_HERO_MARKET_ADDRESS
      : S2_PLANT_HERO_MARKET_ADDRESS
    : PE_PLANT_HERO_MARKET_ADDRESS

  const marketCTF = new web3.eth.Contract(
    PE_PLANT_HERO_MARKET_ABI as AbiItem[],
    chainId ? PLANT_MARKET_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )

  const nftAddress = server
    ? server === 's1'
      ? PE_PLANT_HERO_NFT_ADDRESS[56]
      : S2_PLANT_HERO_NFT_ADDRESS[56]
    : PE_PLANT_HERO_NFT_ADDRESS[56]

  useEffect(() => {
    fetchAllHeroes(account)
    getNextSuccessPercent()
  }, [account, plantSelected])

  const [myNftsData, setMyNftsData] = useState<any[]>([])
  const [myHolyData, setMyHolyData] = useState<any[]>([])
  const [availableHeroes, setAvailableHeroes] = useState<any[]>([])
  const [sellingHeroes, setSellingHeroes] = useState<any[]>([])
  const [randomRate, setRandomRate] = useState<any>(0)

  const fetchAllHeroes = async (owner: any) => {
    const nfts = await getNftsOfOwner(owner)
    const nfts_update_detail = updateHeroDetailName(nfts)
    const nfts_update_inFarm = await updateIsInFarm(nfts_update_detail)
    const available = nfts_update_inFarm.filter((nft: any) => nft.status === 'Available' && nft.star > 1)
    const selling = nfts_update_inFarm.filter((nft: any) => nft.status === 'Selling')
    const nfts_update_price = await updatePrice(selling)
    const all_heroes = available.concat(nfts_update_price)
    setMyNftsData(all_heroes)
    setAvailableHeroes(available)
    setSellingHeroes(nfts_update_price)

    const holy = await getHolyOfOwner(owner)
    setMyHolyData(holy)
  }

  const isInFarm = async (nftId: any) => {
    const url = `${PLANT_ENDPOINT.NFT_DETAIL}/${nftId}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.response
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      // NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const updateIsInFarm = async (nfts: any) => {
    const array = []
    for (const nft of nfts) {
      let nftRef = nft
      const nftDetail = await isInFarm(nft.tokenId)
      const inFarm = nftDetail.inFarm
      nftRef = {
        ...nft,
        inFarm: inFarm,
      }
      array.push(nftRef)
    }
    return array
  }

  const updateHeroDetailName = (nfts: any) => {
    const array = []
    for (const nft of nfts) {
      let nftRef = nft
      const rarityName = getHeroRarity(nft.rarity)
      const className = getHeroClassName(nft.classType)
      nftRef = {
        ...nft,
        rarityName: rarityName,
        className: className,
      }
      array.push(nftRef)
    }
    return array
  }

  const getHolyOfOwner = async (owner: any) => {
    const url = `${HOLY_ENDPOINT.NFTS}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const getNextSuccessPercent = async () => {
    if (!evolveCTF || !plantSelected) return undefined
    const heroId = plantSelected.tokenId
    const next = await evolveCTF.methods.getNextSuccessPercent(heroId).call()
    setRandomRate(next)
    return
  }

  const updateStatusUpgradeable = (nfts: any) => {
    const array = []
    for (const nft of nfts) {
      let nftRef = nft
      if (nft.status === 'Available' && nft.level === 30) {
        nftRef = {
          ...nft,
          status: 'Upgradeable',
        }
      }
      array.push(nftRef)
    }
    return array
  }

  const updatePrice = async (sortedMyNftsData: any) => {
    const array = []
    for (const nft of sortedMyNftsData) {
      const price = await getPriceInMarket(nft)
      const nftRef = {
        ...nft,
        price: price,
      }
      array.push(nftRef)
    }
    return array
  }

  const getPriceInMarket = async (nft: any) => {
    // const useCTF = nft.isOnOldMarket ? oldMarketCTF : marketCTF
    const useCTF = marketCTF
    if (!useCTF || nft.status !== 'Selling') return undefined
    const saleInfo = await useCTF.methods.getSale(nftAddress, Number(nft.tokenId)).call()
    return Number(saleInfo.price / 10 ** 18).toFixed(2)
  }

  const getNftsOfOwner = async (owner: any) => {
    const url = `${PLANT_ENDPOINT.NFTS}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        setAvailableHeroes(response.data.data)
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      // NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const [statusHeroType, setStatusHeroType] = useState<string>('')
  const [filter, setFilter] = useState<PEFilterConditions>({
    classNames: [],
    rarityNames: [],
    star: { from: 0, to: 0 },
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredOrders = useMemo(() => {
    if (
      filter.classNames.length === 0 &&
      filter.rarityNames.length === 0 &&
      filter.star.from === 0 &&
      filter.star.to === 0 &&
      searchValue === ''
    ) {
      return myNftsData
    }
    if (searchValue !== '') {
      return myNftsData.filter((order) => {
        return !searchValue
          ? true
          : new RegExp(searchValue.trim(), 'ig').test(order.detail.name) ||
              new RegExp(searchValue.trim(), 'ig').test(order.tokenId)
      })
    }
    return myNftsData.filter((order) => {
      return (
        (filter.classNames.length > 0 ? includes(filter.classNames, order.className) : true) &&
        (filter.rarityNames.length > 0 ? includes(filter.rarityNames, order.rarityName) : true) &&
        filter.star.from <= order.star &&
        filter.star.to >= order.star &&
        (!statusHeroType || order.status === statusHeroType)
      )
    })
  }, [myNftsData, filter, statusHeroType, searchValue])
  // const [currentPage, setCurrentPage] = useState<number>(1)
  // const currentOrderList = useMemo(
  //   () => paginate(filteredOrders, NUMBER_PER_PAGE, currentPage),
  //   [filteredOrders, currentPage]
  // )

  useEffect(() => {
    ;(async () => {
      setNfts(filteredOrders)
    })()
  }, [filteredOrders])

  const {
    Available: totalAvailable = 0,
    Selling: totalSelling = 0,
    Mating: totalMating = 0,
  } = useMemo(() => countBy(myNftsData, 'status'), [myNftsData])

  const renderHeroState = (nft: any) => {
    const { status, price, lockToTime, inFarm } = nft
    if (inFarm) {
      return (
        <FarmingStatus>
          <Text fontSize={20} fontWeight={700} color={'#fff'}>
            FARMING
          </Text>
        </FarmingStatus>
      )
    }
    const isLockIngame = lockToTime ? Date.parse(lockToTime) > Date.now() : false
    switch (status) {
      case 'Available':
      case 'Upgradeable':
        return (
          <Row>
            <ButtonSell
              src={buttonSell}
              onClick={() => {
                if (isLockIngame) return
                setSellState({
                  sellErrorMessage: undefined,
                  showSell: true,
                  nftSellSelected: nft,
                })
              }}
              style={{ opacity: isLockIngame ? '0.3' : '1' }}
            />
            <ButtonSell
              src={buttonGift}
              onClick={() => {
                setGiftState({
                  giftErrorMessage: undefined,
                  showGift: true,
                  nftGiftSelected: nft,
                })
                // NotificationManager.info('Coming soon', 'Maintenance', 2000)
              }}
            />
          </Row>
        )
      case 'Selling':
        return (
          <>
            <SellingStatus>
              <Text fontSize={20} fontWeight={700} color={'#000'}>
                {price && (
                  <span>
                    {/* <TokenLogo width="20px" src={PEFiToken} /> */}
                    {price} BUSD
                  </span>
                )}
              </Text>
            </SellingStatus>
            <Row>
              <ButtonSell
                src={buttonUpdate}
                onClick={() => {
                  setUpdatePriceState({
                    showUpdatePrice: true,
                    nftUpdateSelected: nft,
                  })
                }}
              />
              {orderCanceling.tokenId === nft.tokenId && orderCanceling.isCanceling ? (
                <ButtonSell src={buttonCancelling} />
              ) : (
                <ButtonSell src={buttonCancel} onClick={() => onCancelOrderClick(nft)} />
              )}
            </Row>
          </>
        )
      default:
        return null
    }
  }

  const NftCard = ({ nft }: NftCardProps) => {
    const { status, rarityName, className } = nft
    const can_be_evolve = check_can_be_evolve(nft)
    return (
      <Card>
        <div style={{ display: 'flex', position: 'relative' }}>
          <Preview nft={nft} />
        </div>
        <CardBody>
          <PropertyRow>
            <Property>
              <PropertyName>Rarity:&nbsp;</PropertyName>
              <PropertyName style={{ color: '#9ce315' }}>{rarityName}</PropertyName>
            </Property>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>|</div>
            <Property>
              <PropertyName>Class:&nbsp;</PropertyName>
              <PropertyName style={{ color: '#9ce315' }}>{className}</PropertyName>
            </Property>
          </PropertyRow>
          <>{renderHeroState(nft)}</>
        </CardBody>
        <ButtonDetails
          onClick={() => {
            setShowDetail({
              buyErrorMessage: undefined,
              showConfirm: true,
              nftDetailSelected: nft,
            })
          }}
        >
          DETAILS
          {can_be_evolve && (
            <span>
              <InfoIcon src={button_info} />
              <Tips className="tips">Plant can be evolve</Tips>
            </span>
          )}
        </ButtonDetails>
      </Card>
    )
  }

  const [txHash, setTxHash] = useState<string>('')

  const handleConfirmDismiss = useCallback(() => {
    setShowDetail({ showConfirm: false, buyErrorMessage, nftDetailSelected: undefined })
  }, [buyErrorMessage, txHash])

  const handleSellDismiss = useCallback(() => {
    setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
  }, [buyErrorMessage, txHash])

  const handleUpdatePriceDismiss = useCallback(() => {
    setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
  }, [])

  const handleUpgradeStarDismiss = useCallback(() => {
    setUpgradeStar({
      showUpgradeStar: false,
      upgradeStarErrorMessage,
      plantSelected: undefined,
      materialHolySelected: undefined,
    })
  }, [upgradeStarErrorMessage, txHash])

  const handleUpgradeResultDismiss = () => {
    if (upgradeResult) {
      setUpgradeStar({
        showUpgradeStar: false,
        upgradeStarErrorMessage,
        plantSelected: undefined,
        materialHolySelected: undefined,
      })
    } else {
      setUpgradeStar({
        upgradeStarErrorMessage: undefined,
        showUpgradeStar: true,
        plantSelected: plantSelected,
        materialHolySelected: undefined,
      })
    }
    setUpgradeResult({
      upgradeResult: false,
      nftResult: undefined,
      showUpgradeResult: false,
    })
    setAttemptingTxn(false)
  }

  async function onSellConfirm(price: string) {
    console.log('on sell item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftSellSelected) return
    const method: (...args: any) => Promise<TransactionResponse> = market.placeOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSellSelected.tokenId, decimal_price]

    setAttemptingTxn(true)
    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        console.log('txHash', txFull.transactionHash)
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        await fetchAllHeroes(account)
        setTimeout(() => {
          NotificationManager.success('Sell plant success.', 'Success', 2000)
          setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
          setAttemptingTxn(false)
        }, 9000)
        // marketCTF.getPastEvents(
        //   'PlaceOrder',
        //   {
        //     fromBlock: txFull.blockNumber,
        //     toBlock: txFull.blockNumber,
        //     filter: {
        //       nftAddress: nftSellSelected.nftAddress,
        //       tokenId: nftSellSelected.tokenId,
        //     },
        //   },
        //   async (error: any, events) => {
        //     if (error) {
        //       console.error(error)
        //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        //       return
        //     }
        //     console.log('PlaceOrder', events)
        //     if (Object.values(events).length === 0) {
        //       setAttemptingTxn(false)
        //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
        //       return
        //     }
        //     fetchAllHeroes(account)
        //     setTimeout(() => {
        //       NotificationManager.success('Sell plant success.', 'Success', 2000)
        //       setSellState({ showSell: false, sellErrorMessage, nftSellSelected: undefined })
        //       setAttemptingTxn(false)
        //     }, 3000)
        //   }
        // )
      })
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onUpdatePriceConfirm(price: string) {
    console.log('on update price item')
    const decimal_price = getDecimalAmount(new BigNumber(price), decimals).toFixed()
    if (!market || !nftUpdateSelected) return
    const estimate = market.estimateGas.updatePrice
    const method: (...args: any) => Promise<TransactionResponse> = market.updatePrice
    const args: Array<string | string[] | number> = [nftAddress, nftUpdateSelected.tokenId, decimal_price]
    setAttemptingTxn(true)
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            await fetchAllHeroes(account)
            setTimeout(() => {
              NotificationManager.success('Update price success.', 'Success', 2000)
              setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
              setAttemptingTxn(false)
            }, 9000)
            // marketCTF.getPastEvents(
            //   'UpdatePrice',
            //   {
            //     fromBlock: txFull.blockNumber,
            //     toBlock: txFull.blockNumber,
            //     filter: {
            //       nftAddress: nftUpdateSelected.nftAddress,
            //       tokenId: nftUpdateSelected.tokenId,
            //     },
            //   },
            //   async (error: any, events) => {
            //     if (error) {
            //       console.error(error)
            //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            //       return
            //     }
            //     console.log('UpdatePrice', events)
            //     if (Object.values(events).length === 0) {
            //       setAttemptingTxn(false)
            //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            //       return
            //     }
            //     fetchAllHeroes(account)
            //     setTimeout(() => {
            //       NotificationManager.success('Update price success.', 'Success', 2000)
            //       setUpdatePriceState({ showUpdatePrice: false, nftUpdateSelected: undefined })
            //       setAttemptingTxn(false)
            //     }, 3000)
            //   }
            // )
          })
          .catch((error) => {
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onCancelOrderClick(nftSelected: Nft) {
    console.log('on cancel item')
    const useContract = market
    const useCTF = marketCTF
    if (!useContract || !nftSelected) return
    const estimate = useContract.estimateGas.cancelOrder
    const method: (...args: any) => Promise<TransactionResponse> = useContract.cancelOrder
    const args: Array<string | string[] | number> = [nftAddress, nftSelected.tokenId]

    setOrderCanceling({
      tokenId: nftSelected.tokenId,
      isCanceling: true,
    })
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            await fetchAllHeroes(account)
            setTimeout(() => {
              NotificationManager.success('Cancel order success.', 'Success', 2000)
              setOrderCanceling({
                tokenId: nftSelected.tokenId,
                isCanceling: false,
              })
            }, 9000)
            // useCTF.getPastEvents(
            //   'CancelOrder',
            //   {
            //     fromBlock: txFull.blockNumber,
            //     toBlock: txFull.blockNumber,
            //     filter: {
            //       nftAddress: nftSelected.nftAddress,
            //       tokenId: nftSelected.tokenId,
            //     },
            //   },
            //   async (error: any, events) => {
            //     if (error) {
            //       console.error(error)
            //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            //       setOrderCanceling({
            //         tokenId: nftSelected.tokenId,
            //         isCanceling: false,
            //       })
            //       return
            //     }
            //     console.log('CancelOrder', events)
            //     if (Object.values(events).length === 0) {
            //       setAttemptingTxn(false)
            //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            //       return
            //     }
            //     NotificationManager.success('Cancel order success.', 'Success', 2000)
            //     fetchAllHeroes(account)
            //     setOrderCanceling({
            //       tokenId: nftSelected.tokenId,
            //       isCanceling: false,
            //     })
            //   }
            // )
          })
          .catch((error) => {
            setOrderCanceling({
              tokenId: nftSelected.tokenId,
              isCanceling: false,
            })
            // we only care if the error is something _other_ than the user rejected the tx
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      )
      .catch((error) => {
        setOrderCanceling({
          tokenId: nftSelected.tokenId,
          isCanceling: false,
        })
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onGiftConfirm(nft: Nft, receiver: string) {
    if (!heroNftContract || !nft || !account) return
    setAttemptingTxn(true)

    const method: (...args: any) => Promise<TransactionResponse> = heroNftContract.transferFrom
    const args: Array<string | string[] | number> = [account, receiver, nft.tokenId]

    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        if (txFull.status === 0) {
          NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
          setAttemptingTxn(false)
          return
        }
        await fetchAllHeroes(account)
        setTimeout(() => {
          NotificationManager.success('Gift plant completed.', 'Success', 2000)
          setAttemptingTxn(false)
          setGiftState({
            nftGiftSelected: undefined,
            showGift: false,
            giftErrorMessage,
          })
        }, 9000)
      })
      .catch((error) => {
        setAttemptingTxn(false)
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  function onSelectMaterialHero(nft: any, slot: string) {
    setShowSelectHeroForUpgradeStar(false)
    const refMaterialHeroSelected = {
      ...materialHolySelected,
    }
    refMaterialHeroSelected[slot] = nft
    setUpgradeStar({
      upgradeStarErrorMessage: undefined,
      showUpgradeStar: true,
      plantSelected: plantSelected,
      materialHolySelected: refMaterialHeroSelected,
    })
  }

  async function onEvolveConfirm() {
    try {
      console.log('on evolve plant')
      if (!evolveContract || !plantSelected || !materialHolySelected) return
      const heroId = plantSelected.tokenId
      const level = plantSelected.level
      const holyPackageIds: string[] = []
      Object.values(materialHolySelected).forEach((n: any) => {
        if (!n) return
        holyPackageIds.push(n.tokenId)
      })
      axios({
        method: 'post',
        url: PLANT_ENDPOINT.EVOLVE,
        headers: {},
        data: { heroId },
      })
        .then((response) => {
          if (response.status === 200) {
            const signed = response.data.data
            const method: (...args: any) => Promise<TransactionResponse> = evolveContract.upgradeStar
            const args: Array<string | string[] | number> = [
              heroId,
              holyPackageIds,
              level,
              signed.nonce,
              signed.signature,
            ]
            setAttemptingTxn(true)
            const estimate = evolveContract.estimateGas.upgradeStar
            return estimate(...args, {})
              .then((estimatedGasLimit) => {
                method(...args, {
                  gasLimit: calculateGasMargin(estimatedGasLimit),
                })
                  .then(async (response) => {
                    const txFull = await response.wait()
                    console.log('txHash', txFull.transactionHash)
                    if (txFull.status === 0) {
                      NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
                      setAttemptingTxn(false)
                      return
                    }
                    evolveCTF.getPastEvents(
                      'StarUpgrade',
                      {
                        fromBlock: txFull.blockNumber,
                      },
                      async (error: any, events) => {
                        if (error) {
                          console.error(error)
                          setAttemptingTxn(false)
                          NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
                          return
                        }
                        if (Object.values(events).length === 0) return
                        const this_event = Object.values(events).find((e: any) => e.returnValues.heroId === heroId)
                        if (!this_event) return
                        console.log('StarUpgrade', this_event)
                        const isSuccess = this_event.returnValues.isSuccess
                        if (!isSuccess) {
                          await getNextSuccessPercent()
                        }
                        setUpgradeResult({
                          upgradeResult: isSuccess,
                          nftResult: plantSelected,
                          showUpgradeResult: true,
                        })
                      }
                    )
                  })
                  .catch((error) => {
                    setAttemptingTxn(false)
                    if (error?.data?.message === 'execution reverted: Must wait') {
                      return NotificationManager.error(
                        `Only evolve one time each 5 minutes. Please try again later.`,
                        'Error',
                        5000
                      )
                    }
                    if (error?.data?.message === 'execution reverted: Request expired') {
                      return NotificationManager.error('Evolve request expired. Please try again', 'Error', 5000)
                    }
                    NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
                  })
              })
              .catch((error) => {
                setAttemptingTxn(false)
                NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
              })
          }
          NotificationManager.error('Can not get data from server. Please try again later', 'Error', 2000)
          return
        })
        .catch((error) => {
          console.error(error)
          setAttemptingTxn(false)
          NotificationManager.error('Can not get data from server. Please try again later', 'Error', 2000)
        })
    } catch (error: any) {
      setAttemptingTxn(false)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 1000)
    }
  }

  const NftList = ({ nfts }: { nfts: Nft[] }) => {
    const handleRefresh = () => {
      console.log('Refresh wallet NFTs')
    }

    if (nfts.length === 0) {
      return (
        <EmptyNft>
          <div>No plants available.</div>
        </EmptyNft>
      )
    }

    const available = nfts.filter((n: any) => n.status === 'Available')
    const selling = nfts.filter((n: any) => n.status === 'Selling')

    const Render_container = ({ nfts, title }: { nfts: Nft[]; title: string }) => {
      const [currentPage, setCurrentPage] = useState<number>(1)
      const currentOrderList = useMemo(() => paginate(nfts, NUMBER_PER_PAGE, currentPage), [nfts, currentPage])

      return (
        <ListHeroContainer>
          <StatusTitle>{title}</StatusTitle>
          <NftGrid>
            {currentOrderList.map((nft: Nft, index) => {
              const Card = NftCard
              return (
                // eslint-disable-next-line react/prop-types
                <div key={index}>
                  <Card nft={nft} refresh={handleRefresh} />
                </div>
              )
            })}
          </NftGrid>
          <General>
            <div style={{ width: '100%', marginTop: '20px' }}>
              {nfts.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  onChange={setCurrentPage}
                  totalPage={Math.ceil(nfts.length / NUMBER_PER_PAGE)}
                />
              )}
            </div>
          </General>
        </ListHeroContainer>
      )
    }

    if (statusHeroType === 'Available') {
      return <>{available.length > 0 && <>{Render_container({ nfts: available, title: 'Available' })}</>}</>
    }

    if (statusHeroType === 'Selling') {
      return <>{selling.length > 0 && <>{Render_container({ nfts: selling, title: 'Selling' })}</>}</>
    }

    return (
      <>
        {available.length > 0 && <>{Render_container({ nfts: available, title: 'Available' })}</>}
        {selling.length > 0 && <>{Render_container({ nfts: selling, title: 'Selling' })}</>}
      </>
    )
  }

  const canUpgradeStar = myNftsData.filter((nft: any) => nft.status === 'Upgradeable').length

  const maintain = () => {
    NotificationManager.info('Please try again later.', 'MAINTENANCE', 2000)
  }

  const showSelectHeroForUpgrateStarWithSlot = (slot: string) => {
    setShowSelectHeroForUpgradeStar(true)
    setSelectingSlotHeroForUpgradeStar(slot)
  }

  const renderModals = () => {
    return (
      <>
        <FilterModal
          title="FILTER"
          visible={showFilterModal}
          onCancel={() => setShowFilterModal(false)}
          footer={null}
          className="select-server"
        >
          <div style={{ padding: '0 30px 30px', margin: 'auto', width: '100%' }}>
            <Filter
              id="filter-dialog"
              onConfirm={(newFilter: PEFilterConditions) => {
                setFilter(newFilter)
                setShowFilterModal(false)
              }}
              onCancel={() => {
                setShowFilterModal(false)
              }}
            />
          </div>
        </FilterModal>

        <DetailModal
          nft={nftDetailSelected}
          isOpen={showConfirm}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={null}
          allowedSlippage={undefined}
          onSellClick={() => {
            setSellState({
              sellErrorMessage: undefined,
              showSell: true,
              nftSellSelected: nftDetailSelected,
            })
            handleConfirmDismiss()
          }}
          onUpgradeStarClick={() => {
            setUpgradeStar({
              upgradeStarErrorMessage: undefined,
              showUpgradeStar: true,
              plantSelected: nftDetailSelected,
              materialHolySelected: undefined,
            })
            handleConfirmDismiss()
            // maintain()
          }}
          onWithDrawClick={() => console.log('aaa')}
          swapErrorMessage={undefined}
          onDismiss={handleConfirmDismiss}
        />
        <SellModal
          nft={nftSellSelected}
          isOpen={showSell}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onSellConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleSellDismiss}
        />
        <UpdatePriceModal
          nft={nftUpdateSelected}
          isOpen={showUpdatePrice}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onUpdatePriceConfirm}
          sellErrorMessage={undefined}
          onDismiss={handleUpdatePriceDismiss}
        />
        <GiftModal
          onGift={onGiftConfirm}
          attemptingTxn={attemptingTxn}
          isOpen={showGift}
          onDismiss={() => setGiftState({ nftGiftSelected: undefined, showGift: false, giftErrorMessage })}
          nft={nftGiftSelected}
        />

        <UpgradeStarModal
          nft={plantSelected}
          materialHolySelected={materialHolySelected}
          isOpen={showUpgradeStar}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={null}
          allowedSlippage={undefined}
          randomRate={randomRate}
          onConfirm={onEvolveConfirm}
          upgradeStarErrorMessage={undefined}
          onDismiss={handleUpgradeStarDismiss}
          showSelectHero={showSelectHeroForUpgrateStarWithSlot}
          upgradeSuccess={false}
          onRefreshData={() => {
            fetchAllHeroes(account)
          }}
        />

        {showUpgradeResult && (
          <UpgradeResultModal
            upgradeResult={upgradeResult}
            nft={nftResult}
            isOpen={showUpgradeResult}
            upgradeStarErrorMessage={undefined}
            onDismiss={handleUpgradeResultDismiss}
            onRefreshData={() => {
              fetchAllHeroes(account)
            }}
          />
        )}

        <SelectHolyForUpgradeStarModal
          isOpen={showSelectHeroForUpgradeStar}
          nft={plantSelected}
          materialHolySelected={materialHolySelected}
          slot={selectingSlotHeroForUpgradeStar}
          onConfirm={onSelectMaterialHero}
          onDismiss={() => setShowSelectHeroForUpgradeStar(false)}
          nfts={Object.values(myHolyData)}
        />
      </>
    )
  }

  return (
    <PageContainer className="main-content">
      {renderModals()}
      <NFTArea>
        <General>
          <TabWrapper>
            <Select
              id="status-type"
              // @ts-ignore
              onChange={() => setStatusHeroType(document.getElementById('status-type').value)}
            >
              <Option value={''} selected>
                All
              </Option>
              <Option value={'Available'}>Available</Option>
              <Option value={'Selling'}>Selling</Option>
              {/* <Option value={'Upgradeable'}>Upgradeable</Option> */}
            </Select>
            <SearchBox>
              <TextInput
                id={'hero-order'}
                // @ts-ignore
                onUserInput={() => setSearchValue(document.getElementById('hero-order').value)}
                placeholder="Search for items"
                fontSize="18px"
              />
            </SearchBox>
            <StyledFilterButton onClick={() => setShowFilterModal(true)}>
              <Text fontWeight={700} fontSize={22}>
                FILTER
              </Text>
            </StyledFilterButton>
          </TabWrapper>
        </General>
        <NftList nfts={nfts} />
      </NFTArea>
    </PageContainer>
  )
}
