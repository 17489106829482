import { Trans } from '@lingui/macro'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { getPEFiPrice } from 'utils/getPefiPrice'
import { ButtonConfirm } from 'components/Button'
import { MagraText } from 'components/Text'
import Modal from './Modal'

const Container = styled.div`
  flex: 1;
  align-items: stretch;
  flex-direction: column;
  &.mobile {
    width: 100vw;
    padding: 0 15px;
  }
`

const Title = styled.p`
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
  margin: 0 0 20px;
  padding: 0 20px;
`

const ListMating = styled.div`
  background: #14324c;
  padding: 20px 30px;
  border-radius: 5px;
  color: #fff8e8;
  text-align: center;
  font-size: 18px;
  font-family: Montio;
  &.mobile {
    padding: 0;
    background: none;
  }
`

const rotate = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`

const RewardItem = styled.div`
  margin: 0 auto;
  width: 60%;
  position: relative;
  &.mobile {
    width: 100%;
  }
`
const RewardLine = styled.div`
  display: grid;
  grid-template-columns: 10% 35% 25% 30%;
  border-radius: 5px;
  margin: 5px auto;
  padding: 0 20px;
  height: 40px;
  background: #026092;
  color: #fff8e8;
  font-weight: bold;
  &.claimed {
    background: #000;
    color: #fff8e8 !important;
  }
  &.expired {
    background: #3a3a3a5e;
    color: #6c6c6a !important;
  }
  &.mobile {
    grid-template-columns: 5% 45% 25% 25%;
    padding: 10px 15px;
    font-size: 16px;
  }
`
const NumberOfReward = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const RewardTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const RofiReward = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const RofiTokenLogo = styled.img`
  width: 30px;
  margin-right: 5px;
`
const ClaimedIcon = styled.img`
  position: absolute;
  right: -40px;
  top: 15px;
  width: 20px;
  margin-right: 5px;
`
const BUSDReward = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const BUSDToken = styled.img`
  width: 30px;
  // animation: ${rotate} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  margin-right: 5px;
  position: relative;
  top: 2px;
`
const Description = styled.div`
  position: absolute;
  right: -100px;
  top: 10px;
  z-index: 2;
`
const Line = styled.div`
  border: 1px solid #7e7e7e;
  width: 100%;
  position: absolute;
  top: 20px;
`

export default function RewardsListModal({
  onDismiss,
  isOpen,
  requestsList,
  onRefreshData,
  tokenType,
}: {
  isOpen: boolean
  onDismiss: () => void
  onRefreshData: () => void
  requestsList: any
  tokenType: string
}) {
  const isMobileAndTablet = window.innerWidth <= 1024
  const [shownRewardList, setShownRewardList] = useState<any>([])
  const [RofiPrice, setRofiPrice] = useState<any>(null)

  const isExpired = (r: any) => {
    const now = Date.now() / 1000
    if (now - Number(r.timeStamp) > 10 * 86400) {
      return true
    }
    return false
  }

  //@ts-ignore
  useEffect(async () => {
    const array: any = []
    requestsList.reverse().forEach((reward: any, index: number) => {
      const rewardRef = {
        ...reward,
        status: 'available',
      }
      if (isExpired(reward)) {
        rewardRef.status = 'expired'
      }
      if (reward.claimed) {
        rewardRef.status = 'claimed'
      }
      array.push(rewardRef)
    })
    const numberOfReward = array.length
    for (let i = numberOfReward; i < 10; i++) {
      array.push(null)
    }
    setShownRewardList(array)

    const Rofiprice = await getPEFiPrice()
    setRofiPrice(Rofiprice)
  }, [requestsList])

  function thousands_separators(num: number) {
    const num_parts = num.toString().split('.')
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return num_parts.join('.')
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Container>
        <Title>CONVERT HISTORY</Title>
        {shownRewardList.find((r: any) => r !== null) ? (
          <ListMating className={`${isMobileAndTablet ? 'mobile' : ''}`}>
            <div style={{ marginTop: 30 }}>No rewards.</div>
            <ButtonConfirm
              onClick={() => onDismiss()}
              disabled={false}
              style={{ margin: '10px auto 30px', height: '57px' }}
              width="30%"
            >
              {isMobileAndTablet ? (
                <MagraText fontSize={18} fontWeight={600}>
                  <Trans>Got It</Trans>
                </MagraText>
              ) : (
                <MagraText fontSize={28} fontWeight={600}>
                  <Trans>Got It</Trans>
                </MagraText>
              )}
            </ButtonConfirm>
          </ListMating>
        ) : (
          <>
            <ListMating className={`${isMobileAndTablet ? 'mobile' : ''}`}>
              {shownRewardList.map((r: any, index: any) => {
                if (r === null) {
                  return (
                    <RewardItem key={index} className={`${isMobileAndTablet ? 'mobile' : ''}`}>
                      <RewardLine></RewardLine>
                    </RewardItem>
                  )
                }
                return (
                  <RewardItem key={index} className={`${isMobileAndTablet ? 'mobile' : ''}`}>
                    <RewardLine className={`${isMobileAndTablet ? 'mobile' : ''} ${r.status}`}>
                      <NumberOfReward>{index + 1}.</NumberOfReward>
                      <RewardTime>{moment.unix(r.timestamp - 86400).format('D - MMMM - YYYY')}</RewardTime>
                      <RofiReward>{thousands_separators(Number(Number(r.total).toFixed(2)))} (gHFI)jn gbn v</RofiReward>
                      <BUSDReward>
                        <span style={{ marginRight: 10, fontSize: 30 }}>{'='}</span>
                        {thousands_separators(Number(Number(r.total).toFixed(2)))} (HFI)
                      </BUSDReward>
                    </RewardLine>
                  </RewardItem>
                )
              })}
            </ListMating>
            <ButtonConfirm
              onClick={() => onDismiss()}
              disabled={false}
              style={{ margin: '10px auto 30px', height: '57px' }}
              width="30%"
            >
              {isMobileAndTablet ? (
                <MagraText fontSize={18} fontWeight={600}>
                  <Trans>Got It</Trans>
                </MagraText>
              ) : (
                <MagraText fontSize={28} fontWeight={600}>
                  <Trans>Got It</Trans>
                </MagraText>
              )}
            </ButtonConfirm>
          </>
        )}
      </Container>
    </Modal>
  )
}
