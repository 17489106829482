import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import React, { ReactNode, useCallback } from 'react'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import BuyItem from './BuyItem'
import ItemDetail from 'components/MyAssets/Plant/DetailModal/ItemDetail'
import { Order } from 'constants/types'
import BuyModalContent from 'pages/MarketPlace/PEFI/BuyModalContent'

export default function ConfirmOrderModal({
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  nft,
}: {
  isOpen: boolean
  originalTrade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent | undefined
  onAcceptChanges: () => void
  onConfirm: () => void
  swapErrorMessage: ReactNode | undefined
  onDismiss: () => void
  nft: Order | undefined
}) {
  const modalRight = useCallback(() => {
    return <ItemDetail nft={nft} />
  }, [allowedSlippage, onAcceptChanges, recipient])

  const modalLeft = useCallback(() => {
    return nft ? (
      <BuyItem
        attemptingTxn={attemptingTxn}
        onConfirm={onConfirm}
        disabledConfirm={false}
        swapErrorMessage={swapErrorMessage}
        nft={nft}
      />
    ) : null
  }, [onConfirm, swapErrorMessage])

  // text to show while loading
  const pendingText = <Trans>Buying...</Trans>

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <BuyModalContent onDismiss={onDismiss} leftContent={modalLeft} rightContent={modalRight} />
      ),
    [onDismiss, modalRight, modalLeft, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
