import level from 'assets/pefi/special-pack/level-bg.png'
import lockIcon from 'assets/pefi/my-assets/icon_lock.png'
import star from 'assets/pefi/special-pack/star.png'
import loadingPreview from 'assets/pefi/my-assets/loading-preview.png'
import Text from 'components/Text'
import times from 'lodash/times'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import styled from 'styled-components'
interface PreviewProps {
  nft: {
    image: string
    name: string
    level: number
    tokenId: string
    star: number
    video: string
    generation: string
    legendGuardian?: any
    detail?: any
    plantId?: string
    lockToTime?: string
  }
  isResetLevel?: boolean
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
`
const Level = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${level});
  background-size: cover;
  background-repeat: no-repeat;
  height: 70px;
  width: 45px;
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 1s linear;
  object-fit: cover;
  border-top-right-radius: 5px;
  z-index: 2;
  color: #fff8e8;
  font-weight: 700;
  .label {
    font-size: 14px;
    margin: 0;
  }
  .content {
    font-size: 28px;
    margin: 0;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 45px;
    width: 25px;
    .label {
      font-size: 12px;
    }
    .content {
      font-size: 14px;
    }
  `}
`
const Lock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${lockIcon});
  background-size: cover;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 70px;
  left: 0;
  transition: opacity 1s linear;
  object-fit: cover;
  border-top-right-radius: 5px;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 40px;
    width: 30px;
    height: 30px;
  `}
`

const LockText = styled.div`
  position: absolute;
  bottom: 35px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  background: #00000080;
  padding: 5px 10px;
  color: red;
  font-weight: bold;
  border-radius: 10px;
`

const CountdownContent = styled.div`
  width: 100%;
  position: absolute;
  top: 45px;
  font-size: 14px;
  font-weight: bold;
  background: #00000080;
  padding: 2px;
  border-radius: 5px;
`

const NftID = styled.p`
  position: absolute;
  bottom: 0;
  margin: 0;
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  font-family: Gudea-Medium;
  text-align: center;
  align-self: center;
  color: #fff8e8;
  text-transform: uppercase;
  background: #323232d9;
  padding-top: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `}
`
const StarContainer = styled.div`
  position: absolute;
  top: 30px;
  margin: 10px 0px;
  width: 100%;
  text-align: center;
  align-self: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 20px;
  `}
`

const Star = styled.img`
  width: 26px;
  height: 24px;
  margin: 0px 2px;
  transition: opacity 1s linear;
  object-fit: cover;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 16px;
    height: 14px;
  `}
`

const StyledVideo = styled.video`
  height: auto;
  width: 100%;
`

const Title = styled.p`
  position: absolute;
  width: 100%;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
  margin: 10px 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `}
`

export default function Preview({ nft, isResetLevel = false }: PreviewProps) {
  const { detail, level, tokenId, star: numberOfStar, plantId, lockToTime } = nft
  if (!detail) {
    return null
  }
  const { name } = detail
  const url_webm = `https://plantempires-media.b-cdn.net/webm/${plantId}_${numberOfStar}.webm`
  const url_mp4 = `https://plantempires-media.b-cdn.net/${plantId}_${numberOfStar}.mp4`

  const isLockIngame = lockToTime ? Date.parse(lockToTime) > Date.now() : false

  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: boolean
  }) => {
    if (completed) {
      return null
    } else {
      return (
        <>
          <CountdownContent>
            {hours}H {minutes}M
          </CountdownContent>
        </>
      )
    }
  }

  return (
    <Container className={`preview-container`}>
      <Title>#{tokenId}</Title>
      <StarContainer>
        {times(numberOfStar, (index) => (
          <Star src={star} key={index}></Star>
        ))}
      </StarContainer>
      <Level>
        <Text className="label">Lv.</Text>
        <Text className="content">{isResetLevel ? 1 : level}</Text>
      </Level>
      {lockToTime && isLockIngame && (
        <Lock>
          <Countdown date={Date.parse(lockToTime)} renderer={renderCountdown} zeroPadTime={2} />
        </Lock>
      )}
      <StyledVideo key={url_webm} autoPlay playsInline controls={false} loop muted poster={loadingPreview}>
        <source src={`${url_webm}`} type="video/webm" />
        <source src={`${url_mp4}`} type="video/mp4" />
      </StyledVideo>
      {lockToTime && isLockIngame && (
        <LockText>
          <Content>LOCK In-game</Content>
        </LockText>
      )}
      <NftID>{name}</NftID>
    </Container>
  )
}
