import React, { useState } from 'react'
import GemShop from './GemShop'
import ChestShop from './ChestShop'
import PvPShop from './PvPShop'
import FarmShop from './FarmShop'
import styled, { keyframes } from 'styled-components'
import Footer from 'components/Footer'
import { Redirect, RouteComponentProps } from 'react-router-dom'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const AppBody = styled.div`
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 61px);
  text-align: center;
  background: url(${filterBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #14324c;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 50px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px 50px;
  `};
`

const HeaderPacks = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin-top: 50px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  `}
`

const ShopTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 30px;
`

const PaymentToken = styled.div`
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  font-family: Gudea-Medium;
  border: 2px solid #03c5ff;
  padding: 7px 10px 3px;
  border-radius: 10px;
  min-width: 200px;
  color: #fff;
  background: #1b405c;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 0;
    font-size: 20px;
  `}
  &.active {
    border: 2px solid #9ce315;
    background: #9ce315;
  }
`

const PageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export default function SpecialPack({ location }: RouteComponentProps) {
  const [pageState, setPageState] = useState<string>('chest')

  const server = localStorage.getItem('server')
  if (!server) return <Redirect to={{ ...location, pathname: '/' }} />

  const renderContent = () => {
    if (pageState === 'pvpticket') return <PvPShop />
    if (pageState === 'chest') return <ChestShop />
    if (pageState === 'farm') return <FarmShop />
    return <GemShop />
  }

  return (
    <>
      <AppBody>
        <HeaderPacks>
          <PaymentToken className={pageState === 'chest' ? 'active' : ''} onClick={() => setPageState('chest')}>
            CHEST
          </PaymentToken>
          <PaymentToken className={pageState === 'pvpticket' ? 'active' : ''} onClick={() => setPageState('pvpticket')}>
            Arena Ticket
          </PaymentToken>
          <PaymentToken className={pageState === 'gem' ? 'active' : ''} onClick={() => setPageState('gem')}>
            GEM
          </PaymentToken>
          <PaymentToken className={pageState === 'farm' ? 'active' : ''} onClick={() => setPageState('farm')}>
            FARM
          </PaymentToken>
        </HeaderPacks>
        <PageContent>{renderContent()}</PageContent>
      </AppBody>
      <Footer />
    </>
  )
}
