import { Contract } from '@ethersproject/contracts'

import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC721_ABI from 'abis/erc721.json'
import PANCAKE_PAIR_ABI from 'abis/pancake-pair.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import MULTICALL_ABI from 'abis/multicall2.json'
import WETH_ABI from 'abis/weth.json'
import PE_PLANT_HERO_BOX_ABI from 'abis/pe-plant-hero-chest-abi.json'
import PE_PLANT_HERO_MARKET_ABI from 'abis/pe-plant-hero-market-abi.json'
import PE_PLANT_HERO_NFT_ABI from 'abis/pe-plant-hero-nft-abi.json'
import PE_HOLY_PACKAGE_ABI from 'abis/pe-holy-package-abi.json'
import PE_GEM_SHOP_ABI from 'abis/pe-gem-shop-abi.json'
import PE_CONVERT_GPEFI_ABI from 'abis/pe-convert-gpefi-abi.json'
import PE_ORB_NFT_ABI from 'abis/pe-orb-nft-abi.json'
import PE_MINT_ORB_ABI from 'abis/pe-mint-orb-abi.json'
import PE_ORB_MARKET_ABI from 'abis/pe-orb-market-abi.json'
import PE_PLANT_EVOLVE_ABI from 'abis/pe-plant-evolve-abi.json'
import PE_HOLY_MARKET_ABI from 'abis/pe-holy-market-abi.json'
import PE_HOLY_MARKET_OLD_ABI from 'abis/pe-holy-market-old-abi.json'
import PE_ORB_EVOLVE_ABI from 'abis/pe-orb-evolve-abi.json'
import PE_CHECKIN_ABI from 'abis/pe-checkin-abi.json'
import PE_CLAIM_MINT_TICKET_ABI from 'abis/pe-claim-mint-ticket-abi.json'
import PE_PLANT_FUSION_ABI from 'abis/pe-plant-fusion-abi.json'
import PE_ORB_FUSION_ABI from 'abis/pe-orb-fusion-abi.json'
import PE_LUCKY_SPIN_ABI from 'abis/pe-lucky-spin.json'
import PE_PIECE_MARKET_ABI from 'abis/pe-piece-market-abi.json'
import PE_PIECE_COMBINE_ABI from 'abis/pe-piece-combine-abi.json'

import {
  ENS_REGISTRAR_ADDRESSES,
  MULTICALL2_ADDRESSES,
  PE_PLANT_HERO_CHEST_ADDRESS,
  PE_PLANT_HERO_MARKET_ADDRESS,
  PE_PLANT_HERO_NFT_ADDRESS,
  PE_HOLY_PACKAGE_ADDRESS,
  PE_GEM_SHOP_ADDRESS,
  PE_CONVERT_GPEFI_ADDRESS,
  PE_ORB_NFT_ADDRESS,
  PE_MINT_ORB_ADDRESS,
  PE_ORB_MARKET_ADDRESS,
  PE_PLANT_EVOLVE_ADDRESS,
  PE_HOLY_MARKET_ADDRESS,
  PE_ORB_EVOLVE_ADDRESS,
  PE_CHECKIN_ADDRESS,
  PE_HOLY_MARKET_OLD_ADDRESS,
  PE_CLAIM_MINT_TICKET_ADDRESS,
  PE_PLANT_FUSION_ADDRESS,
  PE_ORB_FUSION_ADDRESS,
  PE_CONVERT_GPEFI_OLD_ADDRESS,
  PE_LUCKY_SPIN_ADDRESS,
  PE_PIECE_MARKET_ADDRESS,
  PE_PIECE_COMBINE_ADDRESS,
  //S2
  S2_PLANT_HERO_CHEST_ADDRESS,
  S2_PLANT_HERO_MARKET_ADDRESS,
  S2_PLANT_HERO_NFT_ADDRESS,
  S2_HOLY_PACKAGE_ADDRESS,
  S2_GEM_SHOP_ADDRESS,
  S2_CONVERT_GPEFI_ADDRESS,
  S2_ORB_NFT_ADDRESS,
  S2_MINT_ORB_ADDRESS,
  S2_ORB_MARKET_ADDRESS,
  S2_PLANT_EVOLVE_ADDRESS,
  S2_HOLY_MARKET_ADDRESS,
  S2_ORB_EVOLVE_ADDRESS,
  S2_CHECKIN_ADDRESS,
  S2_CLAIM_MINT_TICKET_ADDRESS,
  S2_PLANT_FUSION_ADDRESS,
  S2_ORB_FUSION_ADDRESS,
  S2_LUCKY_SPIN_ADDRESS,
  S2_PIECE_MARKET_ADDRESS,
  S2_PIECE_COMBINE_ADDRESS,
  S2_CONVERT_GPEFI_OLD_ADDRESS,
} from 'constants/addresses'
import { useMemo } from 'react'
import { getContract } from 'utils'
import { EnsPublicResolver, EnsRegistrar, Erc20, Multicall2, Weth } from '../abis/types'
import { UNI, WETH9_EXTENDED } from '../constants/tokens'
import { useActiveWeb3React } from './web3'

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useERC721TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC721_ABI, withSignerIfPossible)
}

export function usePancakePairContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, PANCAKE_PAIR_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(chainId ? WETH9_EXTENDED[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticall2Contract() {
  return useContract<Multicall2>(MULTICALL2_ADDRESSES, MULTICALL_ABI, false) as Multicall2
}

//PE

export function usePlantHeroNFTContract(): Contract | null {
  return useContract(PE_PLANT_HERO_NFT_ADDRESS, PE_PLANT_HERO_NFT_ABI, true)
}

export function usePlantHeroBoxContract(): Contract | null {
  return useContract(PE_PLANT_HERO_CHEST_ADDRESS, PE_PLANT_HERO_BOX_ABI, true)
}

export function usePlantHeroMarketContract(): Contract | null {
  return useContract(PE_PLANT_HERO_MARKET_ADDRESS, PE_PLANT_HERO_MARKET_ABI, true)
}

export function useHolyPackageContract(): Contract | null {
  return useContract(PE_HOLY_PACKAGE_ADDRESS, PE_HOLY_PACKAGE_ABI, true)
}

export function useGemShopContract(): Contract | null {
  return useContract(PE_GEM_SHOP_ADDRESS, PE_GEM_SHOP_ABI, true)
}

export function useConvertGPEFIOldContract(): Contract | null {
  return useContract(PE_CONVERT_GPEFI_OLD_ADDRESS, PE_CONVERT_GPEFI_ABI, true)
}

export function useConvertGPEFIContract(): Contract | null {
  return useContract(PE_CONVERT_GPEFI_ADDRESS, PE_CONVERT_GPEFI_ABI, true)
}

export function useOrbNFTContract(): Contract | null {
  return useContract(PE_ORB_NFT_ADDRESS, PE_ORB_NFT_ABI, true)
}

export function useMintOrbContract(): Contract | null {
  return useContract(PE_MINT_ORB_ADDRESS, PE_MINT_ORB_ABI, true)
}

export function useOrbMarketContract(): Contract | null {
  return useContract(PE_ORB_MARKET_ADDRESS, PE_ORB_MARKET_ABI, true)
}

export function usePlantEvolveContract(): Contract | null {
  return useContract(PE_PLANT_EVOLVE_ADDRESS, PE_PLANT_EVOLVE_ABI, true)
}

export function useHolyMarketContract(): Contract | null {
  return useContract(PE_HOLY_MARKET_ADDRESS, PE_HOLY_MARKET_ABI, true)
}

export function useOrbEvolveContract(): Contract | null {
  return useContract(PE_ORB_EVOLVE_ADDRESS, PE_ORB_EVOLVE_ABI, true)
}

export function useCheckinContract(): Contract | null {
  return useContract(PE_CHECKIN_ADDRESS, PE_CHECKIN_ABI, true)
}

export function useHolyMarketOldContract(): Contract | null {
  return useContract(PE_HOLY_MARKET_OLD_ADDRESS, PE_HOLY_MARKET_OLD_ABI, true)
}

export function useClaimMintTicketContract(): Contract | null {
  return useContract(PE_CLAIM_MINT_TICKET_ADDRESS, PE_CLAIM_MINT_TICKET_ABI, true)
}

export function usePlantFusionContract(): Contract | null {
  return useContract(PE_PLANT_FUSION_ADDRESS, PE_PLANT_FUSION_ABI, true)
}
export function useOrbFusionContract(): Contract | null {
  return useContract(PE_ORB_FUSION_ADDRESS, PE_ORB_FUSION_ABI, true)
}

//PE S2

export function usePlantHeroNFTContractS2(): Contract | null {
  return useContract(S2_PLANT_HERO_NFT_ADDRESS, PE_PLANT_HERO_NFT_ABI, true)
}

export function usePlantHeroBoxContractS2(): Contract | null {
  return useContract(S2_PLANT_HERO_CHEST_ADDRESS, PE_PLANT_HERO_BOX_ABI, true)
}

export function usePlantHeroMarketContractS2(): Contract | null {
  return useContract(S2_PLANT_HERO_MARKET_ADDRESS, PE_PLANT_HERO_MARKET_ABI, true)
}

export function useHolyPackageContractS2(): Contract | null {
  return useContract(S2_HOLY_PACKAGE_ADDRESS, PE_HOLY_PACKAGE_ABI, true)
}

export function useGemShopContractS2(): Contract | null {
  return useContract(S2_GEM_SHOP_ADDRESS, PE_GEM_SHOP_ABI, true)
}

export function useConvertGPEFIOldContractS2(): Contract | null {
  return useContract(S2_CONVERT_GPEFI_OLD_ADDRESS, PE_CONVERT_GPEFI_ABI, true)
}

export function useConvertGPEFIContractS2(): Contract | null {
  return useContract(S2_CONVERT_GPEFI_ADDRESS, PE_CONVERT_GPEFI_ABI, true)
}

export function useOrbNFTContractS2(): Contract | null {
  return useContract(S2_ORB_NFT_ADDRESS, PE_ORB_NFT_ABI, true)
}

export function useMintOrbContractS2(): Contract | null {
  return useContract(S2_MINT_ORB_ADDRESS, PE_MINT_ORB_ABI, true)
}

export function useOrbMarketContractS2(): Contract | null {
  return useContract(S2_ORB_MARKET_ADDRESS, PE_ORB_MARKET_ABI, true)
}

export function usePlantEvolveContractS2(): Contract | null {
  return useContract(S2_PLANT_EVOLVE_ADDRESS, PE_PLANT_EVOLVE_ABI, true)
}

export function useHolyMarketContractS2(): Contract | null {
  return useContract(S2_HOLY_MARKET_ADDRESS, PE_HOLY_MARKET_ABI, true)
}

export function useOrbEvolveContractS2(): Contract | null {
  return useContract(S2_ORB_EVOLVE_ADDRESS, PE_ORB_EVOLVE_ABI, true)
}

export function useCheckinContractS2(): Contract | null {
  return useContract(S2_CHECKIN_ADDRESS, PE_CHECKIN_ABI, true)
}

export function useClaimMintTicketContractS2(): Contract | null {
  return useContract(S2_CLAIM_MINT_TICKET_ADDRESS, PE_CLAIM_MINT_TICKET_ABI, true)
}

export function usePlantFusionContractS2(): Contract | null {
  return useContract(S2_PLANT_FUSION_ADDRESS, PE_PLANT_FUSION_ABI, true)
}

export function useOrbFusionContractS2(): Contract | null {
  return useContract(S2_ORB_FUSION_ADDRESS, PE_ORB_FUSION_ABI, true)
}

export function useLuckySpinContract(): Contract | null {
  return useContract(PE_LUCKY_SPIN_ADDRESS, PE_LUCKY_SPIN_ABI, true)
}

export function useLuckySpinContractS2(): Contract | null {
  return useContract(S2_LUCKY_SPIN_ADDRESS, PE_LUCKY_SPIN_ABI, true)
}

export function usePieceMarketContract(): Contract | null {
  return useContract(PE_PIECE_MARKET_ADDRESS, PE_PIECE_MARKET_ABI, true)
}

export function usePieceCombineContract(): Contract | null {
  return useContract(PE_PIECE_COMBINE_ADDRESS, PE_PIECE_COMBINE_ABI, true)
}

export function usePieceMarketContractS2(): Contract | null {
  return useContract(S2_PIECE_MARKET_ADDRESS, PE_PIECE_MARKET_ABI, true)
}

export function usePieceCombineContractS2(): Contract | null {
  return useContract(S2_PIECE_COMBINE_ADDRESS, PE_PIECE_COMBINE_ABI, true)
}
