import Header from 'components/Header'
import Web3ReactManager from 'components/Web3ReactManager'
import React from 'react'
import { NotificationContainer } from 'react-notifications'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import DarkModeQueryParamReader from 'theme/DarkModeQueryParamReader'
import Auth from './Auth'
import MarketPlace from './MarketPlace'
import MyCharacter from './AccountPage/MyAssets'
import Account from './AccountPage'
import SpecialPack from './SpecialPack'
import TokensPage from './AccountPage/TokensPage'
import SelectServer from './SelectServer'
import Shop from './ShopPage'
import { RedirectPathToSwapOnly } from './redirects'
import './style.css'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  .notification-container {
    top: 61px;
    width: 360px;
  }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 61px 0px 0px 0px;
  align-items: center;
  flex: 1;
  z-index: 1;
  /*
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6rem 16px 16px 16px;
  `};
*/
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
`

export default function App() {
  return (
    <div>
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <Web3ReactManager>
            <Switch>
              {/* <Route exact strict path="/" component={SelectServer} /> */}
              <Route exact strict path="/specialpacks" component={SpecialPack} />
              <Route exact strict path="/marketplace" component={MarketPlace} />
              {/* <Route exact strict path="/myassets" component={MyCharacter} /> */}
              <Route exact strict path="/account" component={Account} />
              <Route exact strict path="/shop" component={Shop} />
              <Route exact strict path="/auth" component={Auth} />
              {/* <Route exact strict path="/vault" component={Vault} /> */}
              {/* <Route exact strict path="/tokens" component={TokensPage} /> */}
              <Route component={RedirectPathToSwapOnly} />
            </Switch>
          </Web3ReactManager>
        </BodyWrapper>
        <NotificationContainer />
      </AppWrapper>
    </div>
  )
}
