import React from 'react'
import { useWalletModalToggle } from '../../state/application/hooks'
import { MagraText } from '../../components/Text'

const UnlockButton = () => {
  const toggleWalletModal = useWalletModalToggle()

  return (
    <div className="button" onClick={toggleWalletModal}>
      <MagraText fontSize={28} fontWeight={700}>
        Connect Wallet
      </MagraText>
    </div>
  )
}

export default UnlockButton
