import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import PE_HOLY_MARKET_ABI from 'abis/pe-holy-market-abi.json'
import RoFiToken from 'assets/herofi/RoFiToken.svg'
import SearchBackground from 'assets/images/background-marketplace.png'
import OrderBackground from 'assets/images/background-orders.png'
import button_sort from 'assets/images/button-sort.png'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { ButtonGray, ButtonPrimary } from 'components/Button'
import FilterModal from 'components/FilterModal'
import Footer from 'components/Footer'
import Preview from 'components/PreviewCard/HolyPackCardPreview/Preview'
import Filter from 'components/Filters/HolyFilter/Filter'
import ConfirmOrderModal from 'components/market/Holy/ConfirmOrderModal'
import Text from 'components/Text'
import { TextInput } from 'components/TextInput'
import { PE_HOLY_MARKET_ADDRESS } from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { HOLY_ENDPOINT } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { NftType, Order, PEBoxFilterConditions, Transaction } from 'constants/types'
import { useHolyMarketContract, useHolyMarketContractS2 } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import get from 'lodash/get'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import AppBody from 'pages/AppBody'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { keyframes } from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { getDecimalAmount } from 'utils/formatBalance'
import { getHeroClassName, getHeroRarity } from 'utils/getPlantHeroDetail'
import paginate from 'utils/paginate'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import NftGrid from './NftGrid'
import Pagination from '../Pagination'
import TransactionHistoryModal from './TransactionHistoryModal'
import TransactionSummary from './TransactionSummary'
import UnlockButton from '../UnlockButton'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import PEFiToken from 'assets/pefi/pefi.png'
import BUSDLogo from 'assets/images/busd-logo.svg'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'
export interface NftCardProps {
  nft: Order
  refresh: () => void
}

const NUMBER_PER_PAGE = 12
const SearchBox = styled.div`
  flex: 1;
  padding: 10px 20px;
`

const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const MarketPlaceBody = styled(AppBody)`
  display: flex;
  flex-direction: row;
  background: url(${filterBg});
`
const FilterPanel = styled.div`
  background-color: #0e2d49;
  flex: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
  padding: 25px 25px;
`
const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  width: 50%;
  margin: 20px auto;
`
const General = styled.div`
  display: grid;
  grid-template-columns: 20% 50% 30%;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    width: 50%;
    margin: 0 auto;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
  `};
`

const OrderArea = styled.div`
  flex: 4;
  background-color: #14324c;
  padding: 15px;
`
const TransactionSummaryContainer = styled.div`
  flex: 4;
  padding: 15px;
`
const FilterTitle = styled.p`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 35px;
  line-height: 43px;
`

const SearchWrapper = styled.div`
  display: block;
  background: url(${SearchBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 110%;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: table;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-size: auto;
  `}
`

const rotate = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #3c556c;
`

const Card = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const CardBody = styled.div`
  overflow: hidden;
  margin-top: 20px;
`

const Select = styled.select`
  background: #0e2d49 url(${button_sort}) no-repeat 16px;
  appearance: none;
  outline: none;
  background-position: 95%;
  font-family: Gudea-Medium;
  font-weight: 400;
  font-size: 24px;
  border: none;
  color: #fff8e8;
  border-radius: 5px;
  width: 238px;
  height: 40px;
  padding: 0px 20px;
  margin: 10px 0;

  :after {
    borr: solid rgba(245, 199, 22, 0.18);
    border-width: 0 3px 3px 0;
    border-radius: 6px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 10px auto;
  `}
`

const Option = styled.option`
  background-color: #022443;
  font-family: Gudea-Medium;
  font-weight: 400;
  border-radius: 5px;
  font-size: 24px;
  color: white;
  padding: 7px 20px;
  margin-top: 10px;

  &:hover {
    color: #8cdd1b;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const PropertyRow = styled(Row)`
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 0px 13px;
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const Property = styled.div`
  display: flex;
  align-items: center;
`
const TransactionSummaryWrapper = styled.div`
  width: 55%;
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `}
`

const LzToken = styled.img`
  animation: ${rotate} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  margin-right: 5px;
`

const Maintain = styled.div`
  color: #fff8e8;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
`

const OpenBeta = styled.div`
  color: #fff8e8;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;
  background: #2f292d;
  box-shadow: 0px 3px #000;
  background: url(${OrderBackground});
  &.rewards {
    grid-template-columns: 25% 50% 25%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: grid;
    grid-template-columns: 100%;
  `}
`
const SideBar = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`
const HeaderNavbar = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`
const NavItem = styled.div`
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-family: Gudea-Medium;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  &.active,
  &:hover {
    background: #14324c;
  }
`

const ButtonDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #03c5ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 12px;
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
`

export default function HeroesMarket({
  setPageState,
  setUniverse,
  currentUniverse,
}: {
  setPageState: (state: string) => void
  setUniverse: (state: string) => void
  currentUniverse: string
}) {
  // modal and loading
  const [{ showConfirm, buyErrorMessage, nft }, setBuyState] = useState<{
    showConfirm: boolean
    buyErrorMessage: string | undefined
    nft: Order | undefined
  }>({
    showConfirm: false,
    buyErrorMessage: undefined,
    nft: undefined,
  })
  const { chainId } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18

  const [statistics, setStatistics] = useState([])
  const [ordersDataSelected, setOrdersDataSelected] = useState<any[]>([])
  useEffect(() => {
    fetchOrders()
    fetchStatistics()
  }, [])

  const fetchOrders = async () => {
    const url = HOLY_ENDPOINT.ORDERS
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        setOrdersDataSelected(response.data.data)
        return response.data.data
      }
    } catch (error: any) {
      console.error('fetchOrders', error)
      // NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return null
  }

  const fetchStatistics = async () => {
    const url = HOLY_ENDPOINT.STATISTICS
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        setStatistics(response.data.data)
        return response.data.data
      }
    } catch (error: any) {
      console.error('fetchStatistics', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return null
  }

  const ordersData = useMemo(
    () =>
      ordersDataSelected.map((order) => {
        const newNft = {
          name: get(order, 'nft.holyType'),
          nftAddress: order.nftAddress,
          tokenId: order.tokenId,
          price: new BigNumber(order.price).div(new BigNumber(10).pow(decimals)).toFixed(),
          blockNumber: order.blockNumber,
          owner: order.seller,
          type: NftType.HERO,
          holyType: get(order, 'nft.holyType'),
        }
        return newNft
      }),
    [ordersDataSelected]
  )

  const [sortOption, setSortOption] = useState<string>('asc-price')
  const [filter, setFilter] = useState<PEBoxFilterConditions>({
    rarityNames: ['green', 'red', 'blue', 'yellow'],
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredOrders = useMemo(() => {
    if (searchValue !== '') {
      return ordersData.filter((order) => {
        return !searchValue
          ? true
          : new RegExp(searchValue.trim(), 'ig').test(order.name) ||
              new RegExp(searchValue.trim(), 'ig').test(order.tokenId)
      })
    }
    if (filter.rarityNames.length === 0) {
      return ordersData
    }
    const [order_type, order_field] = sortOption.split('-')
    return orderBy(
      ordersData.filter((order) => {
        return filter.rarityNames.length > 0 ? includes(filter.rarityNames, order.holyType) : true
      }),
      [order_field == 'price' ? (a) => parseFloat(a.price) : order_field],
      [order_type === 'asc' ? 'asc' : 'desc']
    )
  }, [ordersData, filter, sortOption, searchValue])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const currentOrderList = useMemo(
    () => paginate(filteredOrders, NUMBER_PER_PAGE, currentPage),
    [filteredOrders, currentPage]
  )

  const NftCard = ({ nft }: NftCardProps) => {
    const { account } = useActiveWeb3React()
    const { price } = nft
    return (
      <Card>
        <div style={{ display: 'flex', position: 'relative' }}>
          <Preview nft={nft} />
        </div>
        <CardBody>
          {!account ? (
            <UnlockButton />
          ) : (
            <SellingStatus
              onClick={() => {
                setBuyState({
                  buyErrorMessage: undefined,
                  showConfirm: true,
                  nft: nft,
                })
              }}
            >
              <Text fontSize={20} fontWeight={700} color={'#000'} margin={'0'}>
                {price && <span>{new BigNumber(price).toFixed(2)} BUSD</span>}
              </Text>
            </SellingStatus>
          )}
        </CardBody>
      </Card>
    )
  }

  const [txHash, setTxHash] = useState<string>('')

  const handleConfirmDismiss = useCallback(() => {
    setBuyState({ showConfirm: false, buyErrorMessage, nft: undefined })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      // onUserInput(Field.INPUT, '')
      // TODO Remove from list
    }
    setTxHash('')
  }, [buyErrorMessage, txHash])

  const NftList = ({ nfts }: { nfts: Order[] }) => {
    const handleRefresh = () => {
      console.log('Refresh wallet NFTs')
    }

    if (nfts.length === 0) {
      return (
        <EmptyNft>
          <div>No holy packs available.</div>
        </EmptyNft>
      )
    }

    return (
      <NftGrid>
        {nfts.map((nft: Order, index) => {
          const Card = NftCard

          return (
            // eslint-disable-next-line react/prop-types
            <div key={index}>
              <Card nft={nft} refresh={handleRefresh} />
            </div>
          )
        })}
      </NftGrid>
    )
  }
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  const server = localStorage.getItem('server')

  const marketS1 = useHolyMarketContract()
  const marketS2 = useHolyMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const marketCTF = new web3.eth.Contract(
    PE_HOLY_MARKET_ABI as AbiItem[],
    chainId ? PE_HOLY_MARKET_ADDRESS[chainId] : '0x17E6299758262908B2dEdBB753C4e3454d442B1c'
  )

  const removeSoldOrder = (nftId: any) => {
    const orders = ordersDataSelected.filter((order: any) => order.tokenId !== nftId)
    setOrdersDataSelected(orders)
  }

  async function onConfirm() {
    if (!market || !nft) return
    // eslint-disable-next-line react/prop-types
    const decimal_price = getDecimalAmount(new BigNumber(nft.price), decimals).toFixed()
    const estimate = market.estimateGas.fillOrder
    const method: (...args: any) => Promise<TransactionResponse> = market.fillOrder
    // eslint-disable-next-line react/prop-types
    const args: Array<string | string[] | number> = [nft.nftAddress, nft.tokenId, decimal_price]

    setAttemptingTxn(true)
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })
          .then(async (response) => {
            const txFull = await response.wait()
            console.log('txHash', txFull.transactionHash)
            if (txFull.status === 0) {
              NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
              setAttemptingTxn(false)
              return
            }
            // eslint-disable-next-line react/prop-types
            removeSoldOrder(nft.tokenId)
            await fetchOrders()
            setTimeout(() => {
              NotificationManager.success('Buy Holy success.', 'Success', 2000)
              setAttemptingTxn(false)
              setBuyState({ showConfirm: false, buyErrorMessage, nft: undefined })
            }, 9000)
            // marketCTF.getPastEvents(
            //   'FillOrder',
            //   {
            //     fromBlock: txFull.blockNumber,
            //   },
            //   async (error: any, events) => {
            //     if (error) {
            //       console.error(error)
            //       NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
            //       return
            //     }
            //     console.log('FillOrder', events)
            //     if (Object.values(events).length === 0) {
            //       setAttemptingTxn(false)
            //       NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            //       return
            //     }
            //     fetchOrders()
            //     setTimeout(() => {
            //       NotificationManager.success('Buy Holy success.', 'Success', 2000)
            //       setAttemptingTxn(false)
            //       setBuyState({ showConfirm: false, buyErrorMessage, nft: undefined })
            //     }, 500)
            //     setTxHash(response.hash)
            //   }
            // )
          })
          .catch((error) => {
            setAttemptingTxn(false)
            NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
          })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const [nfts, setNfts] = useState<Order[]>([])

  useEffect(() => {
    ;(async () => {
      setNfts(currentOrderList)
    })()
  }, [currentOrderList])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)

  const [showTransactionHistory, setShowTransactionHistory] = useState(false)
  const [showTransactionDetailModal, setShowTransactionDetail] = useState(false)
  const [transactionDetail, setTransactionDetail] = useState<Transaction>()
  const onOpenTransactionDetail = (transaction: Transaction) => {
    setShowTransactionDetail(true)
    setTransactionDetail(transaction)
  }

  return (
    <>
      <SearchWrapper>
        <TransactionSummaryContainer>
          <TransactionSummaryWrapper>
            <TransactionSummary
              isButtonShow={true}
              onClickButton={() => setShowTransactionHistory(true)}
              statistics={statistics}
            />
          </TransactionSummaryWrapper>
        </TransactionSummaryContainer>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}></div>
      </SearchWrapper>
      <PageContainer style={{ background: '#222938' }}>
        <SideBar></SideBar>
        <HeaderNavbar>
          <NavItem onClick={() => setPageState('heroesmarket')}>PLANTS</NavItem>
          <NavItem onClick={() => setPageState('orbsmarket')}>ORBS</NavItem>
          <NavItem className="active" onClick={() => setPageState('holymarket')}>
            HOLY
          </NavItem>
          <NavItem onClick={() => setPageState('piecemarket')}>PIECE</NavItem>
        </HeaderNavbar>
      </PageContainer>
      <MarketPlaceBody>
        <TransactionHistoryModal
          isOpen={showTransactionHistory}
          onDismiss={() => setShowTransactionHistory(false)}
          onOpenTransactionDetail={onOpenTransactionDetail}
          statistics={statistics}
        />
        <FilterModal isOpen={showFilterModal} onDismiss={() => console.log('dismiss')} minHeight={60} maxHeight={100}>
          <div style={{ padding: '20px', backgroundColor: '#14324c', margin: 'auto', width: '100%' }}>
            <Filter
              id="filter-dialog"
              onConfirm={(newFilter: PEBoxFilterConditions) => {
                setFilter(newFilter)
                setShowFilterModal(false)
              }}
              onCancel={() => {
                setShowFilterModal(false)
                setCurrentPage(1)
              }}
            />
          </div>
        </FilterModal>

        <ConfirmOrderModal
          nft={nft}
          isOpen={showConfirm}
          originalTrade={undefined}
          onAcceptChanges={() => console.log('acc')}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={null}
          allowedSlippage={undefined}
          onConfirm={onConfirm}
          swapErrorMessage={undefined}
          onDismiss={handleConfirmDismiss}
        />

        <FilterPanel>
          <div style={{ marginLeft: '10px' }}>
            <FilterTitle>FILTER FEATURE</FilterTitle>
            <Divider />
          </div>
          <Filter
            id="filter-panel"
            onConfirm={(newFilter: PEBoxFilterConditions) => {
              setFilter(newFilter)
              setCurrentPage(1)
            }}
          />
        </FilterPanel>
        <OrderArea>
          <General>
            <Select
              id="sort-type"
              defaultValue={'asc-price'}
              // @ts-ignore
              onChange={() => setSortOption(document.getElementById('sort-type').value)}
            >
              <Option value={'desc-price'}>Highest Price</Option>
              <Option value={'asc-price'}>Lowest Price</Option>
              <Option value={'desc-blockNumber'}>Latest Price</Option>
            </Select>
            <SearchBox>
              <TextInput
                id={'hero-order'}
                // @ts-ignore
                onUserInput={() => setSearchValue(document.getElementById('hero-order').value)}
                placeholder="Search for items..."
                fontSize="18px"
              />
            </SearchBox>
            {window.innerWidth <= 960 && (
              <StyledFilterButton onClick={() => setShowFilterModal(true)}>
                <Text style={{ marginTop: 13 }} fontWeight={700} fontSize={22}>
                  FILTER
                </Text>
              </StyledFilterButton>
            )}
            {filteredOrders.length > 0 && (
              <Pagination
                currentPage={currentPage}
                onChange={setCurrentPage}
                totalPage={Math.ceil(filteredOrders.length / NUMBER_PER_PAGE)}
              />
            )}
          </General>
          <NftList nfts={nfts} />
          {/* <Maintain>
            <div style={{ textAlign: 'center' }}>
              <div>The Marketplace is currently under maintenance, please come back later.</div>
              <div>Thank you!</div>
            </div>
          </Maintain> */}
          {/* <OpenBeta>
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  fontSize: window.innerWidth < 500 ? 35 : 50,
                  fontFamily: 'Montio-bold',
                  padding: '0 0 100px 0',
                }}
              >
                NOTHING HERE YET ...
              </div>
              <div>The Marketplace is closed during HeroFi&apos;s open beta to prevent potential loss for players.</div>
            </div>
          </OpenBeta> */}
        </OrderArea>
      </MarketPlaceBody>
      <Footer />
    </>
  )
}
