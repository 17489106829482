/* eslint-disable react/prop-types */
import { TransactionResponse } from '@ethersproject/providers'
import PE_PLANT_HERO_NFT_ABI from 'abis/pe-plant-hero-nft-abi.json'
import axios from 'axios'
import BoxesFilter from 'components/Filters/ChestFilter/Filter'
import PieceFilter from 'components/Filters/PieceFilter/Filter'
import { Modal } from 'antd'
import GiftModal from 'components/MyAssets/Plant/GiftModal'
import Text from 'components/Text'
import {
  PE_PLANT_HERO_CHEST_ADDRESS,
  PE_PLANT_HERO_NFT_ADDRESS,
  S2_PLANT_HERO_CHEST_ADDRESS,
} from 'constants/addresses'
import { RPC_ENDPOINT_MAINNET, RPC_ENDPOINT_TESTNET } from 'constants/endpoints'
import { CHEST_ENDPOINT, HERO_MARKET_SERVER } from 'constants/mkpconfigs'
import { LZ } from 'constants/tokens'
import { Nft, PEBoxFilterConditions } from 'constants/types'
import { usePlantHeroBoxContract, usePlantHeroBoxContractS2 } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import includes from 'lodash/includes'
import { BoxConfig } from 'pages/SpecialPack/GachaBox/box-config'
import React, { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styled from 'styled-components'
import paginate from 'utils/paginate'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import NftGrid from 'pages/AccountPage/MyAssets/PEFI/Plants/NftGrid'
import Pagination from 'pages/AccountPage/MyAssets/PEFI/Pagination'
import UnlockButton from 'pages/AccountPage/MyAssets/UnlockButton'
import DetailBox from './Details'
import Result from './Result'
import { PieceConfig } from 'pages/SpecialPack/GachaBox/piece-config'
import Piece from './Piece'

const NUMBER_PER_PAGE = 12
const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`
const General = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

const NFTArea = styled.div`
  flex: 4;
  padding: 30px;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const HeaderItem = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin-top: 30px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
  `}
`

const ItemType = styled.div`
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  font-family: Gudea-Medium;
  border: 2px solid #03c5ff;
  padding: 7px 10px 3px;
  border-radius: 10px;
  min-width: 200px;
  color: #fff;
  background: #1b405c;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 0;
    font-size: 20px;
  `}
  &.active {
    border: 2px solid #9ce315;
    background: #9ce315;
  }
`

export const Checkbox = styled.input`
  background-color: #cdc2b7;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 3px solid #cdc2b7;
  border-radius: 5px;
  margin: 0;
  :checked {
    appearance: none;
    border-radius: 5px;
    border: 3px solid #cdc2b7;
    background-color: #984b00;
  }
`

const EmptyNft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  color: #fff8e8;
  font-size: 25px;
  .container {
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 22px;
      line-height: 36px;
      .claim-gift-hero {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background-color: transparent;
  width: 100%;
  max-width: 100vw;
  &.rewards {
    grid-template-columns: 25% 50% 25%;
  }
  &.main-content {
    ${({ theme }) => theme.mediaWidth.upToMedium`
      grid-template-columns: 100%;
    `}
  }
`

const StyledFilterButton = styled.div`
  background: #9ce315;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 30px 3px;
  max-width: 200px;
  margin: 30px auto 0;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 25%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
  `}
`

const ListHeroContainer = styled.div`
  padding: 25px;
  background: #14324c50;
  border-radius: 10px;
`

const ChestCardContainer = styled.div`
  background-color: #026092;
  overflow: hidden;
  border: 2px solid #03c5ff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
`

const GachaBoxPreview = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 50px auto;
`

const ActionButtons = styled.div`
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  font-weight: 500;
  color: #fff;
  background: #9ce315;
  font-size: 16px;
  height: 35px;
  padding: 7px 15px 5px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &.disable {
    cursor: not-allowed;
  }
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #03c5ff;
  background: #013b59;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxName = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #f2c32a;
  font-family: Montio;
`

const BoxId = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  color: #fff;
  font-family: Montio;
`

const FilterModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: rgb(0, 153, 226);
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #02609299 !important;
    border: 2px solid rgb(3, 197, 255);
    border-radius: 10px !important;
    .ant-modal-close {
      color: #fff !important;
      border-radius: 50% !important;
      background: #1bb9e4 !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

export default function Chest() {
  // Define state
  const [{ showGift, giftErrorMessage, nftGiftSelected }, setGiftState] = useState<{
    showGift: boolean
    giftErrorMessage: string | undefined
    nftGiftSelected: Nft | undefined
  }>({
    showGift: false,
    giftErrorMessage: undefined,
    nftGiftSelected: undefined,
  })

  const [{ chestShowDetail, isShowChestDetail }, setShowChestDetails] = useState<{
    chestShowDetail: any
    isShowChestDetail: boolean
  }>({
    chestShowDetail: undefined,
    isShowChestDetail: false,
  })

  const [nfts, setNfts] = useState<any[]>([])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [isShowResult, setIsShowResult] = useState<boolean>(false)
  const [itemsResult, setItemsResult] = useState<any>(null)

  const { chainId, account } = useActiveWeb3React()
  const decimals: number = chainId ? LZ[56].decimals : 18

  const web3 = new Web3(
    chainId === 56
      ? RPC_ENDPOINT_MAINNET
      : chainId === 97
      ? RPC_ENDPOINT_TESTNET
      : 'https://nd-299-786-132.p2pify.com/30ca5b15debfd3fc0b74f8c9a3901363' //others rpc mainnet
  )

  const server = localStorage.getItem('server')

  const boxContract = usePlantHeroBoxContract()
  const boxContractS2 = usePlantHeroBoxContractS2()

  const useContract = server ? (server === 's1' ? boxContract : boxContractS2) : boxContract

  const CHEST_CONTRACT_ADDRESS = server
    ? server === 's1'
      ? PE_PLANT_HERO_CHEST_ADDRESS
      : S2_PLANT_HERO_CHEST_ADDRESS
    : PE_PLANT_HERO_CHEST_ADDRESS

  const plantCTF = new web3.eth.Contract(
    PE_PLANT_HERO_NFT_ABI as AbiItem[],
    chainId ? PE_PLANT_HERO_NFT_ADDRESS[chainId] : '0x482E3f3965274FFdD03A839eEb8c8C828d99615D'
  )

  useEffect(() => {
    fetchAllBoxes(account)
  }, [account])

  const [myNftsData, setMyNftsData] = useState<any[]>([])

  const fetchAllBoxes = async (owner: any) => {
    const boxes = await getNftsOfOwner(owner)
    const sorted_boxes = boxes.sort((a: any, b: any) => a.boxType - b.boxType)
    setMyNftsData(sorted_boxes)
  }

  const getNftsOfOwner = async (owner: any) => {
    const url = `${CHEST_ENDPOINT.NFTS}/${owner}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response.data.data
      }
    } catch (error: any) {
      console.error('getNftsOfOwner', error)
      // NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
    }
    return []
  }

  const [filter, setFilter] = useState<PEBoxFilterConditions>({
    rarityNames: [],
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredOrders = useMemo(() => {
    if (filter.rarityNames.length === 0) return myNftsData
    return myNftsData.filter((order) => {
      console.log(filter.rarityNames)
      return filter.rarityNames.length > 0 ? includes(filter.rarityNames, order.boxType.toString()) : true
    })
  }, [myNftsData, filter, searchValue])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const currentOrderList = useMemo(
    () => paginate(filteredOrders, NUMBER_PER_PAGE, currentPage),
    [filteredOrders, currentPage]
  )

  useEffect(() => {
    ;(async () => {
      setNfts(currentOrderList)
    })()
  }, [currentOrderList])

  // const bnbFee = getDecimalAmount(new BigNumber(0), 18).toString()

  async function onGiftConfirm(nft: Nft, receiver: string) {
    if (!boxContract || !nft || !account) return

    const method: (...args: any) => Promise<TransactionResponse> = boxContract.transferFrom
    const args: Array<string | string[] | number> = [account, receiver, nft.tokenId]

    method(...args, {})
      .then(async (response) => {
        const txFull = await response.wait()
        if (txFull) {
          NotificationManager.success('Gift hero completed.', 'Success', 2000)
          setTimeout(() => {
            fetchAllBoxes(account)
            setGiftState({
              nftGiftSelected: undefined,
              showGift: false,
              giftErrorMessage,
            })
          }, 2000)
        }
      })
      .catch((error) => {
        NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const decode_log = async (logs: any) => {
    const log = logs[logs.length - 1]
    const inputs = [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint8', name: 'boxId', type: 'uint8' },
      { indexed: false, internalType: 'uint256', name: 'heroId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256' },
    ]
    const decoded = web3.eth.abi.decodeLog(inputs, log.data, [log.topics[1]])
    const heroId = decoded.heroId
    return heroId
  }

  async function openBox(chest: any) {
    console.log('open box', chest)
    const chestId = chest.tokenId
    setAttemptingTxn(true)
    try {
      if (!useContract || !account) return
      const args: Array<string | string[] | number> = [[chestId]]
      useContract
        .openBox(...args, { from: account })
        .then(async (response: TransactionResponse) => {
          const txHash = response.hash
          if (!txHash) {
            setAttemptingTxn(false)
            NotificationManager.error('Transaction failed. Please try again.', 'Error', 2000)
            return
          }

          const txFull = await response.wait()
          console.log('txFull', txFull)
          const logs = txFull.logs
          const this_contract_log = logs.filter((log: any) => log.address === CHEST_CONTRACT_ADDRESS[chainId || 56])
          const plant_id = await decode_log(this_contract_log)
          const interval = setInterval(async () => {
            const plant = await getPlantById(plant_id)
            if (!plant) return
            setItemsResult([plant])
            setIsShowResult(true)
            setAttemptingTxn(false)
            fetchAllBoxes(account)
            clearInterval(interval)
          }, 3000)
        })
        .catch((error: any) => {
          console.error('Error', error)
          NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
          setAttemptingTxn(false)
        })
    } catch (error: any) {
      console.error('Error: xxxxxxx', error)
      NotificationManager.error(error.data ? error.data.message : error.message, 'Error', 2000)
      setAttemptingTxn(false)
    }
  }

  const getPlantById = async (heroId: any) => {
    const url = `${HERO_MARKET_SERVER}/plant-empire/plants/${heroId}`
    try {
      const response = await axios.get(url)
      if (response.status !== 200 || !response.data || response.data.data[0] === null) return undefined
      return response.data.data
    } catch (error: any) {
      console.error(error)
      return undefined
    }
  }

  const NftList = ({ nfts }: { nfts: any[] }) => {
    if (nfts.length === 0) {
      return (
        <EmptyNft>
          <div>No chests available.</div>
        </EmptyNft>
      )
    }

    if (nfts.length === 0) {
      return <></>
    }

    return (
      <ListHeroContainer>
        <NftGrid>
          {nfts.map((nft: any, index) => {
            return (
              // eslint-disable-next-line react/prop-types
              <div key={index}>
                <ChestCard nft={nft} />
              </div>
            )
          })}
        </NftGrid>
      </ListHeroContainer>
    )
  }

  const ChestCard = ({ nft }: any) => {
    const box = BoxConfig.find((b: any) => b.box_id === nft.boxType)
    if (!box) return null
    return (
      <ChestCardContainer>
        <Container>
          <BoxName>{box.name}</BoxName>
          <GachaBoxPreview src={box.img} />
          <BoxId>#{nft.tokenId}</BoxId>
        </Container>
        {!account ? (
          <ActionButtons>
            <UnlockButton />
          </ActionButtons>
        ) : (
          <ActionButtons>
            <Button
              onClick={() =>
                setShowChestDetails({
                  chestShowDetail: nft,
                  isShowChestDetail: true,
                })
              }
            >
              OPEN
            </Button>
          </ActionButtons>
        )}
      </ChestCardContainer>
    )
  }

  const onDismissChestDetailModal = (chestId: any) => {
    setShowChestDetails({
      chestShowDetail: undefined,
      isShowChestDetail: false,
    })
  }

  const onDismissResultlModal = () => {
    setIsShowResult(false)
    setItemsResult(undefined)
    setShowChestDetails({
      chestShowDetail: undefined,
      isShowChestDetail: false,
    })
  }

  const renderModals = () => {
    return (
      <>
        <FilterModal
          title="FILTER"
          visible={showFilterModal}
          onCancel={() => setShowFilterModal(false)}
          footer={null}
          className="select-server"
        >
          <div style={{ padding: '0 30px 30px', margin: 'auto', width: '100%' }}>
            <BoxesFilter
              id="filter-dialog"
              onConfirm={(newFilter: PEBoxFilterConditions) => {
                setFilter(newFilter)
                setShowFilterModal(false)
              }}
              onCancel={() => {
                setShowFilterModal(false)
              }}
            />
          </div>
        </FilterModal>
        <GiftModal
          onGift={onGiftConfirm}
          attemptingTxn={false}
          isOpen={showGift}
          onDismiss={() => setGiftState({ nftGiftSelected: undefined, showGift: false, giftErrorMessage })}
          nft={nftGiftSelected}
        />

        {isShowChestDetail && (
          <DetailBox
            attemptingTxn={attemptingTxn}
            onConfirm={openBox}
            onDismiss={onDismissChestDetailModal}
            chest={chestShowDetail}
          />
        )}

        {isShowResult && <Result onDismiss={onDismissResultlModal} items={itemsResult} />}
      </>
    )
  }

  return (
    <PageContainer className="main-content">
      {renderModals()}
      <StyledFilterButton onClick={() => setShowFilterModal(true)}>
        <Text fontWeight={700} fontSize={22}>
          FILTER
        </Text>
      </StyledFilterButton>
      <NFTArea>
        <NftList nfts={nfts} />
        <General>
          <TabWrapper>
            {filteredOrders.length > 0 && (
              <Pagination
                currentPage={currentPage}
                onChange={setCurrentPage}
                totalPage={Math.ceil(filteredOrders.length / NUMBER_PER_PAGE)}
              />
            )}
          </TabWrapper>
        </General>
      </NFTArea>
    </PageContainer>
  )
}
