import React from 'react'
import { Text } from 'rebass'
import { Nft } from 'constants/types'
import styled from 'styled-components'
import Preview from 'components/PreviewCard/PlantCardPreview/Preview'
import Generation from 'components/Generation'

const Container = styled.div`
  flex: 1;
`

const Card = styled.div`
  background-color: transparent;
  overflow: hidden;
`

const CardBody = styled.div`
  overflow: hidden;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`
const PropertyRow = styled(Row)`
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 5px 13px;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
`
const Property = styled.div`
  color: #fff8e8;
  display: flex;
  align-items: center;
`
export default function Item({ nft }: { nft: Nft }) {
  const { className, rarityName } = nft
  return (
    <Container>
      <Card>
        <div style={{ display: 'flex', position: 'relative' }}>
          <Preview nft={nft} />
        </div>
        <CardBody>
          <PropertyRow>
            <Property>
              <PropertyName>Rarity:&nbsp;</PropertyName>
              <Text style={{ color: '#9ce315', fontSize: 16 }}>{rarityName}</Text>
            </Property>
            <div style={{ padding: '0 5px' }}>|</div>
            <Property>
              <PropertyName>Class:&nbsp;</PropertyName>
              <Text style={{ color: '#9ce315', fontSize: 16 }}>{className}</Text>
            </Property>
          </PropertyRow>
        </CardBody>
      </Card>
    </Container>
  )
}
