export function getHeroClassName(classType: number) {
  switch (classType) {
    case 1:
      return 'Farmer'
    case 2:
      return 'DPS'
    case 3:
      return 'Tanker'
    case 4:
      return 'Supporter'
    default:
      return 'Farmer'
  }
}

export function getHeroRarity(rarity: number) {
  switch (rarity) {
    case 1:
      return 'Common'
    case 2:
      return 'Uncommon'
    case 3:
      return 'Rare'
    case 4:
      return 'Mythical'
    case 5:
      return 'Legendary'
    default:
      return 'Common'
  }
}

export function getOrbCdn(skinId: number, rarity: number) {
  switch (rarity) {
    case 3:
      return `https://plantempires-media.b-cdn.net/orb/${skinId}_1.png`
    case 4:
      return `https://plantempires-media.b-cdn.net/orb/${skinId}_2.png`
    case 5:
      return `https://plantempires-media.b-cdn.net/orb/${skinId}_3.png`
    default:
      return ''
  }
}

export function check_can_be_evolve(nft: any) {
  switch (nft.star) {
    case 3:
      if (nft.level === 50) return true
      return false
    case 4:
      if (nft.level === 60) return true
      return false
    case 5:
      if (nft.level === 80) return true
      return false
    default:
      return false
  }
}
