import { Col, Row } from 'antd'
import Footer from 'components/Footer'
import AppBody from 'pages/AppBody'
import React, { useState } from 'react'
import styled from 'styled-components'
import Dashboard from 'pages/AccountPage/Dashboard'
import Checkin from 'pages/AccountPage/Checkin'
import MintTicket from 'pages/AccountPage/MintTicket'
import Token from 'pages/AccountPage/TokensPage'
import Assets from 'pages/AccountPage/MyAssets'
import Fusion from 'pages/AccountPage/Fusion'
import ChestCombine from 'pages/AccountPage/ChestCombine'
import { Redirect, RouteComponentProps } from 'react-router-dom'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'
const PageBody = styled(AppBody)`
  display: flex;
  flex-direction: row;
  max-width: 2000px;
  background: url(${filterBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #14324c;
`

const SideBar = styled(Col)`
  background: #14324c50;
  padding: 30px;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 20px;
  `};
`

const SideBarMobile = styled(Col)``

const SideBarMobileRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #222938;
  margin-bottom: 5px;
`

const PageContent = styled(Col)``

const SidebarTitle = styled.div`
  color: #fff8e8;
  font-family: Gudea-Medium;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  margin-top: 30px;
  cursor: pointer;
  &:hover,
  &.active {
    color: #47ced2;
  }
  ${({ theme }) => theme.mediaWidth.upTo1440`
    font-size: 20px;
    line-height: 35px;
    margin-top: 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 16px;
    line-height: 35px;
    margin-top: 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
  `};
`

const SidebarTitleMobile = styled(SidebarTitle)`
  font-size: 16px;
  margin-top: 0;
  padding: 0 20px;
  text-align: center;
  &:hover,
  &.active {
    color: #fff;
    background: rgb(20, 50, 76);
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-top: 0px;
  `};
`

export default function AccountPage({ location }: RouteComponentProps) {
  const [pageState, setPageState] = useState<string>('dashboard')

  const server = localStorage.getItem('server')
  if (!server) return <Redirect to={{ ...location, pathname: '/' }} />

  const renderContent = (pageState: any) => {
    if (pageState === 'dashboard') {
      return <Dashboard />
    }
    if (pageState === 'checkin') {
      return <Checkin setPageState={(state: string) => setPageState(state)} />
    }
    if (pageState === 'mint-ticket') {
      return <MintTicket setPageState={(state: string) => setPageState(state)} />
    }
    if (pageState === 'token') {
      return <Token setPageState={(state: string) => setPageState(state)} />
    }
    if (pageState === 'assets') {
      return <Assets setPageState={(state: string) => setPageState(state)} />
    }
    if (pageState === 'fusion') {
      return <Fusion setPageState={(state: string) => setPageState(state)} />
    }
    if (pageState === 'chest-combine') {
      return <ChestCombine setPageState={(state: string) => setPageState(state)} />
    }

    return <Dashboard />
  }

  return (
    <>
      <PageBody {...{ maxWidth: '2000px' }}>
        <Row style={{ width: '100vw' }}>
          <SideBar xs={0} sm={0} md={0} lg={4} xl={4}>
            <SidebarTitle
              className={pageState === 'dashboard' ? 'active' : ''}
              onClick={() => setPageState('dashboard')}
            >
              DASHBOARD
            </SidebarTitle>
            <SidebarTitle className={pageState === 'assets' ? 'active' : ''} onClick={() => setPageState('assets')}>
              ASSETS
            </SidebarTitle>
            <SidebarTitle className={pageState === 'token' ? 'active' : ''} onClick={() => setPageState('token')}>
              TOKEN
            </SidebarTitle>
            <SidebarTitle className={pageState === 'fusion' ? 'active' : ''} onClick={() => setPageState('fusion')}>
              FUSION
            </SidebarTitle>
            <SidebarTitle
              className={pageState === 'chest-combine' ? 'active' : ''}
              onClick={() => setPageState('chest-combine')}
            >
              CHEST COMBINE
            </SidebarTitle>
            <SidebarTitle className={pageState === 'checkin' ? 'active' : ''} onClick={() => setPageState('checkin')}>
              CHECK-IN
            </SidebarTitle>
            <SidebarTitle
              className={pageState === 'mint-ticket' ? 'active' : ''}
              onClick={() => setPageState('mint-ticket')}
            >
              MINT TICKET
            </SidebarTitle>
          </SideBar>
          <SideBarMobile xs={24} sm={24} md={24} lg={0} xl={0}>
            <SideBarMobileRow>
              <SidebarTitleMobile
                className={pageState === 'dashboard' ? 'active' : ''}
                onClick={() => setPageState('dashboard')}
              >
                DASHBOARD
              </SidebarTitleMobile>
              <SidebarTitleMobile
                className={pageState === 'assets' ? 'active' : ''}
                onClick={() => setPageState('assets')}
              >
                ASSETS
              </SidebarTitleMobile>
              <SidebarTitleMobile
                className={pageState === 'token' ? 'active' : ''}
                onClick={() => setPageState('token')}
              >
                TOKEN
              </SidebarTitleMobile>
              <SidebarTitleMobile
                className={pageState === 'fusion' ? 'active' : ''}
                onClick={() => setPageState('fusion')}
              >
                FUSION
              </SidebarTitleMobile>
              <SidebarTitleMobile
                className={pageState === 'chest-combine' ? 'active' : ''}
                onClick={() => setPageState('chest-combine')}
              >
                CHEST COMBINE
              </SidebarTitleMobile>
              <SidebarTitleMobile
                className={pageState === 'checkin' ? 'active' : ''}
                onClick={() => setPageState('checkin')}
              >
                CHECK-IN
              </SidebarTitleMobile>
              <SidebarTitleMobile
                className={pageState === 'mint-ticket' ? 'active' : ''}
                onClick={() => setPageState('mint-ticket')}
              >
                MINT TICKET
              </SidebarTitleMobile>
            </SideBarMobileRow>
          </SideBarMobile>
          <PageContent xs={24} sm={24} md={24} lg={20} xl={20}>
            {renderContent(pageState)}
          </PageContent>
        </Row>
      </PageBody>
      <Footer />
    </>
  )
}
