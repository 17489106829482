import { parseUnits } from '@ethersproject/units'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import sellingBg from 'assets/pefi/my-assets/selling-button.png'
import Preview from 'components/PreviewCard/PlantCardPreview/Preview'
import { Order } from 'constants/types'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import useBUSDAddress from 'hooks/useBUSDAddress'
import { usePlantHeroMarketContract, usePlantHeroMarketContractS2 } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import React, { ReactNode, useState, useEffect } from 'react'
import { Text } from 'rebass'
import styled, { keyframes } from 'styled-components'
import { Dots, SwapCallbackError } from '../styleds'

const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`
const Container = styled.div`
  flex: 1;
`
const NftPrice = styled.div`
  padding-top: 4px;
  flex: 2;
  display: flex;
`

const rotate = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`
const LzToken = styled.img`
  animation: ${rotate} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  margin-right: 5px;
`
const Price = styled.div`
  display: flex;
  align-items: center;
`

const NftActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const NftApprove = styled.div`
  flex: 1;
`
const NftConfirm = styled.div`
  flex: 1;
`
const NftImage = styled.div`
  padding-top: 4px;
  flex: 2;
`
const OtherUniverses = styled.div`
  text-align: center;
`
const Title = styled.div`
  font-family: 'Gudea-Bold';
  font-size: 25px;
  font-weight: bold;
  color: #d47b1f;
`
const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #9b8e8e;
`
const AvatarBlank = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
`

const TokenId = styled.div`
  background: #3a3838;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  min-height: 32px;
`

const UniverseName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fff8e8;
`

const SellingStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 9px;
  display: flex;
  width: 100%;
  align-self: center;
  border-radius: 5px;
  background: url(${sellingBg});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`

export default function BuyItem({
  onConfirm,
  swapErrorMessage,
  attemptingTxn,
  disabledConfirm,
  nft,
}: {
  onConfirm: () => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
  attemptingTxn: boolean
  nft: Order
}) {
  const { account } = useActiveWeb3React()

  const BUSD_ADDRESS = useBUSDAddress()
  const busdCurrency = useCurrency(BUSD_ADDRESS)

  const { price } = nft
  const typedValueParsed = parseUnits(price, busdCurrency?.decimals).toString()
  let amount
  if (busdCurrency instanceof Token) {
    amount = CurrencyAmount.fromRawAmount(busdCurrency, JSBI.BigInt(typedValueParsed))
  }

  const server = localStorage.getItem('server')

  const marketS1 = usePlantHeroMarketContract()
  const marketS2 = usePlantHeroMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const [approvalBUSD, approvalBUSDCallback] = useApproveCallback(amount, market?.address)
  const [show_remain_confirm_tx, set_show_remain_confirm_tx] = useState<boolean>(false)

  useEffect(() => {
    if (approvalBUSD === ApprovalState.APPROVED || approvalBUSD === ApprovalState.PENDING) {
      set_show_remain_confirm_tx(false)
    }
  }, [approvalBUSD])

  return (
    <Container>
      <NftImage>{nft && <Preview nft={nft}></Preview>}</NftImage>
      {nft.owner !== account && (
        <NftActions>
          {approvalBUSD !== ApprovalState.APPROVED ? (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {show_remain_confirm_tx ? (
                <SellingStatus>
                  <Dots>LOADING</Dots>
                </SellingStatus>
              ) : (
                <SellingStatus
                  onClick={() => {
                    set_show_remain_confirm_tx(true)
                    approvalBUSDCallback().catch((error) => {
                      set_show_remain_confirm_tx(false)
                    })
                  }}
                >
                  {approvalBUSD === ApprovalState.UNKNOWN ? (
                    <Dots>WAITING</Dots>
                  ) : approvalBUSD === ApprovalState.PENDING ? (
                    <Dots>APPROVING</Dots>
                  ) : (
                    <>APPROVE BUSD</>
                  )}
                </SellingStatus>
              )}
            </div>
          ) : (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SellingStatus onClick={onConfirm}>
                <Text fontSize={20} fontWeight={700} color={'#000'}>
                  {attemptingTxn ? (
                    <Dots>LOADING</Dots>
                  ) : (
                    <span>
                      {/* <LzToken width="20px" src={PEFiToken} /> */}
                      {nft?.price} BUSD
                    </span>
                  )}
                </Text>
              </SellingStatus>
            </div>
          )}
          {show_remain_confirm_tx && (
            <div style={{ textAlign: 'center', marginTop: '10px', color: '#fff' }}>
              Please confirm tx on your wallet
            </div>
          )}
        </NftActions>
      )}
      {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
    </Container>
  )
}
