import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import React, { ReactNode, useCallback, useMemo, useState, useEffect } from 'react'
import { TransactionErrorContent } from '../../../TransactionConfirmationModal'
import { Nft } from 'constants/types'
import SellModalContent from './SellModalContent'
import Preview from 'components/PreviewCard/PlantCardPreview/Preview'
import Text, { MagraText } from 'components/Text'
import { SwapCallbackError } from '../../styleds'
import styled, { keyframes } from 'styled-components'
import Modal from './Modal'
import { usePlantHeroMarketContract, usePlantHeroMarketContractS2 } from 'hooks/useContract'
import { ApprovalState, useApproveERC721Callback } from 'hooks/useApproveCallback'
import { Dots } from 'pages/styleds'
import { PE_PLANT_HERO_NFT_ADDRESS, S2_PLANT_HERO_NFT_ADDRESS } from 'constants/addresses'
import buttonCancel from 'assets/pefi/my-assets/sell-modal/cancel.png'
import buttonSell from 'assets/pefi/my-assets/sell-modal/confirm.png'
import buttonSelling from 'assets/pefi/my-assets/sell-modal/loading.png'
import buttonApprove from 'assets/pefi/my-assets/sell-modal/approve.png'
import buttonApproving from 'assets/pefi/my-assets/sell-modal/approving.png'

const Card = styled.div`
  background-color: transparent;
  overflow: hidden;
  padding: 20px;
  flex: 1;
`
const Divider = styled.div`
  height: 3px;
  width: 100%;
  background-color: #41393e;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`

const PropertyRow = styled(Row)`
  background-color: #015282;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
  padding: 0px 13px;
`
const Property = styled.div`
  display: flex;
  align-items: center;
`
const PropertyName = styled(Text)`
  font-weight: 700;
  size: 23px;
  padding-right: 5px;
  margin-right: 30px;
`
const CardBody = styled.div`
  overflow: hidden;
`
const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    height: 100%;
  `};
`
const Container = styled.div`
  flex: 1;
  align-items: stretch;
  flex-direction: column;
`
const NftImage = styled.div`
  padding-top: 4px;
  flex: 2;
`
const Action = styled.div`
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 30px;
`
const Title = styled.p`
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #fff8e8;
  font-family: Montio;
`
const PriceContainer = styled.div`
  width: 100%;
  padding: 20px;
`
const PriceContent = styled.div`
  padding: 15px;
  border: none;
  border-radius: 6px;
`
const PriceContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00436d;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
`
const PriceInput = styled.input`
  background: transparent;
  width: 152px;
  color: #fff;
  appearance: none;
  font-family: Gudea-Medium;
  font-size: 23px;
  height: 40px;
  border: none;
  outline: none;
  padding: 5px 18px 5px 30px;
  text-align: right;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-ms-input-placeholder {
    font-family: Gudea-Medium;
  }

  :-moz-placeholder {
    font-family: Gudea-Medium;

    ::-moz-placeholder {
      font-family: Gudea-Medium;
    }
`

const rotate = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`

const BUSDToken = styled.img`
  animation: ${rotate} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  margin-right: 5px;
  position: relative;
  top: 2px;
`

const TextWithCurrency = styled.div`
  font-size: 23px;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const ButtonSell = styled.img`
  cursor: pointer;
  margin-bottom: 20px;
`

function SellItem({
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  onDismiss,
  nft,
  attemptingTxn,
}: {
  onConfirm: (price: string) => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
  onDismiss: () => void
  nft: Nft
  attemptingTxn: boolean
}) {
  const { className, rarityName, tokenId } = nft
  const [price, setPrice] = useState('0')
  const TRADING_FEE = 4 // %
  const yourReceived = useMemo(() => parseFloat(price) && (parseFloat(price) * (100 - TRADING_FEE)) / 100, [price])

  const server = localStorage.getItem('server')

  const marketS1 = usePlantHeroMarketContract()
  const marketS2 = usePlantHeroMarketContractS2()
  const market = server ? (server === 's1' ? marketS1 : marketS2) : marketS1

  const nftAddress = server
    ? server === 's1'
      ? PE_PLANT_HERO_NFT_ADDRESS[56]
      : S2_PLANT_HERO_NFT_ADDRESS[56]
    : PE_PLANT_HERO_NFT_ADDRESS[56]

  const isConfirmDisabled = !price || parseFloat(price) <= 0

  const [approvalHero, approvalHeroCallback] = useApproveERC721Callback(nftAddress, tokenId, market?.address)
  const [show_remain_confirm_tx, set_show_remain_confirm_tx] = useState<boolean>(false)

  useEffect(() => {
    if (approvalHero === ApprovalState.APPROVED || approvalHero === ApprovalState.PENDING) {
      set_show_remain_confirm_tx(false)
    }
  }, [approvalHero])

  return (
    <Container>
      <Main>
        <Card>
          <div style={{ display: 'flex', position: 'relative' }}>
            <Preview nft={nft} />
          </div>
          <CardBody>
            <PropertyRow>
              <Property>
                <PropertyName>Rarity:&nbsp;</PropertyName>
                <Text style={{ color: '#9ce315', fontSize: 16 }}>{rarityName}</Text>
              </Property>
              <div style={{ padding: '0 5px' }}>|</div>
              <Property>
                <PropertyName>Class:&nbsp;</PropertyName>
                <Text style={{ color: '#9ce315', fontSize: 16 }}>{className}</Text>
              </Property>
            </PropertyRow>
          </CardBody>
        </Card>
        <PriceContainer>
          <PriceContent>
            <PriceContentRow>
              <PropertyName>Price:&nbsp;</PropertyName>
              <PriceInput placeholder="Enter price" type="number" onChange={(event) => setPrice(event.target.value)} />
            </PriceContentRow>
            <PriceContentRow>
              <PropertyName>Trading Fee:</PropertyName>
              <Text fontSize={23}>{TRADING_FEE}%</Text>
            </PriceContentRow>
            <PriceContentRow>
              <PropertyName>You&apos;ll Receive:&nbsp;</PropertyName>
              <TextWithCurrency>{isNaN(yourReceived) ? 0 : yourReceived.toFixed(2)} BUSD</TextWithCurrency>
            </PriceContentRow>
          </PriceContent>
          <Action>
            <div style={{ width: '100%' }}>
              <ButtonSell src={buttonCancel} onClick={onDismiss} />
            </div>
            {!attemptingTxn && approvalHero !== ApprovalState.APPROVED ? (
              <div style={{ width: '100%' }}>
                {show_remain_confirm_tx ? (
                  <ButtonSell style={{ cursor: 'not-allowed' }} src={buttonSelling} />
                ) : (
                  <>
                    {approvalHero === ApprovalState.PENDING ? (
                      <ButtonSell style={{ cursor: 'not-allowed' }} src={buttonApproving} />
                    ) : (
                      <ButtonSell
                        src={buttonApprove}
                        onClick={() => {
                          set_show_remain_confirm_tx(true)
                          approvalHeroCallback().catch((error) => {
                            set_show_remain_confirm_tx(false)
                          })
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                {attemptingTxn ? (
                  <ButtonSell style={{ cursor: 'not-allowed' }} src={buttonSelling} />
                ) : (
                  <>
                    {isConfirmDisabled ? (
                      <ButtonSell src={buttonSell} style={{ cursor: 'not-allowed', opacity: '0.3' }} />
                    ) : (
                      <ButtonSell
                        src={buttonSell}
                        onClick={() => {
                          onConfirm(price)
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            {show_remain_confirm_tx && (
              <div style={{ textAlign: 'center', marginTop: '10px', color: '#fff' }}>
                Please confirm tx on your wallet
              </div>
            )}
          </Action>
        </PriceContainer>
      </Main>
      {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
    </Container>
  )
}

export default function SellModal({
  onConfirm,
  onDismiss,
  sellErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  nft,
}: {
  isOpen: boolean
  originalTrade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent | undefined
  onAcceptChanges: () => void
  onConfirm: (price: string) => void
  sellErrorMessage: ReactNode | undefined
  onDismiss: () => void
  nft: Nft | undefined
}) {
  if (txHash) onDismiss()
  const content = useCallback(() => {
    return nft ? (
      <SellItem
        onConfirm={onConfirm}
        disabledConfirm={false}
        swapErrorMessage={sellErrorMessage}
        nft={nft}
        onDismiss={onDismiss}
        attemptingTxn={attemptingTxn}
      />
    ) : null
  }, [onConfirm, sellErrorMessage])

  // text to show while loading
  const pendingText = <Trans>Selling...</Trans>

  const confirmationContent = useCallback(
    () =>
      sellErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={sellErrorMessage} />
      ) : (
        <SellModalContent onDismiss={onDismiss} content={content} />
      ),
    [onDismiss, content, sellErrorMessage]
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {confirmationContent()}
    </Modal>
  )
}
