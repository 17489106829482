import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import React, { ReactNode, useCallback } from 'react'
import TransactionConfirmationModal, { TransactionErrorContent } from '../../../TransactionConfirmationModal'
import HeroItem from './HeroItem'
import ItemDetail from './ItemDetail'
import { Nft } from '../../../../constants/types'
import BuyModalContent from '../../../../pages/MarketPlace/PEFI/BuyModalContent'

export default function DetailModal({
  onAcceptChanges,
  allowedSlippage,
  onSellClick,
  onUpgradeStarClick,
  onWithDrawClick,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  nft,
}: {
  isOpen: boolean
  originalTrade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent | undefined
  onAcceptChanges: () => void
  onSellClick: () => void
  onUpgradeStarClick: () => void
  onWithDrawClick: () => void
  swapErrorMessage: ReactNode | undefined
  onDismiss: () => void
  nft: Nft | undefined
}) {
  const modalRight = useCallback(() => {
    return <ItemDetail nft={nft} />
  }, [allowedSlippage, onAcceptChanges, recipient])

  const modalLeft = useCallback(() => {
    return nft ? (
      <HeroItem
        onSellClick={onSellClick}
        onWithdrawClick={onWithDrawClick}
        onUpgradeStarClick={onUpgradeStarClick}
        disabledConfirm={false}
        swapErrorMessage={swapErrorMessage}
        nft={nft}
      />
    ) : null
  }, [onSellClick, swapErrorMessage])

  // text to show while loading
  const pendingText = <Trans>Canceling sell item...</Trans>

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <BuyModalContent onDismiss={onDismiss} leftContent={modalLeft} rightContent={modalRight} />
      ),
    [onDismiss, modalRight, modalLeft, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
