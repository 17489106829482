import React from 'react'
import styled from 'styled-components'
import Text, { MagraText } from 'components/Text'
import UnlockButton from './UnlockButton'
import Footer from 'components/Footer'

const filterBg = 'https://plantempires-media.b-cdn.net/background/filter-bg.png'

const UnAuthorizedContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 500px;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: calc(100vh - 61px);
`

export default function UnAuthorized() {
  return (
    <>
      <UnAuthorizedContainer>
        <MagraText fontSize={35} fontWeight={600}>
          NOTHING HERE YET...
        </MagraText>
        <Text fontSize={24} fontWeight={400}>
          Please connect your wallet to activate this page
        </Text>
        <UnlockButton />
      </UnAuthorizedContainer>
      <Footer />
    </>
  )
}
